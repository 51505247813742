export const formValues = {
    iin: '',
    spec: '',
    ipc_ppbu_fig_ref: '',
    quantity: null,
    part_number: '',
    description:null,
    section:'',
    serial_number: '',
    remarks: null,
    installed: "Yes",
    attachments: [],
    tsn:null,
    csn:null,
    tso: null,
    cso: null,
    condition: null,
    checklist: [],
    folder_uuid: '',
};

export const taskErrorCode = {
    description: {
        0: "",
        1: "Required",
    },
    description_obj: {
        required: true,
    },
};