import React, { useState } from "react";
import { TableCell, TableRow, Tooltip, IconButton } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import RevisionHistory from '../../../shared_elements/components/RevisionHistory';

const ConfigureListing = ({item,toggleModalFn, toggleDelteModalFn }) => {

    let timezone = moment().format()
    const timestamp = Math.floor(Date.now() / 1000);

    const Configure_url = `audit/camo/disclemerrevision/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`
    return (
        <>
            <TableRow >
                <TableCell>{item?.revision_number || '--'}</TableCell>
                <TableCell>{item?.engine_family?.name || '--'}</TableCell>
                <TableCell>{item?.engine_type?.map((item) => item?.name ? item?.name + ',' : '') || '--'}</TableCell>
                <TableCell>{item?.disclemer_message ? item?.disclemer_message : '--'}</TableCell>
                <TableCell>{item?.status ? "Link" : '--'}</TableCell>
                <TableCell >
                    <Tooltip title="Edit" placement='top'>
                        <IconButton style={{ padding: 'unset' }} color='primary' onClick={toggleModalFn} >
                            <CreateOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" placement='top' >
                        <IconButton style={{ padding: 'unset' }} color='secondary' onClick={toggleDelteModalFn}>
                            <DeleteOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <RevisionHistory buttonType={true} url={Configure_url} />
                </TableCell>

            </TableRow>
        </>
    )
}

export default withRouter(ConfigureListing)