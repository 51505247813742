import { React, useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat,fieldDateFormatShort } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus, getLocalStorageInfo } from '../../../../utils';
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { adObj } from '.';
import { assetType, imgStoragePath } from "../../../../constants";
import { smbcInstance, specificKey, specificMessTsn, specificMessCsn } from '../..';


function AddEditAd({ match, toggleModalFn, adData, basicdetail, modeType, getResponseBack, list }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(adData ? adData : adObj)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
    const [linkFile, setlinkFile] = useState({ modal: false, data: null })
    const [sbNumber, setSbNumber] = useState([])

    useEffect(() => {
        sbNumberDropDown()
    }, [])

    const onFieldChange = (keyParam, value) => {
        setData({ ...data, [keyParam]: value });
    }

    const uploadFileData = (file) => {
        setData({ ...data, checklist: file })
    }

    const getData = (selectedFile, type) => {
        setData({ ...data, checklist: selectedFile, linkFile: type });
    }
    const sbNumberDropDown = () => {
        globalGetService(`/camo/aird/${match.params.asset}/${match.params.assetType}/sb_dropdown_list/`)
            .then((response) => {
                if (response.status === 200) {
                    const fetchedData = response?.data?.sb_number || [];
                    const validData = fetchedData.map(item => (
                        typeof item === 'object' && item !== null && 'name' in item ? item : { name: '' }
                    ));
                    setSbNumber(validData.filter(item => item?.name !== null));
                }
            })
            .catch((error) => {
                console.error("Error fetching SB numbers:", error);
            });
    };

    const uploadFileFn = (file, uuid, type) => {
        if (uuid) {
            let storeChecklistData = [];
            let folderUuid = [];

            const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
            const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

            for (const arr of updatedChecklist) {
                storeChecklistData = storeChecklistData.concat(arr);
            }
            for (const arr of folderUUID) {
                folderUuid = folderUuid.concat(arr);
            }
            setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
        } else {
            if (Array.isArray(file)) {
                const updatedAttachments = [...(data?.attachments || []), ...file];
                setData({ ...data, 'attachments': updatedAttachments });
            } else {
                console.error("Value for attachments is not an array:", file);
            }
        }
    }
    const addUploadFile = (item) => {
        if (data?.linkFile === "link") {
            let payload = {};
            if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            } else if (data?.checklist.some(item => item.type === 'folder')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
            } else {
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            }
            globalPutService(`camo/aird/${item?.id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        toggleModalFn()
                        getResponseBack(response.data.data);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0) {
            if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
                let formData = new FormData();
                formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
                for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
                    formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
                }
                globalPutService(`camo/aird/${item?.id}/document-checklists/`, formData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    });
            }
        }

    }
    const importFile = (file) => {
        var formData = new FormData();
        Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
            formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
            formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
            formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
            formData.append("[" + key + "]module_id", file?.id)
            formData.append("[" + key + "]module_type_id", 4)
            formData.append("[" + key + "]asset_type_id", match.params.assetType)
            formData.append("[" + key + "]asset_id", match.params.asset)
        })
        globalFileUploadService(`camo/attachment/upload/`, formData)
            .then(response => {
                toggleModalFn(); getResponseBack()
                if (checkApiStatus(response)) {
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })

    }
    const onRemoveFile = (file, type, fileId, folder) => {
        if (modeType === "add") {
            if (type === "checklist") {
                setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
            } else {
                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
            }
        } else {
            if (type === "checklist") {
                let payload = {};
                payload.delete = true;
                payload.folder_ids = data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
                payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
                globalPutService(`camo/aird/${data.id}/document-checklists/`, payload)
                    .then((response) => {
                        if (checkApiStatus(response)) {
                            const updatedChecklist = { ...data.checklist };
                            updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                            updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
                            setData({ ...data, checklist: updatedChecklist });
                            getResponseBack()
                        }
                    });
            } else {
                if (file.id) {
                    globalDeleteService(`camo/attachment/${file.id}/delete/`)
                        .then((response) => {
                            if (checkApiStatus(response)) {
                                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                                getResponseBack()
                            }
                        });
                } else {
                    setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                }
            }
        }
    }

    const onAddNewAsset = () => {
        let validationInputs = {}
        if (!smbcInstance) {
            validationInputs = {
                ...validationInputs,
                ata: data?.ata ? '' : "Please Enter ATA Chapter",
                ad_no: data?.ad_no ? '' : "Please Enter AD Number",
                description: data?.description ? '' : "Please Enter Description",
                effective_date: moment(data.effective_date).isValid() ? '' : "Please Select Effective Date",
                type: data.type ? '' : "Please Enter Type",
                ad_compliance_status: smbcInstance ? data.ad_compliance_status ? '' : "Please Select Compliance Status" : '',
                ref_documents: data.ref_documents ? '' : "Please Enter Ref Documents",
                last_done_date: data.last_done_date || data.ad_compliance_status === "NOT APPLICABLE" ? '' : "Please Select Last Done Date",
                ad_compliance_dfp: data.ad_compliance_dfp ? '' : "Please Select Complaince",
                workorder: data.ad_compliance_status === "NOT APPLICABLE" ? '' : data.workorder ? '' : "Please Enter Workorder",
                dimension_interval_days: data.dimension_type === '' || data.dimension_type === null || data.dimension_type === 'D' || data.dimension_type === 'FC' || data.dimension_type === 'FH' || data.dimension_type === 'FC,FH' || data.dimension_type === 'FC,D' || data.dimension_type === 'FC,FH,D' ? '' : "Please Enter Dimension Interval",
            }
        }
        if (smbcInstance) {
            validationInputs = {
                ad_compliance_status: data?.ad_compliance_status ? '' : "Please select AD Compliance Status",
            }
        }

        if (!smbcInstance && data.ad_compliance_status == "COMPLIED") {
            if (typeof data.ad_compliance_tsn === 'string' && !specificKey.includes(data.ad_compliance_tsn.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.ad_compliance_tsn))  {
                validationInputs.ad_compliance_tsn = specificMessTsn;
            }
            if (typeof data.ad_compliance_csn === 'string' && !specificKey.includes(data.ad_compliance_csn.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.ad_compliance_csn))  {
                validationInputs.ad_compliance_csn = specificMessCsn;
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/aird/${payload.id}/update/`, {...payload,dimension_interval_days:payload?.dimension_interval_days || null})
                    .then(response => {
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                globalPostService(`camo/aird/create/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType })
                    .then(response => {
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            addUploadFile(response?.data?.data)
                            importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })

            }
        } else {
            setError(validationInputs)
        }
    }


    const remainingVal = () => {
        let date = new Date()
        const lDate = moment(data.last_done_date)
        const todayD = moment(date)
        let remDay = lDate.diff(todayD, "days")
        if (data.dimension_type === '' || null) {
            // const dueDate = new Date(data.due_date);
            // const differenceInMilliseconds = dueDate - date;
            // const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
            data.remaining_val = '' 
        }
        if (data.dimension_type === "D") {
            data.remaining_val = moment(remDay).add(data.dimension_interval_days)
        } else if (data.dimension_type === "FC") {
            data.remaining_val = Number.isInteger(data.ad_due - basicdetail?.csn) && data.ad_due - basicdetail?.csn
        } else if (data.dimension_type === "FH") {
            data.remaining_val = Number.isInteger(data.ad_due - basicdetail?.tsn) && (data.ad_due - basicdetail?.tsn)
        } else if (data.dimension_type === "FC,D") {
            const fcRem_val = Number.isInteger(data.ad_due - basicdetail?.csn) && data.ad_due - basicdetail?.csn
            const dRem_val = moment(remDay).add(data.dimension_interval_days)
            data.remaining_val = fcRem_val < moment(dRem_val, "DD-MM-YYYY") ? fcRem_val : dRem_val;
        } else if (data.dimension_type === "FH,D") {
            const fhRem_val = Number.isInteger(data.ad_due - basicdetail?.tsn) && data.ad_due - basicdetail?.tsn
            const dRem_val = moment(remDay).add(data.dimension_interval_days)
            data.remaining_val = fhRem_val < moment(dRem_val, "DD-MM-YYYY") ? fhRem_val : dRem_val;
        } else if (data.dimension_type === "FC,FH,D") {
            const fcRem_val = Number.isInteger(data.ad_due - basicdetail?.csn) && data.ad_due - basicdetail?.csn
            const fhRem_val = Number.isInteger(data.ad_due - basicdetail?.tsn) && data.ad_due - basicdetail?.tsn
            const dRem_val = moment(remDay).add(data.dimension_interval_days)

            if (fhRem_val <= fcRem_val && fhRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
                data.remaining_val = fhRem_val;
            } else if (fcRem_val <= fhRem_val && fcRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
                data.remaining_val = fcRem_val;
            } else {
                data.remaining_val = dRem_val;
            }
        } else if (data.dimension_type === "FC,FH") {
            const fcRem_val = Number.isInteger(data.ad_due - basicdetail?.csn) && data.ad_due - basicdetail?.csn
            const fhRem_val = Number.isInteger(data.ad_due - basicdetail?.tsn) && data.ad_due - basicdetail?.tsn
            data.remaining_val = fcRem_val < fhRem_val ? fcRem_val : fhRem_val;
        }
        return data.remaining_val

    }

    const nextDueValue = () => {
        if (data.dimension_type === "D") {
            data.ad_due =data.dimension_interval_days ? moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(fieldDateFormatShort) :''
        } else if (data.dimension_type === "FC") {
            data.ad_due = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && +data.last_done_fc + +data.dimension_interval_fc
        } else if (data.dimension_type === "FH") {
            data.ad_due = Number.isInteger(+data.last_done_fh + +data.dimension_interval) && +data.last_done_fh + +data.dimension_interval
        } else if (data.dimension_type === "FC,D") {
            const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && +data.last_done_fc + +data.dimension_interval_fc;
            const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(fieldDateFormatShort);
            data.ad_due = fcDue < moment(dateDue, "DD-MM-YYYY") ? fcDue : dateDue;
        } else if (data.dimension_type === "FH,D") {
            const fhDue = Number.isInteger(+data.last_done_fh + +data.dimension_interval) && +data.last_done_fh + +data.dimension_interval;
            const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(fieldDateFormatShort);
            data.ad_due = fhDue < moment(dateDue, "DD-MM-YYYY") ? fhDue : dateDue;
        } else if (data.dimension_type === "FC,FH,D") {
            const fhDue = Number.isInteger(+data.last_done_fh + +data.dimension_interval) ? +data.last_done_fh + +data.dimension_interval : (+data.last_done_fh + +data.dimension_interval).toFixed(2);
            const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) ? +data.last_done_fc + +data.dimension_interval_fc : (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2);
            const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format(fieldDateFormatShort);

            if (fhDue <= fcDue && fhDue <= moment(dateDue, "DD-MM-YYYY")) {
                data.ad_due = fhDue;
            } else if (fcDue <= fhDue && fcDue <= moment(dateDue, "DD-MM-YYYY")) {
                data.ad_due = fcDue;
            } else {
                data.ad_due = dateDue;
            }
        } else if (data.dimension_type === "FC,FH") {
            const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && +data.last_done_fc + +data.dimension_interval_fc;
            const fhDue = Number.isInteger(+data.last_done_fh + +data.dimension_interval) && +data.last_done_fh + +data.dimension_interval;
            data.ad_due = fcDue < fhDue ? fcDue : fhDue;
        }
        return data.ad_due
    }
      console.log("data",data)
    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Update Airworthiness Directives (ADs)" : " Add Airworthiness Directives (ADs)"} </DialogTitle>
                <DialogContent dividers>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="ATA Chapter"
                                margin='normal'
                                id="ata"
                                fullWidth
                                value={data?.ata}
                                required={!smbcInstance}
                                error={error?.ata ? true : false}
                                InputLabelProps={{ shrink: true }}
                                helperText={error?.ata ? error?.ata : ""}

                                onChange={(e) => { onFieldChange("ata", e.target.value); setError({ ...error, 'ata': '' }) }}
                            />
                        </Grid>
                        {smbcInstance ? <>
                            <Grid item xs={12} md={3} sm={3}>
                                <TextField
                                    variant="outlined"
                                    margin='normal'
                                    label="FAA AD"
                                    id="faa_ad"
                                    fullWidth
                                    value={data?.faa_ad}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => onFieldChange("faa_ad", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} sm={3}>
                                <TextField
                                    variant="outlined"
                                    margin='normal'
                                    label="EASA AD"
                                    id="easa_ad"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    value={data?.easa_ad}
                                    onChange={(e) => onFieldChange("easa_ad", e.target.value)}
                                />
                            </Grid>
                        </>
                            :
                            <>
                                <Grid item xs={12} md={3} sm={3}>
                                    <TextField
                                        variant="outlined"
                                        margin='normal'
                                        label="AD Reference Number"
                                        id="ad_no"
                                        fullWidth
                                        value={data?.ad_no}
                                        required={!smbcInstance}
                                        error={error?.ad_no ? true : false}
                                        helperText={error?.ad_no ? error?.ad_no : ""}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => { onFieldChange("ad_no", e.target.value); setError({ ...error, 'ad_no': '' }) }}
                                    />

                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <TextField
                                        variant="outlined"
                                        label="AD Applicablity"
                                        name="applicability"
                                        fullWidth
                                        margin='normal'
                                        value={data.applicability}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e, value) => onFieldChange("applicability", e.target.value)}
                                    />
                                </Grid>
                            </>
                        }
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                label="Issuing Authority"
                                variant="outlined"
                                margin='normal'
                                fullWidth
                                id="issuing_authority"
                                value={data?.issuing_authority}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("issuing_authority", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {smbcInstance && <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="FAA Superseded"
                                margin='normal'
                                fullWidth
                                name="faa_superseded"
                                value={data?.faa_superseded}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("faa_superseded", e.target.value)}

                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="EASA Superseded"
                                name="easa_superseded"
                                margin='normal'
                                fullWidth
                                value={data?.easa_superseded}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("easa_superseded", e.target.value)}

                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Related Document"
                                margin='normal'
                                name="related_document"
                                fullWidth
                                value={data?.related_document}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("related_document", e.target.value)}

                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="AD Applicablity"
                                name="applicability"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.applicability}
                                onChange={(e, value) => onFieldChange("applicability", e.target.value)}

                            />
                        </Grid>
                    </Grid>}
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={12} sm={12}>
                            <TextField
                                label="Description"
                                variant="outlined"
                                name="description"
                                margin='normal'
                                fullWidth
                                multiline
                                minRows={5}
                                required={!smbcInstance}
                                value={data?.description}
                                InputLabelProps={{ shrink: true }}
                                error={error?.description ? true : false}
                                helperText={error?.description ? error?.description : ""}
                                onChange={(e) => { onFieldChange("description", e.target.value); setError({ ...error, 'description': '' }) }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="ad_type"
                                options={match.params.assetType == 1 ? ['Airframe', 'Engine', 'Appliance'] : ['Engine']}
                                getOptionLabel={option => option}
                                value={data?.ad_type || null}
                                margin='normal'
                                onChange={(e, value) => onFieldChange("ad_type", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="AD Type"
                                        margin='normal'
                                        placeholder="Select AD Type"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>

                        {smbcInstance ? null : <><Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="sb_number"
                                options={sbNumber || []}
                                getOptionLabel={(option) => { return option?.name || ''; }}
                                value={data?.sb_number || []}
                                margin="normal"
                                multiple={true}
                                onChange={(e, value) => onFieldChange("sb_number", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="SB Number"
                                        margin="normal"
                                        placeholder="Select SB Number"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                            <Grid item xs={12} md={3} sm={3}>
                                <TextField
                                    variant="outlined"
                                    label="SB Issue Number"
                                    name="sb_issue_number"
                                    id="sb_issue_number"
                                    fullWidth
                                    margin='normal'
                                    inputProps={{maxLength:50}}
                                    value={data?.sb_issue_number}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => onFieldChange("sb_issue_number", e.target.value)}
                                />
                            </Grid>
                        </>}
                        {smbcInstance && <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Type"
                                id="type"
                                fullWidth
                                margin='normal'
                                required={!smbcInstance}
                                InputLabelProps={{ shrink: true }}
                                error={error?.type ? true : false}
                                helperText={error?.type ? error?.type : ""}
                                value={data?.type}
                                onChange={(e) => { onFieldChange("type", e.target.value); setError({ ...error, 'type': '' }) }}

                            />
                        </Grid>}
                        {smbcInstance ?
                            <>
                                <Grid item xs={12} md={3} sm={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            label="FAA Effective Date"
                                            inputVariant="outlined"
                                            margin='normal'
                                            format={fieldDateFormatShort}
                                            fullWidth
                                            disableFuture
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment().subtract(25, "years")}
                                            error={false}
                                            helperText={""}
                                            value={data?.faa_effective_date || null}
                                            onChange={(data) => onFieldChange("faa_effective_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} md={3} sm={3}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <KeyboardDatePicker
                                            label="EASA Effective Date"
                                            inputVariant="outlined"
                                            format={fieldDateFormatShort}
                                            fullWidth
                                            disableFuture
                                            margin='normal'
                                            InputLabelProps={{ shrink: true }}
                                            minDate={moment().subtract(25, "years")}
                                            error={false}
                                            helperText={""}
                                            value={data?.easa_effective_date || null}
                                            onChange={(data) => onFieldChange("easa_effective_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </>
                            :
                            <Grid item xs={12} md={3} sm={3} >
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        label="Effective Date"
                                        id="effective_date"
                                        margin='normal'
                                        inputVariant="outlined"
                                        required={!smbcInstance}
                                        format={fieldDateFormatShort}
                                        fullWidth
                                        disableFuture
                                        InputLabelProps={{ shrink: true }}
                                        minDate={moment().subtract(25, "years")}
                                        error={error?.effective_date ? true : false}
                                        helperText={error?.effective_date ? error?.effective_date : ""}
                                        value={data?.effective_date || null}
                                        onChange={(data) => { onFieldChange("effective_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null); setError({ ...error, 'effective_date': '' }) }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>}
                    </Grid>
                    <Grid spacing={1} container>
                        {smbcInstance ? <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="sb_number"
                                options={sbNumber}
                                getOptionLabel={(option) => { return option?.name || ''; }}
                                value={data?.sb_number || []}
                                margin="normal"
                                multiple={true}
                                onChange={(e, value) => onFieldChange("sb_number", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="SB Number"
                                        margin="normal"
                                        placeholder="Select SB Number"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid> : null}
                        {smbcInstance ?
                            <Grid item xs={12} md={3} sm={3}>
                                <TextField
                                    variant="outlined"
                                    label="SB Issue Number"
                                    name="sb_issue_number"
                                    id="sb_issue_number"
                                    fullWidth
                                    margin='normal'
                                    value={data?.sb_issue_number}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => onFieldChange("sb_issue_number", e.target.value)}
                                />
                            </Grid> : null}
                        {smbcInstance ? <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Reference"
                                name="reference"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data?.reference}
                                onChange={(e) => onFieldChange("reference", e.target.value)}
                            />
                        </Grid> : null}
                        {smbcInstance ? <Grid item xs={12} md={3} sm={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    label="Date of compliance"
                                    // label={<> Date of compliance {data?.ad_compliance_status !== "COMPLIED" ? null : (smbcInstance ? null : <span style={{ color: "red" }}> *</span>)} </>}
                                    id="ad_compliance_date"
                                    format={fieldDateFormatShort}
                                    inputVariant="outlined"
                                    fullWidth
                                    margin='normal'
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    error={error?.ad_compliance_date ? true : false}
                                    helperText={error?.ad_compliance_date ? error?.ad_compliance_date : ""}
                                    value={data?.ad_compliance_date || null}
                                    onChange={(data) => { onFieldChange("ad_compliance_date", moment(data).format(backendDateFormat)); setError({ ...error, 'ad_compliance_date': '' }) }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid> : null}
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="A/C Comp"
                                id="ac_comp"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data?.ac_comp}
                                onChange={(e) => onFieldChange("ac_comp", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="ad_compliance_status"
                                options={['OPEN', 'COMPLIED', 'PARTIALLY COMPLIED', 'NOT APPLICABLE', 'SUPERSEDED']}
                                getOptionLabel={option => option}
                                margin='normal'
                                value={data?.ad_compliance_status || null}
                                onChange={(e, value) => { onFieldChange("ad_compliance_status", value); setError({ ...error, 'ad_compliance_status': '' }) }}
                                renderInput={(params) => (
                                    <TextField
                                        required={smbcInstance}
                                        {...params}
                                        label="Compliance Status"
                                        placeholder="Select Compliance Status"
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                        error={error?.ad_compliance_status ? true : false}
                                        helperText={error?.ad_compliance_status ? error?.ad_compliance_status : ""}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        AD Compliance TSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="ad_compliance_tsn"
                                fullWidth
                                margin='normal'
                                disabled={data?.ad_compliance_status === "NOT APPLICABLE"}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={data?.ad_compliance_tsn}
                                error={error.ad_compliance_tsn ? true : false}
                                helperText={error.ad_compliance_tsn ? error.ad_compliance_tsn : ""}
                                onChange={(e) => { onFieldChange("ad_compliance_tsn", e.target.value); setError({ ...error, 'ad_compliance_tsn': '' }) }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        AD Compliance CSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                name="ad_compliance_csn"
                                id="ad_compliance_csn"
                                fullWidth
                                margin='normal'
                                disabled={data?.ad_compliance_status === "NOT APPLICABLE"}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                error={error.ad_compliance_csn ? true : false}
                                helperText={error.ad_compliance_csn ? error.ad_compliance_csn : ""}
                                value={data?.ad_compliance_csn}
                                onChange={(e) => { onFieldChange("ad_compliance_csn", e.target.value); setError({ ...error, 'ad_compliance_csn': '' }) }}
                            />
                        </Grid>

                    </Grid>

                    <Grid container spacing={1}  >
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Effectivity"
                                name="effectivity"
                                id="effectivity"
                                fullWidth
                                margin='normal'
                                value={data?.effectivity}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("effectivity", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={smbcInstance ? 12 : 3} sm={smbcInstance ? 12 : 3}>
                            <TextField
                                variant="outlined"
                                label="Reference Documents"
                                id="ref_documents"
                                margin='normal'
                                required={!smbcInstance}
                                error={error?.ref_documents ? true : false}
                                helperText={error?.ref_documents ? error?.ref_documents : ""}
                                multiline
                                fullWidth
                                minRows={5}
                                InputLabelProps={{ shrink: true }}
                                value={data?.ref_documents}
                                onChange={(e) => { onFieldChange("ref_documents", e.target.value); setError({ ...error, 'ref_documents': '' }) }}
                            />
                        </Grid>
                        {!smbcInstance ? <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Type"
                                id="type"
                                fullWidth
                                margin='normal'
                                required={!smbcInstance}
                                InputLabelProps={{ shrink: true }}
                                error={error?.type ? true : false}
                                helperText={error?.type ? error?.type : ""}
                                value={data?.type}
                                onChange={(e) => { onFieldChange("type", e.target.value); setError({ ...error, 'type': '' }) }}

                            />
                        </Grid> : null}
                        {!smbcInstance ? <Grid item xs={12} md={3} sm={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    label="Date of compliance"
                                    // label={<> Date of compliance {data?.ad_compliance_status !== "COMPLIED" ? null : (smbcInstance ? null : <span style={{ color: "red" }}> *</span>)} </>}
                                    id="ad_compliance_date"
                                    format={fieldDateFormatShort}
                                    inputVariant="outlined"
                                    fullWidth
                                    margin='normal'
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    error={error?.ad_compliance_date ? true : false}
                                    helperText={error?.ad_compliance_date ? error?.ad_compliance_date : ""}
                                    value={data?.ad_compliance_date || null}
                                    onChange={(data) => { onFieldChange("ad_compliance_date", moment(data).format(backendDateFormat)); setError({ ...error, 'ad_compliance_date': '' }) }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid> : null}
                    </Grid>
                    <p style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>Last Done</p>
                    <Grid container spacing={1}  >
                        <Grid item xs={12} md={3} sm={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required={!smbcInstance}
                                    label="Last Done Date"
                                    id="last_done_date"
                                    format={fieldDateFormatShort}
                                    inputVariant="outlined"
                                    fullWidth
                                    margin='normal'
                                    disableFuture
                                    disabled={data.ad_compliance_status === "NOT APPLICABLE"}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    error={error.last_done_date ? true : false}
                                    helperText={error.last_done_date ? error.last_done_date : ""}
                                    value={data.last_done_date}
                                    onChange={(data, value) => {
                                        onFieldChange("last_done_date", moment(data).format(backendDateFormat))
                                        setError({ ...error, 'last_done_date': '' })
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} >
                            <TextField
                                variant="outlined"
                                label="Last Done FH"
                                fullWidth
                                margin='normal'
                                id="last_done_fh"
                                disabled={data.ad_compliance_status === "NOT APPLICABLE"}
                                value={data.last_done_fh}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 10 && regexConstants.onlyNumeric.test(input)) {
                                        onFieldChange("last_done_fh", input)
                                        setError({ ...error, 'last_done_fh': '' })
                                    } else if (input.length > 10 && regexConstants.onlyNumeric.test(input)) {
                                        const truncatedInput = input.slice(0, 10)
                                        onFieldChange("last_done_fh", truncatedInput)
                                        setError({ ...error, 'last_done_fh': '' })
                                    } else {
                                        e.preventDefault()
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} >
                            <TextField
                                variant="outlined"
                                label="Last Done FC"
                                fullWidth
                                margin='normal'
                                id="last_done_fc"
                                InputLabelProps={{ shrink: true }}
                                disabled={data.ad_compliance_status === "NOT APPLICABLE"}
                                value={data.last_done_fc}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 10 && regexConstants.onlyNumeric.test(input)) {
                                        onFieldChange("last_done_fc", input)
                                        setError({ ...error, 'last_done_fc': '' })
                                    } else if (input.length > 10 && regexConstants.onlyNumeric.test(input)) {
                                        const truncatedInput = input.slice(0, 10)
                                        onFieldChange("last_done_fc", truncatedInput)
                                        setError({ ...error, 'last_done_fc': '' })
                                    } else {
                                        e.preventDefault()
                                    }
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Workorder"
                                id="workorder"
                                margin='normal'
                                multiline
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                required={!smbcInstance}
                                disabled={data?.ad_compliance_status === "NOT APPLICABLE"}
                                error={error?.workorder ? true : false}
                                helperText={error?.workorder ? error?.workorder : ""}
                                value={data?.workorder}
                                onChange={(e) => { onFieldChange("workorder", e.target.value);  setError({ ...error, 'workorder': '' }) }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={6} sm={6} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>INTERVAL</Grid>
                        <Grid item xs={12} md={2} sm={2} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>Remaining</Grid>
                        <Grid item xs={12} md={4} sm={4} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>Next Due</Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="dimension_type"
                                options={['None', 'D', 'FH', 'FC', 'FC,D', 'FH,D', 'FC,FH,D', 'FC,FH']}
                                getOptionLabel={option => option}
                                margin='normal'
                                value={data?.dimension_type || null}
                                onChange={(e, value) => onFieldChange("dimension_type", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Dimension"
                                        placeholder="Select Dimension Interval"
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <Grid spacing={2} container>
                                {data.dimension_type == "FC" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH" || data.dimension_type == "FC,FH,D" ? <Grid
                                    item
                                    xs={data.dimension_type == "FC" ? 12 : data.dimension_type == "FC,D" || data.dimension_type == "FC,FH" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}
                                >
                                    <TextField
                                        variant="outlined"
                                        label={<span> {data.dimension_type == "FC" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH,D" || data.dimension_type == "FC,FH" ? "FC Interval" : "FC, Interval"}</span>}
                                        name="dimension_interval"
                                        fullWidth
                                        margin='normal'
                                        value={data.dimension_interval_fc}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 5 && regexConstants.onlyNumeric.test(e.target.value)) {
                                                onFieldChange("dimension_interval_fc", input)
                                                setError({ ...error, 'dimension_interval_fc': '' })
                                            } else if (input.length > 5 && regexConstants.onlyNumeric.test(input)) {
                                                const truncatedInput = input.slice(0, 5)
                                                onFieldChange("dimension_interval_fc", truncatedInput)
                                                setError({ ...error, 'dimension_interval_fc': '' })
                                            } else {
                                                e.preventDefault()
                                            }
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        error={error.dimension_interval_fc ? true : false}
                                        helperText={error.dimension_interval_fc ? error.dimension_interval_fc : ""}
                                    />
                                </Grid> : null}
                                {data.dimension_type == "FH" || data.dimension_type == "FH,D" || data.dimension_type == "FC,FH" || data.dimension_type == "FC,FH,D" ? <Grid
                                    item
                                    xs={data.dimension_type == "FH" ? 12 : data.dimension_type == "FH,D" || data.dimension_type == "FC,FH" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}
                                >
                                    <TextField
                                        variant="outlined"
                                        label={<span> {data.dimension_type == "FH" || data.dimension_type == "FH,D" || data.dimension_type == "FC,FH,D" || data.dimension_type == "FC,FH" ? "FH Interval" : "FH, Interval"} </span>}
                                        name="dimension_interval"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                        value={data.dimension_interval}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 5 && regexConstants.onlyNumeric.test(e.target.value)) {
                                                onFieldChange("dimension_interval", input)
                                                setError({ ...error, 'dimension_interval': '' })
                                            } else if (input.length > 5 && regexConstants.onlyNumeric.test(input)) {
                                                const truncatedInput = input.slice(0, 5)
                                                onFieldChange("dimension_interval", truncatedInput)
                                                setError({ ...error, 'dimension_interval': '' })
                                            } else {
                                                e.preventDefault()
                                            }
                                        }}
                                        error={error.dimension_interval ? true : false}
                                        helperText={error.dimension_interval ? error.dimension_interval : ""}
                                    />
                                </Grid> : null}
                                {data.dimension_type == "D" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH,D" ||
                                    data.dimension_type == "FH,D" ? (
                                    <Grid item xs={data.dimension_type == "D" ? 12 : data.dimension_type == "FC,D" || data.dimension_type == "FH,D" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}>
                                        <TextField
                                            variant="outlined"
                                            // required={!smbcInstance}
                                            label="Days"
                                            name="dimension_interval_days"
                                            fullWidth
                                            margin='normal'
                                            value={data.dimension_interval_days}
                                            onChange={(e, value) => {
                                                const input = e.target.value
                                                if (
                                                    input.length <= 5 &&
                                                    regexConstants.onlyNumeric.test(e.target.value)
                                                ) {
                                                    onFieldChange("dimension_interval_days", input)
                                                    setError({ ...error, 'dimension_interval_days': '' })
                                                } else if (
                                                    input.length > 5 &&
                                                    regexConstants.onlyNumeric.test(input)
                                                ) {
                                                    const truncatedInput = input.slice(0, 5)
                                                    onFieldChange("dimension_interval_days", truncatedInput)
                                                    setError({ ...error, 'dimension_interval_days': '' })
                                                } else {
                                                    e.preventDefault()
                                                }
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            error={error.dimension_interval_days ? true : false}
                                            helperText={error.dimension_interval_days ? error.dimension_interval_days : ""}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={2} sm={2}>
                            <TextField
                                variant="outlined"
                                label={data.dimension_type === "D" ? "Days" : data.dimension_type === "FC" ? "FC" : data.dimension_type === "FH" ? "FH" :
                                    data.dimension_type === "FH,D" ? "FH/Days" : data.dimension_type === "FC,D" ? "FC/Days" : data.dimension_type === "FC,FH,D" ? "FC/FH/Days" : data.dimension_type === "FC,FH" ? "FC/FH" : "Days"}
                                name="remaining_val"
                                fullWidth
                                margin='normal'
                                value={remainingVal()}
                                inputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                disabled
                                className="disabled-input"
                            />
                        </Grid>
                        <Grid item xs={12} md={2} sm={2}>
                            {data.dimension_type === '' || data.dimension_type === null ?
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        label='Date'
                                        keyParam='due_date'
                                        format={fieldDateFormatShort}
                                        inputVariant="outlined"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                        value={data.due_date || null }
                                        onChange={(value) => { onFieldChange("due_date", moment(value).format(backendDateFormat)) }}
                                    />
                                </MuiPickersUtilsProvider> :
                                <TextField
                                    variant="outlined"
                                    label={data.dimension_type === "D" ? "Date" : data.dimension_type === "FC" ? "FC" : data.dimension_type === "FH" ? "FH" : data.dimension_type === "FH,D" ? "FH/Date" :
                                        data.dimension_type === "FC,D" ? "FC/Date" : data.dimension_type === "FC,FH,D" ? "FC/FH/Date" : data.dimension_type === "FC,FH" ? "FC/FH " : "Date"}
                                    fullWidth
                                    margin='normal'
                                    id="ad_due"
                                    value={nextDueValue()}
                                    onChange={(e, value) => onFieldChange("ad_due", e.target.value)}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    className="disabled-input"
                                />
                            }
                        </Grid>
                        <Grid item xs={12} md={2} sm={2}>
                            <TextField
                                variant="outlined"
                                label="Note"
                                fullWidth
                                margin='normal'
                                id="next_due_note"
                                value={data?.next_due_note || ''}
                                onChange={(e, value) => onFieldChange("next_due_note", e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                // className="disabled-input"
                            />
                        </Grid>

                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={4} sm={4}>
                            <FormControl>
                                <FormLabel component="legend">AD Compliance DFP {smbcInstance ? null : <span style={{ color: "red" }}> *</span>}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="ad_compliance_dfp"
                                    name="ad_compliance_dfp"
                                    defaultValue="top"
                                    value={data?.ad_compliance_dfp}
                                    onChange={(e) => { onFieldChange("ad_compliance_dfp", e.target.value);  setError({ ...error, 'ad_compliance_dfp': '' }) }}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                                {error?.ad_compliance_dfp && (
                                    <span style={{ color: "red", fontSize: "0.75rem" }}>
                                        {error?.ad_compliance_dfp}
                                    </span>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <FormControl>
                                <FormLabel component="legend">Release certificate</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="release_certificate"
                                    name="release_certificate"
                                    defaultValue="top"
                                    value={data?.release_certificate}
                                    onChange={(e) => onFieldChange("release_certificate", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <FormControl>
                                <FormLabel component="legend">Certificate of Conformity</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="conformity_crt"
                                    name="conformity_crt"
                                    defaultValue="top"
                                    value={data?.conformity_crt}
                                    onChange={(e) => onFieldChange("conformity_crt", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                label="Remarks"
                                margin='normal'
                                variant='outlined'
                                fullWidth
                                value={data.remark}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("remark", e.target.value)}
                                rows={3}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
                                <span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
                                <span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
                            </div>
                        </Grid>
                        <div className="checklist-file" style={{ overflow: "auto" }}>
                            {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
                            <ul className='list-inline'>
                                {(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
                                    return (
                                        <li className='list-inline-item'>
                                            <span className='file-name'>{attachments.name}</span>
                                            <span className='file-remove'>
                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
                                                <CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                            </span>
                                        </li>)
                                }

                                )}
                                {(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
                                    data.checklist?.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        {data?.checklist.some(item => item.type === 'folder') ? null
                                                            : <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.files?.length > 0) &&
                                    data.checklist?.files.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.folders?.length > 0) &&
                                    data.checklist?.folders.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                    <a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                </Tooltip>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {uploadFile.modal ?
                <UploadFile
                    modType="aird"
                    type="upload"
                    list={list}
                    actionType={modeType}
                    editFormId={data?.id}
                    handleClose={() => setUploadFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

                />
                : null}
            {linkFile.modal ?
                <LinkFiles
                    modType="aird"
                    type="link"
                    list={list}
                    editFormId={data?.id}
                    actionType={modeType}
                    uploadFileInfo={data}
                    toggleModalFn={() => setlinkFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    onLinkFile={getData}
                />
                : null
            }
            {isLoading ? <PageLoader /> : null}
        </>
    )

}
export default withRouter(AddEditAd)