import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, FormControl, InputLabel, MenuItem, Select, FormLabel, FormControlLabel, Radio, Dialog, TextareaAutosize,Tooltip } from "@material-ui/core"
import RadioGroup from "@material-ui/core/RadioGroup"
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from "@material-ui/icons/Close"
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { fieldValidation } from "../../../../utils/formValidation";
import { formValues, taskErrorCode } from "../containers/ModTaskFormData";
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers";
import moment from "moment";
import axios from 'axios';
import { getLocalStorageInfo } from "../../../../utils";
import MomentUtils from "@date-io/moment";
import { backendDateFormat, dayMonthDateFormat } from "../../../../constants";
import { addModsFormApi, deleteModsAttachment } from "../apiServices";
import ModsConfirmDeleteAttachment from "./ModsConfirmDeleteAttachment";
import { regexConstants } from "../../../../constants/regEx";
import {checkApiStatus } from "../../../../utils";
import { trackActivity } from '../../../../utils/mixpanel';
import { globalPutService } from "../../../../utils/globalApiServices";
import LinkFiles from "../../../../shared_elements/components/LinkFiles";
import UploadFile from "../../../../shared_elements/components/UploadFile";
import { assetType, imgStoragePath } from "../../../../constants";
import config from "../../../../config";

class ModsTaskForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			error: {},
			data: formValues,
			regex: "^[0-9]+$",
			numberError: false,
			selectLastDDate: null,
			selectNextDueDate: null,
			TSN: 30179,
			CSN: 5,
			attachments: [],
			newUploadedAttachments: [],
			remainingValue: "",
			modsDue: null,
			btnStatus: "",
			open: false,
			deleteAttachmentId: 0,
			globalValidationInputs: {},
			validatedFields: [],
			linkFile: false,
			uploadFile: false
		}

		this.addModsFormApi = addModsFormApi.bind(this)
		this.dropDownChange = this.dropDownChange.bind(this)
		this.dropDownChange1 = this.dropDownChange1.bind(this)
		this.dropDownChange2 = this.dropDownChange2.bind(this)
	}

	componentDidMount = () => {
		if (this.props.editFormId) {
			this.fillEditData()
		}
		trackActivity('CAMO', {
			event_type: 'Modal Opened',
			page_title: 'Mods Add Task Form',
		})
	}
	handleOpenFile = () => {
		this.setState({ linkFile: true })
	};
	handleCloseFile = () => {
		this.setState({ linkFile: false })
	};
	handleOpenUploadFile = () => {
		this.setState({ uploadFile: true })
	};
	handleUploadFileCloseFile = () => {
		this.setState({ uploadFile: false })
	};

	dropDownChange1(e, keyParam, value) {
		//set selection to the value selected
		this.setState(
			(prevState) => (
				{
					...prevState,
					data: {
						...prevState.data,
						type: e.target.value,
					},
				}
			)
		)
	}
	dropDownChange2(e, keyParam, value) {
		//set selection to the value selected
		this.setState(
			(prevState) => (
				{
					...prevState,
					data: {
						...prevState.data,
						mod_interval_type: e.target.value,
					},
				}
			)
		)
	}

	dropDownChange(e, index, value) {
		//set selection to the value selected
		this.setState(
			(prevState) => (
				{
					...prevState,
					data: {
						...prevState.data,
						mod_compliance_status: e.target.value,
					},
				}
			)
		)
	}

	onFieldChange = (keyParam, value) => {
		const { data } = this.state

		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				[keyParam]: value,
			},
		}))

		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				remaining_val: this.state.remainingValue,
			},
		}))

		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				mods_DUE: this.state.modsDue,
			},
		}))
	}

	onRestErrorKey = (keyParam) => {
		this.setState((prevState) => ({
			...prevState,
			error: {
				...prevState.error,
				[keyParam]: "",
			},
		}))
	}
	handleSubmit = (e) => {
		e.preventDefault()

		let validationInputs = {}
		const { data } = this.state
		this.setState({ file: "" })
		if(!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')){
		validationInputs = {
			...validationInputs,
			ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
			mod_ref_number: taskErrorCode["mod_ref_number"][fieldValidation({ ...taskErrorCode["mod_ref_number_obj"], fieldval: data.mod_ref_number, })],
		}
	}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
				},
			}))

			// add task api
			const props = this.props.props
			const modsData = data
			modsData.asset_id = props.match.params.asset
			modsData.asset_type_id = props.match.params.assetType
			// Remove properties from the modsData object
			delete modsData.ad_due
			delete modsData.remaining_val
			modsData.last_done_fc =
				modsData && modsData.last_done_fc === "" ? null : modsData.last_done_fc
			modsData.last_done_fh =
				modsData && modsData.last_done_fh === "" ? null : modsData.last_done_fh
			addModsFormApi(modsData, props).then(async (response) => {
				const uploadId = response.data.data.id
				if (uploadId) {
					await this.uploadFileTest(uploadId, props, false)
					trackActivity('CAMO', {
						event_type: 'Task Added Successfully',
						page_title: 'Mods',
						item_id: uploadId ? uploadId : '',
						request_body: modsData ? modsData : '',
						response_msg: response.data.message ? response.data.message : ''
					  })
				}
			})
			if (this.state.btnStatus !== "addAnother") {
				this.setState((prevState) => ({
					error: {
						...prevState.error,
						dimension_interval: "",
					},
				}))
				this.props.closeAddForm()
			} else {
				this.setState((prevState) => ({
					error: {
						...prevState.error,
						dimension_interval: "",
					},
				}))
				return false
			}
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Add TASK Failed',
				page_title: 'Mods',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			  })
		}
	}

	// edit task form
	fillEditData = () => {
		const updateId = this.props.editFormId
		this.setState({ attachments: this.props.attachments })
		if (updateId === undefined || updateId === null) {
			this.setState({
				data: {},
			})
		} else {
			this.setState({
				data: this.props.editFormData,
			})
		}
	}

	onFocusValidation = (keyParam) => {
		let validationInputs = {}
		const { data } = this.state
		validationInputs = {
			...validationInputs,
			ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
			mod_ref_number: taskErrorCode["mod_ref_number"][fieldValidation({ ...taskErrorCode["mod_ref_number_obj"], fieldval: data.mod_ref_number, })],
		}
		for (let key in validationInputs) {
			if (key === keyParam) {
				this.state.globalValidationInputs[key] = validationInputs[key]
			}
		}
		if (Object.keys(this.state.globalValidationInputs).every((k) => { return this.state.globalValidationInputs[k] === "" })) {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
				},
			}))
			this.setState({ globalValidationInputs: {} })
		} else {
			this.setState({ error: this.state.globalValidationInputs })
		}
	}

	updateData = async (e) => {
		e.preventDefault()

		let validationInputs = {}
		const { data } = this.state
		this.setState({ file: "" })
		if(!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')){
		validationInputs = {
			...validationInputs,
			ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
			mod_ref_number: taskErrorCode["mod_ref_number"][fieldValidation({ ...taskErrorCode["mod_ref_number_obj"], fieldval: data.mod_ref_number, })],
		}
	}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === "" })) {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
				},
			}))
			const updateId = this.props.editFormId
			const updatedData = this.state.data
			delete updatedData.ad_due
			delete updatedData.remaining_val
			updatedData.last_done_fc = updatedData && updatedData.last_done_fc === "" ? null : updatedData.last_done_fc
			updatedData.last_done_fh = updatedData && updatedData.last_done_fh === "" ? null : updatedData.last_done_fh
			const props = this.props.props
			this.setState({ data: this.props.editFormData, })
			if (updateId) {
				await this.uploadFileTest(updateId, props, true)
			}
			await this.props.updateFormData(updateId, updatedData, props)
			this.setState((prevState) => ({
				error: {
					...prevState.error,
					dimension_interval: "",
				},
			}))
			this.props.closeAddForm()
			trackActivity('CAMO', {
				event_type: 'Task Updated Successfully',
				page_title: 'Mods',
				item_id: updateId ? updateId : '',
				request_body: updatedData ? updatedData : ''
			  })
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Update TASK Failed',
				page_title: 'Mods',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			  })
		}
	}

	uploadFileTest = async (uploadId, props, isUpdate) => {
		if (this.state.newUploadedAttachments.length > 0) {
			let newAttachmentObj = []
			let formdata = new FormData()
			const module_type_id = 6
			Object.keys(this.state.newUploadedAttachments).map((key, index) => {
				newAttachmentObj.push({
					file: this.state.newUploadedAttachments[key],
					file_name: this.state.newUploadedAttachments[key].name,
					file_type: this.state.newUploadedAttachments[key].type,
					module_type_id,
					module_id: uploadId,
					asset_id: this.props.props.match.params.asset,
					asset_type_id: this.props.props.match.params.assetType

				})
				formdata.append(
					"[" + key + "]file",
					this.state.newUploadedAttachments[key]
				)
				formdata.append(
					"[" + key + "]file_type",
					this.state.newUploadedAttachments[key].type
				)
				formdata.append(
					"[" + key + "]file_name",
					this.state.newUploadedAttachments[key].name
				)
				formdata.append("[" + key + "]module_id", uploadId)
				formdata.append("[" + key + "]module_type_id", module_type_id)
				formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
				formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
			})
			// uploadOccmFile(uploadId, formdata, props)
			this.props.fileUploadData(uploadId, formdata, props, true)
			if (this.state.btnStatus === "addAnother") {
				this.resetBothAttachmentArray()
			}
			if (!isUpdate) {
				this.onSubmitFileLink(uploadId)
				this.props.fileUploadData(uploadId, null, props, false)
			}
		} else {
			if (this.state.btnStatus === "addAnother") {
				this.setState((prevState) => ({
					...prevState,
					data: formValues,
					attachments: [],
				}))
				document.getElementById("asset-form").reset()
				this.props.fileUploadData(uploadId, null, props, false)
			} else {
				if (!isUpdate) {
					this.onSubmitFileLink(uploadId)
					this.props.fileUploadData(uploadId, null, props, false)
				}
			}
		}
	}

	resetBothAttachmentArray = () => {
		this.setState((prevState) => ({
			...prevState,
			data: formValues,
			attachments: [],
			newUploadedAttachments: [],
			open: false,
			deleteAttachmentId: 0,
		}))
		document.getElementById("asset-form").reset()
	}

	onUpload = (files) => {
		const chosenFiles = Array.prototype.slice.call(files)
		for (let i = 0; i < chosenFiles.length; i++) {
			if (chosenFiles[i].size > 52428800) {
				this.props.props.enqueueSnackbar(
					`${chosenFiles[i].name} Maximum file size should be 50MB.`,
					{
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					}
				)
				delete chosenFiles[i]
				continue
			} else {
				this.handleUploadFiles(chosenFiles)
				// document.querySelector(".MuiDialog-paper").scrollTop = 800;
				this.setState({
					showSuccessUpload: true,
				})
				setTimeout(() => {
					if (this.state.attachments) {
						this.setState({
							showSuccessUpload: false,
						})
					}
				}, 2500)
			}
		}
	}

	handleUploadFiles = (files) => {
		const uploaded = [...this.state.newUploadedAttachments]
		const existingAttachments = [...this.state.attachments]
		files.some((file) => {
			uploaded.push(file)
		})
		files.map((file) => {
			var objc = {
				id: 0,
				module_id: 2,
				module_type_id: 6,
				file_name: file.name,
				file_type: file.type,
				file: "",
				name: file.name,
			}
			existingAttachments.push(objc)
		})

		this.setState({
			newUploadedAttachments: uploaded,
			attachments: existingAttachments,
		})
	}

	handleClickAttachment = (e, id) => {
		this.setState({ open: true })
		this.setState({ deleteAttachmentId: id })
	}

	handleCloseAttachment = (e) => {
		this.setState({ open: false })
		this.setState({ deleteAttachmentId: 0 })
	}

	deleteAttachmentFile = async (id,file) => {
		if (id !== undefined && id != 0) {
			const props = this.props.props
			deleteModsAttachment(id, props)
			var array = [...this.state.attachments]
			array = array.filter((item) => item.id !== id)
			this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
		}else{
            this.onRemoveCamoFile(file)
        }
	}

	removeAttachment = (e, obj) => {
		var array = [...this.state.attachments]
		var newuploadarray = [...this.state.newUploadedAttachments]
		var filteredArray = []
		newuploadarray.map((file) => {
			if (file.name == obj.file_name && file.type == obj.file_type) {
			} else {
				filteredArray.push(file)
			}
		})
		array = array.filter((item) => item !== obj)
		this.setState({
			newUploadedAttachments: filteredArray,
			attachments: array,
		})
	}

	saveAndAddAnother = async (e) => {
		await this.setState({ btnStatus: "addAnother" })
		await this.handleSubmit(e)
	}

	addFormData = async (e) => {
		await this.setState({ btnStatus: "add" })
		this.handleSubmit(e)
	}

	getResponseBack = (file) => {
		const { data, } = this.state
		this.setState({
			data: {
				...data,
				checklist: file,
			},
		});
	}
	listResponseBack = (file) => {
		if (this.props.actionType === "add") {
			return null
		} else {
			this.props.getResponseBack(file)
		}
	}

	downloadFile = (file) => {
		axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file.name);
				document.body.appendChild(link);
				link.click();
			})
	}
	onUnlinkCheckListFile = (checklist, fileId) => {
		const { data } = this.state;
		let payload = {};
		payload.delete = true;
		payload.folder_ids = checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
		payload.file_ids = checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
		globalPutService(`camo/ht/${this.props.editFormId}/document-checklists/`, payload)
			.then((response) => {
				if (checkApiStatus(response)) {
					const updatedChecklist = { ...checklist };
					updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
					updatedChecklist.folders = updatedChecklist?.folders.filter((file) => file.id !== fileId);
					this.setState({
						data: {
							...data,
							checklist: updatedChecklist,
						},
					});
				}
				trackActivity('CAMO', {
					event_type: 'Checklist File Unlinked',
					page_title: 'Mods',
					file_id: fileId ? fileId : '',
					edited_form_id: this.props.editFormId ? this.props.editFormId : '',
					request_body: payload ? payload : ''
				  })
			});
	}
	onRemoveFile = (file) => {
		const { data } = this.state;
		this.setState({
			data: {
				...data,
				checklist: data.checklist.filter(item => item.name !== file.name)
			}
		});
	}
	onRemoveCamoFile = (file) => {
		const { attachments } = this.state;
		this.setState({
			attachments: attachments.filter(item => item.file_name !== file.file_name)
		});
	}

	onLinkFile = (file, uuid, type) => {
		if (type == "link" || uuid) {
			const { data } = this.state;
			const folderUUID = [...data.folder_uuid, uuid];
			const updatedChecklist = [...data.checklist, file];

			let uuidData = [];
			let output = [];

			for (const arr of folderUUID) {
				uuidData = uuidData.concat(arr);
			}

			for (const arr of updatedChecklist) {
				output = output.concat(arr);
			}
			this.setState({
				data: {
					...data,
					checklist: output,
					folder_uuid: uuidData
				},
			});
		} else {
			this.onUpload(file)
		}
	}

	onSubmitFileLink = (id) => {
		const { data } = this.state;
		if (data?.checklist?.length > 0 && data.checklist.map(item => item.id).filter(item => item)) {
			let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
			globalPutService(`camo/mods/${id}/document-checklists/`, payload)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
		if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
			let formData = new FormData();
			formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined));
			for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
				formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
			}
			globalPutService(`camo/mods/${id}/document-checklists/`, formData)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
	}

	render() {
		const { data, error, attachments } = this.state
		var date = new Date()
		const lDate = moment(data.last_done_date)
		const todayD = moment(date)
		let remDay = lDate.diff(todayD, "days")
		return (
			<Fragment>
				<div id="AmpTaskForm">
					<div className="mods" id="HtTaskForm">
						<DialogTitle>
							{this.props.formTitle}
							<CloseIcon
								onClick={() => { this.props.closeAddForm(); this.listResponseBack() }}
								className="close-icon"
							/>
						</DialogTitle>
						<form
							id="asset-form"
							onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
						>
							<DialogContent>
								<Grid spacing={1} container>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={<React.Fragment> ATA Chapter<span style={{ color: "red" }}> *</span> </React.Fragment>}
											name="ata_chapter"
											id="ata_chapter"
											value={data.ata_chapter}
											error={error.ata_chapter ? true : false}
											helperText={error.ata_chapter ? error.ata_chapter : ""}
											onChange={(e, value) => this.onFieldChange("ata_chapter", e.target.value)}
											inputProps={{ maxLength: 15 }}
											onFocus={() => this.onRestErrorKey("ata_chapter")}
											onBlur={() => this.onFocusValidation("ata_chapter")}
										/>
									</Grid>

									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={<React.Fragment> MOD Reference Number <span style={{ color: "red" }}> *</span> </React.Fragment>}
											name="mod_ref_number"
											id="mod_ref_number"
											value={data.mod_ref_number}
											error={error.mod_ref_number ? true : false}
											helperText={error.mod_ref_number ? error.mod_ref_number : ""}
											inputProps={{ maxLength: 25 }}
											onChange={(e, value) => this.onFieldChange("mod_ref_number", e.target.value)}
											onFocus={() => this.onRestErrorKey("mod_ref_number")}
											onBlur={() => this.onFocusValidation("mod_ref_number")}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="MOD Issued by"
											name="mod_issued_by"
											id="mod_issued_by"
											value={data.mod_issued_by}
											inputProps={{ maxLength: 20 }}
											onChange={(e, value) => this.onFieldChange("mod_issued_by", e.target.value)}
											error={error.mod_issued_by ? true : false}
											helperText={error.mod_issued_by ? error.mod_issued_by : ""}
											onFocus={() => this.onRestErrorKey("mod_issued_by")}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="MOD Applicability"
											name="mod_applicability"
											id="mod_applicability"
											value={data.mod_applicability}
											inputProps={{ maxLength: 20 }}
											onChange={(e, value) => this.onFieldChange("mod_applicability", e.target.value)}
										/>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={12}>
										<TextField
											variant="outlined"
											label="MOD Description"
											name="description"
											id="description"
											multiline
											minRows={5}
											value={data.description}
											error={error.description ? true : false}
											helperText={error.description ? error.description : ""}
											onChange={(e, value) => this.onFieldChange("description", e.target.value)}
											onFocus={() => this.onRestErrorKey("description")}
										/>
									</Grid>
								</Grid>

								<div className="border-grey"></div>
								<Grid spacing={1} container id="ht-dropdown">
									<Grid item xs={4}>
										<FormControl>
											<InputLabel
												id="demo-simple-select-label"
												className="dropdown-label"
											>
												<React.Fragment> MOD Type <span style={{ color: "red" }}> *</span> </React.Fragment>
											</InputLabel>
											<Select
												labelId="type"
												name="type"
												id="type"
												error={error.type ? true : false}
												helperText={error.type ? error.type : ""}
												variant="outlined"
												value={data.type}
												onChange={this.dropDownChange1}
											>
												<MenuItem value={"STC"}>STC</MenuItem>
												<MenuItem value={"LOCALMODIFICATION"}>
													Local Modification
												</MenuItem>
												<MenuItem value={"OTHERS"}>Others</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="MOD Effectivity"
											name="effectivity"
											id="effectivity"
											value={data.effectivity}
											inputProps={{ maxLength: 25 }}
											onChange={(e, value) => this.onFieldChange("effectivity", e.target.value)}
											error={error.effectivity ? true : false}
											helperText={error.effectivity ? error.effectivity : ""}
											onFocus={() => this.onRestErrorKey("effectivity")}
										/>
									</Grid>
									<Grid item xs={4} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="effectivity_date"
												label="MOD Effective Date"
												format={dayMonthDateFormat}
												inputVariant="outlined"
												// autoOk={true}
												fullWidth
												disableFuture
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.effectivity_date}
												error={error.effectivity_date ? true : false}
												helperText={error.effectivity_date ? error.effectivity_date : ""}
												onChange={(data, value) => {
													this.onFieldChange("effectivity_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("effectivity_date")
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={4} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="last_done_date"
												label="Last Done Date"
												format={dayMonthDateFormat}
												inputVariant="outlined"
												// autoOk={true}
												fullWidth
												disableFuture
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.last_done_date}
												error={error.last_done_date ? true : false}
												helperText={error.last_done_date ? error.last_done_date : ""}
												onChange={(data, value) => {
													this.onFieldChange("last_done_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("last_done_date")
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Last Done FH"
											name="last_done_fh"
											id="last_done_fh"
											value={data.last_done_fh}
											onChange={(e, value) => this.onFieldChange("last_done_fh", e.target.value)}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Last Done FC"
											name="last_done_fc"
											id="last_done_fc"
											value={data.last_done_fc}
											onChange={(e, value) => this.onFieldChange("last_done_fc", e.target.value)}
										/>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											name="mod_compliance_tsn"
											id="mod_compliance_tsn"
											value={data.mod_compliance_tsn}
											label={
												<span style={{ verticalAlign: 'super' }}>
												   MOD Compliance TSN
													<Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
														<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
													</Tooltip>
												</span>
											}
											InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
											inputProps={{ maxLength: 20 }}
											onChange={(e) => {this.onFieldChange("mod_compliance_tsn", e.target.value);this.onRestErrorKey("mod_compliance_tsn")}}
											error={error.mod_compliance_tsn ? true : false}
											helperText={error.mod_compliance_tsn ? error.mod_compliance_tsn : ""}
											onFocus={() => this.onRestErrorKey("mod_compliance_tsn")}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											name="mod_compliance_csn"
											id="mod_compliance_csn"
											label={
												<span style={{ verticalAlign: 'super' }}>
												   MOD Compliance CSN
													<Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
														<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
													</Tooltip>
												</span>
											}
											InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
											value={data.mod_compliance_csn}
											error={error.mod_compliance_csn ? true : false}
											helperText={error.mod_compliance_csn ? error.mod_compliance_csn : ""}
											onChange={(e) => {this.onFieldChange("mod_compliance_csn", e.target.value) ;this.onRestErrorKey("mod_compliance_csn")}}
											
										/>
									</Grid>
									<Grid item xs={4} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="mod_compliance_date"
												label="MOD Compliance Date"
												format={dayMonthDateFormat}
												inputVariant="outlined"
												// autoOk={true}
												fullWidth
												disableFuture
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.mod_compliance_date}
												error={error.mod_compliance_date ? true : false}
												helperText={error.mod_compliance_date ? error.mod_compliance_date : ""}
												onChange={(data, value) => {
													this.onFieldChange("mod_compliance_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("mod_compliance_date")
												}}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>
								<Grid spacing={1} container id="ht-dropdown">
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Document Number"
											name="document_number"
											id="document_number"
											value={data.document_number}
											inputProps={{ maxLength: 25 }}
											onChange={(e, value) => this.onFieldChange("document_number", e.target.value)}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label="Workorder"
											name="workorder"
											id="workorder"
											inputProps={{ maxLength: 25 }}
											value={data.workorder}
											onChange={(e, value) => this.onFieldChange("workorder", e.target.value)}
										/>
									</Grid>
									<Grid item xs={4}>
										<FormControl>
											<InputLabel id="demo-simple-select-label">
												MOD Compliance Status
											</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												variant="outlined"
												value={data.mod_compliance_status}
												onChange={this.dropDownChange}
											>
												<MenuItem value={"OPEN"}>OPEN</MenuItem>
												<MenuItem value={"COMPLIED"}>COMPLIED</MenuItem>
												<MenuItem value={"PARTIALLY COMPLIED"}>
													PARTIALLY COMPLIED
												</MenuItem>
												<MenuItem value={"CLOSED"}>CLOSED</MenuItem>
												<MenuItem value={"NOT APPLICABLE"}>
													NOT APPLICABLE
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								<div className="border-grey"></div>
								<Grid spacing={1} id="data-container" container>
									<Grid item xs={3} id="ht-dropdown" className="mod-period">
										<label className="ml-10px font-14">INTERVAL</label>
										<FormControl>
											<InputLabel
												id="demo-simple-select-label"
												className="dropdown-label"
											>
												MOD Compliance Period
											</InputLabel>
											<Select
												labelId="mod_interval_type"
												name="mod_interval_type"
												id="mod_interval_type"
												error={error.mod_interval_type ? true : false}
												helperText={error.mod_interval_type ? error.mod_interval_type : ""}
												variant="outlined"
												value={data.mod_interval_type}
												onChange={this.dropDownChange2}
											>
												<MenuItem value={"D"}>Days</MenuItem>
												<MenuItem value={"FH"}>FH</MenuItem>
												<MenuItem value={"FC"}>FC</MenuItem>
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={3} id="dimensions-input">
										<label style={{ marginTop: '11px' }}></label>
										<TextField
											variant="outlined"
											label="MOD Compliance"
											name="mod_interval"
											id="mod_interval"
											value={data.mod_interval}
											onChange={(e, value) => this.onFieldChange("mod_interval", e.target.value)}
										/>
									</Grid>
									<Grid item xs={3} id="custom-padding">
										<label className="ml-10px font-14">NEXT DUE </label>
										<Grid spacing={2} container>
											<Grid className="remaining-input" item xs={6}>
												<TextField
													variant="outlined"
													label={this.state.data.mod_interval_type === "FH" ? "FH" : this.state.data.mod_interval_type === "FC" ? "FC" : "Days"}
													name="ad_due"
													id="ad_due"
													value={
														this.state.data.mod_interval_type === "FH"
															? (data.mods_DUE = Number.isInteger(+data.last_done_fh + +data.mod_interval)
																? +data.last_done_fh + +data.mod_interval
																: (+data.last_done_fh + +data.mod_interval).toFixed(2))
															: this.state.data.mod_interval_type === "FC"
																? (data.mods_DUE = Number.isInteger(+data.last_done_fc + +data.mod_interval) ? +data.last_done_fc + +data.mod_interval
																	: (+data.last_done_fc + +data.mod_interval).toFixed(2))
																: this.state.data.mod_interval_type === "D"
																	? (data.mods_DUE = moment(data.last_done_date).add(data.mod_interval, "days")).format("DD-MM-YYYY") : ""
													}
													onChange={(e, value) => this.onFieldChange("ad_due", e.target.value)}
													inputProps={{ readOnly: true }}
													disabled
													className="disabled-input"
												/>
											</Grid>
										</Grid>
									</Grid>

									<Grid item xs={3} id="custom-padding">
										<label className="ml-10px font-14">REMAINING</label>
										<Grid spacing={1} container>
											<Grid className="remaining-input" item xs={6}>
												<TextField
													variant="outlined"
													label={this.state.data.mod_interval_type === "FH" ? "FH" : this.state.data.mod_interval_type === "FC" ? "FC" : "Days"}
													name="remaining_val"
													id="remaining_val"
													value={
														this.state.data.mod_interval_type === "FH"
															? (data.remaining_val = Number.isInteger(data.mods_DUE - this.props.headerTsn)
																? data.mods_DUE - this.props.headerTsn
																: (data.mods_DUE - this.props.headerTsn).toFixed(2))
															: this.state.data.mod_interval_type === "FC"
																? (data.remaining_val = Number.isInteger(data.mods_DUE - this.props.headerCsn)
																	? data.mods_DUE - this.props.headerCsn
																	: (data.mods_DUE - this.props.headerCsn).toFixed(2))
																: this.state.data.mod_interval_type === "D"
																	? (data.remaining_val = moment(remDay).add(data.mod_interval))
																	: null
													}
													onChange={(e, value) => this.onFieldChange("remaining_val", e.target.value)}
													inputProps={{ readOnly: true }}
													disabled
													className="disabled-input"
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Grid id="data-container" spacing={1} container>
									<Grid item xs={3}>
										<label>MOD Compliance DFP</label>
										<FormControl
											onFocus={() => this.onRestErrorKey("mod_compliance_dfp")}
										>
											<RadioGroup
												required
												row
												aria-label="mod_compliance_dfp"
												name="mod_compliance_dfp"
												defaultValue="top"
												value={data.mod_compliance_dfp}
												onChange={(e, value) => this.onFieldChange("mod_compliance_dfp", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
											</RadioGroup>
											{error.mod_compliance_dfp && (
												<FormLabel component="mod_compliance_dfp">
													<span style={{ color: "red" }}> {error.mod_compliance_dfp} </span>
												</FormLabel>
											)}
										</FormControl>
									</Grid>

									<Grid item xs={2}>
										<label>Release Certificate </label>
										<FormControl component="fieldset">
											<FormLabel component="legend"></FormLabel>
											<RadioGroup
												row
												aria-label="release_certificate"
												name="release_certificate"
												defaultValue="top"
												value={data.release_certificate}
												onChange={(e, value) => this.onFieldChange("release_certificate", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>

									<Grid item xs={4}>
										<label>Certificate of Conformity</label>
										<FormControl component="fieldset">
											<FormLabel component="legend"></FormLabel>
											<RadioGroup
												row
												aria-label="certificate_of_conformity"
												name="certificate_of_conformity"
												defaultValue="top"
												value={data.certificate_of_conformity}
												onChange={(e, value) => this.onFieldChange("certificate_of_conformity", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
												<FormControlLabel
													value="N/A"
													control={<Radio size="small" color="primary" />}
													label="N/A"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={6}>
									<TextField
                                        label="Remarks"
                                        margin='normal'
                                        variant='outlined'
                                        fullWidth
                                        value={data.remark}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => this.onFieldChange("remark", e.target.value)}
                                        rows={3}
                                        multiline
                                        inputProps={{ maxLength: 250 }}
                                    />
									</Grid>
									<Grid item xs={6}>
										<div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
											<ul className="list-inline spacing-list">
												<li className="list-inline-item" onClick={this.handleOpenUploadFile} >
													<span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
												</li>
												<li className="list-inline-item" onClick={this.handleOpenFile}>
													<span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
												</li>
											</ul>
										</div>
									</Grid>
								</Grid>
								<Grid id="data-container" spacing={1} container>
									<Grid spacing={1} container>
										{this.props.actionType === 'add' ?
											<div className='checklist-files'>
												<ul className='list-inline'>
													{data?.checklist?.map((file) =>
														<li className='list-inline-item'>
															<span className='file-name'>{file.name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
													{attachments?.map((attachments) =>
														<li className='list-inline-item'>
															<span className='file-name'>{attachments.file_name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
												</ul>
											</div>
											:
											<div className='checklist-files'>
												<ul className='list-inline'>
													{data?.checklist?.files?.map((file) =>
														<li className='list-inline-item'>
															<span className='file-name'>{file.name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
														{(data.checklist?.folders?.length > 0) &&
														data.checklist?.folders.map((file) => {
															return (
																<li className='list-inline-item' key={file.id}>
																	<Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
																		<a href={`${config.domain.records}${assetType[this.props.props?.match.params.assetType]}/${file?.asset_slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
																	</Tooltip>
																	<span className='file-name'>{file.name}</span>
																	<span className='file-remove'>
																		<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
																	</span>
																</li>
															);
														})}
													{attachments?.map((attachments) =>
														<li className='list-inline-item'>
															<span className='file-name'>{attachments.file_name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id,attachments)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
												</ul>
											</div>}
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions className="actions-btn">
								<Button
									type="button"
									className="btn btn-primary"
									onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)}
								>
									{this.props.actionType === "add" ? "Add" : "Update"}
								</Button>
								{this.props.actionType === "add" ? (
									<Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
								) : (
									""
								)}
								<Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} > Close </Button>
							</DialogActions>
							<Dialog
								open={this.state.open}
								onClose={this.handleCloseAttachment}
								id="htDialog"
							>
								<ModsConfirmDeleteAttachment
									handleCloseAttachment={() => this.handleCloseAttachment()}
									deleteAttachmentFile={() => this.deleteAttachmentFile(this.state.deleteAttachmentId)}
								/>
							</Dialog>
							<Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
								<LinkFiles
									handleClose={() => this.handleCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
									modType="mods"
									last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
									uploadFileInfo={data}
									attachments={attachments}
									type="link"
								/>
							</Dialog>
							<Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
								<UploadFile
									handleClose={() => this.handleUploadFileCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
									modType="mods"
									uploadFileInfo={data}
									last_used_folder_uuid={this.props.last_used_folder_uuid}
									onUpload={(file) => this.onUpload(file)}
									type="upload"
									checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
									checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
								/>
							</Dialog>
						</form>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default ModsTaskForm
