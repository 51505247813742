import AddEditMods from "./AddEditMods";
import ModsHeader from "./ModsHeader";
import ModsListing from "./ModsListing";

export {
    AddEditMods,
    ModsHeader,
    ModsListing
}

export const modsObj = {
    asset_id: null,
    asset_type_id: null,
    ata_chapter: null,
    mod_ref_number: null,
    mod_issued_by: null,
    mod_applicability: null,
    description: "",
    type: "STC",
    effectivity: null,
    effectivity_date: null,
    mod_interval_type: "D",
    mod_interval: null,
    mod_compliance_status: "OPEN",
    mod_compliance_tsn: null,
    mod_compliance_csn: null,
    mod_compliance_date: null,
    document_number: null,
    last_done_date: null,
    last_done_fh: null,
    last_done_fc: null,
    workorder: null,
    remark: "",
    mod_compliance_dfp: null,
    release_certificate: null,
    certificate_of_conformity: null,
    attachments: [],
    checklist: [],
    folder_uuid: "",
    linkFile:''
}