import { globalGetService, globalExportService } from "../../utils/globalApiServices"
import { downloadFileType } from "../../utils";
import { trackActivity } from "../../utils/mixpanel";
import moment from "moment";
import { checkApiStatus } from "../../utils";

export function getAircraftDetailApi(queryParams = {}) {
	const { asset, assetType } = queryParams.props.match.params
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/asset/${asset}/${assetType}/view/?module=${queryParams.module}`).then(
			(response) => {
				resolve(response)
			}
		)
	})
}

export function getTechSpecDetailApi(props) {
	globalGetService(`camo/engine_tech_spec/${props?.slug}/details/`)
		.then(response => {
			if (response?.status === 200) {
				this.setState({ basicDetailData: response?.data?.data })
			}
		})
}

export function exportMasterReport(query = {}, match, enqueueSnackbar) {
	const date = new Date();
	const todayeDate = moment(String(date)).format("DD-MM-YYYY");
	globalExportService(`/camo/smbc_master_excel/${match?.params?.asset}/${match?.params?.assetType}/export/`)
		.then((response) => {
			if (checkApiStatus(response)) {
				downloadFileType(response.data, `Master_Export_${todayeDate}`, query);
				enqueueSnackbar("Master Export Download Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, });
				trackActivity("Master Export", { page_title: "Asset Listing", });
			} else {
				enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, });
			}
		});
}
