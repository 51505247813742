import React, { useState } from 'react';
import { Grid, Tabs, Tab, Paper, Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import EmptyCollection from './components/EmptyCollection';
import CloseIcon from "@material-ui/icons/Close"
import leaseManagementIcon from '../assets/images/LeaseIcon.svg';
import projectManagementIcon from '../assets/images/ProjectsIcon.svg';
import recordDataroomIcon from '../assets/images/RecordsIcom.svg';
import maintenanceManagementIcon from '../assets/images/MaintenaceIcon.svg';
import mrCalcIcon from '../assets/images/acumen_commverge.png';
import aircraftValuationIcon from '../assets/images/valuation_new.png';
import config from '../config';
import { getLocalStorageInfo } from '../utils';
import AppsCard from './AppsCard';
const ApplicationsAccess = ({ handleClose }) => {
    const [tabIndex, setTabIndex] = useState('active');
    const applications = [
        {
            label: 'Lease Management',
            description: 'Effectively manage commercial leases and contracts of the entire fleet - aircraft, engines and even sub-modules through the entire economic life of the asset',
            logo: leaseManagementIcon,
            access: getLocalStorageInfo()?.user?.permission?.contracts,
            url: config.domain.lease,
            isBeta: false
        },
        {
            label: 'Projects Management',
            description: 'Efficiently plan, execute and manage Technical Projects - from Pre-purchase to Redeliveries.',
            logo: projectManagementIcon,
            access: getLocalStorageInfo()?.user?.permission?.technical,
            url: config.domain.projects,
            isBeta: false
        },
        {
            label: 'Records Dataroom',
            description: 'Centralised, secure online Documents Management System (DMS) for digitised aircraft records storage retrieval and collaboration',
            logo: recordDataroomIcon,
            access: getLocalStorageInfo()?.user?.permission?.records,
            url: config.domain.records,
            isBeta: false
        },
        {
            label: 'Maintenance Claim Management',
            description: 'Seamlessly track, execute and manage maintenance events across the fleet, forecast future events and process commercial claims.',
            logo: maintenanceManagementIcon,
            access: getLocalStorageInfo()?.user?.permission?.maintenance,
            url: config.domain.maintenance,
            isBeta: false
        },
        {
            label: 'CommVerge',
            description: 'A potent decision application for computing commercial exposure and simulating complex scenarios on ~200 aircraft - engine combinations.',
            logo: mrCalcIcon,
            access: getLocalStorageInfo()?.user?.permission?.mr_calculator,
            url: config.domain.mrCalculator,
            isBeta: false
        },
        {
            label: 'fin-S',
            description: 'Access values for a wide variety of aircraft, powered by expertise and experience of ISTAT and ASA Certified Appraisers.',
            logo: aircraftValuationIcon,
            access: getLocalStorageInfo()?.user?.permission?.valuation,
            url: config.domain.valuation,
            isBeta: false
        },
    ]
    return (
        <>
            <DialogTitle style={{ backgroundColor: 'unset', color: 'unset', padding: "10px 30px 10px 40px" }}>Applications
                <span style={{ float: 'right' }}><CloseIcon onClick={handleClose} /></span>
            </DialogTitle>
            <DialogContent dividers={true}>
                <section className='application-landing-page' >
                    {applications.filter(item => !item.access).length ?
                        <Paper square style={{ marginBottom: '10px' }}>
                            <Tabs indicatorColor='primary' value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)} aria-label="simple tabs example">
                                <Tab style={{ textTransform: 'none' }} label="ACTIVE APPLICATION(s)" value='active' />
                                <Tab style={{ textTransform: 'none' }} label="INACTIVE APPLICATION(s)" value='inactive' />
                            </Tabs>
                        </Paper> : null
                    }
                    {tabIndex === 'active' ?
                        <>
                            {(() => {
                                const activeApps = applications.filter(item => item.access);
                                if (activeApps.length) {
                                    return (
                                        <div>
                                            <Grid container spacing={2}>
                                                {activeApps.map((app) =>
                                                    <AppsCard item={app} />
                                                )}
                                            </Grid>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div style={{ textAlign: 'center', padding: '20px 0' }}>
                                            <EmptyCollection title='No active application access ' />
                                        </div>
                                    )
                                }
                            })()}
                        </> : null
                    }
                    {tabIndex === 'inactive' ?
                        <>
                            {(() => {
                                const inActiveApps = applications.filter(item => !item.access);
                                if (inActiveApps?.length) {
                                    return (
                                        <div>
                                            <Grid container spacing={2}>
                                                {inActiveApps.map((app) =>
                                                    <AppsCard item={app} />
                                                )}
                                            </Grid>
                                        </div>
                                    )
                                } else {
                                    return null
                                }
                            })()}
                        </> : null
                    }
                </section>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' size='small' color='primary' style={{ marginRight: "15px" }} onClick={handleClose} >Close</Button>
            </DialogActions>
        </>
    )
}
export default ApplicationsAccess;