import { React, useState, useEffect } from 'react'
import moment from 'moment';
import config from '../../../../config'
import { useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import BasicDetail from '../../basicDetail/containers'
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { AddEditMods, ModsHeader, ModsListing } from '../components';
import { ImportFileFormat, BasicTopHeader } from '../../../../shared_elements/components';
import ExportMenu from '../../../../shared_elements/components/ExportMenu';
import { downloadFileType, checkApiStatus } from '../../../../utils';
import { EmptyCollection, PageLoader, DeletePopUp } from '../../../../shared_elements';
import Pagination from '../../../../shared_elements/components/Pagination';
import { globalGetService, globalDeleteService, globalExportService, globalPutService } from '../../../../utils/globalApiServices';
import { Table, TableBody, Paper, Button, Grid, TextField, InputAdornment } from "@material-ui/core";
import { exportMasterReport } from '../../apiServices'
import { DownloadAllApi } from '../../../fleet/containers/apiServices';
import ShowAttachments from '../../../../shared_elements/components/ShowAttachments';

function ModsComp({ match }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [list, setList] = useState({})
    const [basicdetail, setBasicDetail] = useState()
    const [isLoading, setLoading] = useState(false)
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    const [deletelist, setDeleteList] = useState({ modal: false, data: null })
    const [importFile, setImportFile] = useState({ modal: false, data: null })
    const [addEditMods, setAddEditMods] = useState({ modal: false, data: null, type: '' })
    const [attchementsModal, setAttchementsModal] = useState({ modal: false, data: null, })

    useEffect(() => {
        getModsList({ per_page: 20 })
    }, [])
    const createSortHandler = (key) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        getModsList({ ...sortQuery, per_page: 20 });
    }


    const getAttachements = () => {
        const matchingItem = list?.list?.find(item => item?.id === attchementsModal?.data?.id);
        if (!matchingItem) return [];
        const { checklist, attachments } = matchingItem || {};
        if (checklist && attachments || checklist || attachments) {
            const { files, folders } = checklist || {}; // Add null check here
            if (attachments?.length > 0 && files?.length > 0 && folders?.length > 0) {
                return [...attachments, ...files, ...folders];
            } else if (files?.length > 0 && folders?.length > 0) {
                return [...folders, ...files];
            } else if (attachments?.length > 0 && files?.length > 0) {
                return [...attachments, ...files];
            } else if (attachments?.length > 0 && folders?.length > 0) {
                return [...attachments, ...folders];
            } else if (attachments?.length > 0) {
                return [...attachments];
            } else if (folders?.length > 0) {
                return [...folders];
            } else if (files?.length > 0) {
                return [...files];
            }
        }
        return [];
    };


    const getModsList = (query) => {
        setLoading(true)
        globalGetService(`camo/mods/?asset=${match?.params?.asset}&asset_type=${match?.params?.assetType}`, { ...query, search: query?.search })
            .then((response) => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setList(response.data)
                }

            })
    }
    const deleteModsList = () => {
        setLoading(true)
        globalDeleteService(`camo/mods/${deletelist?.data?.id}/`)
            .then((response) => {
                setLoading(false); setDeleteList({ modal: false }); getModsList({ per_page: 20 })
                if (checkApiStatus(response)) {
                    enqueueSnackbar("Record Deleted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                } else {
                    enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }

            })
    }
    const exportMods = (extension) => {
        const date = new Date()
        const todayDate = moment(String(date)).format("DD-MM-YYYY")
        setLoading(true);
        globalExportService(`camo/mods/${match?.params?.asset}/${match?.params?.assetType}/export/`, { download: extension })
            .then(response => {
                setLoading(false);
                downloadFileType(response.data, `Mods${todayDate}`, extension)
                enqueueSnackbar("Download Mods List Successfully", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            });
    }
    const deleteAttachements = (item, checklist, fileId, uuid, folder, type) => {
        if (type) {
            globalDeleteService(`camo/attachments/modstatus/${item?.id}/${uuid}/`)
                .then((response) => {
                    if (checkApiStatus(response)) {
                        getModsList({ per_page: 20 })
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    } else {
                        enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                    }
                })
        } else {
            let payload = {};
            payload.delete = true;
            payload.folder_ids = checklist.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).toString()
            payload.file_ids = checklist.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).toString()
            globalPutService(`camo/mods/${item?.id}/document-checklists/`, payload)
                .then((response) => {
                    if (checkApiStatus(response)) {
                        getModsList({ per_page: 20 })
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    } else {
                        enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                    }
                })
        }
    };
    return (
        <section className='amp-module'>
            <BasicTopHeader slug={basicdetail?.slug} heading="Modfications" onMasterExportFn={(query) => exportMasterReport(query, match, enqueueSnackbar)} refreshData={() => getModsList({ per_page: 20 })} />
            <BasicDetail moduleType="mods" BasicDetailData={(data) => setBasicDetail(data)} />
            <Paper square style={{ padding: "10px" }} variant='outlined'>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5} md={5}>
                        <TextField
                            variant='outlined'
                            fullWidth
                            margin='normal'
                            size='small'
                            placeholder="Search ATA Chapter, MOD Refrence Number, Description"
                            onChange={(e) => getModsList({ search: e.target.value, per_page: 20 })}
                            InputProps={{ style: { fontSize: 12 }, endAdornment: <InputAdornment position="end"><SearchIcon color='primary' cursor="pointer" /></InputAdornment> }}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <ul className='list-inline' style={{ float: "right", margin: "revert" }}>
                            <li className='list-inline-item'>
                                <Button fullWidth variant='contained' color='primary' size='small' onClick={() => setAddEditMods({ modal: true, data: null, type: 'add' })}> + Add MODS</Button>
                            </li>
                            <li className='list-inline-item'>
                                <Button fullWidth variant='outlined' color='primary' size='small' onClick={() => setImportFile({ modal: true, data: null })}>Import</Button>
                            </li>
                            <li className='list-inline-item'>
                                <ExportMenu
                                    exportReportFn={(file) => exportMods(file.extension)}
                                    files={[{ title: 'PDF', extension: 'pdf' }, { title: 'EXCEL', extension: 'xls' }]}
                                    disabled={!list?.list?.length}
                                />
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <div style={{ height: `${window.innerHeight - 360}px`, overflow: 'auto' }}>
                    <Table className='mui-table-format' stickyHeader={true}>
                        <ModsHeader
                            sortInfo={sortInfo}
                            createSortHandler={createSortHandler}
                        />
                        <TableBody>
                            {list?.list?.map((item, index) =>
                                <ModsListing
                                    item={item}
                                    key={index}
                                    toggleModalFn={() => setAddEditMods({ modal: true, data: item, type: 'edit' })}
                                    toggleDelteModalFn={() => setDeleteList({ modal: true, data: item })}
                                    handleOpenAttachments={() => setAttchementsModal({ modal: true, data: item })}
                                />
                            )}
                        </TableBody>
                    </Table>
                    {!list?.list?.length ? <EmptyCollection title="No Records Founds" /> : null}
                </div>
                <Pagination
                    pagination={list?.pagination}
                    onChangeRowsPerPage={(event) => getModsList({ page: 1, per_page: event.target.value })}
                    onChangePage={(event, newPage) => getModsList({ per_page: list.pagination.per_page, page: newPage + 1, })}
                />
            </Paper>
            {addEditMods.modal ?
                <AddEditMods
                    modsData={addEditMods?.data}
                    basicdetail={basicdetail}
                    toggleModalFn={() => setAddEditMods({ modal: false })}
                    modeType={addEditMods.type}
                    getResponseBack={() => getModsList({ per_page: 20, })}
                    list={list}
                /> : null
            }
            {deletelist.modal ?
                <DeletePopUp
                    title='Delete Mods'
                    deleteRecordFn={() => deleteModsList()}
                    modal={() => setDeleteList({ modal: true })}
                    content='Do you really want to delete this record?'
                    toggleModalFn={() => setDeleteList({ modal: false })}

                /> : null

            }
            {importFile.modal ?
                <ImportFileFormat
                    title="Import Mods"
                    moduleType='mods'
                    moduleName="Sample File Mods"
                    getResponseBack={() => getModsList({ per_page: 20 })}
                    toggleModalFn={() => setImportFile({ modal: false })}
                    revampSample={true}
                /> : null
            }
            {attchementsModal.modal ?
                <ShowAttachments
                    handleClose={() => setAttchementsModal({ modal: false, data: null })}
                    handleShowAttachments={getAttachements()}
                    openAttachment={(file) => window.open(decodeURIComponent(file), "_blank")}
                    files={{ title: "Mods", extension: "zip", key: "" }}
                    downloadAllApi={() => DownloadAllApi(list?.list.filter(item=> attchementsModal.data?.id === item.id)[0], 'Mods')}
                    item={attchementsModal.data}
                    removeAttachment={deleteAttachements}
                />
                :
                null
            }
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}

export default withRouter(ModsComp)