import React, { useState, useEffect, Fragment } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Radio, RadioGroup, FormControl, FormLabel, FormControlLabel } from '@material-ui/core';
import { globalGetService, globalPutService } from '../../../../utils/globalApiServices';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers"
import { fieldDateFormat, backendDateFormat } from '../../../../constants';
import Autocomplete from "@material-ui/lab/Autocomplete"
import { checkApiStatus } from '../../../../utils';
import { isMobile } from "react-device-detect"
import { withRouter } from 'react-router';
import MomentUtils from "@date-io/moment"
import { useSnackbar } from 'notistack';
import moment from "moment"
import { PageLoader } from '../../../../shared_elements';

const EditBasicDetail = ({ toggleModalFn, match, basicDetailData, aircraftTypes, pltConstants, lessee, operators, owners, engineTypes, getResponseBack }) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [data, setData] = useState(basicDetailData)
	const [error, setError] = useState({})
	const [mntGrpList, setmntGrpList] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [operatingThrust, setOperatingThrust] = useState([])
	useEffect(() => {
		if (match.params.assetType === "2") {
			getOperatingThrust()
		}
	}, [0])

	const onFieldChange = (keyParam, value, extraprops, label) => {
		if (extraprops) {
			setData({
				...data,
				[extraprops]: {
					...data[extraprops],
					[label]: {
						...data[extraprops][[label]],
						[keyParam]: value
					}
				}
			})
		} else {
			setData({ ...data, [keyParam]: value })
			if (keyParam === 'aircraft_type') {
				setData({ ...data, 'maintenance_event_program_group': null, 'aircraft_type': value })
				if (value) {
					fetchMntGroups(value.id)
				} else {
					setmntGrpList([])
				}
			}

		}

	}

	const onRestErrorKey = (keyParam) => {
		setError({ ...error, [keyParam]: '' })
	}

	let maintanenceList = [];
	let maintanenceData = data?.maintenance_event_program_group;
	if (maintanenceData) {
		Object.keys(maintanenceData).map((item, index) => {
			maintanenceList.push({ label: maintanenceData[item].replace(/,/g, '/'), value: item })
		});
	}

	const fetchMntGroups = (id) => {
		globalGetService(`console/aircraft-type/${id}/events/`)
			.then(response => {
				if (checkApiStatus(response)) {
					let mntList = [];
					const data = response.data.data;
					Object.keys(data).map((item) => {
						mntList.push({ label: data[item].replace(/,/g, '/') + '', value: item })
					});
					setmntGrpList(mntList);
				}
			})
	}

	const getOperatingThrust = () => {
		globalGetService(`camo/current_thrust/${match.params.assetType}/?engine_family_engine_type=${basicDetailData?.engine_type?.engine_family_type_id}`,)
			.then(response => {
				if (response?.status === 200) {
					setOperatingThrust(response.data)
				}
			})
	}

	const onUpdateBasicDetail = () => {
		let validationInputs = {};
		if (match.params.assetType == 1) {
			validationInputs = {
				...validationInputs,
				msn: data.msn ? '' : "Please Enter Msn",
				aircraft_type: data.aircraft_type ? '' : "Please Enter Aircraft ",
				status: data.status ? '' : "Please select Status",
				date_of_manufacture: data.date_of_manufacture ? '' : "Please Enter Date of Manufacture",
				portfolio: data.portfolio ? '' : 'Please Select Portfolio',
				lessee: data.lessee ? '' : "Please Select Lesse",
				operator: data.same_operator_lessee || data.operator ? '' : "Please Select Operator"
			}
		} else {
			validationInputs = {
				...validationInputs,
				date_of_manufacture: data.date_of_manufacture ? '' : "Please Enter Date of Manufacture",
			}
		}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
			setLoading(true)
			let assetPayload = Object.assign({}, data);
			let enginePayload = Object.assign({}, data);
			assetPayload = {
				...assetPayload,
				aircraft_type: match?.params?.assetType == 1 && data.aircraft_type?.id,
				credit_classification: match?.params?.assetType == 1 && data.credit_classification && data.credit_classification.value ? data.credit_classification.value : null,
				maintenance_event_program_group: match?.params?.assetType == 1 && data.maintenance_event_program_group && data.maintenance_event_program_group.value ? data.maintenance_event_program_group.value : null,
				status: match?.params?.assetType == 1 && data.status.value,
				sub_status: match?.params?.assetType == 1 && data.sub_status?.value,
				lessee: match?.params?.assetType == 1 && data.status?.value === 1 && data.lessee ? data.lessee : null,
				operator: match?.params?.assetType == 1 && data?.operator?.id ? data.operator?.id : null,
				off_lease_status: match?.params?.assetType == 1 && typeof data.off_lease_status === "object" ? null : data.off_lease_status,
				country: match?.params?.assetType == 1 && data.country ? data.country : null,
				id: match.params.asset
			};
			enginePayload = {
				...enginePayload,
				csn: +data.csn,
				tsn: +data.tsn,
				manufacturing_date: data.date_of_manufacture,
				engine_type: data?.engine_type?.id ? data?.engine_type?.id : null,
				current_operating_thrust: data.current_operating_thrust ? data.current_operating_thrust : null,
				current_operating_thrust_id: data.current_operating_thrust ? data.current_operating_thrust?.id : null
			}
			if (assetPayload.ownership_type === 2 && assetPayload.owner_portfolio.id) {
				assetPayload = {
					...assetPayload,
					owner_portfolio: assetPayload.owner_portfolio.id,
				};
			} else if (assetPayload.ownership_type === 1) {
				assetPayload = {
					...assetPayload,
					owner_portfolio: null,
				};
			}
			let enginePromises = [];
			data?.engine_detail && Object.values(data.engine_detail).map((engine) => {
				const payload = {
					is_titled: engine.is_titled,
					is_fitted: engine.is_fitted,
					engine_type: engine.engine_type.id,
					csn: engine.csn,
					tsn: engine.tsn,
					esn: engine.esn,
				};
				const updatePromise = globalPutService(`console/aircraft/${basicDetailData.slug}/engine/${engine.id}/`, payload);
				enginePromises.push(updatePromise);
			});
			const updateAssetPromise = match?.params?.assetType == 1 ? globalPutService(`console/aircraft-overview/${basicDetailData.slug}/`, assetPayload) : globalPutService(`console/engine/${basicDetailData.slug}/details/`, enginePayload);
			let anyPromiseFailed = false;
			let failedToastMessage = null;

			Promise.all(match?.params?.assetType == 1 ? [...enginePromises, updateAssetPromise] : [updateAssetPromise]).then(responses => {
				setLoading(false)
				responses.forEach(response => {
					if (!checkApiStatus(response)) {
						anyPromiseFailed = true;
						if (!checkApiStatus(response)) {
							anyPromiseFailed = true;
							if (!failedToastMessage) {
								failedToastMessage = response.data.message;
							}
						}
					}
				});
				if (anyPromiseFailed) {
					enqueueSnackbar(failedToastMessage, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
				} else {
					enqueueSnackbar("Technical Specification Updated Successfully!", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" } });
					toggleModalFn(); getResponseBack()
				}
			}).catch(error => {
				console.error('An error occurred:', error);
			});
		} else {
			setError(validationInputs)
		}
	}
	return (
		<Fragment>
			<Dialog
				open={true}
				onClose={() => toggleModalFn()}
				className='camo-management-modal'
				maxWidth="lg"
				style={{ height: isMobile && "650px" }}
			>
				<DialogTitle id="scroll-dialog-title">
					{match?.params?.assetType == 1 ? "Update Aircraft" : "Update Engine"}
				</DialogTitle>
				<DialogContent dividers={true}>
					<DialogContent>
						<form>
							<Grid container spacing={1}>
								<Grid item xs={12} md={3}>
									{match.params?.assetType == 1 ?
										<TextField
											variant="outlined"
											label="MSN"
											margin="normal"
											required
											fullWidth
											value={data?.msn ? data?.msn : ''}
											error={error.msn ? true : false}
											helperText={error.msn ? error.msn : ""}
											onChange={(e, value) => onFieldChange("msn", e.target.value)}
											onFocus={() => onRestErrorKey("msn")}
											InputLabelProps={{ shrink: true }}
										/>
										:
										<TextField
											variant="outlined"
											label="ESN"
											margin="normal"
											required
											fullWidth
											value={data?.esn}
											disabled
											onChange={(e, value) => onFieldChange("msn", e.target.value)}
											onFocus={() => onRestErrorKey("msn")}
											InputLabelProps={{ shrink: true }}
										/>
									}
								</Grid>
								<Grid item xs={12} md={3}>
									{match?.params?.assetType == 1 ?
										<Autocomplete
											options={aircraftTypes}
											value={data?.aircraft_type}
											getOptionLabel={(option) => option?.name}
											onChange={(e, value) => onFieldChange("aircraft_type", value)}
											renderInput={(params) => (
												(
													<TextField
														{...params}
														variant="outlined"
														required
														label="Type"
														margin="normal"
														InputLabelProps={{ shrink: true }}
														fullWidth
													/>
												)
											)}
										/>
										:
										<TextField
											variant="outlined"
											label="Engine Type"
											margin="normal"
											required
											fullWidth
											disabled
											value={data.engine_type_name}
											InputLabelProps={{ shrink: true }}
										/>
									}
								</Grid>
								{match?.params?.assetType == 1 && <Grid item xs={12} md={3}>
									<TextField
										variant="outlined"
										label="Registration"
										fullWidth
										margin="normal"
										value={data.current_registration_number}
										onChange={(e, value) => onFieldChange("current_registration_number", e.target.value)}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>}
								<Grid item xs={12} md={3}>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											inputVariant="outlined"
											label="Date of Manufacture"
											margin="normal"
											required
											fullWidth
											disableFuture
											format={fieldDateFormat}
											InputLabelProps={{ shrink: true }}
											value={data.date_of_manufacture || null}
											error={error.date_of_manufacture ? true : false}
											helperText={error.date_of_manufacture ? error.date_of_manufacture : " "}
											onChange={(data, value) => onFieldChange("date_of_manufacture", moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								{match?.params?.assetType == 2 &&
									<Grid item xs={12} md={3}>
										<Autocomplete
											options={operatingThrust ? operatingThrust : []}
											getOptionLabel={(option) => option.name}
											onChange={(e, value) => onFieldChange("current_operating_thrust", value)}
											value={{ name: data.current_operating_thrust?.name || "" }}
											renderInput={(params) => (
												<TextField
													{...params}
													variant="outlined"
													label="Op.Thrust (Current)"
													margin="normal"
													fullWidth
													InputLabelProps={{ shrink: true }}
												/>
											)}
										/>
									</Grid>}
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={12} md={3}>
									<TextField
										variant="outlined"
										label="TSN"
										margin="normal"
										fullWidth
										value={data.tsn}
										onChange={(e, value) => onFieldChange("tsn", e.target.value)}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<TextField
										variant="outlined"
										label="CSN"
										margin="normal"
										fullWidth
										value={data.csn}
										onChange={(e, value) => onFieldChange("csn", e.target.value)}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								{match.params?.assetType == 1 && <Grid item xs={12} md={3}>
									<Autocomplete
										options={mntGrpList ? mntGrpList : []}
										getOptionLabel={(option) => option?.label}
										value={{ label: maintanenceList[0]?.label } || null}
										onChange={(e, value) => onFieldChange("maintenance_event_program_group", value)}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Maintenance Program Group"
												variant="outlined"
												margin="normal"
												fullWidth
												InputLabelProps={{ shrink: true }}
											/>
										)}
									/>
								</Grid>}
								{match.params?.assetType == 1 && <Grid item xs={12} md={3}>
									<Autocomplete
										options={pltConstants.filter((item) => item.type === "credit_classification")}
										getOptionLabel={(option) => option?.label}
										value={data.credit_classification}
										onChange={(e, value) => onFieldChange("credit_classification", value)}
										renderInput={(params) => (
											<TextField
												variant="outlined"
												label="Credit Classification"
												margin="normal"
												{...params}
												fullWidth
												InputLabelProps={{ shrink: true }}
											/>
										)}
									/>
								</Grid>
								}
								{match.params?.assetType == 2 && <Grid item xs={12} md={3}>
									<TextField
										variant="outlined"
										label="TSLV"
										margin="normal"
										fullWidth
										disabled
										value={data?.tslv}
										onChange={(e, value) => onFieldChange("tslv", e.target.value)}
										inputProps={{ maxLength: 20 }}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>}
								{match.params?.assetType == 2 && <Grid item xs={12} md={3}>
									<TextField
										variant="outlined"
										label="CSLV"
										margin="normal"
										fullWidth
										value={data?.cslv}
										onChange={(e, value) => onFieldChange("cslv", e.target.value)}
										inputProps={{ maxLength: 20 }}
										disabled
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>}
							</Grid>
							{match.params?.assetType == 2 && <Grid container spacing={2}>
								<Grid item xs={12} md={3}>
									<TextField
										variant="outlined"
										label="LLP Limiter(s)"
										margin="normal"
										fullWidth
										disabled
										value={data?.llp_limiter}
										onChange={(e, value) => onFieldChange("llp_limiter", e.target.value)}
										inputProps={{ maxLength: 20 }}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<TextField
										variant="outlined"
										label="Soft-time Limiter(s)"
										margin="normal"
										fullWidth
										disabled
										value={data?.softTime_limiter}
										onChange={(e, value) => onFieldChange("softTime_limiter", e.target.value)}
										inputProps={{ maxLength: 20 }}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											margin="normal"
											label="Reporting Month"
											format={fieldDateFormat}
											fullWidth
											disableFuture
											disabled
											InputLabelProps={{ shrink: true }}
											minDate={moment(data.date_of_manufacture)}
											value={data?.last_utilization_updated ? data?.last_utilization_updated : null}
											error={false}
											helperText={''}
											onChange={(data, value) => this.onFieldChange('last_utilization_updated', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
											inputVariant='outlined'
										/>
									</MuiPickersUtilsProvider>
								</Grid>
							</Grid>}
							{match.params?.assetType == 1 && <Grid container spacing={2}>
								<Grid item xs={12} md={3}>
									<Autocomplete
										options={pltConstants.filter((item) => item.type === "lease_status")}
										getOptionLabel={(option) => option.label}
										value={data.status}
										onChange={(e, value) => onFieldChange("status", value)}
										renderInput={(params) => (
											<TextField
												variant="outlined"
												label="Lease Status"
												margin="normal"
												required
												error={error.status ? true : false}
												helperText={error.status ? error.status : ""}
												onFocus={() => onRestErrorKey("status")}
												{...params}
												fullWidth
												InputLabelProps={{ shrink: true }}

											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<Autocomplete
										options={pltConstants.filter((item) => item.type === "asset_sub_status")}
										getOptionLabel={(option) => option.label}
										value={data.sub_status ? data.sub_status : null}
										onChange={(e, value) => onFieldChange("sub_status", value)}
										renderInput={(params) => (
											<TextField
												variant="outlined"
												error={error.sub_status ? true : false}
												required={data.status && data.status.value == 1 ? true : false}
												helperText={error.sub_status ? error.sub_status : ""}
												onFocus={() => onRestErrorKey("sub_status")}
												{...params}
												label="Sub Status"
												margin="normal"
												fullWidth
												InputLabelProps={{ shrink: true }}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<Autocomplete
										options={lessee ? lessee : []}
										getOptionLabel={(option) => option.name}
										value={data.status?.value === 1 ? data.lessee : ''}
										disabled={data.status?.value === 1 ? false : true}
										onChange={(e, value) => onFieldChange("lessee", value)}
										renderInput={(params) => (
											<TextField
												variant="outlined"
												error={error.lessee ? true : false}
												required={data.status && data.status.value == 1 ? true : false}
												helperText={error.lessee ? error.lessee : ""}
												onFocus={() => onRestErrorKey("lessee")}
												{...params}
												label="Lessee"
												margin="normal"
												fullWidth
												InputLabelProps={{ shrink: true }}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<TextField
										variant="outlined"
										label="Country"
										fullWidth
										margin="normal"
										value={data.country}
										onChange={(e, value) => onFieldChange("country", e.target.value)}
										inputProps={{ maxLength: 50 }}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
							</Grid>}
							{match?.params?.assetType == 1 && <Grid container spacing={2}>
								<Grid item xs={12} md={3}>
									<FormControl component="fieldset" style={{ top: "11px" }} >
										<FormLabel className="MuiInputLabel-shrink" component="label" > Operator is same as Lessee ? </FormLabel>
										<RadioGroup row aria-label="position" name="same_operator_lessee" defaultValue="top" >
											<FormControlLabel
												value="is_titled"
												control={
													<Radio
														onChange={() => { onFieldChange("same_operator_lessee", true); onRestErrorKey("operator") }}
														checked={data.same_operator_lessee}
														color="primary"
														size="small"
														InputLabelProps={{ shrink: true }}
													/>
												}
												label="Yes"
											/>
											<FormControlLabel
												value="is_fitted"
												control={
													<Radio
														onChange={() => { onFieldChange("same_operator_lessee", false); onRestErrorKey("operator") }}
														checked={!data.same_operator_lessee}
														color="primary"
														size="small"
														InputLabelProps={{ shrink: true }}
													/>
												}
												label="No"
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={3}>
									<Autocomplete
										options={operators ? operators : []}
										getOptionLabel={(option) => option?.name}
										value={data.same_operator_lessee ? data.lessee : data?.operator}
										onChange={(e, value) => onFieldChange("operator", value)}
										disabled={data.same_operator_lessee ? true : false}
										renderInput={(params) => (
											<TextField
												variant="outlined"
												label="Operator"
												margin="normal"
												disabled={data.same_operator_lessee ? true : false}
												required={!data.same_operator_lessee ? true : false}
												error={error.operator ? true : false}
												helperText={error.operator ? error.operator : " "}
												onFocus={() => onRestErrorKey("operator")}
												{...params}
												fullWidth
												InputLabelProps={{ shrink: true }}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12} md={3}>
									<FormControl component="fieldset" style={{ top: "11px" }}>
										<FormLabel className="MuiInputLabel-shrink" component="label" id="form-label" > Ownership Status</FormLabel>
										<RadioGroup
											row
											aria-label="position"
											name="ownership_type"
											defaultValue="top"
											margin="normal"
											fullWidth
										>
											<FormControlLabel
												value={1}
												control={
													<Radio
														checked={data.ownership_type === 1}
														onChange={() => onFieldChange("ownership_type", 1)}
														color="primary"
														size="small"
													/>
												}
												label="Owned"
												InputLabelProps={{ shrink: true }}
												margin="normal"
												fullWidth
											/>
											<FormControlLabel
												value={2}
												fullWidth
												control={
													<Radio
														checked={data.ownership_type === 2}
														onChange={() => onFieldChange("ownership_type", 2)}
														color="primary"
														size="small"
													/>
												}
												label="Managed"
												InputLabelProps={{ shrink: true }}
											/>
										</RadioGroup>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={3}>
									<Autocomplete
										options={owners ? owners : []}
										getOptionLabel={(option) => option?.name}
										value={data.owner_portfolio}
										disabled={data.ownership_type !== 2 ? true : false}
										onChange={(e, value) => onFieldChange("owner_portfolio", value)}
										renderInput={(params) => (
											<TextField
												variant="outlined"
												label="Portfolio"
												margin="normal"
												error={error.portfolio ? true : false}
												required={data.ownership_type === 2 ? true : false}
												helperText={error.portfolio ? error.portfolio : ""}
												onFocus={() => onRestErrorKey("portfolio")}
												{...params}
												fullWidth
												InputLabelProps={{ shrink: true }}
											/>
										)}
									/>
								</Grid>
							</Grid>}
						</form>
						<div className='basicDetail-border'></div>
						{match?.params?.assetType == 1 ?
							<form>
								{Object.keys(data?.engine_detail).map((label) =>
									<Grid container spacing={1}>
										<Grid item xs={12} md={3}>
											<Autocomplete
												options={engineTypes ? engineTypes : []}
												getOptionLabel={(option) => option?.name}
												value={data?.engine_detail?.[label]?.engine_type || null}
												onChange={(e, value) => onFieldChange("engine_type", value, 'engine_detail', label)}
												renderInput={(params) => (
													(
														<TextField
															{...params}
															variant="outlined"
															label={`${label === 1 || label === 3 ? 'LH ' : 'RH '} Engine Type/Model`}
															margin="normal"
															fullWidth
															InputLabelProps={{ shrink: true }}
														/>
													)
												)}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												variant="outlined"
												label={`${label === 1 || label === 3 ? 'LH ' : 'RH '} Engine TSN`}
												fullWidth
												margin="normal"
												value={data?.engine_detail?.[label]?.tsn || ''}
												onChange={(e, value) => onFieldChange("tsn", e.target.value, 'engine_detail', label)}
												inputProps={{ maxLength: 20 }}
												InputLabelProps={{ shrink: true }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												variant="outlined"
												label="LH Engine CSN"
												fullWidth
												margin="normal"
												value={data?.engine_detail?.[label]?.csn || ''}
												onChange={(e, value) => onFieldChange("csn", e.target.value, 'engine_detail', label)}
												inputProps={{ maxLength: 20 }}
												InputLabelProps={{ shrink: true }}
											/>
										</Grid>
										<Grid item xs={12} md={3}>
											<TextField
												variant="outlined"
												label="LH Serial Number"
												fullWidth
												margin="normal"
												value={data?.engine_detail?.[label]?.esn || ''}
												onChange={(e, value) => onFieldChange("esn", e.target.value, 'engine_detail', label)}
												inputProps={{ maxLength: 20 }}
												InputLabelProps={{ shrink: true }}
											/>
										</Grid>
									</Grid>
								)}
							</form> : null
						}
					</DialogContent>
				</DialogContent>
				<DialogActions>
					<Button color="primary" variant='outlined' onClick={() => toggleModalFn()}>Cancel</Button>
					<Button color="primary" variant="contained" onClick={onUpdateBasicDetail}>
						{isLoading ? <CircularProgress color="inherit" size={24} /> : 'Save'}
					</Button>
				</DialogActions>
			</Dialog>
			{isLoading ? <PageLoader /> : null}
		</Fragment>
	)
}
export default withRouter(EditBasicDetail);
