import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip, IconButton, Dialog, } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined"
import { withRouter } from "react-router-dom";

const TechSpecList = ({ item, toggleModalFn, utilisationData }) => {
    return (
        <section className='basic-detail-listing'>
            <Accordion >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="p-5" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Technical Specification</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <Grid className="left-panel" item xs={12} style={{ backgroundColor: " #efefef" }} > <Table className="table-wrapper">
                            <TableHead>
                                <TableRow>
                                    <TableCell>A/C Model</TableCell>
                                    <TableCell>Operator (Lessee)</TableCell>
                                    <TableCell>Operator (Sub-Lessee)</TableCell>
                                    <TableCell>Owner of A/C </TableCell>
                                    <TableCell>Lessor</TableCell>
                                    <TableCell>Delivery Date</TableCell>
                                    <div id="edit-basic-detail" className="d-flex">
                                        <span class="editBasicDetail">
                                            <Tooltip title="Edit">
                                                <IconButton className="edit-asset-icon" onClick={toggleModalFn} > <CreateOutlinedIcon /> </IconButton>
                                            </Tooltip>
                                        </span>
                                    </div>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.aircraft_model || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.lessee?.name || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.operator?.name || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.owner_aircraft || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.lessor_name?.name || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.delivery_date_to_current_operator || "--"} </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableHead className="table-head">
                                <TableRow>
                                    <TableCell>Monthly EFH</TableCell>
                                    <TableCell>Monthly EFC</TableCell>
                                    <TableCell>Date of Pucharse</TableCell>
                                    <TableCell>Next Delivery Date</TableCell>
                                    <TableCell>Location (A/C Reg.)</TableCell>
                                    <TableCell>Location (Position)</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="font-b"> {utilisationData?.sort((a, b) => b - a).map(filterId => filterId?.monthly_FH)[0] || item?.average_monthly_hours || "--"} </TableCell>
                                    <TableCell className="font-b"> {utilisationData?.sort((a, b) => b - a).map(filterId => filterId?.monthly_FC)[0] || item?.average_monthly_cycles || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.purchase_date || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.next_delivery_date || "--"} </TableCell>
                                    <TableCell className="font-b"> {utilisationData?.sort((a, b) => b - a).map(filterId => filterId?.location_reg_aircraft)[0] || item?.new_tec_spec?.location_reg_aircraft || "--"} </TableCell>
                                    <TableCell className="font-b"> {utilisationData?.sort((a, b) => b - a).map(filterId => filterId?.location_position?.label)[0] || item?.position?.label || "--"} </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableHead className="table-head">
                                <TableRow>

                                    <TableCell>Location (Country)</TableCell>
                                    <TableCell>Operating Region</TableCell>
                                    <TableCell>Operating Enviroment</TableCell>
                                    <TableCell>Date of Installation</TableCell>
                                    <TableCell>Date of Removal</TableCell>
                                    <TableCell>Avg. Derate %</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="font-b"> {utilisationData?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] || item?.country || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.operating_region?.name || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.operating_environment || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.date_of_installation || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.date_of_removal || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.avg_derate || "--"} </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableHead className="table-head">
                                <TableRow>
                                    <TableCell>Engine Stand Prime P/N</TableCell>
                                    <TableCell>Engine Stand Prime S/N</TableCell>
                                    <TableCell>Engine Stand Base P/N</TableCell>
                                    <TableCell>Engine Stand Base S/N</TableCell>
                                    <TableCell>Engine Stand Cradle P/N</TableCell>
                                    <TableCell>Engine Stand Cradle S/N</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.engine_stand_part_number || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.engine_stand_serial_number || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.engine_stand_base_pn || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.engine_stand_base_sn || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.engine_stand_cradle_pn || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.engine_stand_cradle_sn || "--"} </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableHead className="table-head">
                                <TableRow>
                                   <TableCell>Engine Stand Location</TableCell>
                                    <TableCell>Asset owner</TableCell>
                                    <TableCell>QEC Config.</TableCell>
                                    <TableCell>QEC P/N</TableCell>
                                    <TableCell>Stand</TableCell>
                                    <TableCell>Bag</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                   <TableCell className="font-b"> {item?.new_tec_spec?.engine_stand_location || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.owner?.name || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.qec_config || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.qec_part_number || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.stand || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.bag || "--"} </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableHead className="table-head">
                                <TableRow>
                                    <TableCell>Sub Status</TableCell>
                                    <TableCell>Lease Start Date</TableCell>
                                    <TableCell>Lease Term Date</TableCell>
                                    <TableCell>Redelivery Date</TableCell>
                                    <TableCell>Month Remaining</TableCell>
                                    <TableCell>On/Off Lease Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="font-b"> {item?.sub_status?.label || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.lease_start_date || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.lease_term_date || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.redelivery_date || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.month_remaining >= 0 && item?.new_tec_spec?.month_remaining || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.status?.label || "--"} </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableHead className="table-head">
                                <TableRow>
                                   <TableCell>Min LLP Prior to Term. Date</TableCell>
                                    <TableCell>EEC Software P/N </TableCell>
                                    <TableCell>EEC Software Version</TableCell>
                                    <TableCell>Monthly Use Fee</TableCell>
                                    <TableCell>Monthly LLP Fee</TableCell>
                                    <TableCell>Accumulated MR</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                   <TableCell className="font-b"> {item?.new_tec_spec?.min_llp_prior_to_term_date || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.eec_software_part_number || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.eec_software_version || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.monthly_use_fee || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.monthly_llp_fee || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.accumulated_mr || "--"} </TableCell>
                                </TableRow>
                            </TableBody>
                            <TableHead className="table-head">
                                <TableRow>
                                    <TableCell>Avg. Flight Ratio</TableCell>
                                    <TableCell>Preservation Date</TableCell>
                                    <TableCell>Preservation Expiry Date</TableCell>
                                    <TableCell>Target On-Wing Hours Sum</TableCell>
                                    <TableCell>Target On-Wing Cycle Sum</TableCell>
                                    <TableCell>Min. Build Standard</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell className="font-b"> {item?.avg_flight_ratio ? (item?.avg_flight_ratio).toFixed(2) : "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.preservation_date || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.preservation_expiry_date || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.target_on_wing_hours || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.target_on_wing_cyle || "--"} </TableCell>
                                    <TableCell className="font-b"> {item?.new_tec_spec?.min_build_standard || "--"} </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </section>
    )
}

export default withRouter(TechSpecList)