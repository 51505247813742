import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus,getLocalStorageInfo } from '../../../../utils';
import {assetType,imgStoragePath } from "../../../../constants";
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { opHistoryObj } from '.';
import { smbcInstance, specificKey, specificMessTsn, specificMessCsn } from '../..';


function AddEditOpHistory({ match, toggleModalFn, opHistoryData, basicdetail, modeType, getResponseBack, list }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(opHistoryData ? opHistoryData : opHistoryObj)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
    const [linkFile, setlinkFile] = useState({ modal: false, data: null })
    const [opThrustList, setOpThrustList] = useState([])
    const [operatorList, setOperatorList] = useState([])
    const [positionList, setPositionList] = useState([])

    useEffect(() => {
        OpThrustList()
        postionList()
        OperatorList()
    }, [])

    const onFieldChange = (keyParam, value) => {
        setData({ ...data, [keyParam]: value });
    }
    const getData = (selectedFile, type) => {
        setData({ ...data, checklist: selectedFile, linkFile: type });
    }

    const uploadFileData = (file) => {
        setData({ ...data, checklist: file })
    }

    const uploadFileFn = (file, uuid, type) => {
        if (uuid) {
            let storeChecklistData = [];
            let folderUuid = [];

            const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
            const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

            for (const arr of updatedChecklist) {
                storeChecklistData = storeChecklistData.concat(arr);
            }
            for (const arr of folderUUID) {
                folderUuid = folderUuid.concat(arr);
            }
            setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
        } else {
            if (Array.isArray(file)) {
                const updatedAttachments = [...(data?.attachments || []), ...file];
                setData({ ...data, 'attachments': updatedAttachments });
            } else {
                console.error("Value for attachments is not an array:", file);
            }
        }
    }
    const OpThrustList = () => {
        globalGetService(`camo/current_thrust/${match.params.assetType}/?engine_family_engine_type=${basicdetail?.engine_type?.engine_family_type_id}`,)
            .then((response) => {
                if (checkApiStatus(response)) {
                    setOpThrustList(response?.data)
                }
            });
    }
    const OperatorList = () => {
        globalGetService(`console/lessees/`)
            .then((response) => {
                if (checkApiStatus(response)) {
                    setOperatorList(response?.data?.data?.lessees)
                }
            });
    }
    const postionList = () => {
        globalGetService(`camo/all_dropdown_list/`)
            .then((response) => {
                if (checkApiStatus(response)) {
                    setPositionList(response?.data?.position_list)
                }
            });
    }
    const addUploadFile = (item) => {
        if (data?.linkFile === "link") {
            let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
            globalPutService(`camo/ope_hist/${item?.id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        toggleModalFn()
                        getResponseBack(response.data.data);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0) {
            if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
                let formData = new FormData();
                formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
                for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
                    formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
                }
                globalPutService(`camo/ope_hist/${item?.id}/document-checklists/`, formData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    });
            }
        }

    }
    const importFile = (file) => {
        var formData = new FormData();
        Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
            formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
            formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
            formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
            formData.append("[" + key + "]module_id", file?.id)
            formData.append("[" + key + "]module_type_id", 13)
            formData.append("[" + key + "]asset_type_id", match.params.assetType)
            formData.append("[" + key + "]asset_id", match.params.asset)
        })
        globalFileUploadService(`camo/attachment/upload/`, formData)
            .then(response => {
                toggleModalFn(); getResponseBack()
                if (checkApiStatus(response)) {
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })

    }
    const onRemoveFile = (file, type, fileId,folder) => {
        if (modeType === "add") {
            if (type === "checklist") {
                setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
            } else {
                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
            }
        } else {
            if (type === "checklist") {
                let payload = {};
                payload.delete = true;
                payload.folder_ids =data.checklist.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
			    payload.file_ids = data.checklist.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
                globalPutService(`camo/ope_hist/${data.id}/document-checklists/`, payload)
                    .then((response) => {
                        if (checkApiStatus(response)) {
                            const updatedChecklist = { ...data.checklist };
                            updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                            updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
                            setData({ ...data, checklist: updatedChecklist });
                            getResponseBack()
                        }
                    });
            } else {
                if (file.id) {
                    globalDeleteService(`camo/attachment/${file.id}/delete/`)
                        .then((response) => {
                            if (checkApiStatus(response)) {
                                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                                getResponseBack()
                            }
                        });
                } else {
                    setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                }
            }
        }
    }

    const onAddNewAsset = () => {
        let validationInputs = {}
        if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')) {
            // if (data.removal_engine_tsn && !specificKey.includes(data?.removal_engine_tsn?.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.removal_engine_tsn)) {
            //     validationInputs.removal_engine_tsn = specificMessTsn;
            // }
            // if (data.removal_engine_csn && !specificKey.includes(data?.removal_engine_csn?.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.removal_engine_csn)) {
            //     validationInputs.removal_engine_csn = specificMessCsn;
            // }
            if (typeof data.removal_engine_csn === 'string' && !specificKey.includes(data.removal_engine_csn.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.removal_engine_csn)) {
                validationInputs.removal_engine_csn = specificMessCsn;
            }
            // if (data.fitment_engine_tsn && !specificKey.includes(data?.fitment_engine_tsn?.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.fitment_engine_tsn)) {
            //     validationInputs.fitment_engine_tsn = specificMessTsn;
            // }
            if (typeof data.fitment_engine_csn === 'string' && !specificKey.includes(data.fitment_engine_csn.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.fitment_engine_csn)) {
                validationInputs.fitment_engine_csn = specificMessCsn;
            }
            // if (data.removal_tslsv && !specificKey.includes(data?.removal_tslsv?.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.removal_tslsv)) {
            //     validationInputs.removal_tslsv = specificMessTsn;
            // }
            // if (data.removal_cslsv && !specificKey.includes(data?.removal_cslsv?.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.removal_cslsv)) {
            //     validationInputs.removal_cslsv = specificMessCsn;
            // }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/ope_hist/${payload.id}/`, payload)
                    .then(response => {
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                if (parseFloat(data?.fitment_engine_tsn) > parseFloat(data?.removal_engine_tsn)) {
                    setLoading(false)
                   return ( enqueueSnackbar("Fitment engine TSN is less than Removal Engine TSN.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } }))
                 
                }else if(data?.fitment_engine_csn > data?.removal_engine_csn){
                    setLoading(false)
                    return ( enqueueSnackbar("Fitment engine CSN is less than Removal Engine CSN.", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } }))

                }else {
                    globalPostService(`camo/ope_hist/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType })
                        .then(response => {
                            setLoading(false)
                            if (checkApiStatus(response)) {
                                enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                                toggleModalFn(); getResponseBack()
                                addUploadFile(response?.data?.data)
                                importFile(response?.data?.data)
                            } else {
                                enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                            }
                        })
                }

            }
        } else {
            setError(validationInputs)
        }
    }

    const removalHoursUsed = () => {
        if(data?.removal_engine_tsn?.length > 0  && data?.fitment_engine_tsn?.length > 0){
           return (data.removal_engine_tsn - data?.fitment_engine_tsn) > 0  ? (data.removal_engine_tsn - data?.fitment_engine_tsn)?.toFixed(2) : 0 
        }else if(data?.removal_engine_tsn?.length > 0  && data?.fitment_engine_tsn === null){
            return (data.removal_engine_tsn)
        }else{
            return (data.removal_hours_used = 0)
        }
    }
    const removalCycleUsed = () => {
        if(data?.removal_engine_csn  && data?.fitment_engine_csn ){
           return (data.removal_engine_csn - data?.fitment_engine_csn) > 0   ? (data.removal_engine_csn - data?.fitment_engine_csn)?.toFixed(2) : 0 
        }else if(data?.removal_engine_csn?.length > 0  && data?.fitment_engine_csn === null){
            return (data.removal_engine_csn)
        }else{
            return (data.removal_cycle_used = 0)
        }
    }
    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Update Op History" : " Add Op History"} </DialogTitle>
                <DialogContent dividers >
                    <Grid spacing={2} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    label="Fitment Date"
                                    id="fitment_date"
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    fullWidth
                                    margin="normal"
                                    disableFuture
                                    minDate={list[0]?.removal_date ? list[0]?.removal_date : moment(basicdetail?.date_of_manufacture)}
                                    InputLabelProps={{ shrink: true }}
                                    value={data.fitment_date ? data.fitment_date : list[0]?.removal_date || null}
                                    onChange={(data, value) => onFieldChange("fitment_date", moment(data).format(backendDateFormat))}
                                    error={error.fitment_date ? true : false}
                                    helperText={error.fitment_date ? error.fitment_date : ""}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                options={operatorList || []}
                                getOptionLabel={(option) => option?.name}
                                id="fitment_operator"
                                value={data?.fitment_operator || null}
                                onChange={(e, value) => onFieldChange("fitment_operator", value)}
                                InputLabelProps={{ shrink: true }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Fitment Operator"
                                        margin="normal"
                                        fullWidth
                                        placeholder="Select Fitment Operator"
                                        variant="outlined"

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Fitment A/C"
                                id="fitment_aircraft"
                                value={data.fitment_aircraft}
                                onChange={(e) => onFieldChange('fitment_aircraft', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Fitment Tail"
                                id="fitment_tail"
                                value={data.fitment_tail}
                                onChange={(e) => onFieldChange('fitment_tail', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 10 }}
                                fullWidth
                                margin="normal"
                            />
                        </Grid>

                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                options={positionList || []}
                                getOptionLabel={(option) => option?.label}
                                id="fitment_operator"
                                value={data?.fitment_psn || ''}
                                onChange={(e, value) => onFieldChange("fitment_psn", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Fitment PSN"
                                        margin="normal"
                                        fullWidth
                                        placeholder="Select Fitment PSN"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                options={opThrustList || []}
                                getOptionLabel={(option) => option?.name}
                                id="fitment_thrust"
                                value={data?.fitment_thrust || null}
                                onChange={(e, value) => onFieldChange("fitment_thrust", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Fitment Thrust"
                                        margin="normal"
                                        fullWidth
                                        placeholder="Select Fitment Thrust"
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Fitment A/F Hours"
                                id="fitment_aircraft_flying_hours"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.fitment_aircraft_flying_hours ? data.fitment_aircraft_flying_hours : ''}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^\d.]/g, '');
                                    const dotCount = (numericValue.match(/\./g) || []).length;
                                    const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                    if (isValid || value === "") {
                                        onFieldChange("fitment_aircraft_flying_hours", numericValue);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Fitment A/F Cycle"
                                name="fitment_aircraft_flying_cycle"
                                value={data.fitment_aircraft_flying_cycle ? data.fitment_aircraft_flying_cycle : ''}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const isValid = regexConstants.numberRegex.test(inputValue);
                                    if (isValid) { onFieldChange('fitment_aircraft_flying_cycle', inputValue); }
                                }}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 5 }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container id="ht-dropdown">
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        Fitment Engine TSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                name="fitment_engine_tsn"
                                id="fitment_engine_tsn"
                                error={error.fitment_engine_tsn ? true : false}
                                helperText={error.fitment_engine_tsn ? error.fitment_engine_tsn : ""}
                                value={data.fitment_engine_tsn || ''}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                margin="normal"
                                fullWidth
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^\d.]/g, '');
                                    const dotCount = (numericValue.match(/\./g) || []).length;
                                    const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                    if (isValid || value === "") {
                                        onFieldChange("fitment_engine_tsn", numericValue);
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        Fitment Engine CSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                name="fitment_engine_csn"
                                id="fitment_engine_csn"
                                value={data.fitment_engine_csn ? data.fitment_engine_csn : ''}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const isValid = regexConstants.numberRegex.test(inputValue);
                                    if (isValid) { onFieldChange('fitment_engine_csn', inputValue); }
                                }}
                                margin="normal"
                                fullWidth
                                error={error.fitment_engine_csn ? true : false}
                                helperText={error.fitment_engine_csn ? error.fitment_engine_csn : ""}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                inputProps={{ maxLength: 5 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    label="Removal Date"
                                    id="removal_date"
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    margin="normal"
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    minDate={list[0]?.removal_date ? list[0]?.removal_date : moment(basicdetail?.date_of_manufacture)}
                                    disableFuture
                                    value={data.removal_date}
                                    onChange={(data, value) => onFieldChange("removal_date", moment(data).format(backendDateFormat))}
                                    error={error.removal_date ? true : false}
                                    helperText={error.removal_date ? error.removal_date : ""}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Removal A/F Hours"
                                id="removal_aircraft_flying_hours"
                                value={data.removal_aircraft_flying_hours ? data.removal_aircraft_flying_hours : ''}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^\d.]/g, '');
                                    const dotCount = (numericValue.match(/\./g) || []).length;
                                    const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                    if (isValid || value === "") {
                                        onFieldChange("removal_aircraft_flying_hours", numericValue);
                                    }
                                }}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Removal A/F Cycle"
                                id="removal_aircraft_flying_cycle"
                                value={data.removal_aircraft_flying_cycle ? data.removal_aircraft_flying_cycle : ''}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const isValid = regexConstants.numberRegex.test(inputValue);
                                    if (isValid) { onFieldChange('removal_aircraft_flying_cycle', inputValue); }
                                }}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 5 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        Removal Engine TSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="removal_engine_tsn"
                                value={data.removal_engine_tsn}
                                error={error.removal_engine_tsn ? true : false}
                                helperText={error.removal_engine_tsn ? error.removal_engine_tsn : ""}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                margin="normal"
                                fullWidth
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^\d.]/g, '');
                                    const dotCount = (numericValue.match(/\./g) || []).length;
                                    const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                    if (isValid || value === "") {
                                        onFieldChange("removal_engine_tsn", numericValue);
                                    }
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        Removal Engine CSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="removal_engine_csn"
                                value={data.removal_engine_csn || ''}
                                error={error.removal_engine_csn ? true : false}
                                helperText={error.removal_engine_csn ? error.removal_engine_csn : ""}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                margin="normal"
                                fullWidth
                                inputProps={{ maxLength: 5 }}
                                onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange('removal_engine_csn', e.target.value)}}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        Removal TSLSV
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="removal_tslsv"
                                error={error.removal_tslsv ? true : false}
                                helperText={error.removal_tslsv ? error.removal_tslsv : ""}
                                value={data?.removal_tslsv ? data?.removal_tslsv : ""}
                                margin="normal"
                                fullWidth
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^\d.]/g, '');
                                    const dotCount = (numericValue.match(/\./g) || []).length;
                                    const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                    if (isValid || value === "") {
                                        onFieldChange("removal_tslsv", numericValue);
                                    }
                                }}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                            />
                        </Grid>

                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        Removal CSLSV
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="removal_cslsv"
                                value={data.removal_cslsv ? data.removal_cslsv : ''}
                                error={error.removal_cslsv ? true : false}
                                helperText={error.removal_cslsv ? error.removal_cslsv : ""}
                                margin="normal"
                                fullWidth
                                onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange('removal_cslsv', e.target.value)}}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                inputProps={{ maxLength: 5 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Removal Hours Used "
                                id="removal_hours_used"
                                value={removalHoursUsed()}
                                disabled
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Removal Cylce Used "
                                id="removal_cycle_used"
                                value={removalCycleUsed()}
                                margin="normal"
                                fullWidth
                                disabled
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="EGTM"
                                id="removal_egtm"
                                value={data.removal_egtm}
                                onChange={(e, value) => onFieldChange("removal_egtm", e.target.value)}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Removal Event"
                                id="removal_event"
                                value={data.removal_event}
                                onChange={(e, value) => onFieldChange("removal_event", e.target.value)}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Effective Work Carried Out"
                                id="maint_effective_work_carried_out"
                                value={data.maint_effective_work_carried_out}
                                onChange={(e, value) => onFieldChange("maint_effective_work_carried_out", e.target.value)}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Maint Shop"
                                id="maint_shop"
                                value={data.maint_shop}
                                onChange={(e, value) => onFieldChange("maint_shop", e.target.value)}
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                label="Remarks"
                                margin='normal'
                                variant='outlined'
                                fullWidth
                                value={data.remarks}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("remarks", e.target.value)}
                                rows={3}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
                                <span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
                                <span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
                            </div>
                        </Grid>
                        <div className="checklist-file" style={{ overflow: "auto" }}>
                        {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
                            <ul className='list-inline'>
                                {(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
                                    return (
                                        <li className='list-inline-item'>
                                            <span className='file-name'>{attachments.name}</span>
                                            <span className='file-remove'>
                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
                                                <CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                            </span>
                                        </li>)
                                }

                                )}
                                {(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
                                    data.checklist?.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        {data?.checklist.some(item => item.type === 'folder') ? null
                                                            : <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.files?.length > 0) &&
                                    data.checklist?.files.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                            {(data.checklist?.folders?.length > 0) &&
                                    data.checklist?.folders.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                    <a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.asset_slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                </Tooltip>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {uploadFile.modal ?
                <UploadFile
                    modType="ope_hist"
                    type="upload"
                    list={list}
                    actionType={modeType}
                    editFormId={data?.id}
                    handleClose={() => setUploadFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}
                />
                : null}
            {linkFile.modal ?
                <LinkFiles
                    modType="ope_hist"
                    type="link"
                    list={list}
                    editFormId={data?.id}
                    actionType={modeType}
                    uploadFileInfo={data}
                    toggleModalFn={() => setlinkFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    onLinkFile={getData}
                />
                : null
            }
            {isLoading ? <PageLoader /> : null}
        </>
    )

}
export default withRouter(AddEditOpHistory)