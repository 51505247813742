import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
const SrHeader = ({ sortInfo, createSortHandler }) => {
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }
    return (
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
                <TableCell colSpan="9" align="center"></TableCell>
                <TableCell colSpan="3" align="center">Repair Compliance</TableCell>
                <TableCell colSpan='1' align="center"></TableCell>
                <TableCell colSpan='3' align="center">Last Done</TableCell>
                <TableCell colSpan='2' align="center"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Action </TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('ata_chapter', 'ATA Chapter')} </TableCell>
                <TableCell style={{ minWidth: "150px" }}> {tableSorting('repair_ref_number', 'Repair Ref No.')} </TableCell>
                <TableCell style={{ minWidth: "180px" }}>{tableSorting('repair_type', 'Repair Type')}  </TableCell>
                <TableCell style={{ minWidth: "280px" }}>{tableSorting('repair_description', 'Repair Description')} </TableCell>
                <TableCell style={{ minWidth: "180px" }}>{tableSorting('repeat_inspection', 'Repair Inspection')}  </TableCell>
                <TableCell style={{ minWidth: "180px" }}>{tableSorting('repair_location', 'Repair Location')} </TableCell>
                <TableCell style={{ minWidth: "180px" }}>{tableSorting('repair_detail', 'Repair Detail')} </TableCell>
                <TableCell style={{ minWidth: "180px" }}>{tableSorting('repair_compliance_ref', 'Repair Compliance Ref')} </TableCell>
                <TableCell >{tableSorting('repair_compliance_date', 'Date')} </TableCell>
                <TableCell >{tableSorting('repair_compliance_tsn', 'TSN')} </TableCell>
                <TableCell >{tableSorting('repair_compliance_csn', 'CSN')} </TableCell>
                <TableCell style={{ minWidth: "150px" }}>Repair Next Due </TableCell>
                <TableCell >{tableSorting('last_done_date', 'Date')} </TableCell>
                <TableCell>{tableSorting('last_done_fh', 'FH')} </TableCell>
                <TableCell>{tableSorting('last_done_fc', 'FC')} </TableCell>
                <TableCell style={{ minWidth: "280px" }}>  {tableSorting('remark', 'Remarks')} </TableCell>
                <TableCell>Attachments </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default SrHeader