import React from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import RevisionHistory from '../../../../shared_elements/components/RevisionHistory';
import AttachFileIcon from "@material-ui/icons/AttachFile";
const UtilizationListing = ({ item, toggleModalFn, toggleDelteModalFn, handleOpenAttachments, list }) => {
    function getAttachmentCount(item) {
        const { attachments, checklist } = item;
        const { files, folders } = checklist || {};
      
        const attachmentCount = attachments?.length || 0;
        const fileCount = files?.length || 0;
        const folderCount = folders?.length || 0;
      
        if (attachmentCount && fileCount && folderCount) {
          return attachmentCount + fileCount + folderCount;
        } else if (fileCount && folderCount) {
          return fileCount + folderCount;
        } else if (attachmentCount && folderCount) {
          return attachmentCount + folderCount;
        } else if (attachmentCount && fileCount) {
          return attachmentCount + fileCount;
        } else {
          return folderCount || fileCount || attachmentCount || '--';
        }
      }
    const attachmentCount = getAttachmentCount(item);
    let utilUrl = `audit/camo/utilization/?object_id=${item.id}&action=0,1&timezone=${moment().format()}&timestamp=${Math.floor(Date.now() / 1000)}`
    return (
        <TableRow>
            <TableCell>
                <Tooltip title="Edit" placement='top'>
                    <IconButton style={{ padding: 'unset' }} color='primary' onClick={toggleModalFn} > <CreateOutlinedIcon /> </IconButton>
                </Tooltip>
                {list?.sort((a, b) => b - a).filter(item => item)[0]?.id === item?.id ? <Tooltip title="Delete" placement='top' >
                    <IconButton style={{ padding: 'unset' }} color='secondary' onClick={toggleDelteModalFn}> <DeleteOutlinedIcon /> </IconButton>
                </Tooltip> : null}
                <RevisionHistory buttonType={true} url={utilUrl} />
            </TableCell>
            <TableCell> {item.utilization_period ? item.utilization_period?.data : "--"} </TableCell>
            <TableCell> {item?.monthly_FH || '--'}</TableCell>
            <TableCell> {item?.monthly_FC || '--'}</TableCell>
            <TableCell> {item?.monthly_derate || '--'}</TableCell>
            <TableCell> {item.tsn || '--'} </TableCell>
            <TableCell> {item.csn || '--'} </TableCell>
            <TableCell> {item.current_operating_thrust ? item.current_operating_thrust?.name : "--"} </TableCell>
            <TableCell> {item.location_reg_aircraft ? item.location_reg_aircraft : "--"}</TableCell>
            <TableCell> {item.location_position?.label ? item.location_position?.label : "--"} </TableCell>
            <TableCell> {item?.engine_stand_location ? item?.engine_stand_location : "--"} </TableCell>
            <TableCell> {item.location_country ? item.location_country : "--"} </TableCell>
            <TableCell> {item.operating_environment ? item.operating_environment : "--"} </TableCell>
            <TableCell> {item.date_of_installation ? item.date_of_installation : "--"} </TableCell>
            <TableCell> {item.date_of_removal ? item.date_of_removal : "--"} </TableCell>
            <TableCell> {item.reason_of_removal ? item.reason_of_removal : "--"} </TableCell>
            <TableCell>
                {attachmentCount && (
                    <Tooltip title="View Attachments">
                        <span className="center">
                            <AttachFileIcon className="attach-icon" onClick={handleOpenAttachments} />
                            <a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
                        </span>
                    </Tooltip>
                )}
            </TableCell>
        </TableRow>
    )
}

export default withRouter(UtilizationListing)