import { globalDeleteService, globalGetService, globalExportService, globalPostService, globalFileUploadService, globalPutService, } from "../../../utils/globalApiServices"
import { downloadFileType, checkApiStatus, permissionCheckFnforCamo, } from "../../../utils"
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { trackActivity } from "../../../utils/mixpanel";
import moment from "moment";
let timeoutId

export function getAMPComplianceApi(queryParams = {}, loaderType, props = {}, searchString = null) {
	if (permissionCheckFnforCamo({ primaryKey: "amp_compliance", keyIndex: "R", })) {
		this.setState({ pageLoader: true })
		const { asset, assetType } = props.match.params
		clearTimeout(timeoutId)
		timeoutId = setTimeout(() => {
			globalGetService(`camo/amp/list/`, {
				...queryParams,
				per_page: queryParams.per_page ? queryParams.per_page : 50,
				asset: asset,
				asset_type: assetType,
				search: searchString,
			}).then((response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				} else {
					this.setState({ pageLoader: false, assetsDetail: { list: response.data.results, }, })
					delete queryParams.page
					delete queryParams.per_page
					delete queryParams.asset_type
					delete queryParams.sort
					delete queryParams.sort_by
					this.setState((prevState) => ({
						...prevState,
						filter: queryParams,
						assetsDetail: {
							...prevState.assetsDetail,
							//list: response.data.results,
							pagination: {
								...prevState.assetsDetail.pagination,
								total: response.data.pagination.total,
								per_page: response.data.pagination.per_page,
								page: parseInt(response.data.pagination.current_page),

							},
							new_task_last_used_folder_uuid: response.data.new_task_last_used_folder_uuid,
							last_used_folder_uuid_link_document: response.data.last_used_folder_uuid_link_document,
							checkbox_last_used_folder_uuid: response.data.checkbox_last_used_folder_uuid,
							checkbox_last_used_folder_name: response.data.checkbox_last_used_folder_name
						},
					}))
				}
			})
		}, 500)
	}
}

export function deleteAssetDetailRow(item, props, per_page) {
	globalDeleteService(`camo/amp/${item.id}/delete/`).then((response) => {
		if (response.status === 200) {
			props.enqueueSnackbar("Record Deleted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
			trackActivity('CAMO', {
				event_type: 'Item Deleted',
				page_title: 'Asset Detail Row',
				item_id: item.id ? item.id : ''
			})
		}
		if (response.status === 500) {
			props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
		}
		this.getAMPComplianceApi({ per_page: per_page }, "skeletonLoader", props)
	})
}

export function addTaskFormApi(data, props) {
	if (permissionCheckFnforCamo({ primaryKey: "amp_compliance", keyIndex: "C", })) {
		return new Promise(function (resolve, reject) {
			globalPostService(`camo/amp/create/`, data).then((response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				} else {
					resolve(response)
				}
			})
		})
	}
}

export function fillEditFromApi(item, props) {
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/amp/view/${item.id}/`).then((response) => {
			if (response.status === 500) {
				props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
			} else {
				resolve(response)
			}
		})
	})
}

export function UpdateTaskFormApi(updateId, updatedData, props, per_page) {
	if (
		permissionCheckFnforCamo({ primaryKey: "amp_compliance", keyIndex: "U", })
	) {
		this.setState({ pageLoader: true })
		globalPutService(`camo/amp/${updateId}/update/`, updatedData).then(
			(response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				} else {
					if (response) {
						this.setState({ pageLoader: false })
						props.enqueueSnackbar("Record Updated Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
					}
					this.getAMPComplianceApi({ per_page: per_page }, "skeletonLoader", props)
				}
			}
		)
	}
}

export function uploadFileApi(uploadId, formdata, props, per_page, haveAttachments) {
	this.setState({ pageLoader: true });
	if (haveAttachments) {
		globalFileUploadService(`camo/attachment/upload/`, formdata).then(
			(response) => {
				if (checkApiStatus(response)) {
					this.setState({ pageLoader: false });
					if (response.data.data.length > 0) {
						// props.enqueueSnackbar("Data and Attachment Submitted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" } });
					}
					this.getAMPComplianceApi({ per_page: per_page }, "skeletonLoader", props);
				} else {
					props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
				}
			}
		);
	} else {
		this.getAMPComplianceApi({ per_page: per_page }, "skeletonLoader", props);
		props.enqueueSnackbar("Data Submitted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" } });
		this.setState({ pageLoader: false });
	}
}

export function exportAssetDetailListApi(queryParams = {}, loaderType, props = {}) {
	this.setState({ pageLoader: true })
	const date = new Date()
	const todayeDate = moment(String(date)).format("DD-MM-YYYY")

	const { asset, assetType } = this.props.match.params
	delete queryParams.asset_type
	globalExportService(`/camo/amp/${asset}/${assetType}/export/`, {
		...queryParams,
	}).then((response) => {
		if (response.status === 500) {
			props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
		} else {
			this.setState({ pageLoader: false })
			downloadFileType(response.data, `AMP_${todayeDate}`, queryParams.download)
			this.props.enqueueSnackbar("Download of AMP List successful", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
			trackActivity("CAMO", { event_type: "Item Exported", page_title: "Asset Listing", extension: queryParams.download })
		}
	})
}

export function deleteAttachment(id, props) {
	globalDeleteService(`camo/attachment/${id}/delete/`).then((response) => {
		if (response.status === 500) {
			props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
		} else {
			if (response) {
				props.enqueueSnackbar("Attachment Deleted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				trackActivity("CAMO", { event_type: "Attachment Deleted", page_title: "AMP Compliance", item_id: id })
			}
		}
	})
}

export function importFileApi(formData, props, isXLSX, per_page) {
	if (isXLSX) {
		this.setState({ pageLoader: true })
		const { asset, assetType } = this.props.match.params
		globalFileUploadService(`camo/amp/${asset}/${assetType}/import/`, formData)
			.then((response) => {
				this.setState({ pageLoader: false })
				if (response.data.statusCode == 200) {
					props.enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
					trackActivity("CAMO", { event_type: 'File Imported', page_title: "AMP Compliance", asset_type: assetType ? assetType : '', asset: asset ? asset : '', form_data: formData ? formData : {} })
				} else {
					props.enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				}
				this.getAMPComplianceApi({ per_page: per_page }, "skeletonLoader", props);
			})
			.catch((err) => {
				console.error(err)
				props.enqueueSnackbar("Please import proper file", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
			})
	} else {
		props.enqueueSnackbar("Please Import only .XLSX File", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
	}
}

export function DownloadSampleFile(queryParams = {}, props = {}) {
	globalExportService(`camo/amp/sample-file/`).then((response) => {
		if (response.status === 500) {
			props.enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
		} else {
			downloadFileType(response.data, `SampleFile_AMP`, queryParams.download)
			trackActivity("CAMO", { event_type: 'Sample File Downloaded', page_title: "AMP Compliance", extension: queryParams.download })
		}
	})
}
export async function DownloadAllApi(queryParams = {}, pageLoader) {
	this.setState({ pageLoader: true })
	const attachment_list = queryParams.item.download_attachments;
	for (var i = 0; i < attachment_list.length; i++) {
		var encodedURL = attachment_list[i][1];
		var decodedURL = decodeURIComponent(encodedURL);
		attachment_list[i][1] = decodedURL;
	}
	const zip = new JSZip();
	const folder = zip.folder('DownloadedFiles');
	try {
		const downloadPromises = attachment_list.map(async (entry) => {
			const name = entry[0];
			const url = entry[1] || '';

			const res = await fetch(url);

			if (!res.ok) {
				throw new Error(`HTTP error! Status: ${res.status}`);
			}

			const blob = await res.blob();
			folder.file(name, blob);
		});

		await Promise.all(downloadPromises);

		const content = await zip.generateAsync({ type: 'blob' });
		saveAs(content, 'AMP.zip');
		this.setState({ pageLoader: false })
	} catch (error) {
		console.error('Error:', error);
	}
}
