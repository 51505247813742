import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip, CircularProgress, TextareaAutosize } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import { DropzoneArea } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { getLocalStorageInfo } from "../../../../utils";
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus } from '../../../../utils';
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { assetType, imgStoragePath } from "../../../../constants";
import { svObj } from '.';
import { smbcInstance, specificKey, specificMessTsn, specificMessCsn } from '../..';


function AddEditShopVisit({ match, toggleModalFn, shopVisitData, basicdetail, modeType, getResponseBack, list }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(shopVisitData ? shopVisitData : svObj)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
    const [linkFile, setlinkFile] = useState({ modal: false, data: null })

    useEffect(() => {

    }, [])

    const onFieldChange = (keyParam, value) => {
        setData({ ...data, [keyParam]: value });
    }
    const onRestErrorKey = (keyParam) => {
        setError({ [keyParam]: '' })
    }

    const uploadFileData = (file) => {
        setData({ ...data, checklist: file })
    }

    const getData = (selectedFile, type) => {
        setData({ ...data, checklist: selectedFile, linkFile: type });
    }

    const uploadFileFn = (file, uuid, type) => {
        if (uuid) {
            let storeChecklistData = [];
            let folderUuid = [];

            const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
            const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

            for (const arr of updatedChecklist) {
                storeChecklistData = storeChecklistData.concat(arr);
            }
            for (const arr of folderUUID) {
                folderUuid = folderUuid.concat(arr);
            }
            setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
        } else {
            if (Array.isArray(file)) {
                const updatedAttachments = [...(data?.attachments || []), ...file];
                setData({ ...data, 'attachments': updatedAttachments });
            } else {
                console.error("Value for attachments is not an array:", file);
            }
        }
    }
    const addUploadFile = (item) => {
        if (data?.linkFile === "link") {
            let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
            globalPutService(`camo/shop/${item?.id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        toggleModalFn()
                        getResponseBack(response.data.data);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0) {
            if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
                let formData = new FormData();
                formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
                for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
                    formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
                }
                globalPutService(`camo/shop/${item?.id}/document-checklists/`, formData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    });
            }
        }

    }
    const importFile = (file) => {
        var formData = new FormData();
        Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
            formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
            formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
            formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
            formData.append("[" + key + "]module_id", file?.id)
            formData.append("[" + key + "]module_type_id", 9)
            formData.append("[" + key + "]asset_type_id", match.params.assetType)
            formData.append("[" + key + "]asset_id", match.params.asset)
        })
        globalFileUploadService(`camo/attachment/upload/`, formData)
            .then(response => {
                toggleModalFn(); getResponseBack()
                if (checkApiStatus(response)) {
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })

    }
    const onRemoveFile = (file, type, fileId,folder) => {
        if (modeType === "add") {
            if (type === "checklist") {
                setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
            } else {
                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
            }
        } else {
            if (type === "checklist") {
                let payload = {};
                payload.delete = true;
				payload.folder_ids =data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
			    payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
                globalPutService(`camo/shop/${data.id}/document-checklists/`, payload)
                    .then((response) => {
                        if (checkApiStatus(response)) {
                            const updatedChecklist = { ...data.checklist };
                            updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                            updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
                            setData({ ...data, checklist: updatedChecklist });
                            getResponseBack()
                        }
                    });
            } else {
                if (file.id) {
                    globalDeleteService(`camo/attachment/${file.id}/delete/`)
                        .then((response) => {
                            if (checkApiStatus(response)) {
                                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                                getResponseBack()
                            }
                        });
                } else {
                    setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                }
            }
        }
    }

    const onAddNewAsset = () => {
        let validationInputs = {}
    
       if (!smbcInstance || !getLocalStorageInfo()?.user?.security_groups?.some(item=>item?.name === 'CAMO Lead')) {
            validationInputs = {
                ...validationInputs,
                removal_date: data.removal_date ? '' : 'Please Select Removal Date',
                removal_reason: data.removal_reason ? '' : 'PLease Enter Removal Reason',
                tsn_at_removal: data.tsn_at_removal ? '' : 'PLease Enter TSN at Removal',
                csn_at_removal: data.csn_at_removal ? '' : 'PLease Enter CSN at Removal',
                sv_completion_date: data.sv_completion_date ? '' : 'PLease Select SV Completion Date',
                sv_type: data.sv_type ? '' : 'PLease Enter SV Type',
            }
        }
        if (!smbcInstance) {
            if (typeof data.csn_at_removal === 'string' && !specificKey.includes(data.csn_at_removal.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.csn_at_removal)) {
                validationInputs.csn_at_removal = specificMessCsn;
            }
            if (typeof data.tsn_at_removal === 'string' && !specificKey.includes(data.tsn_at_removal.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.tsn_at_removal)) {
                validationInputs.tsn_at_removal = specificMessTsn;
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/shop/${payload.id}/`, payload)
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response?.data?.data)

                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                globalPostService(`camo/shop/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            addUploadFile(response?.data?.data)
                            importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })

            }
        } else {
            setError(validationInputs)
        }
    }

    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Update Shop Visits" : " Add Shop Visits"} </DialogTitle>
                <DialogContent dividers>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required
                                    id="removal_date"
                                    label="Removal Date"
                                    inputVariant="outlined"
                                    margin='normal'
                                    fullWidth
                                    format={fieldDateFormat}
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.removal_date}
                                    error={error.removal_date ? true : false}
                                    helperText={error.removal_date ? error.removal_date : ""}
                                    onFocus={() => onRestErrorKey("removal_date")}
                                    onChange={(data, value) => { onFieldChange("removal_date", moment(data).format(backendDateFormat)); onRestErrorKey("removal_date") }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                required
                                variant="outlined"
                                label='Removal Reason'
                                id="removal_reason"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.removal_reason}
                                error={error.removal_reason ? true : false}
                                helperText={error.removal_reason ? error.removal_reason : ""}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 20) {
                                        onFieldChange("removal_reason", input)
                                        onRestErrorKey("removal_reason")
                                    } else {
                                        const truncatedInput = input.slice(0, 20)
                                        onFieldChange("removal_reason", truncatedInput)
                                        onRestErrorKey("removal_reason")
                                    }
                                }}
                                onFocus={() => onRestErrorKey("removal_reason")}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        TSN at Removal
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="tsn_at_removal"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true,style: { pointerEvents: 'auto' } }}
                                value={data.tsn_at_removal ? data.tsn_at_removal : ''}
                                error={error.tsn_at_removal ? true : false}
                                helperText={error.tsn_at_removal ? error.tsn_at_removal : ""}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^\d.]/g, '');
                                    const dotCount = (numericValue.match(/\./g) || []).length;
                                    const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                    if (isValid || value === "") {
                                        onFieldChange("tsn_at_removal", numericValue);
                                    }

                                    onRestErrorKey("tsn_at_removal");
                                }}

                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                required
                                variant="outlined"
                                margin='normal'
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        CSN at Removal
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="csn_at_removal"
                                fullWidth
                                InputLabelProps={{ shrink: true,style: { pointerEvents: 'auto' } }}
                                value={data.csn_at_removal ? data.csn_at_removal : ''}
                                error={error.csn_at_removal ? true : false}
                                helperText={error.csn_at_removal ? error.csn_at_removal : ""}
                                onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange('csn_at_removal', e.target.value); onRestErrorKey("csn_at_removal") }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    id="sv_completion_date"
                                    label={<span>Shop Visit Completion Date <span style={{ color: "red" }}> *</span> </span>}
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    margin='normal'
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.sv_completion_date}
                                    error={error.sv_completion_date ? true : false}
                                    helperText={error.sv_completion_date ? error.sv_completion_date : ""}
                                    onFocus={() => onRestErrorKey("sv_completion_date")}
                                    onChange={(data, value) => {
                                        onFieldChange("sv_completion_date", moment(data).format(backendDateFormat))
                                        onRestErrorKey("sv_completion_date")
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Shop Visit Location /MRO"
                                margin='normal'
                                id="sv_location_mro"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.sv_location_mro}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 20) {
                                        onFieldChange("sv_location_mro", input)
                                        onRestErrorKey("sv_location_mro")
                                    } else {
                                        const truncatedInput = input.slice(0, 20)
                                        onFieldChange("sv_location_mro", truncatedInput)
                                        onRestErrorKey("sv_location_mro")
                                    }
                                }}
                                onFocus={() => onRestErrorKey("sv_location_mro")}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="Shop Visit Workscope"
                                margin='normal'
                                id="sv_workscope"
                                fullWidth
                                value={data.sv_workscope}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 20) {
                                        onFieldChange("sv_workscope", input)
                                        onRestErrorKey("sv_workscope")
                                    } else {
                                        const truncatedInput = input.slice(0, 20)
                                        onFieldChange("sv_workscope", truncatedInput)
                                        onRestErrorKey("sv_workscope")
                                    }
                                }}
                                onFocus={() => onRestErrorKey("sv_workscope")}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <label>Shop Visit Type <span style={{ color: "red" }}> *</span> </label>
                            <FormControl id="d-block" margin='normal' style={{width:'max-content'}}>
                                <FormLabel component="legend"
                                    error={error.sv_type ? true : false}
                                ></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="sv_type"
                                    name="sv_type"
                                    defaultValue="top"
                                    value={data.sv_type}
                                    onChange={(e, value) => onFieldChange("sv_type", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="PRSV"
                                        control={<Radio size="small" color="primary" />}
                                        label="PRSV"
                                    />
                                    <FormControlLabel
                                        value="Repair"
                                        control={<Radio size="small" color="primary" />}
                                        label="Repair"
                                    />
                                      <FormControlLabel
                                        value="Overhaul"
                                        control={<Radio size="small" color="primary" />}
                                        label="Overhaul"
                                    />
                                </RadioGroup>
                                {error.sv_type && (
                                    <FormLabel component="faa_easa"> <span style={{ color: "red" }}>{error.sv_type}</span> </FormLabel>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                label="Remarks"
                                margin='normal'
                                variant='outlined'
                                fullWidth
                                value={data.remarks}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("remarks", e.target.value)}
                                rows={3}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
                                <span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
                                <span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
                            </div>
                        </Grid>
                        <div className="checklist-file" style={{ overflow: "auto" }}>
                        {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
                            <ul className='list-inline'>
                                {(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
                                    return (
                                        <li className='list-inline-item'>
                                            <span className='file-name'>{attachments.name}</span>
                                            <span className='file-remove'>
                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
                                                <CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                            </span>
                                        </li>)
                                }

                                )}
                                {(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
                                    data.checklist?.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        {data?.checklist.some(item => item.type === 'folder') ? null
                                                            : <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.files?.length > 0) &&
                                    data.checklist?.files.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.folders?.length > 0) &&
									data.checklist?.folders.map((file) => {
										return (
											<li className='list-inline-item' key={file.id}>
												<Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
													<a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
												</Tooltip>
												<span className='file-name'>{file.name}</span>
												<span className='file-remove'>
													<CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
												</span>
											</li>
										);
									})}
                            </ul>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {uploadFile.modal ?
                <UploadFile
                    modType="shop"
                    type="upload"
                    list={list}
                    actionType={modeType}
                    editFormId={data?.id}
                    handleClose={() => setUploadFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

                />
                : null}
            {linkFile.modal ?
                <LinkFiles
                    modType="shop"
                    type="link"
                    list={list}
                    editFormId={data?.id}
                    actionType={modeType}
                    uploadFileInfo={data}
                    toggleModalFn={() => setlinkFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    onLinkFile={getData}
                />
                : null
            }
            {isLoading ? <PageLoader /> : null}
        </>
    )

}
export default withRouter(AddEditShopVisit)