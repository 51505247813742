import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
const LLPHeader = ({ sortInfo, createSortHandler, basicdetail }) => {
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }
    return (
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
                <TableCell colSpan={5} align="center"></TableCell>
                <TableCell colSpan={2} align="center" style={{ minWidth: "220px" }}>Total Since New</TableCell>
                <TableCell colSpan={basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? 2 : 5} align="center" style={{ minWidth: "320px" }}>Cycles Operated on Thrust Rating</TableCell>
                <TableCell colSpan={basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? 2 : 5} align="center" style={{ minWidth: "320px" }}>Life Limit</TableCell>
                <TableCell colSpan={basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? 2 : 5} align="center" style={{ minWidth: "350px" }}> Cycles Remaining</TableCell>
                <TableCell colSpan={2} align="center"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Action </TableCell>
                <TableCell>{tableSorting('module_type', 'Module')} </TableCell>
                <TableCell style={{ minWidth: "250px" }}> {tableSorting('description', 'Description')}  </TableCell>
                <TableCell style={{ minWidth: "120px" }}> {tableSorting('part_number', 'Part Number')}  </TableCell>
                <TableCell style={{ minWidth: "120px" }}> {tableSorting('serial_number', 'Serial Number')}  </TableCell>
                <TableCell>{tableSorting('tsn', 'TSN')} </TableCell>
                <TableCell>CSN</TableCell>
                {
                    basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details).filter(item => item !== "five_column").map(row => (
                        <TableCell style={{ minWidth: "120px" }}>{row} </TableCell>
                    ))
                }
                {
                    basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details).filter(item => item !== "five_column").map(row => (
                        <TableCell style={{ minWidth: "140px" }}>{tableSorting('tsn', row)} </TableCell>
                    ))
                }
                {
                    basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details).filter(item => item !== "five_column").map(row => (
                        <TableCell style={{ minWidth: "130px" }}>{row} </TableCell>
                    ))
                }
                <TableCell style={{ minWidth: "250px" }}> {tableSorting('remarks', 'Remarks')} </TableCell>
                <TableCell>Attachments </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default LLPHeader