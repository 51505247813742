import AMPCompliance from "./amp/containers";
import HTComponent from "./ht/containers";
import ADComponent from "./ad/containers";
import OccmComponents from "./occm/containers";
import SbComponents from "./sb/containers";
import pageLayoutHoc from "../../hocs/pageLayoutHoc";
import userRoleBaseAccessHoc from "../../hocs/userRoleBaseAccessHoc";
import ModsComponents from "./mods/containers";
import SRComponent from "./sr/containers";
import LLPComponents from "./llp/containers/index.js";
import ShopVisitComponents from './shopVisit/containers/index.js'
import MainInventory from "./inventory/containers/index.js";
import MainUtilization from "./utilization/containers/index.js"
import SoftTimeComponent from "./softTime/containers"
import AmpMainComp from "./amp/containers/AmpMainComp.js";
import SoftTimeMainComp from "./softTime/containers/SoftTimeMainComp.js";
import ShopVisitComp from "./shopVisit/containers/ShopVisitComp.js";
import UtilizationComp from "./utilization/containers/UtilizationComp.js";
import InventoryComp from "./inventory/containers/InventoryComp.js";
import OpHistoryComponents from './opHistory/containers'
import LLPComp from "./llp/containers/LLPComp.js";
import OpHistoryComp from "./opHistory/containers/OpHistoryComp.js";
import AdComp from "./ad/containers/AdComp.js";
import OccmComp from "./occm/containers/OccmComp.js";
import SbComp from "./sb/containers/SbComp.js";
import HtComp from "./ht/containers/HtComp.js";
import ModsComp from "./mods/containers/ModsComp.js";
import SrComp from "./sr/containers/SrComp.js";
import MasterComp from "./masterComponent/container/MasterComp.js";
import MasterListComponent from "./masterComponent/containers/index.js";
export const camoRoutes = [
	{
		path: "/:asset/:assetType/op_history/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(OpHistoryComponents, []), {
			apps: "Op",
			layoutPhase: 2,
		}),
		key: "Op",
	},
	// {
	// 	path: "/:asset/:assetType/op_history/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(OpHistoryComp, []), { apps: "Op", layoutPhase: 2, }),
	// 	key: "Op",
	// },

	// {
	// 	path: "/:asset/:assetType/amp/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(AmpMainComp, []), {
	// 		apps: "AMP",
	// 		layoutPhase: 2,
	// 	}),
	// 	key: "AMP",
	// },

	{
		path: "/:asset/:assetType/amp/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(AMPCompliance, []), {
			apps: "AMP",
			layoutPhase: 2,
		}),
		key: "AMP",
	},
	{
		path: "/:asset/:assetType/ht/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(HTComponent, []), {
			apps: "HT",
			layoutPhase: 2,
		}),
		key: "HT",
	},

	// {
	// 	path: "/:asset/:assetType/ht/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(HtComp, []), { apps: "HT", layoutPhase: 2, }),
	// 	key: "HT",
	// },

	{
		path: "/:asset/:assetType/ad/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(ADComponent, []), {
			apps: "AD",
			layoutPhase: 2,
		}),
		key: "AD",
	},

	// {
	// 	path: "/:asset/:assetType/ad/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(AdComp, []), { apps: "AD", layoutPhase: 2, }),
	// 	key: "AD",
	// },

	{
		path: "/:asset/:assetType/occm/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(OccmComponents, []), {
			apps: "OCCM",
			layoutPhase: 2,
		}),
		key: "OCCM",
	},
	// {
	// 	path: "/:asset/:assetType/occm/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(OccmComp, []), { apps: "OCCM", layoutPhase: 2, }),
	// 	key: "OCCM",
	// },

	{
		path: "/:asset/:assetType/sb/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(SbComponents, []), {
			apps: "SB",
			layoutPhase: 2,
		}),
		key: "SB",
	},
	// {
	// 	path: "/:asset/:assetType/sb/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(SbComp, []), { apps: "SB", layoutPhase: 2, }),
	// 	key: "SB",
	// },

	{
		path: "/:asset/:assetType/mods/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(ModsComponents, []), {
			apps: "MODS",
			layoutPhase: 2,
		}),
		key: "MODS",
	},
	// {
	// 	path: "/:asset/:assetType/mods/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(ModsComp, []), { apps: "MODS", layoutPhase: 2, }),
	// 	key: "MODS",
	// },

	{
		path: "/:asset/:assetType/sr/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(SRComponent, []), {
			apps: "SR",
			layoutPhase: 2,
		}),
		key: "SR",
	},
	// {
	// 	path: "/:asset/:assetType/sr/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(SrComp, []), { apps: "SR", layoutPhase: 2, }),
	// 	key: "SR",
	// },

	{
		path: "/:asset/:assetType/llp/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(LLPComponents, []), {
			apps: "LLP",
			layoutPhase: 2,
		}),
		key: "LLP",
	},

	// {
	// 	path: "/:asset/:assetType/llp/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(LLPComp, []), { apps: "LLP", layoutPhase: 2, }),
	// 	key: "LLP",
	// },


	{
		path: "/:asset/:assetType/shopVisit/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(ShopVisitComponents, []), {
			apps: "sv",
			layoutPhase: 2,
		}),
		key: "sv",
	},

	// {
	// 	path: "/:asset/:assetType/shopVisit/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(ShopVisitComp, []), { apps: "ShopVisitComp", layoutPhase: 2, }),
	// 	key: "ShopVisitComp",
	// },

	{
		path: "/:asset/:assetType/inventory/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(MainInventory, []), {
			apps: "MainInventory",
			layoutPhase: 2,
		}),
		key: "MainInventory",
	},

	// {
	// 	path: "/:asset/:assetType/inventory/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(InventoryComp, []), { apps: "InventoryComp", layoutPhase: 2, }),
	// 	key: "InventoryComp",
	// },

	{
		path: "/:asset/:assetType/utilization/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(MainUtilization, []), {
			apps: "MainUtilization",
			layoutPhase: 2,
		}),
		key: "MainUtilization",
	},

	// {
	// 	path: "/:asset/:assetType/utilization/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(UtilizationComp, []), { apps: "UtilizationComp", layoutPhase: 2, }),
	// 	key: "UtilizationComp",
	// },

	 {
	 	path: "/:asset/:assetType/softTime/list",
	 	component: pageLayoutHoc(userRoleBaseAccessHoc(SoftTimeComponent, []), {
	 		apps: "SoftTimeComponent",
	 		layoutPhase: 2,
	 	}),
	 	key: "SoftTimeComponent",
	 },
	// {
	// 	path: "/:asset/:assetType/softTime/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(SoftTimeMainComp, []), { apps: "SoftTimeMainComp", layoutPhase: 2, }),
	// 	key: "SoftTimeMainComp",
	// },
	
	// {
	// 	path: "/:asset/:assetType/master_components/list",
	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(MasterComp, []), { apps: "mc", layoutPhase: 2, }),
	// 	key: "MasterComp",
	// },
	{
		path: "/:asset/:assetType/master_components/list",
		component: pageLayoutHoc(userRoleBaseAccessHoc(MasterListComponent, []), {
			apps: "MC",
			layoutPhase: 2,
		}),
		key: "MC",
	},

];
