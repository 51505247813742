import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Dialog, TableRow, TableCell, Tooltip, IconButton,Checkbox } from "@material-ui/core";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import ConfirmDialog from "../components/ConfirmDialog";
import { fillSbEditFormApi } from "../apiService";
import SbTaskForm from "./SbTaskForm";
import moment from "moment";
import { regexConstants } from "../../../../constants/regEx";
import {dayMonthDateFormat } from "../../../../constants";
import ShowAttachments from "../../../../shared_elements/components/ShowAttachments";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory";
import { permissionCheckFnforCamo, checkApiStatus,getLocalStorageInfo,Comma_format } from "../../../../utils";
import { globalPutService, globalDeleteService } from "../../../../utils/globalApiServices";
import config from "../../../../config"

const SbList = ({ item, index, handleDeleteRow, updateFormData, props, fileUploadData, currentRecordPerPage, headerData, downloadAllApi, headerTsn, headerCsn, uuid, last_used_folder_uuid, last_used_folder_uuid_link_document, getResponseBack, checkbox_last_used_folder_uuid, checkbox_last_used_folder_name,assetsDetail,bulkOperation, toggleBulkOps, }) => {
	const [open, setOpen] = useState(false);
	const [openEditForm, setEditForm] = useState(false);
	const [editFormId, setEditFormId] = useState(null);
	const [editFormData, setEditFormData] = useState(null);
	const [formTitle, setFormTitle] = useState("");
	const [attachments, setAttachments] = useState(null);
	const [openGetAttachmentsDialogue, setAttachmentsDialogue] = useState(false);
	const [AllAttachmentsforDialogue, setAllAttachmentsforDialogue] =
		useState(null);
	const [moduleId, setmoduleId] = useState(null);
	const [lessMore, setLessMore] = useState({});

	const handleClick = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	const closeAddForm = () => {
		setEditForm(false);
	};

	const handleEditRow = () => {
		fillSbEditFormApi(item, props).then((response) => {
			setEditFormData(response.data.data);
			setEditFormId(response.data.data.id);
			setAttachments(response.data.data.attachments);
			setFormTitle("Update SB Components");
			setEditForm(true);
		});
	};

	const downAllAttach = () => {
		fillSbEditFormApi(item, props).then((response) => {
			setmoduleId(response.data.data.id);
		});
		getAttachements()
	};

	const getAttachements = () => {
		const matchingItem = assetsDetail?.list?.find(items => items?.id === item.id);
		if (!matchingItem) return [];
		const { checklist, attachments } = matchingItem || {};
		if (checklist && attachments || checklist || attachments) {
			const { files, folders } = checklist || {}; // Add null check here
			if (attachments?.length > 0 && files?.length > 0 && folders?.length > 0) {
				getAttachments([...attachments, ...files, ...folders])
			} else if (files?.length > 0 && folders?.length > 0) {
				getAttachments([...folders, ...files])
			} else if (attachments?.length > 0 && files?.length > 0) {
				getAttachments([...attachments, ...files])
			} else if (attachments?.length > 0 && folders?.length > 0) {
				getAttachments([...attachments, ...folders])
			} else if (attachments?.length > 0) {
				getAttachments([...attachments])
			} else if (folders?.length > 0) {
				getAttachments([...folders])
			} else if (files?.length > 0) {
				getAttachments([...files])
			}
		}
		return [];

	};

	const openAttachment = (file) => {
		var decodedURL = decodeURIComponent(file);
		window.open(decodedURL, "_blank");
	};

	const formatDate = (date) => {
		return moment(date).format(dayMonthDateFormat);
	};


	const getAttachments = (attachments) => {
		setAllAttachmentsforDialogue(attachments);
		setAttachmentsDialogue(true);
	};

	const handleCloseAttachmentDialogue = () => {
		setAttachmentsDialogue(false);
	};
	const toggleLessMore = (lessMr) => {
		setLessMore({
			...lessMore,
			[lessMr]: !lessMore[lessMr]
		});
	}
	const lessMoreFn = (name, lessMr) => {
		return <>
			{name && name?.length > 35 ? (
				<>
					{lessMore[lessMr] ?
						(<> {name} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(lessMr); }}  > ..less </span> </>)
						:
						(<>{name.substring(0, 35)} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(lessMr); }}  > ..more </span> </>)
					}
				</>
			) : name || '--'
			}
		</>
	}
	const removeAttachment = (item, checklist, fileId, uuid) => {
		if (uuid) {
			let payload = {};
			payload.delete = true;
			payload.folder_ids = checklist.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).toString()
			payload.file_ids = checklist.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).toString()
			globalPutService(`camo/sb/${item?.id}/document-checklists/`, payload)
				.then((response) => {
					if (checkApiStatus(response)) {
						fillSbEditFormApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							const matchingItem = response.data.data;
							if (!matchingItem) return [];
							const { checklist, attachments } = matchingItem || {};
							if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

							return [];
						});
					}
				})
		} else {
			globalDeleteService(`camo/attachment/${fileId}/delete/`).then((response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					});
				} else {
					if (response) {
						fillSbEditFormApi(item, props).then((response) => {
							setEditFormData(response.data.data);
							const matchingItem = response.data.data;
							if (!matchingItem) return [];
							const { checklist, attachments } = matchingItem || {};
							if (checklist && attachments || checklist || attachments) {
								if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0 && item?.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data?.data?.checklist?.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0 && item.checklist?.files?.length > 0) {
									getAttachments([...response.data.data.attachments, ...response?.data?.data?.checklist?.files])
								} else if (item.checklist?.files?.length > 0 && item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.files, ...response.data.data.checklist.folders])
								} else if (item?.attachments?.length > 0) {
									getAttachments([...response.data.data.attachments])
								} else if (item.checklist?.folders?.length > 0) {
									getAttachments([...response.data.data.checklist.folders])
								} else if (item.checklist?.files?.length > 0) {
									getAttachments([...response?.data?.data?.checklist?.files])
								}
								getResponseBack()
							}

							return [];
						});
					}
				}
			});
		}
	};

	let timezone = moment().format()
	const timestamp = Math.floor(Date.now() / 1000);
	const sburl = `audit/camo/sbstatus/?object_id=${item.id}&action=0,1&timezone=${timezone}&timestamp=${timestamp}`
	const smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config?.env?.key === "api" ? 465 : 532)

	function getAttachmentCount(item) {
		const { attachments, checklist } = item;
		const { files, folders } = checklist || {};

		const attachmentCount = attachments?.length || 0;
		const fileCount = files?.length || 0;
		const folderCount = folders?.length || 0;

		if (attachmentCount && fileCount && folderCount) {
			return attachmentCount + fileCount + folderCount;
		} else if (fileCount && folderCount) {
			return fileCount + folderCount;
		} else if (attachmentCount && folderCount) {
			return attachmentCount + folderCount;
		} else if (attachmentCount && fileCount) {
			return attachmentCount + fileCount;
		} else {
			return folderCount || fileCount || attachmentCount || '--';
		}
	}
	const attachmentCount = getAttachmentCount(item);
	const selectedItem = bulkOperation.ids.find(data => data.id === item?.id);
	return (
		<>
			<TableRow
				hover
				tabIndex={-1}
				style={{ cursor: "pointer" }}
				className={
					item.remainingValues_D < 0 || item.remainingValues_D == null
						? "red-row"
						: "none"
				}
			>
				  <TableCell width="30">
                    <Checkbox
                        name={item.id}
                        color="primary"
                        size="small"
                        onChange={(e) => toggleBulkOps(e.target.checked)}
                        checked={selectedItem ? true : false}
                    />
                </TableCell>
				<TableCell>
					{permissionCheckFnforCamo({
						primaryKey: "sb_status",
						keyIndex: "U",
					}) ? (
						<Tooltip title="Edit">
							<IconButton
								className="edit-icon"
								onClick={(e) => {
									handleEditRow(e);
								}}
							>
								<CreateOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : null}
					{permissionCheckFnforCamo({
						primaryKey: "sb_status",
						keyIndex: "D",
					}) ||
						permissionCheckFnforCamo({
							primaryKey: "sb_status",
							keyIndex: "OD",
							userId: item.created_by,
						}) ? (
						<Tooltip title="Delete" arrow>
							<IconButton className="delete-icon" onClick={handleClick}>
								<DeleteOutlinedIcon />
							</IconButton>
						</Tooltip>
					) : null}
					<RevisionHistory buttonType={true} url={sburl} />
				</TableCell>
				{smbcInstance ? null : <TableCell>{item.ata_chapter ? item.ata_chapter : "--"}</TableCell>}
				<TableCell style={{minWidth:"250px" }}>{item.sb_ref_number ? item.sb_ref_number : "--"}</TableCell>
				{smbcInstance ? <TableCell>{item.sb_category ? item.sb_category : "--"}</TableCell> : null}
				{smbcInstance ? <TableCell style={{ textAlign: "left",minWidth:"200px" }}> {lessMoreFn(item?.sb_impact, "sb_impact")} </TableCell> : null}
				{smbcInstance ? null : <TableCell>{item.sb_issued_by ? item.sb_issued_by : "--"}</TableCell> }
				{smbcInstance ? null :<TableCell> {item.sb_applicability ? item.sb_applicability : "--"} </TableCell>}
				<TableCell style={{ textAlign: "left",minWidth:"200px" }}> {lessMoreFn(item?.description, "description")} </TableCell>
				{smbcInstance ? null :<TableCell>{item.type ? item.type : "--"}</TableCell>}
				{smbcInstance ? null :<TableCell>{item.effectivity ? item.effectivity : "--"}</TableCell>}
				{smbcInstance ? null :<TableCell> {item.effectivity_date ? formatDate(item.effectivity_date) : "--"} </TableCell>}
				{smbcInstance ? null :<TableCell> {item.sb_interval_type ? item.sb_interval_type : "--"} </TableCell>}
				<TableCell> {item.sb_compliance_status ? item.sb_compliance_status : "--"} </TableCell>
				<TableCell> {item.sb_compliance_tsn ?  regexConstants.alphanumespcespclchar.test(item.sb_compliance_tsn) ? item.sb_compliance_tsn :  Comma_format(parseFloat(item.sb_compliance_tsn)) : "--"} </TableCell>
				<TableCell> {item.sb_compliance_csn ? regexConstants.alphanumespcespclchar.test(item.sb_compliance_csn) ? item.sb_compliance_csn :  Comma_format(parseInt(item.sb_compliance_csn)) : "--"} </TableCell>
				<TableCell> {item.sb_compliance_date ? formatDate(item.sb_compliance_date) : "--"} </TableCell>
				{smbcInstance ? null :<TableCell> {item.remaining.due_at ? item.sb_interval_type === "D" ? formatDate(item.remaining.due_at) : item.remaining.due_at : "--"} </TableCell>}
				{smbcInstance ? null :<TableCell> {item.remaining.remaining ? item.remaining.remaining : "--"} </TableCell>}
				{smbcInstance ? <TableCell style={{ textAlign: "left",minWidth:"200px" }}> {lessMoreFn(item?.remark, "remark")} </TableCell> : null}
				

				<TableCell>
					{attachmentCount ? (
						<>
							<Tooltip title="View Attachments">
								<span className="center" onClick={() => downAllAttach()} >
									<AttachFileIcon className="attach-icon" />
									<a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
								</span>
							</Tooltip>
						</>
					)
						: (
							"--"
						)}
				</TableCell>
			</TableRow>
			<Dialog open={open} onClose={handleClose} id="htDialog">
				<ConfirmDialog
					handleClose={() => handleClose()}
					handleDeleteRow={handleDeleteRow}
				/>
			</Dialog>
			<Dialog
				position="relative"
				maxWidth="lg"
				open={openEditForm}
			>
				<SbTaskForm
					formTitle={formTitle}
					closeAddForm={() => closeAddForm()}
					addForm={() => setEditForm(true)}
					props={props}
					headerTsn={headerTsn}
					headerCsn={headerCsn}
					editFormId={editFormId}
					editFormData={editFormData}
					attachments={attachments}
					item={item}
					updateFormData={updateFormData}
					fileUploadData={fileUploadData}
					currentRecordPerPage={currentRecordPerPage}
					headerData={headerData}
					uuid={uuid}
					last_used_folder_uuid={last_used_folder_uuid}
					last_used_folder_uuid_link_document={last_used_folder_uuid_link_document}
					checkbox_last_used_folder_uuid={checkbox_last_used_folder_uuid}
					checkbox_last_used_folder_name={checkbox_last_used_folder_name}
					getResponseBack={() => getResponseBack()}
				/>
			</Dialog>

			<Dialog
				open={openGetAttachmentsDialogue}
				onClose={handleCloseAttachmentDialogue}
				id="htDialog"
			>
				<ShowAttachments
					handleClose={() => handleCloseAttachmentDialogue()}
					handleShowAttachments={AllAttachmentsforDialogue}
					openAttachment={openAttachment}
					files={{ title: "AMP", extension: "zip", key: "" }}
					downloadAllApi={downloadAllApi}
					moduleId={moduleId}
					item={item}
					removeAttachment={removeAttachment}
				/>
			</Dialog>
		</>
	);
};
export default withRouter(SbList);
