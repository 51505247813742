import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
const ShopVisitHeader = ({ sortInfo, createSortHandler }) => {
	const tableSorting = (key, name) => {
		return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
	}
	return (
		<TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
			<TableRow>
				<TableCell>Action </TableCell>
				<TableCell style={{ minWidth: "180px" }}>{tableSorting('removal_date', 'Removal Date')} </TableCell>
				<TableCell style={{ minWidth: "180px" }}>{tableSorting('removal_reason', 'Removal Reason')}</TableCell>
				<TableCell style={{ minWidth: "180px" }}>{tableSorting('tsn_at_removal', 'TSN at Removal')}</TableCell>
				<TableCell style={{ minWidth: "180px" }}>{tableSorting('csn_at_removal', 'CSN at Removal')} </TableCell>
				<TableCell style={{ minWidth: "200px" }}>{tableSorting('sv_completion_date', 'Shop Visit Completion Date')} </TableCell>
				<TableCell style={{ minWidth: "200px" }}>{tableSorting('sv_location_mro', 'Shop Visit  Location /MRO')} </TableCell>
				<TableCell style={{ minWidth: "180px" }}>{tableSorting('sv_workscope', 'Shop Visit  Workscope')} </TableCell>
				<TableCell style={{ minWidth: "200px" }}>{tableSorting('sv_type', 'Shop Visit  Type')}</TableCell>
				<TableCell style={{ minWidth: "250px" }}>{tableSorting('remarks', 'Remarks')}</TableCell>
				<TableCell>Attachments </TableCell>
			</TableRow>
		</TableHead>
	)
}

export default ShopVisitHeader