import React from "react"
import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
const AmpTableHeader = ({ createSortHandler, sortInfo }) => {
	const tableSorting = (key, name) => {
		return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
	}
	return (

		<TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
			<TableRow>
				<TableCell colSpan={5} align="center"></TableCell>
				<TableCell colSpan={3} align="center">Threshold</TableCell>
				<TableCell colSpan={3} align="center">Interval</TableCell>
				<TableCell colSpan={3} align="center">Last Done</TableCell>
				<TableCell colSpan={3} align="center">Next Due</TableCell>
				<TableCell colSpan={3} align="center">Remaining</TableCell>
				<TableCell colSpan={2} align="center"></TableCell>
			</TableRow>
			<TableRow>
				<TableCell>Action </TableCell>
				<TableCell style={{ minWidth: "180px" }}>{tableSorting('MPD_item_no', 'MPD Item No.')} </TableCell>
				<TableCell style={{ minWidth: "180px" }}>{tableSorting('task_card_no', 'Task Card No.')} </TableCell>
				<TableCell style={{ minWidth: "180px" }}>{tableSorting('previous_LDND_Taskcard', 'Previous LDND TaskCard')} </TableCell>
				<TableCell style={{ minWidth: "250px" }}>{tableSorting('Task_Description', 'Task Description')} </TableCell>
				<TableCell>{tableSorting('threshold_D', 'Days')}</TableCell>
				<TableCell>{tableSorting('threshold_FH', 'FH')} </TableCell>
				<TableCell>{tableSorting('threshold_FC', 'FC')} </TableCell>
				<TableCell>{tableSorting('interval_D', 'Days')} </TableCell>
				<TableCell>{tableSorting('interval_FH', 'FH')}</TableCell>
				<TableCell>{tableSorting('interval_FC', 'FC')}</TableCell>
				<TableCell>{tableSorting('lastDone_D', 'Date')}</TableCell>
				<TableCell>{tableSorting('lastDone_FH', 'FH')}</TableCell>
				<TableCell>{tableSorting('lastDone_FC', 'FC')} </TableCell>
				<TableCell>{tableSorting('nextDue_D', 'Date')} </TableCell>
				<TableCell>{tableSorting('nextDue_FH', 'FH')} </TableCell>
				<TableCell>{tableSorting('nextDue_FC', 'FC')} </TableCell>
				<TableCell>{tableSorting('remainingValues_D', 'Days')}</TableCell>
				<TableCell>{tableSorting('remainingValues_FH', 'FH')}</TableCell>
				<TableCell>{tableSorting('remainingValues_FC', 'FC')}</TableCell>
				<TableCell style={{ minWidth: "250px" }}>{tableSorting('remarks', 'Remarks')} </TableCell>
				<TableCell>Attachments </TableCell>
			</TableRow>
		</TableHead>
	)
}

export default AmpTableHeader
