import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
const UtilizationHeader = ({ sortInfo, createSortHandler }) => {
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }
    return (
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
                <TableCell>Action </TableCell>
                <TableCell style={{ minWidth: '150px' }}>Utilization Period </TableCell>
                <TableCell style={{ minWidth: '100px' }}>{tableSorting('monthly_FH', 'Monthly FH')}</TableCell>
                <TableCell style={{ minWidth: '100px' }}>{tableSorting('monthly_FC', 'Monthly FC')}</TableCell>
                <TableCell style={{ minWidth: '170px' }}>{tableSorting('monthly_derate', 'Monthly Derates')} </TableCell>
                <TableCell>{tableSorting('tsn', 'TSN')} </TableCell>
                <TableCell>{tableSorting('csn', 'CSN')}</TableCell>
                <TableCell style={{ minWidth: '215px' }}>{tableSorting('operating_thrust_current', 'Operating Thrust (Current)')}</TableCell>
                <TableCell style={{ minWidth: '180px' }}>{tableSorting('location_reg_aircraft', 'Location (A/C Reg)')}</TableCell>
                <TableCell style={{ minWidth: '158px' }}>Location (Position)</TableCell>
                <TableCell style={{ minWidth: '190px' }}>{tableSorting('engine_stand_location', 'Engine Stand Location')}</TableCell>
                <TableCell style={{ minWidth: '158px' }}>{tableSorting('location_country', 'Location (Country)')}</TableCell>
                <TableCell style={{ minWidth: '158px' }}>{tableSorting('operating_environment', 'Operating Environment')}</TableCell>
                <TableCell style={{ minWidth: '158px' }}>{tableSorting('date_of_installation', 'Date of Installation')}</TableCell>
                <TableCell style={{ minWidth: '150px' }}>{tableSorting('date_of_removal', 'Date of Removal')}</TableCell>
                <TableCell style={{ minWidth: '160px' }}>{tableSorting('reason_of_removal', 'Reason of Removal')} </TableCell>
                <TableCell>Attachments </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default UtilizationHeader