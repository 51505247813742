import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip, CircularProgress, TextareaAutosize } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import { DropzoneArea } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus, getLocalStorageInfo } from '../../../../utils';
import { assetType, imgStoragePath } from "../../../../constants";
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { ampObj } from '.';

function AddEditAmp({ match, toggleModalFn, ampData, basicdetail, modeType, getResponseBack, list }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [data, setData] = useState(ampData ? ampData : ampObj)
	const [error, setError] = useState({})
	const [isLoading, setLoading] = useState(false)
	const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
	const [linkFile, setlinkFile] = useState({ modal: false, data: null })

	const onFieldChange = (keyParam, value) => {
		setData({ ...data, [keyParam]: value });
	}
	const uploadFileData = (file) => {
		setData({ ...data, checklist: file })
	}

	const getData = (selectedFile, type) => {
		setData({ ...data, checklist: selectedFile, linkFile: type });
	}

	const uploadFileFn = (file, uuid, type) => {
		if (uuid) {
			let storeChecklistData = [];
			let folderUuid = [];

			const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
			const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

			for (const arr of updatedChecklist) {
				storeChecklistData = storeChecklistData.concat(arr);
			}
			for (const arr of folderUUID) {
				folderUuid = folderUuid.concat(arr);
			}
			setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
		} else {
			if (Array.isArray(file)) {
				const updatedAttachments = [...(data?.attachments || []), ...file];
				setData({ ...data, 'attachments': updatedAttachments });
			} else {
				console.error("Value for attachments is not an array:", file);
			}
		}
	}
	const addUploadFile = (item) => {
		if (data?.linkFile === "link") {
			let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
			globalPutService(`camo/amp/${item?.id}/document-checklists/`, payload)
				.then(response => {
					if (checkApiStatus(response)) {
						toggleModalFn()
						getResponseBack(response.data.data);
						enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					} else {
						enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
		if (data?.folder_uuid?.length > 0) {
			if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
				let formData = new FormData();
				formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
				for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
					formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
				}
				globalPutService(`camo/amp/${item?.id}/document-checklists/`, formData)
					.then(response => {
						if (checkApiStatus(response)) {
							getResponseBack()
						} else {
							enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
						}
					});
			}
		}

	}
	const importFile = (file) => {
		var formData = new FormData();
        Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
            formData.append('files', data?.attachments.filter(item => item instanceof File)[key]);
    })
		globalFileUploadService(`camo/attachments/ampcompliance/${file?.id}/`, formData)
			.then(response => {
				toggleModalFn(); getResponseBack()
				if (checkApiStatus(response)) {
				} else {
					enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				}
			})

	}
	const onRemoveFile = (file, type, fileId, folder) => {
		if (modeType === "add") {
			if (type === "checklist") {
				setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
			} else {
				setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
			}
		} else {
			if (type === "checklist") {
				let payload = {};
				payload.delete = true;
				payload.folder_ids =data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
			    payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
				globalPutService(`camo/amp/${data.id}/document-checklists/`, payload)
					.then((response) => {
						if (checkApiStatus(response)) {
							const updatedChecklist = { ...data.checklist };
							updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                            updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
							setData({ ...data, checklist: updatedChecklist });
							getResponseBack()
						}
					});
			} else {
				if (file.id) {
					globalDeleteService(`camo/attachment/${file.id}/delete/`)
						.then((response) => {
							if (checkApiStatus(response)) {
								setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
								getResponseBack()
							}
						});
				} else {
					setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
				}
			}
		}
	}

	const onAddNewAsset = () => {
		let validationInputs = {}
		if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')) {
			validationInputs = {
				...validationInputs,
				MPD_item_no: data?.MPD_item_no ? '' : "Please Enter MPD Item NO",
				task_card_no: data?.task_card_no ? '' : "Please Task Card No",
				Task_Description: data?.Task_Description ? '' : "Please Enter Description",
				threshold_D: data?.threshold_D ? '' : "Please Enter Threshold D",
				interval_FH: data.interval_FH  ? '' :  data?.threshold_FH  &&  "Please Enter Interval FH",
				interval_FC: data.interval_FC  ? '': data?.threshold_FC && "Please Enter Interval FC",
				interval_D: data.interval_D ? '' : data?.threshold_D && "Please Enter Interval D",
				lastDone_D: data.lastDone_D ? '' : data?.threshold_D && "Please Enter Last Done D",
				lastDone_FH: data.lastDone_FH  ? '' : data?.threshold_FH && "Please Enter Last Done FH",
				lastDone_FC: data.lastDone_FC ? '' : data?.threshold_FC &&"Please Enter Last Done FC",
			}
		}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
			setLoading(true)
			let payload = Object.assign({}, data);
			if (data?.id) {
				globalPutService(`camo/amp/${payload.id}/`, payload)
					.then(response => {
						setLoading(false)
						if (response.data?.statusCode === 200) {
							enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
							toggleModalFn(); getResponseBack()
							importFile(response?.data?.data)

						} else {
							enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

						}
					})
			} else {
				globalPostService(`camo/amp/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType })
					.then(response => {
						setLoading(false)
						if (response.data?.statusCode === 200) {
							enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
							toggleModalFn(); getResponseBack()
							addUploadFile(response?.data?.data)
							importFile(response?.data?.data)
						} else {
							enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

						}
					})

			}
		} else {
			setError(validationInputs)
		}
	}

	const nextDueValue = () => {
		var date = new Date(data.lastDone_D)
		date.setDate(date.getDate() + parseInt(data.interval_D))
		var dom = new Date(basicdetail?.date_of_manufacture)
		dom.setDate(dom.getDate() + parseInt(data.threshold_D))
		if (data.lastDone_D && data.interval_D) {
			if (data.lastDone_D?.length > 0) {
				data.nextDue_D = data.lastDone_D === "Invalid date" ? data.threshold_D != '' && moment(dom).format(backendDateFormat) : moment(date).format(backendDateFormat)
			} else {
				data.nextDue_D = null || ''
			}
		} else {
			if (data.threshold_D != '') {
				data.nextDue_D = moment(dom).format(backendDateFormat)
			} else {
				data.nextDue_D = null
			}
		}
		return data.nextDue_D

	}
	const nextDueFHValue = () => {
		if (data.lastDone_FH > 0 && data.interval_FH > 0) {
			if (data.lastDone_FH === '') {
				data.nextDue_FH = null
			} else {
				data.nextDue_FH = (+data.lastDone_FH + +data.interval_FH).toFixed(0)
			}
		} else {
			if (data.threshold_FH?.length > 0) {
				data.nextDue_FH = (+data.threshold_FH + +basicdetail?.tsn).toFixed(0)
			} else {
				data.nextDue_FH = null
			}
		}
		return data.nextDue_FH

	}
	const nextDueFCValue = () => {
		if (data.lastDone_FC > 0 && data.interval_FC > 0) {
			if (data.lastDone_FC === '' || null) {
				data.nextDue_FC = null
			} else {
				data.nextDue_FC = (+data.lastDone_FC + +data.interval_FC).toFixed(0)
			}
		} else {
			if (data.threshold_FC?.length > 0) {
				data.nextDue_FC = (+data.threshold_FC + +basicdetail?.csn).toFixed(0)
			} else {
				data.nextDue_FC = null
			}
		}
		return data.nextDue_FC
	}
	const remainingDaysValues = () => {
		const calDate = new Date()
		const todayD = moment(calDate).format(backendDateFormat)
		const lDate = moment(data.nextDue_D)
		if (data.nextDue_D) {
			data.remainingValues_D = lDate.diff(todayD, "days") ? lDate.diff(todayD, "days") : null
		}
		return data.remainingValues_D
	}

	return (
		<>
			<Dialog
				open={true}
				onClose={toggleModalFn}
			>
				<DialogTitle> {modeType === "edit" ? "Update AMP/MRB Compliance" : " Add AMP/MRB Compliance"} </DialogTitle>
				<DialogContent dividers>
					<Grid spacing={1} container>
						<Grid item xs={4}>
							<TextField
								required
								variant="outlined"
								label="MPD Item Number"
								id="MPD_item_no"
								margin='normal'
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={data.MPD_item_no}
								error={error.MPD_item_no ? true : false}
								helperText={error.MPD_item_no ? error.MPD_item_no : ""}
								onChange={(e, value) => {
									const input = e.target.value
									if (input.length <= 20) {
										onFieldChange("MPD_item_no", input)
										setError({ ...error, 'MPD_item_no': '' })
									} else {
										const truncatedInput = input.slice(0, 20)
										onFieldChange("MPD_item_no", truncatedInput)
										setError({ ...error, 'MPD_item_no': '' })
									}
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								required
								variant="outlined"
								label="Task Card Number"
								id="task_card_no"
								margin='normal'
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={data.task_card_no}
								error={error.task_card_no ? true : false}
								helperText={error.task_card_no ? error.task_card_no : ""}
								onChange={(e, value) => {
									const input = e.target.value
									if (input.length <= 20) {
										onFieldChange("task_card_no", input)
										setError({ ...error, 'task_card_no': '' })
									} else {
										const truncatedInput = input.slice(0, 20)
										onFieldChange("task_card_no", truncatedInput)
										setError({ ...error, 'task_card_no': '' })
									}
								}}
							/>
						</Grid>
						<Grid item xs={4}>
							<TextField
								variant="outlined"
								label="Previous LDND Task Number"
								id="previous_LDND_Taskcard"
								margin='normal'
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={data.previous_LDND_Taskcard ? data.previous_LDND_Taskcard : ""}
								onChange={(e, value) => {
									const input = e.target.value
									if (input.length <= 20) {
										onFieldChange("previous_LDND_Taskcard", input)
										setError({ ...error, 'previous_LDND_Taskcard': '' })
									} else {
										const truncatedInput = input.slice(0, 20)
										onFieldChange(
											"previous_LDND_Taskcard",
											truncatedInput
										)
										setError({ ...error, 'previous_LDND_Taskcard': '' })
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid spacing={1} container>
						<Grid item xs={12}>
							<TextField
								required
								variant="outlined"
								label="Task Description"
								id="Task_Description"
								multiline
								minRows={5}
								margin='normal'
								fullWidth
								InputLabelProps={{ shrink: true }}
								error={error.Task_Description ? true : false}
								helperText={error.Task_Description ? error.Task_Description : ""}
								value={data.Task_Description ? data.Task_Description : ""}
								onChange={(e, value) => {
									const input = e.target.value
									if (input.length <= 255) {
										onFieldChange("Task_Description", input)
										setError({ ...error, 'Task_Description': '' })
									} else {
										const truncatedInput = input.slice(0, 255)
										onFieldChange("Task_Description", truncatedInput)
										setError({ ...error, 'Task_Description': '' })
									}
								}}
							/>
						</Grid>
					</Grid>
					<Grid id="data-container" spacing={1} container>
						<Grid item xs={4}>
							<label className="ml-10px font-14">THRESHOLD</label>
							<Grid spacing={1} container>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label={<span> Days {data.threshold_FH == null && data.threshold_FC == null ? (<span style={{ color: "red" }}> *</span>) : null} </span>}
										id="threshold_D"
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										error={error.threshold_D ? true : false}
										helperText={error.threshold_D ? error.threshold_D : ""}
										value={data.threshold_D || data.threshold_D === 0 ? data.threshold_D : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (
												input.length <= 5 &&
												regexConstants.onlyNumeric.test(e.target.value)
											) {
												onFieldChange("threshold_D", input)
												setError({ ...error, 'threshold_D': '' })
											} else if (
												input.length > 5 &&
												regexConstants.onlyNumeric.test(input)
											) {
												const truncatedInput = input.slice(0, 5)
												onFieldChange("threshold_D", truncatedInput)
												setError({ ...error, 'threshold_D': '' })
											} else {
												e.preventDefault()
											}
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label="FH"
										id="threshold_FH"
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										error={error.threshold_FH ? true : false}
										helperText={error.threshold_FH ? error.threshold_FH : ""}
										value={data.threshold_FH || data.threshold_FH === 0 ? data.threshold_FH : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (
												input.length <= 10 &&
												regexConstants.onlyNumeric.test(e.target.value)
											) {
												onFieldChange("threshold_FH", input)
												setError({ ...error, 'threshold_FH': '' })
											} else if (
												input.length > 10 &&
												regexConstants.onlyNumeric.test(input)
											) {
												const truncatedInput = input.slice(0, 10)
												onFieldChange("threshold_FH", truncatedInput)
												setError({ ...error, 'threshold_FH': '' })
											} else {
												e.preventDefault()
											}
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label="FC"
										id="threshold_FC"
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										error={error.threshold_FC ? true : false}
										helperText={error.threshold_FC ? error.threshold_FC : ""}
										value={data.threshold_FC || data.threshold_FC === 0 ? data.threshold_FC : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (
												input.length <= 10 &&
												regexConstants.onlyNumeric.test(e.target.value)
											) {
												onFieldChange("threshold_FC", input)
												setError({ ...error, 'threshold_FC': '' })
											} else if (
												input.length > 10 &&
												regexConstants.onlyNumeric.test(input)
											) {
												const truncatedInput = input.slice(0, 10)
												onFieldChange("threshold_FC", truncatedInput)
												setError({ ...error, 'threshold_FC': '' })
											} else {
												e.preventDefault()
											}
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={4}>
							<label className="ml-10px font-14">INTERVAL</label>
							<Grid spacing={1} container>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label={<span> Days {data.threshold_D !== undefined && data.threshold_D !== null && data.threshold_D !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </span>}
										id="interval_D"
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										error={error.interval_D ? true : false}
										helperText={error.interval_D ? error.interval_D : ""}
										value={data.interval_D || data.interval_D === 0 ? data.interval_D : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (
												input.length <= 5 &&
												regexConstants.onlyNumeric.test(e.target.value)
											) {
												onFieldChange("interval_D", input)
												setError({ ...error, 'interval_D': '' })
											} else if (
												input.length > 5 &&
												regexConstants.onlyNumeric.test(input)
											) {
												const truncatedInput = input.slice(0, 5)
												onFieldChange("interval_D", truncatedInput)
												setError({ ...error, 'interval_D': '' })
											} else {
												e.preventDefault()
											}
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label={<span> FH {data.threshold_FH !== undefined && data.threshold_FH !== null && data.threshold_FH !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </span>}
										id="interval_FH"
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										error={error.interval_FH ? true : false}
										helperText={error.interval_FH ? error.interval_FH : ""}
										value={data.interval_FH || data.interval_FH === 0 ? data.interval_FH : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (
												input.length <= 10 &&
												regexConstants.onlyNumeric.test(e.target.value)
											) {
												onFieldChange("interval_FH", input)
												setError({ ...error, 'interval_FH': '' })
											} else if (
												input.length > 10 &&
												regexConstants.onlyNumeric.test(input)
											) {
												const truncatedInput = input.slice(0, 10)
												onFieldChange("interval_FH", truncatedInput)
												setError({ ...error, 'interval_FH': '' })
											} else {
												e.preventDefault()
											}
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label={<span> FC {data.threshold_FC !== undefined && data.threshold_FC !== null && data.threshold_FC !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </span>}
										id="interval_FC"
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										error={error.interval_FC ? true : false}
										helperText={error.interval_FC ? error.interval_FC : ""}
										value={data.interval_FC || data.interval_FC === 0 ? data.interval_FC : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (
												input.length <= 10 &&
												regexConstants.onlyNumeric.test(e.target.value)
											) {
												onFieldChange("interval_FC", input)
												setError({ ...error, 'interval_FC': '' })
											} else if (
												input.length > 10 &&
												regexConstants.onlyNumeric.test(input)
											) {
												const truncatedInput = input.slice(0, 10)
												onFieldChange("interval_FC", truncatedInput)
												setError({ ...error, 'interval_FC': '' })
											} else {
												e.preventDefault()
											}
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={4}>
							<label className="ml-10px font-14">LAST DONE</label>
							<Grid spacing={1} container>
								<Grid item xs={4} className="input-calender">
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											id="lastDone_D"
											label={<span> Date {data.threshold_D !== undefined && data.threshold_D !== null && data.threshold_D !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </span>}
											format={fieldDateFormat}
											inputVariant="outlined"
											fullWidth
											disableFuture
											margin='normal'
											InputLabelProps={{ shrink: true }}
											minDate={moment().subtract(25, "years")}
											value={data.lastDone_D || ''}
											error={error.lastDone_D ? true : false}
											helperText={error.lastDone_D ? error.lastDone_D : ""}
											onChange={(data, value) => {
												onFieldChange(
													"lastDone_D",
													moment(data).format(backendDateFormat)
												)
												setError({ ...error, 'lastDone_D': '' })
											}}

										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label={<span> FH {data.threshold_FH !== undefined && data.threshold_FH !== null && data.threshold_FH !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </span>}
										id="lastDone_FH"
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										error={error.lastDone_FH ? true : false}
										helperText={error.lastDone_FH ? error.lastDone_FH : ""}
										value={data.lastDone_FH ? data.lastDone_FH : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (
												input.length <= 10 &&
												regexConstants.onlyNumeric.test(e.target.value)
											) {
												onFieldChange("lastDone_FH", input)
												setError({ ...error, 'lastDone_FH': '' })
											} else if (
												input.length > 10 &&
												regexConstants.onlyNumeric.test(input)
											) {
												const truncatedInput = input.slice(0, 10)
												onFieldChange("lastDone_FH", truncatedInput)
												setError({ ...error, 'lastDone_FH': '' })
											} else {
												e.preventDefault()
											}
										}}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label={<span> FC {data.threshold_FC !== undefined && data.threshold_FC !== null && data.threshold_FC !== "" ? (<span style={{ color: "red" }}> *</span>) : null} </span>}
										id="lastDone_FC"
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										error={error.lastDone_FC ? true : false}
										helperText={error.lastDone_FC ? error.lastDone_FC : ""}
										value={data.lastDone_FC ? data.lastDone_FC : ""}
										onChange={(e, value) => {
											const input = e.target.value
											if (
												input.length <= 10 &&
												regexConstants.onlyNumeric.test(e.target.value)
											) {
												onFieldChange("lastDone_FC", input)
												setError({ ...error, 'lastDone_FC': '' })
											} else if (
												input.length > 10 &&
												regexConstants.onlyNumeric.test(input)
											) {
												const truncatedInput = input.slice(0, 10)
												onFieldChange("lastDone_FC", truncatedInput)
												setError({ ...error, 'lastDone_FC': '' })
											} else {
												e.preventDefault()
											}
										}}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid id="data-container" spacing={1} container>
						<Grid item xs={6}>
							<label className="ml-10px font-14">NEXT DUE</label>
							<Grid spacing={1} container>
								<Grid item xs={4} className="input-calender" id="disabled">
									<MuiPickersUtilsProvider utils={MomentUtils}>
										<KeyboardDatePicker
											id="date_of_manufacture"
											format={fieldDateFormat}
											inputVariant="outlined"
											label="Date"
											fullWidth
											disableFuture
											disabled
											margin='normal'
											InputLabelProps={{ shrink: true }}
											minDate={moment().subtract(25, "years")}
											value={nextDueValue()}
											error={error.nextDue_D ? true : false}
											helperText={error.nextDue_D ? error.nextDue_D : ""}
											onChange={(data, value) => onFieldChange("nextDue_D", moment(data).format(backendDateFormat))}
											className="disabled-input"
										/>
									</MuiPickersUtilsProvider>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label="FH"
										name="nextDue_FH"
										id="nextDue_FH"
										value={nextDueFHValue()}
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										disabled
										className="disabled-input"
										onChange={(e, value) => onFieldChange("nextDue_FH", e.target.value)}
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label="FC"
										name="nextDue_FC"
										id="nextDue_FC"
										value={nextDueFCValue()}
										inputProps={{ readOnly: true }}
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										disabled
										className="disabled-input"
									/>
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={6}>
							<label className="ml-10px font-14">REMAINING</label>
							<Grid spacing={1} container>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label="Days"
										name="remainingValues_D"
										id="remainingValues_D"
										// value={data.remainingValues_D}
										value={remainingDaysValues()}
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										inputProps={{ readOnly: true }}
										disabled
										className="disabled-input"
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label="FH"
										name="remainingValues_FH"
										id="remainingValues_FH"
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										value={
											basicdetail?.tsn &&
												data.interval_FH &&
												data.interval_FH > 0 &&
												data.lastDone_FH &&
												parseInt(data.lastDone_FH) +
												parseInt(data.interval_FH) >
												0
												? (data.remainingValues_FH =
													data.nextDue_FH !== 0
														? Number.isInteger(
															data.nextDue_FH - basicdetail?.tsn
														)
															? data.nextDue_FH - basicdetail?.tsn
															: (
																data.nextDue_FH - basicdetail?.tsn
															).toFixed(2)
														: "")
												: ""
										}
										inputProps={{ readOnly: true }}
										disabled
										className="disabled-input"
									/>
								</Grid>
								<Grid item xs={4}>
									<TextField
										variant="outlined"
										label="FC"
										name="remainingValues_FC"
										id="remainingValues_FC"
										margin='normal'
										fullWidth
										InputLabelProps={{ shrink: true }}
										value={
											basicdetail?.csn &&
												data.interval_FC &&
												data.interval_FC > 0 &&
												data.lastDone_FC &&
												parseInt(data.lastDone_FC) +
												parseInt(data.interval_FC) >
												0
												? (data.remainingValues_FC = (
													data.nextDue_FC - basicdetail?.csn
												).toFixed(
													Number.isInteger(
														data.nextDue_FC - basicdetail?.csn
													)
														? 0
														: 2
												))
												: ""
										}
										inputProps={{ readOnly: true }}
										disabled
										className="disabled-input"
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6} sm={6}>
							<TextField
								label="Remarks"
								margin='normal'
								variant='outlined'
								fullWidth
								value={data.remark}
								InputLabelProps={{ shrink: true }}
								onChange={(e) => onFieldChange("remark", e.target.value)}
								rows={3}
								multiline
							/>
						</Grid>
						<Grid item xs={6}>
							<div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
								<span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
								<span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
							</div>
						</Grid>
						<div className="checklist-file" style={{ overflow: "auto" }}>
                        {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
							<ul className='list-inline'>
								{(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
									return (
										<li className='list-inline-item'>
											<span className='file-name'>{attachments.name}</span>
											<span className='file-remove'>
												<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
												<CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
											</span>
										</li>)
								}

								)}
								{(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
									data.checklist?.map((file) => {
										return (
											<li className='list-inline-item' key={file.id}>
												<span className='file-name'>{file.name}</span>
												<span className='file-remove'>
													<a href={decodeURIComponent(file.path)} download={file.name}>
														{data?.checklist.some(item => item.type === 'folder') ? null
															: <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
													</a>
													<CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
												</span>
											</li>
										);
									})}
								{(data.checklist?.files?.length > 0) &&
									data.checklist?.files.map((file) => {
										return (
											<li className='list-inline-item' key={file.id}>
												<span className='file-name'>{file.name}</span>
												<span className='file-remove'>
													<a href={decodeURIComponent(file.path)} download={file.name}>
														<GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
													</a>
													<CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
												</span>
											</li>
										);
									})}
								{(data.checklist?.folders?.length > 0) &&
									data.checklist?.folders.map((file) => {
										return (
											<li className='list-inline-item' key={file.id}>
												<Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
													<a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
												</Tooltip>
												<span className='file-name'>{file.name}</span>
												<span className='file-remove'>
													<CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
												</span>
											</li>
										);
									})}
							</ul>
						</div>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
					<Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
				</DialogActions>
			</Dialog>
			{uploadFile.modal ?
				<UploadFile
					modType="amp"
					type="upload"
					list={list}
					actionType={modeType}
					editFormId={data?.id}
					handleClose={() => setUploadFile({ modal: false })}
					uuid={basicdetail?.record_folders?.technical_records?.uuid}
					getResponseBack={(file) => uploadFileData(file)}
					uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

				/>
				: null}
			{linkFile.modal ?
				<LinkFiles
					modType="amp"
					type="link"
					list={list}
					editFormId={data?.id}
					actionType={modeType}
					uploadFileInfo={data}
					toggleModalFn={() => setlinkFile({ modal: false })}
					uuid={basicdetail?.record_folders?.technical_records?.uuid}
					getResponseBack={(file) => uploadFileData(file)}
					onLinkFile={getData}
				/>
				: null
			}
			{isLoading ? <PageLoader /> : null}
		</>
	)

}
export default withRouter(AddEditAmp)
