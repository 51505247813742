import React from "react"
import { Tooltip, IconButton, Button } from "@material-ui/core"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"

const DownloadPDFAssetDetail = ({ exportReportFn, files }) => {
	const handleClose = (event, files, triggerExport) => {
		if (triggerExport) {
			exportReportFn(files)
		}
	}

	return (
		<>
			<Button
				onClick={(event) => handleClose(event, files, "triggerExport")}
				files={{ title: "PDF", extension: "pdf", key: "" }}
			>
				<ExitToAppIcon className="import-task-icon" />
				{/* Export   */}
				{files.extension === "pdf"}
				{files.title}
			</Button>
		</>
	)
}

export default DownloadPDFAssetDetail
