import { React, useState, useEffect } from 'react'
import moment from 'moment';
import config from '../../../../config'
import { useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import BasicDetail from '../../basicDetail/containers'
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { UtilizationHeader, UtilizationListing, AddEditUtilization } from '../components';
import { ImportFileFormat, BasicTopHeader } from '../../../../shared_elements/components';
import ExportMenu from '../../../../shared_elements/components/ExportMenu';
import { downloadFileType, checkApiStatus } from '../../../../utils';
import { EmptyCollection, PageLoader, DeletePopUp } from '../../../../shared_elements';
import Pagination from '../../../../shared_elements/components/Pagination';
import { globalGetService, globalDeleteService, globalExportService, globalPutService } from '../../../../utils/globalApiServices';
import { Table, TableBody, Paper, Button, Grid, TextField, Tooltip, InputAdornment } from "@material-ui/core";
import { exportMasterReport } from '../../apiServices';
import { DownloadAllApi } from '../../../fleet/containers/apiServices';
import ShowAttachments from '../../../../shared_elements/components/ShowAttachments';

function UtilizationComp({ match }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [list, setList] = useState({})
    const [basicdetail, setBasicDetail] = useState()
    const [techSpecData, setTechSpecData] = useState()
    const [isLoading, setLoading] = useState(false)
    const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
    const [deletelist, setDeleteList] = useState({ modal: false, data: null })
    const [importFile, setImportFile] = useState({ modal: false, data: null })
    const [addEditUtil, setaddEditUtil] = useState({ modal: false, data: null, type: '' })
    const [attchementsModal, setAttchementsModal] = useState({ modal: false, data: null, })
     
    useEffect(() => {
        getUtiliztionList({ per_page: 20 })
    }, [])
    const createSortHandler = (key) => {
        let sortQuery = { sort: key };
        if (sortInfo.sort === key) {
            sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
        } else {
            sortQuery = { ...sortQuery, sort_by: 'asc' }
        }
        setSortInfo(sortQuery)
        getUtiliztionList({ ...sortQuery, per_page: 20 });
    }


    const getAttachements = () => {
		const matchingItem = list?.results?.find(item => item?.id === attchementsModal?.data?.id);
		if (!matchingItem) return []; 
		const { checklist, attachments } = matchingItem || {};
		if (checklist && attachments || checklist || attachments) {
			const { files, folders } = checklist || {}; // Add null check here
			 if (attachments?.length > 0 && files?.length > 0 && folders?.length > 0) {
				return [...attachments, ...files, ...folders];
			}else if (files?.length > 0 && folders?.length > 0)  {
				return [...folders, ...files];
			}else if (attachments?.length > 0 && files?.length > 0) {
				return [...attachments, ...files];
			}else if (attachments?.length > 0 && folders?.length > 0) {
				return [...attachments, ...folders];
			}else if (attachments?.length > 0) {
				return [...attachments];
			} else if (folders?.length > 0) {
				return [...folders];
			} else if (files?.length > 0) {
				return [...files];
			}
		}
		return []; 
	};

    const getUtiliztionList = (query) => {
        setLoading(true)
        globalGetService(`camo/util/?asset=${match?.params?.asset}&asset_type=${match?.params?.assetType}`, { ...query, search: query?.search })
            .then((response) => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setList(response.data)
                }

            })
    }
    const deleteSoftTimeList = () => {
        setLoading(true)
        globalDeleteService(`camo/util/${deletelist?.data?.id}/`)
            .then((response) => {
                setLoading(false); setDeleteList({ modal: false }); getUtiliztionList({ per_page: 20 })
                if (checkApiStatus(response)) {
                    enqueueSnackbar("Record Deleted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                } else {
                    enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }

            })
    }
    const exportSoftTime = (extension) => {
        const date = new Date()
        const todayDate = moment(String(date)).format("DD-MM-YYYY")
        setLoading(true);
        globalExportService(`/camo/util/${match?.params?.asset}/${match?.params?.assetType}/export/`, { download: extension })
            .then(response => {
                setLoading(false);
                downloadFileType(response.data, `Utilization_${todayDate}`, extension)
                enqueueSnackbar("Download Utilization List Successfully", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            });
    }
    const deleteAttachements = (item, checklist, fileId, uuid) => {
        const removeID = item.attachments.map((data) => data.id).filter((id) => id).join(',')
        const attchementRemoveID = checklist.map((file) => file.id).filter((id) => !removeID.includes(id))
        if (uuid) {
           let payload = {};
			payload.delete = true;
			payload.folder_ids =checklist.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).toString()
			payload.file_ids = checklist.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).toString()
            globalPutService(`camo/util/${item?.id}/document-checklists/`, payload)
                .then((response) => {
                    if (checkApiStatus(response)) {
                        getUtiliztionList({ per_page: 20 })
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    } else {
                        enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                    }
                })
        } else {
            globalDeleteService(`camo/attachment/${fileId}/delete/`)
                .then((response) => {
                    if (checkApiStatus(response)) {
                        getUtiliztionList({ per_page: 20 })
                        enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    } else {
                        enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                    }
                })
        }
    };
    const getBasicDetailApi = async () => {
		try {
			const response = await globalGetService(`camo/asset/${match.params.asset}/${match.params.assetType}/view/?module=util`)
			if (checkApiStatus(response)) {
				setBasicDetail(response.data.data);
				if (match?.params?.assetType == 2) {
					await getTechSpecDetailApi(response.data.data);
					
				}
			}
		} catch (error) {
			console.error("Error in getBasicDetailApi:", error);
		}
	};
    const getTechSpecDetailApi = () => {
        globalGetService(`camo/engine_tech_spec/${techSpecData?.slug ? techSpecData?.slug :basicdetail.slug}/details/`)
			.then(response => {
				if (checkApiStatus(response)) {
					setTechSpecData(response?.data?.data)
				}
			})
	};

    return (
        <section className='amp-module'>
            <BasicTopHeader slug={basicdetail?.slug} heading="Utilization" onMasterExportFn={(query) => exportMasterReport(query, match, enqueueSnackbar)} refreshData={() => getUtiliztionList({ per_page: 20 })} />
            <BasicDetail moduleType="util" BasicDetailData={(data) => setBasicDetail(data)} basicdetail={basicdetail} techSpec_Data={techSpecData} utilisationData={list?.results}/>
            <Paper square style={{ padding: "10px" }} variant='outlined'>
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={5} md={5}>
                        <TextField
                            variant='outlined'
                            fullWidth
                            margin='normal'
                            size='small'
                            style={{ fontSize: "12px" }}
                            placeholder="Search By Location (A/c) Reg, Location Country,Reason of Removal "
                            onChange={(e) => getUtiliztionList({ search: e.target.value, per_page: 20 })}
                            InputProps={{ style: { fontSize: 12 }, endAdornment: <InputAdornment position="end"><SearchIcon color='primary' cursor="pointer" /></InputAdornment> }}
                        />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <ul className='list-inline' style={{ float: "right", margin: "revert" }}>
                            <li className='list-inline-item'>
                                <Button fullWidth variant='contained' color='primary' size='small' onClick={() => setaddEditUtil({ modal: true, data: null, type: 'add' })}> + Add Utilization</Button>
                            </li>
                            <li className='list-inline-item'>
                                <Button fullWidth variant='outlined' color='primary' size='small' onClick={() => setImportFile({ modal: true, data: null })}>Import</Button>
                            </li>
                            <li className='list-inline-item'>
                                <ExportMenu
                                    exportReportFn={(file) => exportSoftTime(file.extension)}
                                    files={[{ title: 'PDF', extension: 'pdf' }, { title: 'EXCEL', extension: 'xls' }]}
                                    disabled={!list?.results?.length}
                                />
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <div style={{ height: `${window.innerHeight - 360}px`, overflow: 'auto' }}>
                    <Table className='mui-table-format' stickyHeader={true}>
                        <UtilizationHeader
                            sortInfo={sortInfo}
                            createSortHandler={createSortHandler} />
                        <TableBody>
                            { list?.results?.length > 0 && list?.results?.map((item, index) =>
                                <UtilizationListing
                                    item={item}
                                    key={index}
                                    toggleModalFn={() => setaddEditUtil({ modal: true, data: item, type: 'edit' })}
                                    toggleDelteModalFn={() => setDeleteList({ modal: true, data: item })}
                                    handleOpenAttachments={() => setAttchementsModal({ modal: true, data: item })}
                                    list={list?.results}
                                />
                            )}
                        </TableBody>
                    </Table>
                    {!list?.results?.length ? <EmptyCollection title="No Records Founds" /> : null}
                </div>
                <Pagination
                    pagination={list?.pagination}
                    onChangeRowsPerPage={(event) => getUtiliztionList({ page: 1, per_page: event.target.value })}
                    onChangePage={(event, newPage) => getUtiliztionList({ per_page: list.pagination.per_page, page: newPage + 1, })}
                />
            </Paper>
            {addEditUtil.modal ?
                <AddEditUtilization
                    UtilData={addEditUtil?.data}
                    toggleModalFn={() => setaddEditUtil({ modal: false })}
                    basicdetail={basicdetail}
                    modeType={addEditUtil.type}
                    getResponseBack={() => {getUtiliztionList({ per_page: 20, });getBasicDetailApi();getTechSpecDetailApi()}}
                    list={list?.results}
                    moduleType="util"
                /> : null
            }
            {deletelist.modal ?
                <DeletePopUp
                    title='Delete Utilization'
                    deleteRecordFn={() => deleteSoftTimeList()}
                    modal={() => setDeleteList({ modal: true })}
                    content='Do you really want to delete this record?'
                    toggleModalFn={() => setDeleteList({ modal: false })}

                /> : null

            }
            {importFile.modal ?
                <ImportFileFormat
                    title="Import Utilization"
                    moduleType='util'
                    moduleName="Sample File Utilization"
                    getResponseBack={() => getUtiliztionList({ per_page: 20 })}
                    toggleModalFn={() => setImportFile({ modal: false })}
                /> : null
            }
            {attchementsModal.modal ?
                <ShowAttachments
                    handleClose={() => setAttchementsModal({ modal: false, data: null })}
                    handleShowAttachments={getAttachements()}
                    openAttachment={(file) => window.open(decodeURIComponent(file), "_blank")}
                    files={{ title: "Utilization", extension: "zip", key: "" }}
                    downloadAllApi={() => DownloadAllApi(list?.results.filter(item=> attchementsModal.data?.id === item.id)[0], 'Utilization')}
                    item={attchementsModal.data}
                    removeAttachment={deleteAttachements}
                />
                :
                null
            }
            {isLoading ? <PageLoader /> : null}
        </section>
    )
}

export default withRouter(UtilizationComp)