import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip, CircularProgress, InputLabel, Select, MenuItem } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import { DropzoneArea } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { getLocalStorageInfo } from "../../../../utils";
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus } from '../../../../utils';
import SectionCreates from "../../../../shared_elements/components/SectionCreates"
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { occmObj } from '.';
import { assetType, imgStoragePath } from "../../../../constants";
import { specificKey, specificMessTsn, specificMessCsn } from '../..';


function AddEditOccm({ match, toggleModalFn, occmData, basicdetail, modeType, getResponseBack, list }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(occmData ? occmData : occmObj)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
    const [linkFile, setlinkFile] = useState({ modal: false, data: null })

    const onFieldChange = (keyParam, value) => {
        setData({ ...data, [keyParam]: value });
    }

    const uploadFileData = (file) => {
        setData({ ...data, checklist: file })
    }

    const getData = (selectedFile, type) => {
        setData({ ...data, checklist: selectedFile, linkFile: type });
    }

    const uploadFileFn = (file, uuid, type) => {
        if (uuid) {
            let storeChecklistData = [];
            let folderUuid = [];

            const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
            const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

            for (const arr of updatedChecklist) {
                storeChecklistData = storeChecklistData.concat(arr);
            }
            for (const arr of folderUUID) {
                folderUuid = folderUuid.concat(arr);
            }
            setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
        } else {
            if (Array.isArray(file)) {
                const updatedAttachments = [...(data?.attachments || []), ...file];
                setData({ ...data, 'attachments': updatedAttachments });
            } else {
                console.error("Value for attachments is not an array:", file);
            }
        }
    }
    const addUploadFile = (item) => {
        if (data?.linkFile === "link") {
            let payload = {};
            if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            } else if (data?.checklist.some(item => item.type === 'folder')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
            } else {
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            }
            globalPutService(`camo/occm/${item?.id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        toggleModalFn()
                        getResponseBack(response.data.data);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0) {
            if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
                let formData = new FormData();
                formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
                for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
                    formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
                }
                globalPutService(`camo/occm/${item?.id}/document-checklists/`, formData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    });
            }
        }

    }
    const importFile = (file) => {
        var formData = new FormData();
        Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
            formData.append('files', data?.attachments.filter(item => item instanceof File)[key]);
        })
        globalFileUploadService(`camo/attachments/occmcomponent/${file?.id}/`, formData)
            .then(response => {
                toggleModalFn(); getResponseBack()
                if (checkApiStatus(response)) {
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })

    }
    const onRemoveFile = (file, type, fileId, folder) => {
        if (modeType === "add") {
            if (type === "checklist") {
                setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
            } else {
                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
            }
        } else {
            if (type === "checklist") {
                let payload = {};
                payload.delete = true;
                payload.folder_ids = data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
                payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
                globalPutService(`camo/occm/${data.id}/document-checklists/`, payload)
                    .then((response) => {
                        if (checkApiStatus(response)) {
                            const updatedChecklist = { ...data.checklist };
                            updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                            updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
                            setData({ ...data, checklist: updatedChecklist });
                            getResponseBack()
                        }
                    });
            } else {
                if (file.id) {
                    globalDeleteService(`camo/attachment/${file.id}/delete/`)
                        .then((response) => {
                            if (checkApiStatus(response)) {
                                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                                getResponseBack()
                            }
                        });
                } else {
                    setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                }
            }
        }
    }

    const onAddNewAsset = () => {
        let validationInputs = {}
        if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')) {
            validationInputs = {
                ...validationInputs,
                ata_chapter: data?.ata_chapter ? '' : 'Please Enter ATA Chapter',
                part_number: data?.part_number ? '' : 'Please Enter Part Number',
                part_serial_number: data?.part_serial_number ? '' : 'Please Enter Serial Number',
                part_installation_date: data?.part_installation_date ? '' : 'Please Select Instalation Date',
                position: data?.position ? '' : 'Please Select Postion',
                faa_easa_certificate: data?.faa_easa_certificate ? '' : 'Please Select FAA EASA Postion',
                part_description: data?.part_description ? '' : 'Please Enter Descriptiion',

            }
        }
        if (typeof data.aircraft_csn === 'string' && !specificKey.includes(data.aircraft_csn.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.aircraft_csn)) {
            validationInputs.aircraft_csn = specificMessCsn;
        }
        if (typeof data.aircraft_tsn === 'string' && !specificKey.includes(data.aircraft_tsn.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.aircraft_tsn)) {
            validationInputs.aircraft_tsn = specificMessTsn;
        }
        if (typeof data.part_csn === 'string' && !specificKey.includes(data.part_csn.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.part_csn)) {
            validationInputs.part_csn = specificMessCsn;
        }
        if (typeof data.part_tsn === 'string' && !specificKey.includes(data.part_tsn.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.part_tsn)) {
            validationInputs.part_tsn = specificMessTsn;
        }

        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/occm/${payload.id}/`, payload)
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response?.data?.data)

                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                globalPostService(`camo/occm/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            addUploadFile(response?.data?.data)
                            importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })

            }
        } else {
            setError(validationInputs)
        }
    }

    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Update OCCM" : " Add OCCM"} </DialogTitle>
                <DialogContent dividers>
                    <Grid spacing={1} container>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                variant="outlined"
                                label="ATA Chapter"
                                id="ata_chapter"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.ata_chapter}
                                error={error.ata_chapter ? true : false}
                                onFocus={() => setError({...error,'ata_chapter':''})}
                                helperText={error.ata_chapter ? error.ata_chapter : ""}
                                onChange={(e, value) => onFieldChange("ata_chapter", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                variant="outlined"
                                label="Part Number"
                                id="part_number"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.part_number}
                                error={error.part_number ? true : false}
                                helperText={error.part_number ? error.part_number : ""}
                                onFocus={() => setError({...error,'part_number':''})}
                                onChange={(e, value) => onFieldChange("part_number", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                required
                                variant="outlined"
                                label="Part Serial Number"
                                id="part_serial_number"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.part_serial_number}
                                error={error.part_serial_number ? true : false}
                                helperText={error.part_serial_number ? error.part_serial_number : ""}
                                onFocus={() => setError({...error,'part_serial_number':''})}
                                onChange={(e, value) => onFieldChange("part_serial_number", e.target.value)}
                           
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required
                                    id="part_installation_date"
                                    label="Part Installation Date"
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    fullWidth
                                    disableFuture
                                    margin='normal'
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.part_installation_date}
                                    error={error.part_installation_date ? true : false}
                                    helperText={error.part_installation_date ? error.part_installation_date : ""}
                                    onChange={(data, value) => {
                                        onFieldChange("part_installation_date", moment(data).format(backendDateFormat))
                                        setError({...error,'part_installation_date':''})
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12}>
                            <TextField
                                required
                                variant="outlined"
                                label="Part Description"
                                id="part_description"
                                multiline
                                minRows={5}
                                margin='normal'
                                fullWidth
                                inputProps={{ maxLength: 255 }}
                                InputLabelProps={{ shrink: true }}
                                value={data.part_description}
                                error={error.part_description ? true : false}
                                helperText={error.part_description ? error.part_description : ""}
                                onFocus={() => setError({ ...error, 'part_description': '' })}
                                onChange={(e, value) => onFieldChange("part_description", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} sm={2} md={2}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        <span>
                                            {match.params.assetType == 1 ? "TSN Installation" : "TSN Installation"}
                                        </span>
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="aircraft_tsn"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={data.aircraft_tsn}
                                error={error.aircraft_tsn ? true : false}
                                helperText={error.aircraft_tsn ? error.aircraft_tsn : ""}
                                onFocus={() => setError({ ...error, 'aircraft_tsn': '' })}
                                onChange={(e, value) => onFieldChange("aircraft_tsn", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        <span>
                                            {match.params.assetType == 1 ? " CSN Installation" : "CSN Installation"}
                                        </span>
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="aircraft_csn"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={data.aircraft_csn}
                                error={error.aircraft_csn ? true : false}
                                helperText={error.aircraft_csn ? error.aircraft_csn : ""}
                                onFocus={() => setError({ ...error, 'aircraft_csn': '' })}
                                onChange={(e, value) => onFieldChange("aircraft_csn", e.target.value)}
                                // onChange={(e, value) => {
                                //     const input = e.target.value
                                //     if (
                                //         input.length <= 10 &&
                                //         regexConstants.onlyNumeric.test(e.target.value)
                                //     ) {
                                //         onFieldChange("aircraft_csn", input)
                                //         onRestErrorKey("aircraft_csn")
                                //     } else if (
                                //         input.length > 10 &&
                                //         regexConstants.onlyNumeric.test(e.target.value)
                                //     ) {
                                //         const truncatedInput = input.slice(0, 10)
                                //         onFieldChange("aircraft_csn", truncatedInput)
                                //         onRestErrorKey("aircraft_csn")
                                //     } else {
                                //         e.preventDefault()
                                //     }
                                // }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        Part TSN Installation
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="part_tsn"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={data.part_tsn}
                                error={error.part_tsn ? true : false}
                                helperText={error.part_tsn ? error.part_tsn : ""}
                                onFocus={() => setError({ ...error, 'part_tsn': '' })}
                                onChange={(e)=>onFieldChange("part_tsn",e.target.value)}
                                // onChange={(e, value) => {
                                //     const input = e.target.value
                                //     if (
                                //         input.length <= 10 &&
                                //         regexConstants.onlyNumeric.test(e.target.value)
                                //     ) {
                                //         onFieldChange("part_tsn", input)
                                //         onRestErrorKey("part_tsn")
                                //     } else if (
                                //         input.length > 10 &&
                                //         regexConstants.onlyNumeric.test(e.target.value)
                                //     ) {
                                //         const truncatedInput = input.slice(0, 10)
                                //         onFieldChange("part_tsn", truncatedInput)
                                //         onRestErrorKey("part_tsn")
                                //     } else {
                                //         e.preventDefault()
                                //     }
                                // }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        Part CSN Installation
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="part_csn"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={data.part_csn}
                                error={error.part_csn ? true : false}
                                helperText={error.part_csn ? error.part_csn : ""}
                                onFocus={() => setError({ ...error, 'part_csn': '' })}
                                onChange={(e)=>onFieldChange("part_csn",e.target.value)}
                                // onChange={(e, value) => {
                                //     const input = e.target.value
                                //     if (
                                //         input.length <= 10 &&
                                //         regexConstants.onlyNumeric.test(e.target.value)
                                //     ) {
                                //         onFieldChange("part_csn", input)
                                //         onRestErrorKey("part_csn")
                                //     } else if (
                                //         input.length > 10 &&
                                //         regexConstants.onlyNumeric.test(e.target.value)
                                //     ) {
                                //         const truncatedInput = input.slice(0, 10)
                                //         onFieldChange("part_csn", truncatedInput)
                                //         onRestErrorKey("part_csn")
                                //     } else {
                                //         e.preventDefault()
                                //     }
                                // }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <TextField
                                required
                                variant="outlined"
                                label="Position"
                                id="position"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.position}
                                error={error.position ? true : false}
                                helperText={error.position ? error.position : ""}
                                onFocus={() => setError({ ...error, 'position': '' })}
                                onChange={(e,value)=>onFieldChange("position",e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid id="data-container" spacing={1} container>
                        <Grid item xs={12} sm={2} md={2}>
                            <label>
                                FAA / EASA Certificate
                                <span style={{ color: "red" }}> *</span>
                            </label>
                            <FormControl
                                error={error.faa_easa_certificate ? true : false}
                                helperText={error.faa_easa_certificate ? error.faa_easa_certificate : ""}
                            >
                                <RadioGroup
                                    required
                                    row
                                    aria-label="faa_easa_certificate"
                                    name="faa_easa_certificate"
                                    defaultValue="top"
                                    value={data.faa_easa_certificate}
                                    onChange={(e, value) => onFieldChange("faa_easa_certificate", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                                {error.faa_easa_certificate && (
                                    <FormLabel component="faa_easa_certificate">
                                        <span style={{ color: "red" }}> {error.faa_easa_certificate} </span>
                                    </FormLabel>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <label>Shop Report </label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="shop_report"
                                    name="shop_report"
                                    defaultValue="top"
                                    value={data.shop_report}
                                    onChange={(e, value) => onFieldChange("shop_report", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <label>Certificate of Conformity</label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="conformity_certificate"
                                    name="conformity_certificate"
                                    defaultValue="top"
                                    value={data.conformity_certificate}
                                    onChange={(e, value) => onFieldChange("conformity_certificate", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <label>Goods receipt note</label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="receipt_note"
                                    name="receipt_note"
                                    defaultValue="top"
                                    value={data.receipt_note}
                                    onChange={(e, value) => onFieldChange("receipt_note", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <label>Installation Work order </label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="work_order"
                                    name="work_order"
                                    defaultValue="top"
                                    value={data.work_order}
                                    onChange={(e, value) =>
                                        onFieldChange("work_order", e.target.value)
                                    }
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                label="Remarks"
                                margin='normal'
                                variant='outlined'
                                fullWidth
                                value={data.remarks}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("remarks", e.target.value)}
                                rows={3}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
                                <span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
                                <span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
                            </div>
                        </Grid>
                        <div className="checklist-file" style={{ overflow: "auto" }}>
                            {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
                            <ul className='list-inline'>
                                {(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
                                    return (
                                        <li className='list-inline-item'>
                                            <span className='file-name'>{attachments.name}</span>
                                            <span className='file-remove'>
                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
                                                <CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                            </span>
                                        </li>)
                                }

                                )}
                                {(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
                                    data.checklist?.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        {data?.checklist.some(item => item.type === 'folder') ? null
                                                            : <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.files?.length > 0) &&
                                    data.checklist?.files.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.folders?.length > 0) &&
                                    data.checklist?.folders.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                    <a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                </Tooltip>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {uploadFile.modal ?
                <UploadFile
                    modType="occm"
                    type="upload"
                    list={list}
                    actionType={modeType}
                    editFormId={data?.id}
                    handleClose={() => setUploadFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

                />
                : null}
            {linkFile.modal ?
                <LinkFiles
                    modType="occm"
                    type="link"
                    list={list}
                    editFormId={data?.id}
                    actionType={modeType}
                    uploadFileInfo={data}
                    toggleModalFn={() => setlinkFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    onLinkFile={getData}
                />
                : null
            }
            {isLoading ? <PageLoader /> : null}
        </>
    )

}
export default withRouter(AddEditOccm)