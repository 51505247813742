import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Skeleton } from '@material-ui/lab';
export default function LabelValueCard({ xs = 12, sm = 12, md, label, value, classes = '', skeletonLoader = false }) {
	let classlist = 'label-value-card ' + classes;
	return (
		<Grid item xs={xs} md={md} sm={sm} className={classlist}>
			<p>{label}</p>
			{skeletonLoader ? <Skeleton variant="text" animation="wave" height={30} /> : <h6>{value}</h6>}
		</Grid>
	)
}
