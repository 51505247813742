import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
import { smbcInstance } from "../.."
const SbHeader = ({ sortInfo, createSortHandler }) => {
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }
    return (
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            {smbcInstance ?
                <TableRow>
                    <TableCell>Action </TableCell>
                    <TableCell style={{ minWidth: "150px" }}><TableSortLabel active={sortInfo.sort === "sb_ref_number" ? true : false} direction={sortInfo.sort === "sb_ref_number" ? sortInfo.sort_by : 'desc'} onClick={() => createSortHandler("sb_ref_number")} > Reference Number</TableSortLabel></TableCell>
                    <TableCell>{tableSorting('sb_category', 'Category')}</TableCell>
                    <TableCell style={{ minWidth: "250px" }}> {tableSorting('description', 'Description')}</TableCell>
                    <TableCell style={{ minWidth: "200px" }}> {tableSorting('sb_compliance_status', 'Compliance Status')} </TableCell>
                    <TableCell>{tableSorting('sb_compliance_tsn', 'TSN')}</TableCell>
                    <TableCell>{tableSorting('sb_compliance_csn', 'CSN')}</TableCell>
                    <TableCell>{tableSorting('sb_compliance_date', 'Date')}</TableCell>
                    <TableCell style={{ minWidth: "280px" }}> {tableSorting('remark', 'Remarks')}</TableCell>
                    <TableCell>Attachments </TableCell>
                </TableRow>
                : <TableRow>
                    <TableCell>Action </TableCell>
                    <TableCell style={{ minWidth: "150px" }}>{tableSorting('ata_chapter', 'ATA Chapter')}</TableCell>
                    <TableCell style={{ minWidth: "150px" }}>{tableSorting('sb_ref_number', 'Reference Number')}</TableCell>
                    <TableCell style={{ minWidth: "150px" }}>{tableSorting('sb_issued_by', 'Issued by')} </TableCell>
                    <TableCell style={{ minWidth: "150px" }}>{tableSorting('sb_applicability', 'Applicability')}</TableCell>
                    <TableCell style={{ minWidth: "150px" }}>{tableSorting('description', 'Description')}</TableCell>
                    <TableCell style={{ minWidth: "100px" }}>{tableSorting('type', 'SB Type')}</TableCell>
                    <TableCell style={{ minWidth: "150px" }}>{tableSorting('effectivity', 'Effectivity')} </TableCell>
                    <TableCell style={{ minWidth: "150px" }}>{tableSorting('effectivity_date', 'Effective Date')} </TableCell>
                    <TableCell style={{ minWidth: "150px" }}> {tableSorting('sb_interval_type', 'Compliance Period')}</TableCell>
                    <TableCell style={{ minWidth: "150px" }}> {tableSorting('sb_compliance_status', 'Compliance Status')} </TableCell>
                    <TableCell>{tableSorting('sb_compliance_tsn', 'TSN')}</TableCell>
                    <TableCell>{tableSorting('sb_compliance_csn', 'CSN')}</TableCell>
                    <TableCell style={{ minWidth: "150px" }}>Date of Compliance</TableCell>
                    <TableCell style={{ minWidth: "100px" }}>Due at</TableCell>
                    <TableCell style={{ minWidth: "280px" }}>Remaining</TableCell>
                    <TableCell>Attachments </TableCell>
                </TableRow>}
        </TableHead>
    )
}

export default SbHeader