import React from 'react';
import MainNavLink from './MainNavLink';

export default function DesktopSideNav() {
	return (
		<div className="desktop-py-sidenav">
			<nav>
				<MainNavLink />
			</nav>
		</div>
	)
}
