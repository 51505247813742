import { regexConstants } from "../../../../constants/regEx";

export const formValues = {
	asset_id: '',
	asset_type_id: '',
	module_type: '',
	part_number: '',
	serial_number: '',
	description: '',
	tsn: '',
	csn: '',
	remarks: '',
	checklist: [],
	folder_uuid: '',
};

export const taskErrorCode = {
	module_type: {
		0: "",
		1: "Please Select Module",
	},
	module_type_obj: {
		required: true,
	},
	description: {
		0: "",
		1: "Please Enter Description",
	},
	description_obj: {
		required: true,
	},
	part_number: {
		0: "",
		1: "Please Enter Part Number",
	},
	part_number_obj: {
		required: true,
	},
	serial_number: {
		0: "",
		1: "Please Enter Serial Number",
	},
	serial_number_obj: {
		required: true,
	},
	tsn: {
		0: "",
		1: "Please Enter TSN",
	},
	tsn_obj: {
		required: true,
	},
	life_limit_thrust_a: {
		0: "",
		1: "Please Enter Thrust A",
	},
	life_limit_thrust_a_obj: {
		required: true,
	},
	life_limit_thrust_b: {
		0: "",
		1: "Please Enter Thrust B",
	},
	life_limit_thrust_b_obj: {
		required: true,
	},
	life_limit_thrust_c: {
		0: "",
		1: "Please Enter Thrust C",
	},
	life_limit_thrust_c_obj: {
		required: true,
	},
	life_limit_thrust_d: {
		0: "",
		1: "Please Enter Thrust D",
	},
	life_limit_thrust_d_obj: {
		required: true,
	},
	life_limit_thrust_e: {
		0: "",
		1: "Please Enter Thrust E",
	},
	life_limit_thrust_e_obj: {
		required: true,
	},
};
