import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputLabel, MenuItem, Select, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip, CircularProgress, TextareaAutosize } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import { DropzoneArea } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { getLocalStorageInfo } from "../../../../utils";
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus } from '../../../../utils';
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { sbobjData } from '.';
import { assetType, imgStoragePath } from "../../../../constants";
import { smbcInstance, specificKey, specificMessTsn, specificMessCsn } from '../..';

function AddEditSb({ match, toggleModalFn, sbData, basicdetail, modeType, getResponseBack, list }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(sbData ? sbData : sbobjData)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
    const [linkFile, setlinkFile] = useState({ modal: false, data: null })

console.log("data",data?.sb_compliance_status?.toUpperCase())
    const onFieldChange = (keyParam, value) => {
        setData({ ...data, [keyParam]: value });
    }
    const onRestErrorKey = (keyParam) => {
        setError({ [keyParam]: '' })
    }

    const uploadFileData = (file) => {
        setData({ ...data, checklist: file })
    }

    const getData = (selectedFile, type) => {
        setData({ ...data, checklist: selectedFile, linkFile: type });
    }

    const uploadFileFn = (file, uuid, type) => {
        if (uuid) {
            let storeChecklistData = [];
            let folderUuid = [];

            const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
            const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

            for (const arr of updatedChecklist) {
                storeChecklistData = storeChecklistData.concat(arr);
            }
            for (const arr of folderUUID) {
                folderUuid = folderUuid.concat(arr);
            }
            setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
        } else {
            if (Array.isArray(file)) {
                const updatedAttachments = [...(data?.attachments || []), ...file];
                setData({ ...data, 'attachments': updatedAttachments });
            } else {
                console.error("Value for attachments is not an array:", file);
            }
        }
    }
    const addUploadFile = (item) => {
        if (data?.linkFile === "link") {
            let payload = {};
            if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            } else if (data?.checklist.some(item => item.type === 'folder')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
            } else {
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            }
            globalPutService(`camo/sb/${item?.id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        toggleModalFn()
                        getResponseBack(response.data.data);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0) {
            if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
                let formData = new FormData();
                formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
                for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
                    formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
                }
                globalPutService(`camo/sb/${item?.id}/document-checklists/`, formData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    });
            }
        }

    }
    const importFile = (file) => {
        var formData = new FormData();
        Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
            formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
            formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
            formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
            formData.append("[" + key + "]module_id", file?.id)
            formData.append("[" + key + "]module_type_id", 5)
            formData.append("[" + key + "]asset_type_id", match.params.assetType)
            formData.append("[" + key + "]asset_id", match.params.asset)
        })
        globalFileUploadService(`camo/attachment/upload/`, formData)
            .then(response => {
                toggleModalFn(); getResponseBack()
                if (checkApiStatus(response)) {
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })

    }
    const onRemoveFile = (file, type, fileId, folder) => {
        if (modeType === "add") {
            if (type === "checklist") {
                setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
            } else {
                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
            }
        } else {
            if (type === "checklist") {
                let payload = {};
                payload.delete = true;
                payload.folder_ids = data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
                payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
                globalPutService(`camo/sb/${data.id}/document-checklists/`, payload)
                    .then((response) => {
                        if (checkApiStatus(response)) {
                            const updatedChecklist = { ...data.checklist };
                            updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                            updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
                            setData({ ...data, checklist: updatedChecklist });
                            getResponseBack()
                        }
                    });
            } else {
                if (file.id) {
                    globalDeleteService(`camo/attachment/${file.id}/delete/`)
                        .then((response) => {
                            if (checkApiStatus(response)) {
                                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                                getResponseBack()
                            }
                        });
                } else {
                    setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                }
            }
        }
    }

    const onAddNewAsset = () => {
        let validationInputs = {}
        if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead') && !smbcInstance) {
            validationInputs = {
                ...validationInputs,
                ata_chapter: data?.ata_chapter ? '' : "Please Enter ATA Chapter",
                sb_ref_number: data?.sb_ref_number ? '' : "Please Enter SB Number",
                description: data?.description ? '' : "Please Enter Description",
                revision: data?.revision ? '' : "Please Enter revision",
                revision_date: moment(data.revision_date).isValid() ? '' : "Please Select Revision Date",
            }
        }
        if (typeof data.sb_compliance_csn === 'string' && !specificKey.includes(data.sb_compliance_csn.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.sb_compliance_csn)) {
            validationInputs.sb_compliance_csn = specificMessCsn;
        }
        if (typeof data.sb_compliance_tsn === 'string' && !specificKey.includes(data.sb_compliance_tsn.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.sb_compliance_tsn)) {
            validationInputs.sb_compliance_tsn = specificMessTsn;
        }

        if (data.sb_interval_type == "FC") {
            validationInputs = {
                ...validationInputs,
                dimension_interval_fc: smbcInstance ? "" : data?.dimension_interval_fc ? '' : "Please Enter Interval FC"
            }
        } else if (data.sb_interval_type == "FC,D") {
            validationInputs = {
                ...validationInputs,
                dimension_interval_fc: smbcInstance ? "" : data?.dimension_interval_fc ? '' : "Please Enter Interval FC",
                dimension_interval_days: smbcInstance ? "" : data?.dimension_interval_fc ? '' : "Please Enter Interval Days"
            }
        } else if (data.sb_interval_type == "FH") {
            validationInputs = {
                ...validationInputs,
                sb_interval: smbcInstance ? "" : data?.sb_interval ? '' : "Please Enter SB  Interval",
            }
        } else if (data.sb_interval_type == "D") {
            validationInputs = {
                ...validationInputs,
                dimension_interval_days: smbcInstance ? "" : smbcInstance ? "" : data?.dimension_interval_days ? '' : "Please Enter Interval Days",
            }
        } else if (data.sb_interval_type == "FH,D") {
            validationInputs = {
                ...validationInputs,
                sb_interval: smbcInstance ? "" : data?.sb_interval ? '' : "Please Enter SB  Interval",
                dimension_interval_days: smbcInstance ? "" : data?.dimension_interval_fc ? '' : "Please Enter Interval Days"
            }
        } else if (data.sb_interval_type == "FC,FH") {
            validationInputs = {
                ...validationInputs,
                dimension_interval_fc: smbcInstance ? "" : data?.dimension_interval_fc ? '' : "Please Enter Interval FC",
                sb_interval: smbcInstance ? "" : data?.sb_interval ? '' : "Please Enter SB  Interval",
            }
        }
        else if (data.sb_interval_type == "FC,FH,D") {
            validationInputs = {
                ...validationInputs,
                dimension_interval_fc: smbcInstance ? "" : data?.dimension_interval_fc ? '' : "Please Enter Interval FC",
                sb_interval: smbcInstance ? "" : data?.sb_interval ? '' : "Please Enter SB  Interval",
                dimension_interval_days: smbcInstance ? "" : smbcInstance ? "" : data?.dimension_interval_days ? '' : "Please Enter Interval Days",

            }
        }
        if (data.sb_compliance_status == "COMPLIED") {
            validationInputs = {
                ...validationInputs,
                sb_compliance_tsn: smbcInstance ? "" : data.sb_compliance_tsn ? '' : "Please Enter TSN",
                sb_compliance_csn: smbcInstance ? "" : data.sb_compliance_csn ? '' : "Please Enter CSN",
                sb_compliance_date: smbcInstance ? "" : data.sb_compliance_date ? '' : "Please Select Date",
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/sb/${payload.id}/update/`, payload)
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response?.data?.data)

                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                globalPostService(`camo/sb/create/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            addUploadFile(response?.data?.data)
                            importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })

            }
        } else {
            setError(validationInputs)
        }
    }

    const remainingVal = () => {
        var date = new Date()
        const lDate = moment(data.last_done_date)
        const todayD = moment(date)
        let remDay = lDate.diff(todayD, "days")
        if (data.sb_interval_type === "D") {
            data.remaining_val = moment(remDay).add(data.dimension_interval_days) || ''
        } else if (data.sb_interval_type === "FC") {
            data.remaining_val = Number.isInteger(data.sb_due - basicdetail.csn) && (data.sb_due - basicdetail.csn).toFixed(2) || ''
        } else if (data.sb_interval_type === "FH") {
            data.remaining_val = Number.isInteger(data.sb_due - basicdetail.tsn) && (data.sb_due - basicdetail.tsn).toFixed(2) || ''
        } else if (data.sb_interval_type === "FC,D") {
            const fcRem_val = Number.isInteger(data.sb_due - basicdetail.csn) && (data.sb_due - basicdetail.csn).toFixed(2) || ''
            const dRem_val = moment(remDay).add(data.dimension_interval_days)
            data.remaining_val = fcRem_val < moment(dRem_val, "DD-MM-YYYY") ? fcRem_val : dRem_val;
        } else if (data.sb_interval_type === "FH,D") {
            const fhRem_val = Number.isInteger(data.sb_due - basicdetail.tsn) && (data.sb_due - basicdetail.tsn).toFixed(2) || ''
            const dRem_val = moment(remDay).add(data.dimension_interval_days)
            data.remaining_val = fhRem_val < moment(dRem_val, "DD-MM-YYYY") ? fhRem_val : dRem_val;
        } else if (data.sb_interval_type === "FC,FH,D") {
            const fcRem_val = Number.isInteger(data.sb_due - basicdetail.csn) && (data.sb_due - basicdetail.csn).toFixed(2) || ''
            const fhRem_val = Number.isInteger(data.sb_due - basicdetail.tsn) && (data.sb_due - basicdetail.tsn).toFixed(2) || ''
            const dRem_val = moment(remDay).add(data.dimension_interval_days)

            if (fhRem_val <= fcRem_val && fhRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
                data.remaining_val = fhRem_val;
            } else if (fcRem_val <= fhRem_val && fcRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
                data.remaining_val = fcRem_val;
            } else {
                data.remaining_val = dRem_val;
            }
        } else if (data.sb_interval_type === "FC,FH") {
            const fcRem_val = Number.isInteger(data.sb_due - basicdetail.csn) && (data.sb_due - basicdetail.csn).toFixed(2) || ''
            const fhRem_val = Number.isInteger(data.sb_due - basicdetail.tsn) && (data.sb_due - basicdetail.tsn).toFixed(2) || ''
            data.remaining_val = fcRem_val < fhRem_val ? fcRem_val : fhRem_val;
        }
        return data.remaining_val || ''

    }

    const nextDueValue = () => {
        if (data.sb_interval_type === "D") {
            data.sb_due = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY") || ''
        } else if (data.sb_interval_type === "FC") {
            data.sb_due = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2) || ''
        } else if (data.sb_interval_type === "FH") {
            data.sb_due = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2) || ''
        } else if (data.sb_interval_type === "FC,D") {
            const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2) || ''
            const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY") || ''
            data.sb_due = fcDue < moment(dateDue, "DD-MM-YYYY") ? fcDue : dateDue || ''
        } else if (data.sb_interval_type === "FH,D") {
            const fhDue = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2) || ''
            const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY") || ''
            data.sb_due = fhDue < moment(dateDue, "DD-MM-YYYY") ? fhDue : dateDue;
        } else if (data.sb_interval_type === "FC,FH,D") {
            const fhDue = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2) || ''
            const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2) || ''
            const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY") || ''

            if (fhDue <= fcDue && fhDue <= moment(dateDue, "DD-MM-YYYY")) {
                data.sb_due = fhDue || ''
            } else if (fcDue <= fhDue && fcDue <= moment(dateDue, "DD-MM-YYYY")) {
                data.sb_due = fcDue;
            } else {
                data.sb_due = dateDue;
            }
        } else if (data.sb_interval_type === "FC,FH") {
            const fcDue = Number.isInteger(+data.last_done_fh + +data.sb_interval) && (+data.last_done_fh + +data.sb_interval).toFixed(2) || ''
            const fhDue = Number.isInteger(+data.last_done_fc + +data.sb_interval) && (+data.last_done_fc + +data.sb_interval).toFixed(2) || ''
            data.sb_due = fcDue < fhDue ? fcDue : fhDue;
        }
        return data.sb_due
    }

    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Update Service Bulletin" : " Add  Service Bulletin"} </DialogTitle>
                <DialogContent dividers>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                required={!smbcInstance}
                                variant="outlined"
                                label="ATA Chapter"
                                id="ata_chapter"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.ata_chapter ? data.ata_chapter : ''}
                                error={error.ata_chapter ? true : false}
                                helperText={error.ata_chapter ? error.ata_chapter : ""}
                                onFocus={() => setError({ ...error, 'ata_chapter': '' })}
                                onChange={(e) => { e.target.value.length <= 20 && (regexConstants.numericWithHyphen.test(e.target.value) || e.target.value === "") && onFieldChange('ata_chapter', e.target.value) }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                required
                                label="SB Reference Number"
                                id="sb_ref_number"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.sb_ref_number}
                                error={error.sb_ref_number ? true : false}
                                helperText={error.sb_ref_number ? error.sb_ref_number : ""}
                                onFocus={() => setError({ ...error, 'sb_ref_number': '' })}
                                onChange={(e) => onFieldChange("sb_ref_number", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="SB Issued by"
                                id="sb_issued_by"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.sb_issued_by}
                                onChange={(e) => onFieldChange("sb_issued_by", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label='SB Applicability'
                                id="sb_applicability"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.sb_applicability}
                                error={error.sb_applicability ? true : false}
                                helperText={error.sb_applicability ? error.sb_applicability : ""}
                                onChange={(e) => onFieldChange("sb_applicability", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12}>
                            <TextField
                                required={!smbcInstance}
                                variant="outlined"
                                label="SB Description"
                                id="description"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.description}
                                onFocus={() => setError({ ...error, 'description': '' })}
                                onChange={(e) => onFieldChange("description", e.target.value)}
                                error={error.description ? true : false}
                                helperText={error.description ? error.description : ""}
                            />
                        </Grid>
                    </Grid>

                    <div className="border-grey"></div>

                    <Grid spacing={1} container>
                        <Grid item xs={12} md={4} sm={4}>
                            <Autocomplete
                                id="type"
                                options={['ALERT', 'STANDARD', 'OPTIONAL']}
                                getOptionLabel={option => option}
                                margin='normal'
                                value={data?.type || null}
                                onChange={(e, value) => onFieldChange("type", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label=" SB Type"
                                        placeholder="Select SB Type"
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <TextField
                                variant="outlined"
                                label="SB Effectivity"
                                id="effectivity"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.effectivity}
                                onChange={(e) => onFieldChange("effectivity", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={4} >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    id="effectivity_date"
                                    label={<span>SB Effective Date</span>}
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    disableFuture
                                    margin='normal'
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.effectivity_date}
                                    error={error.effectivity_date ? true : false}
                                    helperText={error.effectivity_date ? error.effectivity_date : ""}
                                    onChange={(data, value) => {
                                        onFieldChange("effectivity_date", moment(data).format(backendDateFormat))
                                        setError({ ...error, 'effectivity_date': '' })
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                    <Grid spacing={1} container>
                        <Grid item xs={12} md={4} sm={4} >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    id="last_done_date"
                                    label="Last Done Date"
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    margin='normal'
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.last_done_date}
                                    error={error.last_done_date ? true : false}
                                    helperText={error.last_done_date ? error.last_done_date : ""}
                                    onChange={(data, value) => onFieldChange("last_done_date", moment(data).format(backendDateFormat))}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <TextField
                                variant="outlined"
                                label="Last Done FH"
                                name="last_done_fh"
                                id="last_done_fh"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.last_done_fh ? data.last_done_fh : ''}
                                onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange('last_done_fh', e.target.value) }}

                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <TextField
                                variant="outlined"
                                label="Last Done FC"
                                name="last_done_fc"
                                id="last_done_fc"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.last_done_fc ? data.last_done_fc : ''}
                                onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange('last_done_fc', e.target.value) }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={6} sm={6} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>INTERVAL</Grid>
                        <Grid item xs={12} md={3} sm={3} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>Remaining</Grid>
                        <Grid item xs={12} md={3} sm={3} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>Next Due</Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="sb_interval_type"
                                options={['None', 'D', 'FH', 'FC', 'FC,D', 'FH,D', 'FC,FH,D', 'FC,FH']}
                                getOptionLabel={option => option}
                                margin='normal'
                                value={data?.sb_interval_type || null}
                                onChange={(e, value) => onFieldChange("sb_interval_type", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Dimension"
                                        placeholder="Select Dimension Interval"
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid id="dimensions-input" item xs={12} md={3} sm={3}>
                            <Grid spacing={1} container>
                                {data.sb_interval_type == "FC" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH" || data.sb_interval_type == "FC,FH,D" ? <Grid
                                    item
                                    xs={data.sb_interval_type == "FC" ? 12 : data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH" ? 6 : data.sb_interval_type == "FC,FH,D" ? 4 : 12}
                                >
                                    <TextField
                                        variant="outlined"
                                        label={data.sb_interval_type == "FC" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH,D" || data.sb_interval_type == "FC,FH" ? "FC Interval" : "FC, Interval"}
                                        name="sb_interval"
                                        id="sb_interval"
                                        margin='normal'
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        value={data.dimension_interval_fc}
                                        onChange={(e) => {
                                            const input = e.target.value
                                            if (
                                                regexConstants.onlyNumeric.test(input) &&
                                                input.length <= 5
                                            ) {
                                                onFieldChange("dimension_interval_fc", input)
                                                setError({ ...error, 'dimension_interval_fc': '' })
                                            } else if (
                                                regexConstants.onlyNumeric.test(input) &&
                                                input.length > 5
                                            ) {
                                                const truncatedInput = input.slice(0, 5)
                                                onFieldChange("dimension_interval_fc", truncatedInput)
                                                setError({ ...error, 'dimension_interval_fc': '' })
                                            } else {
                                                e.preventDefault()
                                            }
                                        }}
                                        error={error.dimension_interval_fc ? true : false}
                                        helperText={error.dimension_interval_fc ? error.dimension_interval_fc : ""}
                                    />
                                </Grid> : null}
                                {data.sb_interval_type == "FH" || data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH" || data.sb_interval_type == "FC,FH,D" ? <Grid
                                    item
                                    xs={data.sb_interval_type == "FH" ? 12 : data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH" ? 6 : data.sb_interval_type == "FC,FH,D" ? 4 : 12}
                                >
                                    <TextField
                                        variant="outlined"
                                        label={data.sb_interval_type == "FH" || data.sb_interval_type == "FH,D" || data.sb_interval_type == "FC,FH,D" || data.sb_interval_type == "FC,FH" ? "FH Interval" : "FH, Interval"}
                                        name="sb_interval"
                                        id="sb_interval"
                                        value={data.sb_interval}
                                        margin='normal'
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) => {
                                            const input = e.target.value
                                            if (
                                                regexConstants.onlyNumeric.test(input) &&
                                                input.length <= 5
                                            ) {
                                                onFieldChange("sb_interval", input)
                                                setError({ ...error, 'sb_interval': '' })
                                            } else if (
                                                regexConstants.onlyNumeric.test(input) &&
                                                input.length > 5
                                            ) {
                                                const truncatedInput = input.slice(0, 5)
                                                onFieldChange("sb_interval", truncatedInput)
                                                setError({ ...error, 'sb_interval': '' })
                                            } else {
                                                e.preventDefault()
                                            }
                                        }}
                                        error={error.sb_interval ? true : false}
                                        helperText={error.sb_interval ? error.sb_interval : ""}
                                    />
                                </Grid> : null}
                                {data.sb_interval_type == "D" || data.sb_interval_type == "FC,D" || data.sb_interval_type == "FC,FH,D" ||
                                    data.sb_interval_type == "FH,D" ? (
                                    <Grid item
                                        xs={data.sb_interval_type == "D" ? 12 : data.sb_interval_type == "FC,D" || data.sb_interval_type == "FH,D" ? 6 : data.sb_interval_type == "FC,FH,D" ? 4 : 12}
                                    >
                                        <TextField
                                            variant="outlined"
                                            required={!smbcInstance}
                                            label="Days"
                                            name="dimension_interval_days"
                                            id="dimension_interval_days"
                                            margin='normal'
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={data.dimension_interval_days}
                                            onChange={(e, value) => {
                                                const input = e.target.value
                                                if (
                                                    input.length <= 5 &&
                                                    regexConstants.onlyNumeric.test(e.target.value)
                                                ) {
                                                    onFieldChange("dimension_interval_days", input)
                                                    setError({ ...error, 'dimension_interval_days': '' })
                                                } else if (
                                                    input.length > 5 &&
                                                    regexConstants.onlyNumeric.test(input)
                                                ) {
                                                    const truncatedInput = input.slice(0, 5)
                                                    onFieldChange("dimension_interval_days", truncatedInput)
                                                    setError({ ...error, 'dimension_interval_days': '' })
                                                } else {
                                                    e.preventDefault()
                                                }
                                            }}
                                            error={error.dimension_interval_days ? true : false}
                                            helperText={
                                                error.dimension_interval_days
                                                    ? error.dimension_interval_days
                                                    : ""
                                            }
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={data.sb_interval_type === "D" ? "Days" : data.sb_interval_type === "FC" ? "FC" : data.sb_interval_type === "FH" ? "FH" :
                                    data.sb_interval_type === "FH,D" ? "FH/Days" : data.sb_interval_type === "FC,D" ? "FC/Days" : data.sb_interval_type === "FC,FH,D" ? "FC/FH/Days" : data.sb_interval_type === "FC,FH" ? "FC/FH" : "Days"}
                                name="remaining_val"
                                id="remaining_val"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={remainingVal()}
                                onChange={(e, value) => onFieldChange("remaining_val", e.target.value)}
                                inputProps={{ readOnly: true }}
                                disabled
                                className="disabled-input"
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>

                            <TextField
                                variant="outlined"
                                label={data.sb_interval_type === "D" ? "Date" : data.sb_interval_type === "FC" ? "FC" : data.sb_interval_type === "FH" ? "FH" : data.sb_interval_type === "FH,D" ? "FH/Date" :
                                    data.sb_interval_type === "FC,D" ? "FC/Date" : data.sb_interval_type === "FC,FH,D" ? "FC/FH/Date" : data.sb_interval_type === "FC,FH" ? "FC/FH " : "Date"}
                                name="ad_due"
                                id="ad_due"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={nextDueValue()}
                                onChange={(e, value) => onFieldChange("ad_due", e.target.value)}
                                inputProps={{ readOnly: true }}
                                disabled
                                className="disabled-input"
                            />
                        </Grid>
                    </Grid>
                    <div className="border-grey"></div>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <Grid container id="ht-dropdown">
                                <Grid item>
                                    <FormControl margin='normal'>
                                        <InputLabel
                                            id="demo-simple-select-label"
                                            style={{ padding: "0px 14px" }}
                                        >
                                            SB Compliance Status{" "}
                                            <span style={{ color: "red" }}> *</span>
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            variant="outlined"
                                            value={data.sb_compliance_status ? data?.sb_compliance_status?.toUpperCase() : ''}
                                            onChange={(e, value) => onFieldChange("sb_compliance_status", e.target.value)}
                                        // onChange={dropDownChange2}
                                        >
                                            <MenuItem value={"OPEN"}>OPEN</MenuItem>
                                            <MenuItem value={"COMPLIED"}>COMPLIED</MenuItem>
                                            <MenuItem value={"FACTORY COMPLIED"}>
                                                FACTORY COMPLIED
                                            </MenuItem>
                                            <MenuItem value={"PARTIALLY COMPLIED"}>
                                                PARTIALLY COMPLIED
                                            </MenuItem>
                                            <MenuItem value={"INFORMATION ONLY"}>
                                                INFORMATION ONLY
                                            </MenuItem>
                                            <MenuItem value={"CLOSED"}>CLOSED</MenuItem>
                                            <MenuItem value={"NOT APPLICABLE"}>
                                                NOT APPLICABLE
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={<span> SB Compliance TSN {smbcInstance ? null : data.sb_compliance_status === "COMPLIED" ? (<span style={{ color: "red" }}> *</span>) : null} </span>}
                                name="sb_compliance_tsn"
                                id="sb_compliance_tsn"
                                value={data.sb_compliance_tsn}
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                error={error.sb_compliance_tsn ? true : false}
                                helperText={error.sb_compliance_tsn ? error.sb_compliance_tsn : ""}
                                disabled={data?.sb_compliance_status === "NOT APPLICABLE"}
                                onChange={(e) => onFieldChange("sb_compliance_tsn", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={<span> SB Compliance CSN {smbcInstance ? null : data.sb_compliance_status === "COMPLIED" ? (<span style={{ color: "red" }}> *</span>) : null} </span>}
                                name="sb_compliance_csn"
                                id="sb_compliance_csn"
                                value={data.sb_compliance_csn}
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                error={error.sb_compliance_csn ? true : false}
                                helperText={error.sb_compliance_csn ? error.sb_compliance_csn : ""}
                                disabled={data?.sb_compliance_status === "NOT APPLICABLE"}
                                onChange={(e) => onFieldChange("sb_compliance_csn", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    id="sb_compliance_date"
                                    label={<span> SB Compliance Date {smbcInstance ? null : data.sb_compliance_status === "COMPLIED" ? (<span style={{ color: "red" }}> *</span>) : null} </span>}
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    disableFuture
                                    margin='normal'
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.sb_compliance_date}
                                    error={error.sb_compliance_date ? true : false}
                                    helperText={error.sb_compliance_date ? error.sb_compliance_date : ""}
                                    onChange={(data, value) => {
                                        onFieldChange("sb_compliance_date", moment(data).isValid() ? moment(data).format(backendDateFormat) : null)
                                        setError({ ...error, 'sb_compliance_date': '' })
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                    <Grid spacing={1} container>
                        {smbcInstance ? <Grid item xs={12} md={4} sm={4}>
                            <TextField
                                variant="outlined"
                                label="Category"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.sb_category}
                                onChange={(e) => onFieldChange("sb_category", e.target.value)}
                            />
                        </Grid> :
                            <Grid item xs={12} md={4} sm={4}>
                                <TextField
                                    variant="outlined"
                                    label="Service Bulletin"
                                    name="service_bulletin"
                                    id="service_bulletin"
                                    value={data.service_bulletin}
                                    margin='normal'
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    onFocus={() => setError({ ...error, 'service_bulletin': '' })}
                                    onChange={(e) => onFieldChange("service_bulletin", e.target.value)}
                                />
                            </Grid>
                        }
                        <Grid item xs={12} md={4} sm={4}>
                            <TextField
                                variant="outlined"
                                label="Revision"
                                id="revision"
                                required={!smbcInstance}
                                value={data.revision}
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                error={error.revision ? true : false}
                                helperText={error.revision ? error.revision : ""}
                                onFocus={() => setError({ ...error, 'revision': '' })}
                                onChange={(e) => onFieldChange("revision", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={4} >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    id="revision_date"
                                    required={!smbcInstance}
                                    label="Revision Date"
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    margin='normal'
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    disableFuture
                                    minDate={moment().subtract(25, "years")}
                                    value={data.revision_date}
                                    error={error.revision_date ? true : false}
                                    helperText={error.revision_date ? error.revision_date : ""}
                                    onChange={(data, value) => {
                                        onFieldChange("revision_date", moment(data).format(backendDateFormat))
                                        setError({ ...error, 'revision_date': '' })
                                    }}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                    <Grid spacing={1} container>
                        <Grid item xs={12} md={4} sm={4}>
                            <TextField
                                variant="outlined"
                                label="AD"
                                name="ad_no"
                                id="ad_no"
                                value={data.ad_no}
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                error={error.ad_no ? true : false}
                                helperText={error.ad_no ? error.ad_no : ""}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 20) {
                                        onFieldChange("ad_no", input)
                                        onRestErrorKey("ad_no")
                                    } else {
                                        const truncatedInput = input.slice(0, 20)
                                        onFieldChange("ad_no", truncatedInput)
                                        onRestErrorKey("ad_no")
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <TextField
                                variant="outlined"
                                label="Subject"
                                name="subject"
                                id="subject"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.subject}
                                error={error.subject ? true : false}
                                helperText={error.subject ? error.subject : ""}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 30) {
                                        onFieldChange("subject", input)
                                        onRestErrorKey("subject")
                                    } else {
                                        const truncatedInput = input.slice(0, 30)
                                        onFieldChange("subject", truncatedInput)
                                        onRestErrorKey("subject")
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <TextField
                                variant="outlined"
                                label={<span>Work Order</span>}
                                name="workorder"
                                id="workorder"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.workorder}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 25) {
                                        onFieldChange("workorder", input)
                                        onRestErrorKey("workorder")
                                    } else {
                                        const truncatedInput = input.slice(0, 25)
                                        onFieldChange("workorder", truncatedInput)
                                        onRestErrorKey("workorder")
                                    }
                                }}
                                error={error.workorder ? true : false}
                                helperText={error.workorder ? error.workorder : ""}
                            />
                        </Grid>
                    </Grid>
                    <div className="border-grey"></div>
                    <Grid spacing={3} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <label>SB Compliance DFP</label>
                            <FormControl
                                error={error.sb_compliance_dfp ? true : false}
                                helperText={
                                    error.sb_compliance_dfp ? error.sb_compliance_dfp : ""
                                }
                            >
                                <RadioGroup
                                    required
                                    row
                                    aria-label="sb_compliance_dfp"
                                    name="sb_compliance_dfp"
                                    defaultValue="top"
                                    value={data.sb_compliance_dfp}
                                    onChange={(e, value) => onFieldChange("sb_compliance_dfp", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                                {error.sb_compliance_dfp && (
                                    <FormLabel component="sb_compliance_dfp">
                                        <span style={{ color: "red" }}>
                                            {error.sb_compliance_dfp}
                                        </span>
                                    </FormLabel>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <label>Release Certificate</label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="release_certificate"
                                    name="release_certificate"
                                    defaultValue="top"
                                    value={data.release_certificate}
                                    onChange={(e, value) => onFieldChange("release_certificate", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3} sm={3}>
                            <label>Certificate of Conformity</label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="certificate_of_conformity"
                                    name="certificate_of_conformity"
                                    defaultValue="top"
                                    value={data.certificate_of_conformity}
                                    onChange={(e, value) => onFieldChange("certificate_of_conformity", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                label="Remarks"
                                margin='normal'
                                variant='outlined'
                                fullWidth
                                value={data.remark}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("remark", e.target.value)}
                                rows={3}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
                                <span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
                                <span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
                            </div>
                        </Grid>
                        <div className="checklist-file" style={{ overflow: "auto" }}>
                            {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
                            <ul className='list-inline'>
                                {(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
                                    return (
                                        <li className='list-inline-item'>
                                            <span className='file-name'>{attachments.name}</span>
                                            <span className='file-remove'>
                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
                                                <CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                            </span>
                                        </li>)
                                }

                                )}
                                {(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
                                    data.checklist?.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        {data?.checklist.some(item => item.type === 'folder') ? null
                                                            : <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.files?.length > 0) &&
                                    data.checklist?.files.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.folders?.length > 0) &&
                                    data.checklist?.folders.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                    <a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                </Tooltip>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {uploadFile.modal ?
                <UploadFile
                    modType="sb"
                    type="upload"
                    list={list}
                    actionType={modeType}
                    editFormId={data?.id}
                    handleClose={() => setUploadFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

                />
                : null}
            {linkFile.modal ?
                <LinkFiles
                    modType="sb"
                    type="link"
                    list={list}
                    editFormId={data?.id}
                    actionType={modeType}
                    uploadFileInfo={data}
                    toggleModalFn={() => setlinkFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    onLinkFile={getData}
                />
                : null
            }
            {isLoading ? <PageLoader /> : null}
        </>
    )

}

export default withRouter(AddEditSb)