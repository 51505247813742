import React, { Fragment } from 'react';
import { withRouter, generatePath } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getLocalStorageInfo } from '../../utils';
import { assetTypeId, assetType } from '../../constants';
const AssetSwitcher = ({ match, history, assets, onAssetChange }) => {
	// function onFieldChange(asset) {
	//   history.push({
	//     pathname: generatePath(match.path, {assetType: assetType[asset.asset_type], assetSlug: asset.slug})
	//   });
	// }\console.log(asset);
	return (
		<div style={{ width: '200px' }}>
			<Autocomplete
				options={assets}
				getOptionLabel={option => option.unique_name}
				value={assets.find(asset => asset.asset_type === assetTypeId[match.params.assetType] && asset.slug === match.params.assetSlug)}
				id="asset"
				disableClearable={true}
				filterSelectedOptions={true}
				renderOption={(option) => (
					<Fragment>
						{option.unique_name}
					</Fragment>
				)}
				onChange={(e, asset) => onAssetChange(asset)}
				renderInput={params => <TextField {...params} placeholder="Select Asset" margin="none" fullWidth InputLabelProps={{ shrink: true }} />}
			/>
		</div>
	)
}
export default withRouter(AssetSwitcher);
