import Dashboard from '../dashboard/containers';
import pageLayoutHoc from '../../hocs/pageLayoutHoc';
import userRoleBaseAccessHoc from '../../hocs/userRoleBaseAccessHoc';
import dashboardWrapper from './containers'
import { SmbcDashboardList } from './components';
export const dashboardRoutes = [
	{
		path: '/reports/portfolio',
		component: pageLayoutHoc(userRoleBaseAccessHoc(SmbcDashboardList, []), { apps: 'Reports', layoutPhase: 1 }),
		key: 'Dashboard'
	},
	{
		path: '/dashboard',
		component: pageLayoutHoc(userRoleBaseAccessHoc(dashboardWrapper(Dashboard), []), { apps: 'Dashboard', layoutPhase: 1 }),
		key: 'Dashboard'
	}
]
