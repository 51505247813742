import React, { useState } from 'react';
import { Dialog, } from '@material-ui/core';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import ApplicationsAccess from '../ApplicationsAccess';

const CamoMgmtDropdown = ({ props, title }) => {
	const [openModal, setOpenModal] = useState(false)

	const handleClickOpen = () => {
		setOpenModal(true)
	}
	const handleClose = () => {
		setOpenModal(false);
	};
	return (
		<div>
			<h5 onClick={handleClickOpen} className="camo-heading">{title} <KeyboardArrowDownIcon style={{ verticalAlign: 'bottom' }} /></h5>
			<Dialog
				open={openModal}
				className='application-swicther-modal'
				fullScreen
			>
				<ApplicationsAccess
					handleClose={() => handleClose()}
				/>
			</Dialog>
		</div>
	)
}
export default CamoMgmtDropdown
