import { regexConstants } from "../../../../constants/regEx"

export const formValues = {
	asset_id: null,
	asset_type_id: null,
	ata_chapter: null,
	part_description: "",
	part_number: null,
	part_serial_number: null,
	part_installation_date: null,
	crs_date: null,
	aircraft_tsn_installation: null,
	aircraft_csn_installation: null,
	part_tsn_installation:null,
	part_csn_installation:null,
	ht_requirement: null,
	dimension_type: "D",
	dimension_interval: null,
	dimension_interval_days: null,
	dimension_interval_fc: null,
	remark: "",
	task_card: null,
	component: null,
	position: null,
	tso: null,
	cso: null,
	faa_easa: null,
	shop_report: "",
	conformity_crt: "",
	goods_rec_note: "",
	installation_wo: null,
	attachments: [],
	checklist: [],
	folder_uuid: ''
}

export const taskErrorCode = {
	ata_chapter: {
		0: "",
		1: "Required",
	},
	ata_chapter_obj: {
		required: true,
		// regexPattern: regexConstants.onlyNumeric,
	},
	part_number: {
		0: "",
		1: "Required",
	},
	part_number_obj: {
		required: true,
		//regexPattern: regexConstants.onlyNumeric,
	},
	dimension_interval_days: {
		0: "",
		1: "Required",
	},
	dimension_interval_days_obj: {
		required: true,
	},
	aircraft_tsn_installation: {
		0: "",
		1: "Required",
	},
	aircraft_tsn_installation_obj: {
		required: true,
	},
	threshold_D: {
		0: "",
		4: "Invalid Format",
	},
	threshold_D_obj: {
		required: true,
	},
	threshold_FH: {
		0: "",
		1: "Invalid Format",
	},
	threshold_FH_obj: {
		required: true,
	},
	threshold_FC: {
		0: "",
		1: "Invalid Format",
	},
	threshold_FC_obj: {
		required: true,
	},
	part_installation_date: {
		0: "",
		1: "Required",
		5: "Please enter valid date",
	},
	part_installation_date_obj: {
		required: true,
		datePattern: true,
	},
	crs_date: {
		0: "",
		1: "Required",
		5: "Please enter crs date",
	},
	crs_date_obj: {
		required: true,
		datePattern: true,
	},
	part_serial_number: {
		0: "",
		1: "Required",
	},
	part_serial_number_obj: {
		required: true,
	},

	ht_requirement: {
		0: "",
		1: "Required",
	},
	ht_requirement_obj: {
		required: true,
	},

	task_card: {
		0: "",
		1: "Required",
	},
	task_card_obj: {
		required: true,
	},

	component: {
		0: "",
		1: "Required",
	},
	component_obj: {
		required: true,
		//regexPattern: regexConstants.onlyNumeric,
	},

	aircraft_csn_installation: {
		0: "",
		1: "Required",
	},
	aircraft_csn_installation_obj: {
		required: true,
	},
	position: {
		0: "",
		1: "Required",
	},
	position_obj: {
		required: true,
		//regexPattern: regexConstants.onlyNumeric,
	},
	faa_easa: {
		0: "",
		1: "Required",
	},
	faa_easa_obj: {
		required: true,
		radioGroup: true,
	},
	installation_wo: {
		0: "",
		1: "Required",
	},
	installation_wo_obj: {
		required: true,
		radioGroup: true,
	},
	part_description: {
		0: "",
		1: "Required",
	},
	part_description_obj: {
		required: true,
	},
	tso: {
		0: "",
		1: "Required",
	},
	tso_obj: {
		required: true,
	},
	cso: {
		0: "",
		1: "Required",
	},
	cso_obj: {
		required: true,
	},
	dimension_interval: {
		0: "",
		1: "Required",
	},
	dimension_interval_obj: {
		required: true,
	},
	dimension_interval_fc: {
		0: "",
		1: "Required",
	},
	dimension_interval_fc_obj: {
		required: true,
	},
}

