import AddEditHt from "./AddEditHt";
import HtListing from "./HtListing";
import HtHeader from "./HtHeader";

export {
    AddEditHt,
    HtListing,
    HtHeader
}
export const htObj = {
    asset_id: null,
    asset_type_id: null,
    ata_chapter: null,
    part_description: "",
    part_number: null,
    part_serial_number: null,
    part_installation_date: null,
    crs_date: null,
    aircraft_tsn_installation: "",
    aircraft_csn_installation: "",
    part_tsn_installation: "",
    part_csn_installation: "",
    ht_requirement: null,
    dimension_type: "D",
    dimension_interval: null,
    dimension_interval_days: null,
    dimension_interval_fc: null,
    remark: "",
    task_card: null,
    component: null,
    position: null,
    tso: null,
    cso: null,
    faa_easa: null,
    shop_report: "",
    conformity_crt: "",
    goods_rec_note: "",
    installation_wo: null,
    attachments: [],
    checklist: [],
    folder_uuid: '',
    linkFile:''
}