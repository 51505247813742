import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
const ModsHeader = ({ sortInfo, createSortHandler }) => {
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }
    return (
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
                <TableCell colSpan="12" align="center"></TableCell>
                <TableCell colSpan="3" align="center">MOD Compliance details</TableCell>
                <TableCell colSpan='2' align="center"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Action </TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('ata_chapter', 'ATA Chapter')} </TableCell>
                <TableCell style={{ minWidth: "180px" }}>{tableSorting('mod_ref_number', 'MOD Refrence Number')}</TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('mod_issued_by', 'MOD Issued By')}</TableCell>
                <TableCell style={{ minWidth: "180px" }}>{tableSorting('mod_applicability', 'MOD Applicability ')}</TableCell>
                <TableCell style={{ minWidth: "250px" }}>{tableSorting('description', 'Description')}</TableCell>
                <TableCell style={{ minWidth: "200px" }}>{tableSorting('type', 'Type')}</TableCell>
                <TableCell style={{ minWidth: "200px" }}>{tableSorting('effectivity', 'Effectivity')}</TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('effectivity_date', 'Effectivity Date')}</TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('mod_interval_type', 'MOD Compliance')}</TableCell>
                <TableCell style={{ minWidth: "180px" }}>{tableSorting('mod_interval', 'MOD Compliance Period ')}</TableCell>
                <TableCell style={{ minWidth: "180px" }}>{tableSorting('mod_compliance_status', 'MOD Compliance Status')}</TableCell>
                <TableCell style={{ minWidth: "10px" }}>{tableSorting('mod_compliance_tsn', 'TSN')}</TableCell>
                <TableCell style={{ minWidth: "10px" }}>{tableSorting('mod_compliance_csn', 'CSN')}</TableCell>
                <TableCell style={{ minWidth: "10px" }}>{tableSorting('mod_compliance_date', 'Date')}</TableCell>
                <TableCell style={{ minWidth: "100px" }}>MOD Next Due</TableCell>
                <TableCell style={{ minWidth: "250px" }}>{tableSorting('remark', 'Remarks')}</TableCell>
                <TableCell>Attachments </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default ModsHeader