import AddEditMasterComp from "./AddEditMasterComp";
import MasterCompHeader from "./MasterCompHeader";
import MasterCompListing from "./MasterCompListing";
export {
    AddEditMasterComp,
    MasterCompHeader,
    MasterCompListing
}

export const mcObj = {
    asset_id: '',
    asset_type_id: '',
    part_number: '',
    serial_number: '',
    description: '',
    life_limited_part: '',
    tsn: '',
    csn: '',
    remarks: '',
    checklist: [],
    attachments:[],
    folder_uuid: '',
    linkFile:''
};