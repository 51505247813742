import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableHead, TableCell, TableBody, TableContainer, TableRow, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip, CircularProgress, InputLabel, Select, MenuItem } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import { DropzoneArea } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { getLocalStorageInfo } from "../../../../utils";
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus } from '../../../../utils';
import { assetType, imgStoragePath } from "../../../../constants";
import SectionCreates from "../../../../shared_elements/components/SectionCreates"
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { llpObj } from '.';
import { smbcInstance, specificKey, specificMessTsn, specificMessCsn } from '../..';


function AddEditLLP({ match, toggleModalFn, llpData, basicdetail, modeType, getResponseBack, list }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(llpData ? llpData : llpObj)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
    const [linkFile, setlinkFile] = useState({ modal: false, data: null })

    const onFieldChange = (keyParam, value, index) => {
        setData(prevData => {
            const newData = { ...prevData };
            if (keyParam === "cootr_thrust") {
                const thrustKey = index >= 0 ? `COOTR_thrust_${String.fromCharCode(97 + index)}` : keyParam;
                newData[thrustKey] =parseInt(value);
            } else if (keyParam === "life_limit") {
                const thrustKey = index >= 0 ? `life_limit_thrust_${String.fromCharCode(97 + index)}` : keyParam;
                newData[thrustKey] =parseInt(value);;
            } else {
                newData[keyParam] = value;;
            }
            return newData;
        });
    }

    const uploadFileData = (file) => {
        setData({ ...data, checklist: file })
    }

    const getData = (selectedFile, type) => {
        setData({ ...data, checklist: selectedFile, linkFile: type });
    }

    const uploadFileFn = (file, uuid, type) => {
        if (uuid) {
            let storeChecklistData = [];
            let folderUuid = [];

            const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
            const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

            for (const arr of updatedChecklist) {
                storeChecklistData = storeChecklistData.concat(arr);
            }
            for (const arr of folderUUID) {
                folderUuid = folderUuid.concat(arr);
            }
            setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
        } else {
            if (Array.isArray(file)) {
                const updatedAttachments = [...(data?.attachments || []), ...file];
                setData({ ...data, 'attachments': updatedAttachments });
            } else {
                console.error("Value for attachments is not an array:", file);
            }
        }
    }
    const addUploadFile = (item) => {
        if (data?.linkFile === "link") {
            let payload = {};
            if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            } else if (data?.checklist.some(item => item.type === 'folder')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
            } else {
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            }
            globalPutService(`camo/llp/${item?.id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        toggleModalFn()
                        getResponseBack(response.data.data);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0) {
            if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
                let formData = new FormData();
                formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
                for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
                    formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
                }
                globalPutService(`camo/llp/${item?.id}/document-checklists/`, formData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    });
            }
        }

    }
    const importFile = (file) => {
        var formData = new FormData();
        Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
            formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
            formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
            formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
            formData.append("[" + key + "]module_id", file?.id)
            formData.append("[" + key + "]module_type_id", 8)
            formData.append("[" + key + "]asset_type_id", match.params.assetType)
            formData.append("[" + key + "]asset_id", match.params.asset)
        })
        globalFileUploadService(`camo/attachment/upload/`, formData)
            .then(response => {
                toggleModalFn(); getResponseBack()
                if (checkApiStatus(response)) {
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })

    }
    const onRemoveFile = (file, type, fileId,folder) => {
        if (modeType === "add") {
            if (type === "checklist") {
                setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
            } else {
                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
            }
        } else {
            if (type === "checklist") {
                let payload = {};
                payload.delete = true;
				payload.folder_ids =data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
			    payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
                globalPutService(`camo/llp/${data.id}/document-checklists/`, payload)
                    .then((response) => {
                        if (checkApiStatus(response)) {
                            const updatedChecklist = { ...data.checklist };
                            updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                            updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
                            setData({ ...data, checklist: updatedChecklist });
                            getResponseBack()
                        }
                    });
            } else {
                if (file.id) {
                    globalDeleteService(`camo/attachment/${file.id}/delete/`)
                        .then((response) => {
                            if (checkApiStatus(response)) {
                                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                                getResponseBack()
                            }
                        });
                } else {
                    setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                }
            }
        }
    }

    const onAddNewAsset = () => {
        let validationInputs = {}
        if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')) {
            validationInputs = {
                ...validationInputs,
                module_type: data.module_type ? '' : 'Please Select Module Type',
                description: data.description ? '' : 'Please Enter Description',
                part_number: data.part_number ? '' : 'Please Enter Part Number',
                serial_number: data.serial_number ? '' : 'Please Enter Serial Number',
                tsn: data.tsn ? '' : 'Please Enter TSN',
                life_limit_thrust_a: data.life_limit_thrust_a ? '' : 'Please Enter Thrust A',
                life_limit_thrust_b: data.life_limit_thrust_b ? '' : 'Please Enter Thrust B',
                life_limit_thrust_c: data.life_limit_thrust_c ? '' : 'Please Enter Thrust C',
                life_limit_thrust_d: data.life_limit_thrust_d ? '' : 'Please Enter Thrust D',
                life_limit_thrust_e: data.life_limit_thrust_e ? '' : 'Please Enter Thrust E',
            }
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/llp/${payload.id}/`, {...payload,csn:parseInt(payload.COOTR_thrust_a) + parseInt(payload.COOTR_thrust_b) + parseInt(payload.COOTR_thrust_c) + parseInt(payload.COOTR_thrust_d) + parseInt(payload.COOTR_thrust_e)})
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response.data.data)

                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                globalPostService(`camo/llp/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType,csn:parseInt(payload.COOTR_thrust_a) + parseInt(payload.COOTR_thrust_b) + parseInt(payload.COOTR_thrust_c) + parseInt(payload.COOTR_thrust_d) + parseInt(payload.COOTR_thrust_e) })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            addUploadFile(response?.data?.data)
                            importFile(response.data.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })

            }
        } else {
            setError(validationInputs)
        }
    }
    const lifeLimithelperText = (index) => {
        const thrustKey = `life_limit_thrust_${String.fromCharCode(97 + index)}`;
        return error[thrustKey] || "";
    }
    const lifeLimitError = (index) => {
        const thrustKey = `life_limit_thrust_${String.fromCharCode(97 + index)}`;
        return !!error[thrustKey];
    }
    const operatedThrustValue = (index) => {
        const thrustKey = `COOTR_thrust_${String.fromCharCode(97 + index)}`;
        return data[thrustKey] || '';
    }
    const lifeLimitValue = (index) => {
        const thrustKey = `life_limit_thrust_${String.fromCharCode(97 + index)}`;
        return data[thrustKey] || '';
    }
    const remainingCycle = (index) => {
        if (index === 0) {
            return data.remaining_cycle_thrust?.remaining_cycle_thrust_a ? data.remaining_cycle_thrust?.remaining_cycle_thrust_a : data.remaining_cycle_thrust_a = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
                Math.ceil((1 - (
                    (parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
                    (parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
                    (parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
                    (parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
                    (parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
                )) * parseInt(data.life_limit_thrust_a)) || "" : parseInt(data.life_limit_thrust_a) || '';

        } else if (index === 1) {
            return data.remaining_cycle_thrust?.remaining_cycle_thrust_b ? data.remaining_cycle_thrust?.remaining_cycle_thrust_b : data.remaining_cycle_thrust_b = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
                Math.ceil((1 - (
                    (parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
                    (parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
                    (parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
                    (parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
                    (parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
                )) * parseInt(data.life_limit_thrust_b)) || "" : parseInt(data.life_limit_thrust_b) || '';

        } else if (index === 2) {
            return data.remaining_cycle_thrust?.remaining_cycle_thrust_c ? data.remaining_cycle_thrust?.remaining_cycle_thrust_c : data.remaining_cycle_thrust_c = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
                Math.ceil((1 - (
                    (parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
                    (parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
                    (parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
                    (parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
                    (parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
                )) * parseInt(data.life_limit_thrust_c)) || "" : parseInt(data.life_limit_thrust_c) || '';
        } else if (index === 3) {
            return data.remaining_cycle_thrust?.remaining_cycle_thrust_d ? data.remaining_cycle_thrust?.remaining_cycle_thrust_d : data.remaining_cycle_thrust_d = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
                Math.ceil((1 - (
                    (parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
                    (parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
                    (parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
                    (parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
                    (parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
                )) * parseInt(data.life_limit_thrust_d)) || "" : parseInt(data.life_limit_thrust_d) || '';
        } else if (index === 4) {
            return data.remaining_cycle_thrust?.remaining_cycle_thrust_e ? data.remaining_cycle_thrust?.remaining_cycle_thrust_e : data.remaining_cycle_thrust_e = (data.COOTR_thrust_a && data.COOTR_thrust_b && data.COOTR_thrust_c && data.COOTR_thrust_d && data.COOTR_thrust_e) ?
                Math.ceil((1 - (
                    (parseInt(data.COOTR_thrust_a) / parseInt(data.life_limit_thrust_a)) +
                    (parseInt(data.COOTR_thrust_b) / parseInt(data.life_limit_thrust_b)) +
                    (parseInt(data.COOTR_thrust_c) / parseInt(data.life_limit_thrust_c)) +
                    (parseInt(data.COOTR_thrust_d) / parseInt(data.life_limit_thrust_d)) +
                    (parseInt(data.COOTR_thrust_e) / parseInt(data.life_limit_thrust_e))
                )) * parseInt(data.life_limit_thrust_e)) || "" : parseInt(data.life_limit_thrust_e) || '';
        }

    }

    const thrust = ["Thrust A", "Thrust B", "Thrust C", "Thrust D", "Thrust E"]
    const modulType = ["FAN","LPC", "HPC", "HPT", "LPT", "Static", "Blades"]
    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Update LLP" : " Add LLP"} </DialogTitle>
                <DialogContent dividers>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                required
                                variant="outlined"
                                label="Part Number"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                id="part_number"
                                value={data.part_number ? data.part_number : ''}
                                error={error.part_number ? true : false}
                                helperText={error.part_number ? error.part_number : ""}
                                onChange={(e) => { e.target.value.length <= 20 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && onFieldChange('part_number', e.target.value);  setError({ ...error, 'part_number': '' }) }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                required
                                variant="outlined"
                                label="Serial Number"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                id="serial_number"
                                value={data.serial_number}
                                error={error.serial_number ? true : false}
                                helperText={error.serial_number ? error.serial_number : ""}
                                onChange={(e) => { e.target.value.length <= 20 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && onFieldChange('serial_number', e.target.value);  setError({ ...error, 'serial_number': '' }) }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                required
                                variant="outlined"
                                label="TSN"
                                id="tsn"
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                error={error.tsn ? true : false}
                                helperText={error.tsn ? error.tsn : ""}
                                value={data.tsn}
                                onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange('tsn', parseInt(e.target.value));  setError({ ...error, 'tsn': '' }) }}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="CSN"
                                disabled
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={parseInt(data.COOTR_thrust_a) + parseInt(data.COOTR_thrust_b) + parseInt(data.COOTR_thrust_c) + parseInt(data.COOTR_thrust_d) + parseInt(data.COOTR_thrust_e) || data.csn || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                options={modulType || []}
                                getOptionLabel={(option) => option}
                                id="module_type"
                                value={data?.module_type ? data?.module_type : null}
                                onChange={(e, value) => { onFieldChange("module_type", value); setError({ ...error, 'module_type': '' })}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        label="Module"
                                        margin="normal"
                                        fullWidth
                                        error={error.module_type ? true : false}
                                        helperText={error.module_type ? error.module_type : ""}
                                        InputLabelProps={{ shrink: true }}
                                        placeholder="Select Module"
                                        variant="outlined"

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={9} sm={9}>
                            <TextField
                                required
                                multiline
                                minRows={5}
                                variant="outlined"
                                label="Description"
                                margin="normal"
                                fullWidth
                                error={error.description ? true : false}
                                helperText={error.description ? error.description : ""}
                                InputLabelProps={{ shrink: true }}
                                value={data.description}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 255) {
                                        onFieldChange("description", input)
                                        setError({ ...error, 'description': '' })
                                    } else {
                                        const truncatedInput = input.slice(0, 255)
                                        onFieldChange("description", truncatedInput)
                                        setError({ ...error, 'description': '' })
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <TableContainer>
                            <Table aria-label="simple table">
                                <TableHead style={{ background: '#f0f2f3' }}>
                                    <TableRow>
                                        <TableCell>Thrust</TableCell>
                                        <TableCell align="left">Cycles Operated</TableCell>
                                        <TableCell align="left">Life Limit <sup style={{ color: " #ff0000", fontSize: "14px", position: "relative", top: 0 }}> * </sup></TableCell>
                                        <TableCell align="left">Remaining Cycles</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                { basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details).filter(item => item !== "five_column").map((row, index) => (
                                        <TableRow >
                                            <TableCell> {row} </TableCell>
                                            <TableCell>
                                                <TextField
                                                    variant="outlined"
                                                    name="cootr_thrust"
                                                    margin="normal"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    keyParams="cootr_thrust"
                                                    id="cootr_thrust"
                                                    // onChange={(e) => { e.target.value.length <= 10 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange('cootr_thrust', e.target.value, index); onRestErrorKey("cootr_thrust") }}
                                                    onChange={(e) => onFieldChange('cootr_thrust', e.target.value, index)}
                                                    value={operatedThrustValue(index)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextField
                                                    variant="outlined"
                                                    onChange={(e) => { onFieldChange('life_limit', e.target.value, index);   setError({ ...error, 'life_limit': '' })}}
                                                    error={lifeLimitError(index)}
                                                    margin="normal"
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    helperText={lifeLimithelperText(index)}
                                                    value={lifeLimitValue(index)}
                                                />
                                            </TableCell>
                                            <TableCell> <TextField variant="outlined"
                                                disabled
                                                margin="normal"
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                value={remainingCycle(index)}
                                            />
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                label="Remarks"
                                margin='normal'
                                variant='outlined'
                                fullWidth
                                value={data.remark}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("remark", e.target.value)}
                                rows={3}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
                                <span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
                                <span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
                            </div>
                        </Grid>
                        <div className="checklist-file" style={{ overflow: "auto" }}>
                        {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
                            <ul className='list-inline'>
                                {(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
                                    return (
                                        <li className='list-inline-item'>
                                            <span className='file-name'>{attachments.name}</span>
                                            <span className='file-remove'>
                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
                                                <CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                            </span>
                                        </li>)
                                }

                                )}
                                {(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
                                    data.checklist?.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        {data?.checklist.some(item => item.type === 'folder') ? null
                                                            : <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.files?.length > 0) &&
                                    data.checklist?.files.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.folders?.length > 0) &&
                                    data.checklist?.folders.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                    <a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                </Tooltip>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {uploadFile.modal ?
                <UploadFile
                    modType="llp"
                    type="upload"
                    list={list}
                    actionType={modeType}
                    editFormId={data?.id}
                    handleClose={() => setUploadFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

                />
                : null}
            {linkFile.modal ?
                <LinkFiles
                    modType="llp"
                    type="link"
                    list={list}
                    editFormId={data?.id}
                    actionType={modeType}
                    uploadFileInfo={data}
                    toggleModalFn={() => setlinkFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    onLinkFile={getData}
                />
                : null
            }
            {isLoading ? <PageLoader /> : null}
        </>
    )

}
export default withRouter(AddEditLLP)