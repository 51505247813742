import { React, useState, useEffect } from 'react'
import moment from 'moment';
import { useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import BasicDetail from '../../basicDetail/containers'
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { AddEditAd, AdHeader, AdListing } from '../components';
import { ImportFileFormat, BasicTopHeader } from '../../../../shared_elements/components';
import ExportMenu from '../../../../shared_elements/components/ExportMenu';
import { downloadFileType, checkApiStatus } from '../../../../utils';
import Pagination from '../../../../shared_elements/components/Pagination';
import { EmptyCollection, PageLoader, DeletePopUp } from '../../../../shared_elements';
import { globalGetService, globalDeleteService, globalExportService, globalPutService } from '../../../../utils/globalApiServices';
import { Table, TableBody, Paper, Button, Grid, TextField, InputAdornment, } from "@material-ui/core";
import { DownloadAllApi } from '../../../fleet/containers/apiServices';
import { exportMasterReport } from '../../apiServices';
import ShowAttachments from '../../../../shared_elements/components/ShowAttachments';

function AdComp({ match }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [list, setList] = useState({})
	const [basicdetail, setBasicDetail] = useState()
	const [isLoading, setLoading] = useState(false)
	const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
	const [deletelist, setDeleteList] = useState({ modal: false, data: null })
	const [importFile, setImportFile] = useState({ modal: false, data: null })
	const [addEditAd, setAddEditAd] = useState({ modal: false, data: null, type: '' })
	const [attchementsModal, setAttchementsModal] = useState({ modal: false, data: null, })

	useEffect(() => {
		getAdList({ per_page: 20 })
	}, [])

	const getAdList = (query) => {
		setLoading(true)
		globalGetService(`camo/aird/list/?asset=${match?.params?.asset}&asset_type=${match?.params?.assetType}`, { ...query, search: query?.search })
			.then((response) => {
				setLoading(false)
				if (checkApiStatus(response)) {
					setList(response.data)
				}

			})
	}
	const deleteAdList = () => {
		setLoading(true)
		globalDeleteService(`camo/aird/${deletelist?.data?.id}/delete/`)
			.then((response) => {
				setLoading(false); setDeleteList({ modal: false }); getAdList({ per_page: 20 })
				if (checkApiStatus(response)) {
					enqueueSnackbar("Record Deleted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				}

			})
	}
	const exportAd = (extension) => {
		const date = new Date()
		const todayDate = moment(String(date)).format("DD-MM-YYYY")
		setLoading(true);
		globalExportService(match?.params?.assetType == 2 ? `/camo/aird/engine/${match?.params?.asset}/${match?.params?.assetType}/export/` : `/camo/aird/${match?.params?.asset}/${match?.params?.assetType}/export/`, { download: extension })
		// globalExportService(`/camo/aird/${match?.params?.asset}/${match?.params?.assetType}/export/`, { download: extension })
			.then(response => {
				setLoading(false);
				downloadFileType(response.data, `AD_${todayDate}`, extension)
				enqueueSnackbar("Download AD List Successfully", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
			});
	}
	const deleteAttachements = (item, checklist, fileId, uuid) => {
		const removeID = item.attachments.map((data) => data.id).filter((id) => id).join(',')
		const attchementRemoveID = checklist.map((file) => file.id).filter((id) => !removeID.includes(id))
		if (uuid) {
			let payload = {};
			payload.delete = true;
			payload.folder_ids =checklist.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).toString()
			payload.file_ids = checklist.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).toString()
			globalPutService(`camo/aird/${item?.id}/document-checklists/`, payload)
				.then((response) => {
					if (checkApiStatus(response)) {
						getAdList({ per_page: 20 })
						enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
					} else {
						enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

					}
				})
		} else {
			globalDeleteService(`camo/attachment/${fileId}/delete/`)
				.then((response) => {
					if (checkApiStatus(response)) {
						getAdList({ per_page: 20 })
						enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
					} else {
						enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

					}
				})
		}
	};

	const createSortHandler = (key) => {
		let sortQuery = { sort: key };
		if (sortInfo.sort === key) {
			sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
		} else {
			sortQuery = { ...sortQuery, sort_by: 'asc' }
		}
		setSortInfo(sortQuery)
		getAdList({ ...sortQuery, per_page: 20 });
	}


	
	const getAttachements = () => {
		const matchingItem = list?.results?.find(item => item?.id === attchementsModal?.data?.id);
		if (!matchingItem) return []; 
		const { checklist, attachments } = matchingItem || {};
		if (checklist && attachments || checklist || attachments) {
			const { files, folders } = checklist || {}; // Add null check here
			 if (attachments?.length > 0 && files?.length > 0 && folders?.length > 0) {
				return [...attachments, ...files, ...folders];
			}else if (files?.length > 0 && folders?.length > 0)  {
				return [...folders, ...files];
			}else if (attachments?.length > 0 && files?.length > 0) {
				return [...attachments, ...files];
			}else if (attachments?.length > 0 && folders?.length > 0) {
				return [...attachments, ...folders];
			}else if (attachments?.length > 0) {
				return [...attachments];
			} else if (folders?.length > 0) {
				return [...folders];
			} else if (files?.length > 0) {
				return [...files];
			}
		}
		return []; 
	};
	

	return (
		<section className='amp-module'>
			<BasicTopHeader heading='Airworthiness Directives' slug={basicdetail?.slug} analytic='ad_Module' onMasterExportFn={(query) => exportMasterReport(query, match, enqueueSnackbar)} refreshData={() => getAdList({ per_page: 20 })} />
			<BasicDetail moduleType="ad" BasicDetailData={(data) => setBasicDetail(data)} />
			<Paper square style={{ padding: "10px" }} variant='outlined'>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={5} md={5}>
						<TextField
							variant='outlined'
							fullWidth
							margin='normal'
							size='small'
							placeholder="Search ATA Chapter,AD Reference Number,AD Issuing Authority,Description"
							onChange={(e) => getAdList({ search: e.target.value,per_page:20 })}
							InputProps={{ style: { fontSize: 12 }, endAdornment: <InputAdornment position="end"><SearchIcon color='primary' cursor="pointer" /></InputAdornment> }}
						/>
					</Grid>
					<Grid item xs={12} md={7}>
						<ul className='list-inline' style={{ float: "right", margin: "revert" }}>
							<li className='list-inline-item'>
								<Button fullWidth variant='contained' color='primary' size='small' onClick={() => setAddEditAd({ modal: true, data: null, type: 'add' })}> + Add AD</Button>
							</li>
							<li className='list-inline-item'>
								<Button fullWidth variant='outlined' color='primary' size='small' onClick={() => setImportFile({ modal: true, data: null })}>Import</Button>
							</li>
							<li className='list-inline-item'>
								<ExportMenu
									exportReportFn={(file) => exportAd(file.extension)}
									files={[{ title: 'PDF', extension: 'pdf' }, { title: 'EXCEL', extension: 'xls' }]}
									disabled={!list?.results?.length}
								/>
							</li>
						</ul>
					</Grid>
				</Grid>
				<div style={{ height: `${window.innerHeight - 360}px`, overflow: 'auto' }}>
					<Table className='mui-table-format' stickyHeader={true}>
						<AdHeader
							sortInfo={sortInfo}
							createSortHandler={createSortHandler}
						/>
						<TableBody>
							{list?.results?.map((item, index) =>
								<AdListing
									item={item}
									key={index}
									toggleModalFn={() => setAddEditAd({ modal: true, data: item, type: 'edit' })}
									toggleDelteModalFn={() => setDeleteList({ modal: true, data: item })}
									handleOpenAttachments={() => setAttchementsModal({ modal: true, data: item })}
								/>
							)}
						</TableBody>
					</Table>
					{!list?.results?.length ? <EmptyCollection title="No Records Founds" /> : null}
				</div>
				<Pagination
					pagination={list?.pagination}
					onChangeRowsPerPage={(event) => getAdList({ page: 1, per_page: event.target.value })}
					onChangePage={(event, newPage) => getAdList({ per_page: list.pagination.per_page, page: newPage + 1, })}
				/>
			</Paper>
			{addEditAd.modal ?
				<AddEditAd
					adData={addEditAd?.data}
					basicdetail={basicdetail}
					modeType={addEditAd.type}
					getResponseBack={() => getAdList({ per_page: 20, })}
					toggleModalFn={() => setAddEditAd({ modal: false })}
					list={list}
				/> : null
			}
			{deletelist.modal ?
				<DeletePopUp
					title='Delete AD'
					deleteRecordFn={() => deleteAdList()}
					modal={() => setDeleteList({ modal: true })}
					content='Do you really want to delete this record?'
					toggleModalFn={() => setDeleteList({ modal: false })}
				/> : null

			}
			{importFile.modal ?
				<ImportFileFormat
					title="Import AD"
					moduleType='aird'
					moduleName="Sample File AD"
					getResponseBack={() => getAdList({per_page:20})}
					toggleModalFn={() => setImportFile({ modal: false })}
					assetType={true}
				/> : null
			}
			{attchementsModal.modal ?
				<ShowAttachments
					handleClose={() => setAttchementsModal({ modal: false, data: null })}
					handleShowAttachments={getAttachements()}
					openAttachment={(file)=>window.open(decodeURIComponent(file), "_blank")}
					files={{ title: "AD", extension: "zip", key: "" }}
					downloadAllApi={() => DownloadAllApi(list?.results.filter(item=> attchementsModal.data?.id === item.id)[0], 'AD',)}
					item={attchementsModal.data}
					removeAttachment={deleteAttachements}
				/>
				:
				null
			}
			{isLoading ? <PageLoader /> : null}
		</section>
	)
}

export default withRouter(AdComp)