import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { DashboardChart, SmbcDashboardList } from "../components";
import { getLocalStorageInfo } from "../../../utils";
import config from '../../../config'
import DeploymentMessage from "../../../shared_elements/components/DeploymentMessage";
import DashboardAnalytics from "./DashboardAnalytics";

function DashboardWrapper(HocComponent, extraProps = {}) {
	class EnhancedComponent extends Component {

		render() {
			let smbcInstance = getLocalStorageInfo()?.defaultLessor?.id === (config.env.key === "api" ? 465 : 532)
			return (
				<div className="camo-dashboard">
					<DeploymentMessage />
					{smbcInstance ? <>
						<DashboardAnalytics />
						<HocComponent {...this.props} {...extraProps} />
					</> :
						<>
							<DashboardChart />
							<HocComponent {...this.props} {...extraProps} />
						</>
					}
				</div>
			);
		}
	}

	return withRouter(EnhancedComponent);
}

export default DashboardWrapper;
