import React, { useState } from "react";
import { TableCell, TableRow, Tooltip, IconButton } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { withRouter } from "react-router-dom";
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory";
import moment from 'moment';
import { fieldDateFormat } from '../../../../constants';
import AttachFileIcon from "@material-ui/icons/AttachFile";
const OccmListing = ({ item, toggleModalFn, toggleDelteModalFn, handleOpenAttachments }) => {
	const [lessMore, setLessMore] = useState({});

	const toggleLessMore = (id) => {
		setLessMore({
			...lessMore,
			[id]: !lessMore[id],
		});
	};


	const lessMoreFn = (name) => {
		return <>
			{name && name.length > 22 ? (
				<>
					{lessMore[name] ?
						(<> {name} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..less </span> </>)
						:
						(<>{name.substring(0, 22)} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..more </span> </>)
					}
				</>
			) : name || '--'
			}
		</>
	}
	function getAttachmentCount(item) {
        const { attachments, checklist } = item;
        const { files, folders } = checklist || {};
      
        const attachmentCount = attachments?.length || 0;
        const fileCount = files?.length || 0;
        const folderCount = folders?.length || 0;
      
        if (attachmentCount && fileCount && folderCount) {
          return attachmentCount + fileCount + folderCount;
        } else if (fileCount && folderCount) {
          return fileCount + folderCount;
        } else if (attachmentCount && folderCount) {
          return attachmentCount + folderCount;
        } else if (attachmentCount && fileCount) {
          return attachmentCount + fileCount;
        } else {
          return folderCount || fileCount || attachmentCount || '--';
        }
      }
    const attachmentCount = getAttachmentCount(item);
	let occmUrl = `audit/camo/occmcomponent/?object_id=${item.id}&action=0,1&timezone=${moment().format()}&timestamp=${Math.floor(Date.now() / 1000)}`

	return (
		<TableRow>
			<TableCell>
				<Tooltip title="Edit" placement='top'>
					<IconButton color='primary' style={{ padding: 'unset' }} onClick={toggleModalFn} >
						<CreateOutlinedIcon size='small' />
					</IconButton>
				</Tooltip>
				<Tooltip title="Delete" placement='top' >
					<IconButton color='secondary' style={{ padding: 'unset' }} onClick={toggleDelteModalFn}>
						<DeleteOutlinedIcon />
					</IconButton>
				</Tooltip>
				<RevisionHistory buttonType={true} url={occmUrl} />
			</TableCell>
			<TableCell>{item.ata_chapter ? item.ata_chapter : "--"}</TableCell>
			<TableCell>{item.part_number ? item.part_number : "--"}</TableCell>
			<TableCell> {item.part_serial_number ? item.part_serial_number : "--"} </TableCell>
			<TableCell>{lessMoreFn(item.part_description)} </TableCell>
			<TableCell> {item.part_installation_date ? item.part_installation_date : "--"} </TableCell>
			<TableCell>{item.aircraft_tsn ? item.aircraft_tsn : "--"}</TableCell>
			<TableCell>{item.aircraft_csn ? item.aircraft_csn : "--"}</TableCell>
			<TableCell>{item.part_tsn ? item.part_tsn : "--"}</TableCell>
			<TableCell>{item.part_csn ? item.part_csn : "--"}</TableCell>
			<TableCell> {item.occm_requirement ? item.occm_requirement : "--"} </TableCell>
			<TableCell >{lessMoreFn(item.remark)} </TableCell>
			<TableCell>
                {attachmentCount && (
                    <Tooltip title="View Attachments">
                        <span className="center">
                            <AttachFileIcon className="attach-icon" onClick={handleOpenAttachments} />
                            <a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
                        </span>
                    </Tooltip>
                )}
            </TableCell>
		</TableRow>
	)
}

export default withRouter(OccmListing)