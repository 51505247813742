import { regexConstants } from "../../../../constants/regEx"

export const formValues = {
	asset_id: null,
	asset_type_id: null,
	ata_chapter: null,
	part_description: "",
	part_number: null,
	part_serial_number: null,
	part_installation_date: null,
	aircraft_tsn: '',
	aircraft_csn: '',
	part_tsn: '',
	part_csn: '',
	remark: "",
	component: null,
	position: null,
	occm_requirement: null,
	faa_easa_certificate: null,
	shop_report: null,
	conformity_certificate: null,
	receipt_note: null,
	work_order: null,
	attachments: [],
	checklist: [],
	folder_uuid: ''
}

export const taskErrorCode = {
	ata_chapter: {
		0: "",
		1: "Required",
	},
	ata_chapter_obj: {
		required: true,
	},
	part_number: {
		0: "",
		1: "Required",
	},
	part_number_obj: {
		required: true,
	},
	aircraft_tsn: {
		0: "",
		1: "Required",
	},
	aircraft_tsn_obj: {
		required: true,
	},
	threshold_D: {
		0: "",
		4: "Invalid Format",
	},
	threshold_D_obj: {
		required: true,
		regexPattern: regexConstants.onlyNumeric,
	},
	threshold_FH: {
		0: "",
		1: "Invalid Format",
	},
	threshold_FH_obj: {
		required: true,
	},
	threshold_FC: {
		0: "",
		1: "Invalid Format",
	},
	threshold_FC_obj: {
		required: true,
	},
	part_installation_date: {
		0: "",
		1: "Required",
		5: "Please enter valid date",
	},
	part_installation_date_obj: {
		required: true,
		datePattern: true,
	},
	part_serial_number: {
		0: "",
		1: "Required",
	},
	part_serial_number_obj: {
		required: true,
	},
	aircraft_csn: {
		0: "",
		1: "Required",
	},
	aircraft_csn_obj: {
		required: true,
	},
	position: {
		0: "",
		1: "Required",
	},
	position_obj: {
		required: true,
	},
	faa_easa_certificate: {
		0: "",
		1: "Required",
	},
	faa_easa_certificate_obj: {
		required: true,
		radioGroup: true,
	},
	part_description: {
		0: "",
		1: "Required",
	},
	part_description_obj: {
		required: true,
	},
}
