import React from "react"
import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import { smbcInstance } from "../.."
const AdHeader = ({ sortInfo, createSortHandler }) => {
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }
    return (
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
                <TableCell colSpan={smbcInstance ? 9 : 11} align="center"></TableCell>
                {smbcInstance ? <TableCell colSpan={2} align="center">EFFECTIVE DATE</TableCell> : <TableCell colSpan={3} align="center">AD Compliance</TableCell>}
                <TableCell colSpan={smbcInstance ? 5 : 3} align="center"></TableCell>
            </TableRow>
            {!smbcInstance ? <TableRow>
                <TableCell>Action </TableCell>
                <TableCell style={{ minWidth: '110px' }}>{tableSorting('ata', 'ATA Chapter')} </TableCell>
                <TableCell style={{ minWidth: "200px" }}>{tableSorting('ad_no', 'Reference Number')}</TableCell>
                <TableCell style={{ minWidth: "200px" }}>{tableSorting('issuing_authority', 'Issuing Authority')}</TableCell>
                <TableCell style={{ minWidth: "250px" }}>Corresponding SB</TableCell>
                <TableCell style={{ minWidth: "250px" }}>{tableSorting('description', 'Description')}</TableCell>
                <TableCell style={{ minWidth: "280px" }}>{tableSorting('applicability', 'Applicability')}</TableCell>
                <TableCell style={{ minWidth: "280px" }}>{tableSorting('effectivity', 'Effectivity')}</TableCell>
                <TableCell style={{ minWidth: "120px" }}>{tableSorting('ad_type', 'AD type')}</TableCell>
                <TableCell style={{ minWidth: "120px" }}>{tableSorting('effective_date', 'Effective Date')}</TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('dimension_type', ' Compliance Period')}</TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('ad_compliance_status', 'Compliance Status')}</TableCell>
                <TableCell>{tableSorting('ad_compliance_tsn', 'TSN')}</TableCell>
                <TableCell>{tableSorting('ad_compliance_csn', 'CSN')}</TableCell>
                <TableCell>{tableSorting('ad_compliance_date', 'Date')}</TableCell>
                <TableCell style={{ minWidth: "120px" }}>Next Due</TableCell>
                <TableCell style={{ minWidth: "250px" }}>{tableSorting('remark', 'Remarks')}</TableCell>
                <TableCell>Attachments </TableCell>
            </TableRow> :
                <TableRow>
                    <TableCell>Action </TableCell>
                    <TableCell >{tableSorting('faa_ad', 'FAA AD ')} </TableCell>
                    <TableCell style={{ minWidth: "200px" }}>{tableSorting('faa_superseded', 'FAA Superseded')}</TableCell>
                    <TableCell>{tableSorting('easa_ad', 'EASA AD')}</TableCell>
                    <TableCell style={{ minWidth: "200px" }}>{tableSorting('easa_superseded', 'EASA Superseded')}</TableCell>
                    <TableCell style={{ minWidth: "250px" }}>Corresponding SB</TableCell>
                    <TableCell style={{ minWidth: "250px" }}>{tableSorting('description', 'Description')}</TableCell>
                    <TableCell style={{ minWidth: "190px" }}>{tableSorting('related_document', 'Related Document')}</TableCell>
                    <TableCell style={{ minWidth: "280px" }}>{tableSorting('applicability', 'Applicability')}</TableCell>
                    <TableCell style={{ minWidth: "280px" }}>{tableSorting('effectivity', 'Effectivity')}</TableCell>
                    <TableCell>{tableSorting('faa_effective_date', 'FAA')}</TableCell>
                    <TableCell>{tableSorting('easa_effective_date', 'EASA')}</TableCell>
                    <TableCell style={{ minWidth: "200px" }}>{tableSorting('ad_compliance_status', 'Compliance Status')} </TableCell>
                    <TableCell style={{ minWidth: "120px" }}>Next Due</TableCell>
                    <TableCell>{tableSorting('reference', 'Reference')}</TableCell>
                    <TableCell style={{ minWidth: "250px" }}>{tableSorting('remark', 'Remarks')}</TableCell>
                    <TableCell>Attachments </TableCell>
                </TableRow>}
        </TableHead>
    )
}


export default AdHeader