import React, { useState } from 'react'
import { CircularProgress, Button, Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { DropzoneArea } from "material-ui-dropzone";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import PageLoader from './PageLoader';
import { useSnackbar } from 'notistack';
import { globalExportService, globalFileUploadService } from '../../utils/globalApiServices';
import { checkApiStatus, downloadFileType } from '../../utils';


function ImportFileFormat({ match, toggleModalFn, getResponseBack, title, moduleType, moduleName,assetType,fleet,revampSample ,assetId}) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [file, setFile] = useState([]);
	const [isLoading, setLoading] = useState(false)

	const downloadSample = (query) => {
		globalExportService(revampSample ? `camo/${moduleType}/${match?.params?.asset}/${match?.params?.assetType}/export/`
			: assetType ? `camo/${moduleType}/sample-file/${match?.params?.assetType}/` : assetId ?  `camo/${moduleType}/sample-file/${match?.params?.asset}/`: `camo/${moduleType}/sample-file/`, query)
			.then(response => {
				if (checkApiStatus(response)) {
					downloadFileType(response.data, `${moduleName}`, 'xlsx')
					enqueueSnackbar("Sample File Download Successfully", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } })
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } })
				}
			});
	}

	const importFile = () => {
		var formData = new FormData();
		formData.append('file', file[0])
		setLoading(true);
		globalFileUploadService(fleet ? `camo/${moduleType}/import/`: `camo/${moduleType}/${match?.params?.asset}/${match?.params?.assetType}/import/`, formData)
			.then(response => {
				setLoading(false); toggleModalFn(); getResponseBack()
				if (response.data.statusCode == 200) {
					enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				} else {
					enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				}
			})

	}

	return (
		<Dialog
			open={true}
			onClose={toggleModalFn}
			className='console-management-modal'
			maxWidth={'md'}
		>
			<DialogTitle id="scroll-dialog-title">
				{title ? title : "Import File"}
			</DialogTitle>
			<DialogContent dividers={true}>
				<div style={{ width: '380px' }}>
					<p style={{ marginBottom: '5px' }}>Please refer the sample sheet, <span style={{ color: '#3f51b5', cursor: 'pointer' }} onClick={()=>downloadSample({download:'xls',download_sample:'true'})}>Download</span></p>
					<div>
						<DropzoneArea
							filesLimit={1}
							maxFileSize={104857600}
							acceptedFiles={['.xlsx']}
							showPreviewsInDropzone={false}
							useChipsForPreview={true}
							showPreviews={true}
							dropzoneText={<p>Drag & Drop file Or Click Here </p>}
							dropzoneClass="drag-drop-cnt"
							maxWidth="sm"
							showAlerts={['info', 'error']}
							alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
							clearOnUnmount={true}
							onChange={(file) => setFile(file)}
						/>
					</div>
				</div>
				<span style={{ fontSize: '11px' }} className='files-upload-limit' > Note : Supported extension only ('.xlsx').</span>
			</DialogContent>
			<DialogActions>
				<Button disabled={isLoading} onClick={toggleModalFn} color='primary' size='small' variant='outlined'>Cancel</Button>
				<Button disabled={isLoading || !file.length} onClick={importFile} color='primary' size='small' variant='contained'>
					{isLoading ? <CircularProgress size={24} color='white' /> : 'Save'}
				</Button>
			</DialogActions>
			{isLoading ? <PageLoader /> : null}
		</Dialog>

	)
}

export default withRouter(ImportFileFormat) 