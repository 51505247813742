import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, InputLabel, Select, MenuItem, Tooltip, CircularProgress, TextareaAutosize } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import { DropzoneArea } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { getLocalStorageInfo } from "../../../../utils";
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus } from '../../../../utils';
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { srObj } from '.';
import { assetType, imgStoragePath } from "../../../../constants";
import { smbcInstance, specificKey, specificMessTsn, specificMessCsn } from '../..';

function AddEditSr({ match, toggleModalFn, srData, basicdetail, modeType, getResponseBack, list }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(srData ? srData : srObj)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
    const [linkFile, setlinkFile] = useState({ modal: false, data: null })

    const onFieldChange = (keyParam, value) => {
        setData({ ...data, [keyParam]: value });
    }
    const onRestErrorKey = (keyParam) => {
        setError({ [keyParam]: '' })
    }

    const uploadFileData = (file) => {
        setData({ ...data, checklist: file })
    }

    const getData = (selectedFile, type) => {
        setData({ ...data, checklist: selectedFile, linkFile: type });
    }

    const uploadFileFn = (file, uuid, type) => {
        if (uuid) {
            let storeChecklistData = [];
            let folderUuid = [];

            const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
            const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

            for (const arr of updatedChecklist) {
                storeChecklistData = storeChecklistData.concat(arr);
            }
            for (const arr of folderUUID) {
                folderUuid = folderUuid.concat(arr);
            }
            setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
        } else {
            if (Array.isArray(file)) {
                const updatedAttachments = [...(data?.attachments || []), ...file];
                setData({ ...data, 'attachments': updatedAttachments });
            } else {
                console.error("Value for attachments is not an array:", file);
            }
        }
    }
    const addUploadFile = (item) => {
        if (data?.linkFile === "link") {
            let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
            globalPutService(`camo/repair/${item?.id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        toggleModalFn()
                        getResponseBack(response.data.data);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0) {
            if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
                let formData = new FormData();
                formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
                for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
                    formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
                }
                globalPutService(`camo/repair/${item?.id}/document-checklists/`, formData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    });
            }
        }

    }
    const importFile = (file) => {
        var formData = new FormData();
        Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
            formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
            formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
            formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
            formData.append("[" + key + "]module_id", file?.id)
            formData.append("[" + key + "]module_type_id", 7)
            formData.append("[" + key + "]asset_type_id", match.params.assetType)
            formData.append("[" + key + "]asset_id", match.params.asset)
        })
        globalFileUploadService(`camo/attachment/upload/`, formData)
            .then(response => {
                toggleModalFn(); getResponseBack()
                if (checkApiStatus(response)) {
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })

    }
    const onRemoveFile = (file, type, fileId,folder) => {
        if (modeType === "add") {
            if (type === "checklist") {
                setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
            } else {
                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
            }
        } else {
            if (type === "checklist") {
                let payload = {};
                payload.delete = true;
				payload.folder_ids =data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
			    payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
                globalPutService(`camo/repair/${data.id}/document-checklists/`, payload)
                    .then((response) => {
                        if (checkApiStatus(response)) {
                            const updatedChecklist = { ...data.checklist };
                            updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
							updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
                            setData({ ...data, checklist: updatedChecklist });
                            getResponseBack()
                        }
                    });
            } else {
                if (file.id) {
                    globalDeleteService(`camo/attachment/${file.id}/delete/`)
                        .then((response) => {
                            if (checkApiStatus(response)) {
                                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                                getResponseBack()
                            }
                        });
                } else {
                    setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                }
            }
        }
    }

    const onAddNewAsset = () => {
        let validationInputs = {}
        if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')) {
            validationInputs = {
                ...validationInputs,
                ata_chapter: data.ata_chapter ? '' : "Please Enter ATA Chapter",
                repair_ref_number: data.repair_ref_number ? '' : "Please Enter Repair Ref Number",
                repair_type: data.repair_type ? '' : "Please Select Repair Type",
                repair_description: data.repair_description ? '' : "Please Repair Description",
                srm_ref_document: data.srm_ref_document ? '' : "Please SRM Ref Documents",
            }
        }
        if (data?.repair_compliance_tsn && !specificKey.includes(data?.repair_compliance_tsn?.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.repair_compliance_tsn)) {
            validationInputs.repair_compliance_tsn = specificMessTsn;
        }
        if (data?.repair_compliance_csn && !specificKey.includes(data?.repair_compliance_csn?.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.repair_compliance_csn)) {
            validationInputs.repair_compliance_csn = specificMessCsn;
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/repair/${payload.id}/update/`, payload)
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response?.data?.data)

                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                globalPostService(`camo/repair/create/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            addUploadFile(response?.data?.data)
                            importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })

            }
        } else {
            setError(validationInputs)
        }
    }
    const nextDueValue = () => {
        if (data.repair_status === "CLOSED") {
            if (["FH", "FC", "D", "FH,D", "FC,D", "FC,FH,D", "FC,FH"].includes(data.repair_interval_type)) {
                data.repair_DUE = ""
            }
        } else {
            if (data.repair_interval_type === "FH") {
                data.repair_DUE = Number.isInteger(+data.last_done_fh + +data.repair_interval) && (+data.last_done_fh + +data.repair_interval).toFixed(2)
            } else if (data.repair_interval_type === "FC") {
                data.repair_DUE = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2)
            } else if (data.repair_interval_type === "D" && data.last_done_date !== null) {
                data.repair_DUE = moment(data.last_done_date).add(data.repair_interval, "days").format("DD-MM-YYYY")
            } else if (data.repair_interval_type === "FC,D") {
                const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2)
                const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");
                data.repair_DUE = fcDue < moment(dateDue, "DD-MM-YYYY") ? fcDue : dateDue;
            } else if (data.repair_interval_type === "FH,D") {
                const fhDue = Number.isInteger(+data.last_done_fh + +data.repair_interval) && (+data.last_done_fh + +data.repair_interval).toFixed(2)
                const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");
                data.repair_DUE = fhDue < moment(dateDue, "DD-MM-YYYY") ? fhDue : dateDue;
            } else if (data.repair_interval_type === "FC,FH,D") {
                const fhDue = Number.isInteger(+data.last_done_fh + +data.repair_interval) && (+data.last_done_fh + +data.repair_interval).toFixed(2)
                const fcDue = Number.isInteger(+data.last_done_fc + +data.dimension_interval_fc) && (+data.last_done_fc + +data.dimension_interval_fc).toFixed(2)
                const dateDue = moment(data.last_done_date, "YYYY-MM-DD").add(data.dimension_interval_days, "d").format("DD-MM-YYYY");
                if (fhDue <= fcDue && fhDue <= moment(dateDue, "DD-MM-YYYY")) {
                    data.repair_DUE = fhDue;
                } else if (fcDue <= fhDue && fcDue <= moment(dateDue, "DD-MM-YYYY")) {
                    data.repair_DUE = fcDue;
                } else {
                    data.repair_DUE = dateDue;
                }
            } else if (data.repair_interval_type === "FC,FH") {
                const fhDue = Number.isInteger(+data.last_done_fh + +data.repair_interval) && (+data.last_done_fh + +data.repair_interval).toFixed(2)
                const fcDue = Number.isInteger(+data.last_done_fc + +data.repair_interval) && (+data.last_done_fc + +data.repair_interval).toFixed(2)
                data.repair_DUE = fcDue < fhDue ? fcDue : fhDue;
            }

        }
        return data.repair_DUE
    }
    const remainingVal = () => {
        if (data.repair_status === "CLOSED") {
            if (["FH", "FC", "D", "FH,D", "FC,D", "FC,FH,D", "FC,FH"].includes(data.repair_interval_type)) {
                data.remaining_val = ""
            }
        } else {
            var date = new Date()
            const lDate = moment(data.last_done_date)
            const todayD = moment(date)
            let remDay = lDate.diff(todayD, "days")
            if (data.repair_interval_type === "D") {
                data.remaining_val = moment(remDay).add(data.repair_interval)
            } else if (data.repair_interval_type === "FC") {
                data.remaining_val = Number.isInteger(data.repair_DUE - basicdetail?.csn) && (data.repair_DUE - basicdetail?.csn).toFixed(2)
            } else if (data.repair_interval_type === "FH") {
                data.remaining_val = Number.isInteger(data.repair_DUE - basicdetail?.tsn) && (data.repair_DUE - basicdetail?.tsn).toFixed(2)
            } else if (data.repair_interval_type === "FC,D") {
                const fcRem_val = Number.isInteger(data.repair_DUE - basicdetail?.csn) && (data.repair_DUE - basicdetail?.csn).toFixed(2)
                const dRem_val = moment(remDay).add(data.dimension_interval_days)
                data.remaining_val = fcRem_val < moment(dRem_val, "DD-MM-YYYY") ? fcRem_val : dRem_val;
            } else if (data.repair_interval_type === "FH,D") {
                const fhRem_val = Number.isInteger(data.repair_DUE - basicdetail?.tsn) && (data.repair_DUE - basicdetail?.tsn).toFixed(2)
                const dRem_val = moment(remDay).add(data.dimension_interval_days)
                data.remaining_val = fhRem_val < moment(dRem_val, "DD-MM-YYYY") ? fhRem_val : dRem_val;
            } else if (data.repair_interval_type === "FC,FH,D") {
                const fcRem_val = Number.isInteger(data.repair_DUE - basicdetail?.csn) && (data.repair_DUE - basicdetail?.csn).toFixed(2)
                const fhRem_val = Number.isInteger(data.repair_DUE - basicdetail?.tsn) && (data.repair_DUE - basicdetail?.tsn).toFixed(2)
                const dRem_val = moment(remDay).add(data.dimension_interval_days)

                if (fhRem_val <= fcRem_val && fhRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
                    data.remaining_val = fhRem_val;
                } else if (fcRem_val <= fhRem_val && fcRem_val <= moment(dRem_val, "DD-MM-YYYY")) {
                    data.remaining_val = fcRem_val;
                } else {
                    data.remaining_val = dRem_val;
                }
            } else if (data.repair_interval_type === "FC,FH") {
                const fcRem_val = Number.isInteger(data.repair_DUE - basicdetail?.csn) && (data.repair_DUE - basicdetail?.csn).toFixed(2)
                const fhRem_val = Number.isInteger(data.repair_DUE - basicdetail?.tsn) && (data.repair_DUE - basicdetail?.tsn).toFixed(2)
                data.remaining_val = fcRem_val < fhRem_val ? fcRem_val : fhRem_val;
            }
        }
        return data.remaining_val
    }

    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Update Struture Repair" : " Add Struture Repair"} </DialogTitle>
                <DialogContent dividers>
                    <Grid spacing={1} container >
                        <Grid item xs={4}>
                            <TextField
                                required
                                variant="outlined"
                                label="ATA Chapter"
                                id="ata_chapter"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.ata_chapter ? data.ata_chapter : ""}
                                inputProps={{ maxLength: 15 }}
                                error={error.ata_chapter ? true : false}
                                helperText={error.ata_chapter ? error.ata_chapter : ""}
                                onChange={(e) => { onFieldChange("ata_chapter", e.target.value); onRestErrorKey("ata_chapter") }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                required
                                variant="outlined"
                                label="Repair Ref Number"
                                id="repair_ref_number"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.repair_ref_number ? data.repair_ref_number : ""}
                                onChange={(e) => { onFieldChange("repair_ref_number", e.target.value); onRestErrorKey("repair_ref_number") }}
                                inputProps={{ maxLength: 25 }}
                                error={error.repair_ref_number ? true : false}
                                helperText={error.repair_ref_number ? error.repair_ref_number : ""}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Grid item>
                                <Autocomplete
                                    id="repair_type"
                                    options={['DENT', 'GOUGE', 'SCRATCH', 'CORROSION', 'PAINT DAMAGE', 'CRACK', 'SCRIBE LINR DAMAGE', 'FOD', 'CAT A', 'CAT B', 'CAT C', 'OTHERS']}
                                    getOptionLabel={option => option}
                                    value={data?.repair_type || null}
                                    margin='normal'
                                    onChange={(e, value) => onFieldChange("repair_type", value)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Repair Type"
                                            margin='normal'
                                            placeholder="Select  Repair Type"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12}>
                            <TextField
                                required
                                variant="outlined"
                                label="Repair Description"
                                id="repair_description"
                                multiline
                                minRows={5}
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                error={error.repair_description ? true : false}
                                helperText={error.repair_description ? error.repair_description : ""}
                                value={data.repair_description ? data.repair_description : ""}
                                onChange={(e) => { onFieldChange("repair_description", e.target.value); onRestErrorKey("repair_description") }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                variant="outlined"
                                label="Repair Compliance Ref"
                                id="repair_compliance_ref"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.repair_compliance_ref}
                                error={error.repair_compliance_ref ? true : false}
                                helperText={error.repair_compliance_ref ? error.repair_compliance_ref : ""}
                                inputProps={{ maxLength: 255 }}
                                onChange={(e, value) => { onFieldChange("repair_compliance_ref", e.target.value); onRestErrorKey("repair_compliance_ref") }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label="Repair Inspection"
                                id="repair_inspection"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.repair_inspection}
                                error={error.repair_inspection ? true : false}
                                helperText={error.repair_inspection ? error.repair_inspection : ""}
                                onChange={(e) => { onFieldChange("repair_inspection", e.target.value); onRestErrorKey("repair_inspection") }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label="Repair Location"
                                id="repair_location"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.repair_location}
                                error={error.repair_location ? true : false}
                                helperText={error.repair_location ? error.repair_location : ""}
                                onChange={(e) => { onFieldChange("repair_location", e.target.value); onRestErrorKey("repair_location") }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label="Repair Detail"
                                id="repair_detail"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.repair_detail ? data.repair_detail : ""}
                                onChange={(e,) => onFieldChange("repair_detail", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        Repair TSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                id="repair_compliance_tsn"
                                margin='normal'
                                fullWidth
                                value={data.repair_compliance_tsn}
                                error={error.repair_compliance_tsn ? true : false}
                                helperText={error.repair_compliance_tsn ? error.repair_compliance_tsn : ""}
                                onChange={(e) => { onFieldChange("repair_compliance_tsn", e.target.value); onRestErrorKey("repair_compliance_tsn") }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        Repair CSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                id="repair_compliance_csn"
                                margin='normal'
                                fullWidth
                                value={data.repair_compliance_csn}
                                error={error.repair_compliance_csn ? true : false}
                                helperText={error.repair_compliance_csn ? error.repair_compliance_csn : ""}
                                onChange={(e) => { onFieldChange("repair_compliance_csn", e.target.value); onRestErrorKey("repair_compliance_csn") }}
                            />
                        </Grid>
                        <Grid item xs={4} >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    id="repair_compliance_date"
                                    label="Repair Compliance Date"
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    disableFuture
                                    margin='normal'
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.repair_compliance_date}
                                    error={false}
                                    helperText={""}
                                    onChange={(data) => onFieldChange("repair_compliance_date", moment(data).format(backendDateFormat))}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={3}>
                            <Autocomplete
                                id="repair_status"
                                options={['OPEN', 'CLOSED', 'REPETITIVE']}
                                getOptionLabel={option => option}
                                value={data?.repair_status || null}
                                margin='normal'
                                onChange={(e, value) => onFieldChange("repair_status", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Repair Status"
                                        margin='normal'
                                        placeholder="Select Repair Status"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    id="last_done_date"
                                    label="Last Done Date"
                                    margin='normal'
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.last_done_date}
                                    error={false}
                                    helperText={""}
                                    onChange={(data) => { onFieldChange("last_done_date", moment(data).format(backendDateFormat)) }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={3} >
                            <TextField
                                variant="outlined"
                                label="Last Done FH"
                                id="last_done_fh"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.last_done_fh}
                                onChange={(e) => onFieldChange("last_done_fh", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                label="Last Done FC"
                                id="last_done_fc"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.last_done_fc}
                                onChange={(e) => onFieldChange("last_done_fc", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={6} sm={6} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>INTERVAL</Grid>
                        <Grid item xs={12} md={3} sm={3} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>Remaining</Grid>
                        <Grid item xs={12} md={3} sm={3} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>Next Due</Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="repair_interval_type"
                                options={['None', 'D', 'FH', 'FC', 'FC,D', 'FH,D', 'FC,FH,D', 'FC,FH']}
                                getOptionLabel={option => option}
                                margin='normal'
                                value={data?.repair_interval_type || null}
                                onChange={(e, value) => onFieldChange("repair_interval_type", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Repair Compliance Period"
                                        placeholder="Select Repair Compliance Period"
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3} >
                            <Grid spacing={1} container>
                                {data.repair_interval_type == "FC" || data.repair_interval_type == "FC,D" || data.repair_interval_type == "FC,FH" || data.repair_interval_type == "FC,FH,D" ?
                                    <Grid item
                                        xs={data.repair_interval_type == "FC" ? 12 : data.repair_interval_type == "FC,D" ? 6 : data.repair_interval_type == "FC,FH" ? 6 : data.repair_interval_type == "FC,FH,D" ? 4 : 4}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label={data.repair_interval_type == "FC" || data.repair_interval_type == "FC,D" || data.repair_interval_type == "FC,FH,D" || data.repair_interval_type == "FC,FH" ? "FC Interval" : "FC, Interval"}
                                            id="dimension_interval_fc"
                                            margin='normal'
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={data.dimension_interval_fc}
                                            onChange={(e) => onFieldChange("dimension_interval_fc", e.target.value)}
                                        />
                                    </Grid> : null}
                                {data.repair_interval_type == "FH" || data.repair_interval_type == "FH,D" || data.repair_interval_type == "FC,FH" || data.repair_interval_type == "FC,FH,D" ?
                                    <Grid item
                                        xs={data.repair_interval_type == "FH" ? 12 : data.repair_interval_type == "FH,D" ? 6 : data.repair_interval_type == "FC,FH" ? 6 : data.repair_interval_type == "FC,FH,D" ? 4 : 4}
                                    >
                                        <TextField
                                            variant="outlined"
                                            label={data.repair_interval_type == "FH" || data.repair_interval_type == "FH,D" || data.repair_interval_type == "FC,FH,D" || data.repair_interval_type == "FC,FH" ? "FH Interval" : "FH, Interval"}
                                            margin='normal'
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            id="repair_interval"
                                            value={data.repair_interval}
                                            onChange={(e) => onFieldChange("repair_interval", e.target.value)}
                                        />
                                    </Grid> : null}
                                {
                                    data.repair_interval_type == "D" || data.repair_interval_type == "FC,D" || data.repair_interval_type == "FH,D" || data.repair_interval_type == "FC,FH,D" ? (
                                        <Grid item
                                            xs={data.repair_interval_type == "D" ? 12 : data.repair_interval_type == "FC,D" || data.repair_interval_type == "FH,D" ? 6 : data.repair_interval_type == "FC,FH,D" ? 4 : 4}
                                        >
                                            <TextField
                                                label="Days"
                                                variant="outlined"
                                                id="dimension_interval_days"
                                                margin='normal'
                                                fullWidth
                                                InputLabelProps={{ shrink: true }}
                                                value={data.dimension_interval_days}
                                                onChange={(e, value) => onFieldChange("dimension_interval_days", e.target.value)}
                                            />
                                        </Grid>
                                    ) : null
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={data.repair_interval_type === "D" ? "Days" : data.repair_interval_type === "FC" ? "FC" : data.repair_interval_type === "FH" ? "FH" :
                                    data.repair_interval_type === "FH,D" ? "FH/Days" : data.repair_interval_type === "FC,D" ? "FC/Days" : data.repair_interval_type === "FC,FH,D" ? "FC/FH/Days" : data.repair_interval_type === "FC,FH" ? "FC/FH" : "Days"}
                                id="remaining_val"
                                value={remainingVal()}
                                onChange={(e, value) => onFieldChange("remaining_val", e.target.value)}
                                inputProps={{ readOnly: true }}
                                disabled
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                className="disabled-input"
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={data.repair_interval_type === "D" ? "Date" : data.repair_interval_type === "FC" ? "FC" : data.repair_interval_type === "FH" ? "FH" : data.repair_interval_type === "FH,D" ? "FH/Date" :
                                    data.repair_interval_type === "FC,D" ? "FC/Date" : data.repair_interval_type === "FC,FH,D" ? "FC/FH/Date" : data.repair_interval_type === "FC,FH" ? "FC/FH " : "Date"}
                                name="repair_DUE"
                                id="repair_DUE"
                                value={nextDueValue()}
                                onChange={(e, value) => onFieldChange("repair_DUE", e.target.value)}
                                inputProps={{ readOnly: true }}
                                disabled
                                className="disabled-input"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <FormControl
                                error={error.dnb_compliance_dfp ? true : false}
                                helperText={error.dnb_compliance_dfp ? error.dnb_compliance_dfp : ""}
                            >   <FormLabel component="legend">D&B Compliance DFP</FormLabel>
                                <RadioGroup
                                    required
                                    row
                                    aria-label="dnb_compliance_dfp"
                                    name="dnb_compliance_dfp"
                                    defaultValue="top"
                                    value={data.dnb_compliance_dfp}
                                    onChange={(e) => onFieldChange("dnb_compliance_dfp", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                                {error.dnb_compliance_dfp && (
                                    <FormLabel component="dnb_compliance_dfp">
                                        <span style={{ color: "red" }}>
                                            {error.dnb_compliance_dfp}
                                        </span>
                                    </FormLabel>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <FormControl
                                component="fieldset"
                                error={error.release_certificate ? true : false}
                                helperText={error.release_certificate ? error.release_certificate : ""}
                            >
                                <FormLabel component="legend">Release Certificate</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="release_certificate"
                                    name="release_certificate"
                                    defaultValue="top"
                                    value={data.release_certificate}
                                    onChange={(e, value) => onFieldChange("release_certificate", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                                {error.release_certificate && (
                                    <FormLabel component="release_certificate">
                                        <span style={{ color: "red" }}> {error.release_certificate} </span>
                                    </FormLabel>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <FormControl
                                component="fieldset"
                                error={error.certificate_of_conformity ? true : false}
                                helperText={error.certificate_of_conformity ? error.certificate_of_conformity : ""}
                            >
                                <FormLabel component="legend">Certificate of Conformity</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="certificate_of_conformity"
                                    name="certificate_of_conformity"
                                    defaultValue="top"
                                    value={data.certificate_of_conformity}
                                    onChange={(e, value) => onFieldChange("certificate_of_conformity", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                                {error.certificate_of_conformity && (
                                    <FormLabel component="certificate_of_conformity">
                                        <span style={{ color: "red" }}>
                                            {error.certificate_of_conformity}
                                        </span>
                                    </FormLabel>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <FormControl
                                error={error.srm_ref_document ? true : false}
                                helperText={error.srm_ref_document ? error.srm_ref_document : ""}
                            >
                                <FormLabel component="legend">SRM reference Documents<span style={{ color: "red" }}>*</span></FormLabel>
                                <RadioGroup
                                    required
                                    row
                                    aria-label="srm_ref_document"
                                    name="srm_ref_document"
                                    defaultValue="top"
                                    value={data.srm_ref_document}
                                    onChange={(e, value) => onFieldChange("srm_ref_document", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                                {error.srm_ref_document && (
                                    <FormLabel component="srm_ref_document">
                                        <span style={{ color: "red" }}>
                                            {error.srm_ref_document}
                                        </span>
                                    </FormLabel>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <FormControl
                                component="fieldset"
                                error={error.oem_repair_scheme ? true : false}
                                helperText={error.oem_repair_scheme ? error.oem_repair_scheme : ""}
                            >   <FormLabel component="legend">OEM Repair Scheme</FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="oem_repair_scheme"
                                    name="oem_repair_scheme"
                                    defaultValue="top"
                                    value={data.oem_repair_scheme}
                                    onChange={(e, value) => onFieldChange("oem_repair_scheme", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                                {error.oem_repair_scheme && (
                                    <FormLabel component="oem_repair_scheme">
                                        <span style={{ color: "red" }}> {error.oem_repair_scheme} </span>
                                    </FormLabel>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={3} sm={3}>
                            <FormControl
                                component="fieldset"
                                error={error.faa_equivalent ? true : false}
                                helperText={error.faa_equivalent ? error.faa_equivalent : ""}
                            ><FormLabel component="legend">FAA 8100-9 / Equivalent</FormLabel>

                                <RadioGroup
                                    row
                                    aria-label="faa_equivalent"
                                    name="faa_equivalent"
                                    defaultValue="top"
                                    value={data.faa_equivalent}
                                    onChange={(e, value) =>
                                        onFieldChange("faa_equivalent", e.target.value)
                                    }
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                                {error.faa_equivalent && (
                                    <FormLabel component="faa_equivalent">
                                        <span style={{ color: "red" }}> {error.faa_equivalent} </span>
                                    </FormLabel>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                label="Remarks"
                                margin='normal'
                                variant='outlined'
                                fullWidth
                                value={data.remark}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("remark", e.target.value)}
                                rows={3}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
                                <span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
                                <span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
                            </div>
                        </Grid>
                        <div className="checklist-file" style={{ overflow: "auto" }}>
                        {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
                            <ul className='list-inline'>
                                {(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
                                    return (
                                        <li className='list-inline-item'>
                                            <span className='file-name'>{attachments.name}</span>
                                            <span className='file-remove'>
                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
                                                <CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                            </span>
                                        </li>)
                                }

                                )}
                                {(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
                                    data.checklist?.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                <a href={decodeURIComponent(file.path)} download={file.name}>
														{data?.checklist.some(item => item.type === 'folder') ? null
															: <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
													</a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.files?.length > 0) &&
                                    data.checklist?.files.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.folders?.length > 0) &&
                                    data.checklist?.folders.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                    <a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                </Tooltip>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {uploadFile.modal ?
                <UploadFile
                    modType="repair"
                    type="upload"
                    list={list}
                    actionType={modeType}
                    editFormId={data?.id}
                    handleClose={() => setUploadFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

                />
                : null}
            {linkFile.modal ?
                <LinkFiles
                    modType="repair"
                    type="link"
                    list={list}
                    editFormId={data?.id}
                    actionType={modeType}
                    uploadFileInfo={data}
                    toggleModalFn={() => setlinkFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    onLinkFile={getData}
                />
                : null
            }
            {isLoading ? <PageLoader /> : null}
        </>
    )

}
export default withRouter(AddEditSr)