import AddEditShopVisit from "./AddEditShopVisit";
import ShopVisitHeader from "./ShopVisitHeader";
import ShopVisitListing from "./ShopVisitListing";
export {
    AddEditShopVisit,
    ShopVisitHeader,
    ShopVisitListing
}

export const svObj = {
    removal_date: null,
    removal_reason: null,
    tsn_at_removal: null,
    csn_at_removal: null,
    sv_completion_date: null,
    sv_location_mro: '',
    sv_workscope: '',
    sv_type: '',
    remarks: "",
    attachments: [],
    checklist: [],
    folder_uuid: '',
    linkFile:''
}