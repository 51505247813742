import AddEditAd from "./AddEditAd";
import AdListing from "./AdListing";
import AdHeader from "./AdHeader";
export {
    AdHeader,
    AddEditAd,
    AdListing
}
export const adObj = {
    asset_id: null,
    asset_type_id: null,
    ata: null,
    ad_no: null,
    issuing_authority: null,
    applicability: null,
    description: "",
    ad_type:"",
    effectivity: null,
    sb_issue_number:'',
    effective_date: null,
    dimension_type: "",
    dimension_interval: null,
    dimension_interval_days: null,
    dimension_interval_fc: null,
    ad_compliance_status: "OPEN",
    ad_compliance_tsn: null,
    ad_compliance_csn: null,
    ad_compliance_date: null,
    sb_number: null,
    ad_compliance_dfp: null,
    release_certificate: null,
    conformity_crt: null,
    type: null,
    ac_comp: null,
    ref_documents: "",
    last_done_date: null,
    last_done_fh: null,
    last_done_fc: null,
    workorder: "",
    remark: "",
    attachments: [],
    ad_due: "",
    due_date: null,
    checklist: [],
    folder_uuid: '',
    faa_ad: null,
    easa_ad: null,
    faa_superseded: null,
    easa_superseded: null,
    related_document: null,
    ad_applicablity: null,
    faa_effective_date: null,
    easa_effective_date: null,
    linkFile:'',
    next_due_note:null
}
