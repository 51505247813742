import { React, useState, useEffect } from 'react'
import moment from 'moment';
import config from '../../../../config'
import { useSnackbar } from 'notistack';
import SearchIcon from '@material-ui/icons/Search';
import BasicDetail from '../../basicDetail/containers'
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { AmpTableHeader, AmpListing, AddEditAmp } from '../components';
import { ImportFileFormat, BasicTopHeader } from '../../../../shared_elements/components';
import ExportMenu from '../../../../shared_elements/components/ExportMenu';
import { downloadFileType, checkApiStatus } from '../../../../utils';
import Pagination from '../../../../shared_elements/components/Pagination';
import { EmptyCollection, PageLoader, DeletePopUp } from '../../../../shared_elements';
import { DownloadAllApi } from '../../../fleet/containers/apiServices';
import ShowAttachments from '../../../../shared_elements/components/ShowAttachments';
import { globalGetService, globalDeleteService, globalExportService, globalPutService } from '../../../../utils/globalApiServices';
import { Table, TableBody, Paper, Button, Grid, IconButton, TextField, Tooltip, InputAdornment, TablePagination } from "@material-ui/core";

function AmpMainComp({ match }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [list, setList] = useState({})
	const [basicdetail, setBasicDetail] = useState()
	const [isLoading, setLoading] = useState(false)
	const [sortInfo, setSortInfo] = useState({ sort: '', sort_by: '' });
	const [deletelist, setDeleteList] = useState({ modal: false, data: null })
	const [importFile, setImportFile] = useState({ modal: false, data: null })
	const [addEditAmp, setAddEditAmp] = useState({ modal: false, data: null, type: '' })
	const [attchementsModal, setAttchementsModal] = useState({ modal: false, data: null, })

	useEffect(() => {
		getAmpList({ per_page: 20 })
	}, [])

	const createSortHandler = (key) => {
		let sortQuery = { sort: key };
		if (sortInfo.sort === key) {
			sortQuery = { ...sortQuery, sort_by: sortInfo.sort_by === 'asc' ? 'desc' : 'asc' }
		} else {
			sortQuery = { ...sortQuery, sort_by: 'asc' }
		}
		setSortInfo(sortQuery)
		getAmpList({ ...sortQuery, per_page: 20 });
	}
	const getAttachements = () => {
		const matchingItem = list?.list?.find(item => item?.id === attchementsModal?.data?.id);
		if (!matchingItem) return [];
		const { checklist, attachments } = matchingItem || {};
		if (checklist && attachments || checklist || attachments) {
			const { files, folders } = checklist || {}; // Add null check here
			if (attachments?.length > 0 && files?.length > 0 && folders?.length > 0) {
				return [...attachments, ...files, ...folders];
			} else if (files?.length > 0 && folders?.length > 0) {
				return [...folders, ...files];
			} else if (attachments?.length > 0 && files?.length > 0) {
				return [...attachments, ...files];
			} else if (attachments?.length > 0 && folders?.length > 0) {
				return [...attachments, ...folders];
			} else if (attachments?.length > 0) {
				return [...attachments];
			} else if (folders?.length > 0) {
				return [...folders];
			} else if (files?.length > 0) {
				return [...files];
			}
		}
		return [];
	};

	const getAmpList = (query) => {
		setLoading(true)
		globalGetService(`camo/amp/?asset=${match?.params?.asset}&asset_type=${match?.params?.assetType}`, { ...query, search: query?.search })
			.then((response) => {
				setLoading(false)
				if (checkApiStatus(response)) {
					setList(response.data)
				}

			})
	}
	const deleteAmpList = () => {
		setLoading(true)
		globalDeleteService(`camo/amp/${deletelist?.data?.id}/`)
			.then((response) => {
				setLoading(false); setDeleteList({ modal: false }); getAmpList({ per_page: 20 })
				if (checkApiStatus(response)) {
					enqueueSnackbar("Record Deleted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				} else {
					enqueueSnackbar("Something went wrong.", { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				}

			})
	}
	const exportAmpListing = (extension) => {
		const date = new Date()
		const todayDate = moment(String(date)).format("DD-MM-YYYY")
		setLoading(true);
		globalExportService(`/camo/amp/${match?.params?.asset}/${match?.params?.assetType}/export/`, { download: extension })
			.then(response => {
				setLoading(false);
				downloadFileType(response.data, `AMP_${todayDate}`, extension)
				enqueueSnackbar("Download AMP List Successfully", { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
			});
	}
	const deleteAttachements = (item, checklist, fileId, uuid,folder, type) => {
		if (type) {
			globalDeleteService(`camo/attachments/ampcompliance/${item?.id}/${uuid}/`)
				.then((response) => {
					if (checkApiStatus(response)) {
						getAmpList({ per_page: 20 })
						enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
					} else {
						enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

					}
				})

		} else {
			let payload = {};
			payload.delete = true;
			payload.folder_ids = checklist.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).toString()
			payload.file_ids = checklist.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).toString()
			globalPutService(`camo/amp/${item?.id}/document-checklists/`, payload)
				.then((response) => {
					if (checkApiStatus(response)) {
						getAmpList({ per_page: 20 })
						enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
					} else {
						enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

					}
				})
		}
	};

	return (
		<section className='amp-module'>
			<BasicTopHeader heading='AMP/MRB Compliance' slug={basicdetail?.slug} analytic='amp_Module' refreshData={() => getAmpList({ per_page: 20 })} />
			<BasicDetail moduleType="amp" BasicDetailData={(data) => setBasicDetail(data)} />
			<Paper square style={{ padding: "10px" }} variant='outlined'>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={5} md={5}>
						<TextField
							variant='outlined'
							fullWidth
							margin='normal'
							size='small'
							placeholder='Search MPD Item No,Task Card No,Task Description'
							onChange={(e) => getAmpList({ search: e.target.value, per_page: 20 })}
							InputProps={{ style: { fontSize: 14, marginTop: 5 }, endAdornment: <InputAdornment position="end"><SearchIcon color='primary' cursor="pointer" /></InputAdornment> }}
						/>
					</Grid>
					<Grid item xs={12} md={7}>
						<ul className='list-inline' style={{ float: "right", margin: "revert" }}>
							<li className='list-inline-item'>
								<Button variant='contained' color='primary' size='small' onClick={() => setAddEditAmp({ modal: true, data: null, type: 'add' })}> + Add AMP</Button>
							</li>
							<li className='list-inline-item'>
								<Button variant='outlined' color='primary' size='small' onClick={() => setImportFile({ modal: true, data: null })}>Import</Button>
							</li>
							<li className='list-inline-item'>
								<ExportMenu
									exportReportFn={(file) => exportAmpListing(file.extension)}
									files={[{ title: 'PDF', extension: 'pdf' }, { title: 'EXCEL', extension: 'xls' }]}
									disabled={!list?.list?.length}
								/>
							</li>
						</ul>
					</Grid>
				</Grid>
				<div style={{ height: `${window.innerHeight - 360}px`, overflow: 'auto' }}>
					<Table className='mui-table-format' stickyHeader={true}>
						<AmpTableHeader
							sortInfo={sortInfo}
							createSortHandler={createSortHandler}
						/>
						<TableBody>
							{list?.list?.map((item, index) =>
								<AmpListing
									item={item}
									key={index}
									toggleModalFn={() => setAddEditAmp({ modal: true, data: item, type: 'edit' })}
									toggleDelteModalFn={() => setDeleteList({ modal: true, data: item })}
									handleOpenAttachments={() => setAttchementsModal({ modal: true, data: item })}
								/>
							)}
						</TableBody>
					</Table>
					{!list?.list?.length ? <EmptyCollection title="No Records Founds" /> : null}
				</div>
				<Pagination
					pagination={list?.pagination}
					onChangeRowsPerPage={(event) => getAmpList({ page: 1, per_page: event.target.value })}
					onChangePage={(event, newPage) => getAmpList({ per_page: list.pagination.per_page, page: newPage + 1, })}
				/>
			</Paper>
			{addEditAmp.modal ?
				<AddEditAmp
					ampData={addEditAmp?.data}
					basicdetail={basicdetail}
					modeType={addEditAmp.type}
					getResponseBack={() => getAmpList({ per_page: 20 })}
					toggleModalFn={() => setAddEditAmp({ modal: false })}
					list={list}
				/> : null
			}
			{deletelist.modal ?
				<DeletePopUp
					title='Delete AMP/MRB Compliance'
					deleteRecordFn={() => deleteAmpList()}
					modal={() => setDeleteList({ modal: true })}
					content='Do you really want to delete this record?'
					toggleModalFn={() => setDeleteList({ modal: false })}

				/> : null

			}
			{importFile.modal ?
				<ImportFileFormat
					title="Import AMP"
					moduleType='amp'
					moduleName="SampleFile_AMP"
					getResponseBack={() => getAmpList({ per_page: 20 })}
					toggleModalFn={() => setImportFile({ modal: false })}
					revampSample={true}
				/> : null
			}
			{attchementsModal.modal ?
				<ShowAttachments
					handleClose={() => setAttchementsModal({ modal: false, data: null })}
					handleShowAttachments={getAttachements()}
					openAttachment={(file) => window.open(decodeURIComponent(file), "_blank")}
					files={{ title: "AMP", extension: "zip", key: "" }}
					downloadAllApi={() => DownloadAllApi(list?.list.filter(item=> attchementsModal.data?.id === item.id)[0], 'AMP',)}
					item={attchementsModal.data}
					removeAttachment={deleteAttachements}
				/>
				:
				null
			}
			{isLoading ? <PageLoader /> : null}
		</section>
	)
}

export default withRouter(AmpMainComp)