import AddEditInventory from "./AddEditInventory";
import InventoryListing from "./InventoryListing";
import InventoryHeader from "./InventoryHeader";
export {
    AddEditInventory,
    InventoryListing,
    InventoryHeader

}

export const inventoryObj = {
    iin: '',
    spec: '',
    ipc_ppbu_fig_ref: '',
    quantity: null,
    part_number: '',
    description:null,
    section:'',
    serial_number: '',
    remarks: null,
    installed: "Yes",
    attachments: [],
    tsn:null,
    csn:null,
    tso: null,
    cso: null,
    condition: null,
    checklist: [],
    folder_uuid: '',
    linkFile:''
}