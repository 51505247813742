import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField,Tooltip, InputLabel, Select, MenuItem, CircularProgress, TextareaAutosize } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import { DropzoneArea } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { getLocalStorageInfo } from "../../../../utils";
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus } from '../../../../utils';
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { modsObj } from '.';
import { assetType, imgStoragePath } from "../../../../constants";
import {specificKey, specificMessTsn, specificMessCsn } from '../..';

function AddEditMods({ match, toggleModalFn, modsData, basicdetail, modeType, getResponseBack, list }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(modsData ? modsData : modsObj)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
    const [linkFile, setlinkFile] = useState({ modal: false, data: null })

    const onFieldChange = (keyParam, value) => {
        setData({ ...data, [keyParam]: value });
    }
    const onRestErrorKey = (keyParam) => {
        setError({ [keyParam]: '' })
    }

    const uploadFileData = (file) => {
        setData({ ...data, checklist: file })
    }

    const getData = (selectedFile, type) => {
        setData({ ...data, checklist: selectedFile, linkFile: type });
    }

    const uploadFileFn = (file, uuid, type) => {
        if (uuid) {
            let storeChecklistData = [];
            let folderUuid = [];

            const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
            const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

            for (const arr of updatedChecklist) {
                storeChecklistData = storeChecklistData.concat(arr);
            }
            for (const arr of folderUUID) {
                folderUuid = folderUuid.concat(arr);
            }
            setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
        } else {
            if (Array.isArray(file)) {
                const updatedAttachments = [...(data?.attachments || []), ...file];
                setData({ ...data, 'attachments': updatedAttachments });
            } else {
                console.error("Value for attachments is not an array:", file);
            }
        }
    }
    const addUploadFile = (item) => {
        if (data?.linkFile === "link") {
            let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
            globalPutService(`camo/mods/${item?.id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        toggleModalFn()
                        getResponseBack(response.data.data);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0) {
            if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
                let formData = new FormData();
                formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
                for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
                    formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
                }
                globalPutService(`camo/mods/${item?.id}/document-checklists/`, formData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    });
            }
        }

    }
    const importFile = (file) => {
        var formData = new FormData();
        Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
            formData.append('files', data?.attachments.filter(item => item instanceof File)[key]);
    })
        globalFileUploadService(`camo/attachments/modstatus/${file?.id}/`, formData)
            .then(response => {
                toggleModalFn(); getResponseBack()
                if (checkApiStatus(response)) {
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })

    }
    const onRemoveFile = (file, type, fileId,folder) => {
        if (modeType === "add") {
            if (type === "checklist") {
                setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
            } else {
                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
            }
        } else {
            if (type === "checklist") {
                let payload = {};
                payload.delete = true;
				payload.folder_ids =data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
			    payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
                globalPutService(`camo/mods/${data.id}/document-checklists/`, payload)
                    .then((response) => {
                        if (checkApiStatus(response)) {
                            const updatedChecklist = { ...data.checklist };
                            updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                            updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
                            setData({ ...data, checklist: updatedChecklist });
                            getResponseBack()
                        }
                    });
            } else {
                if (file.id) {
                    globalDeleteService(`camo/attachment/${file.id}/delete/`)
                        .then((response) => {
                            if (checkApiStatus(response)) {
                                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                                getResponseBack()
                            }
                        });
                } else {
                    setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                }
            }
        }
    }

    const onAddNewAsset = () => {
        let validationInputs = {}
        if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')) {
            validationInputs = {
                ...validationInputs,
                ata_chapter: data.ata_chapter ? '' : "Please Enter ATA Chapter",
                mod_ref_number: data.mod_ref_number ? '' : "Please Enter MODS Ref Number"
            }
        }
        if (typeof data.mod_compliance_csn === 'string' && !specificKey.includes(data.mod_compliance_csn.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.mod_compliance_csn)) {
            validationInputs.mod_compliance_csn = specificMessCsn;
        }
        if (typeof data.mod_compliance_tsn === 'string' && !specificKey.includes(data.mod_compliance_tsn.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.mod_compliance_tsn)) {
            validationInputs.mod_compliance_tsn = specificMessTsn;
        }
      
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/mods/${payload.id}/`, payload)
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response?.data?.data)

                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                globalPostService(`camo/mods/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                             addUploadFile(response?.data?.data)
                             importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })

            }
        } else {
            setError(validationInputs)
        }
    }

    const nextDueValue = () => {
        var date = new Date(data.lastDone_D)
        date.setDate(date.getDate() + parseInt(data.interval_D))
        var dom = new Date(basicdetail?.date_of_manufacture)
        dom.setDate(dom.getDate() + parseInt(data.threshold_D))
        if (data.lastDone_D && data.interval_D) {
            if (data.lastDone_D?.length > 0) {
                data.nextDue_D = data.lastDone_D === "Invalid date" ? data.threshold_D != '' && moment(dom).format(backendDateFormat) : moment(date).format(backendDateFormat)
            } else {
                data.nextDue_D = null || ''
            }
        } else {
            if (data.threshold_D != '') {
                data.nextDue_D = moment(dom).format(backendDateFormat)
            } else {
                data.nextDue_D = null
            }
        }
        return data.nextDue_D

    }
    const nextDueFHValue = () => {
        if (data.lastDone_FH > 0 && data.interval_FH > 0) {
            if (data.lastDone_FH === '') {
                data.nextDue_FH = null
            } else {
                data.nextDue_FH = (+data.lastDone_FH + +data.interval_FH).toFixed(0)
            }
        } else {
            if (data.threshold_FH?.length > 0) {
                data.nextDue_FH = (+data.threshold_FH + +basicdetail?.tsn).toFixed(0)
            } else {
                data.nextDue_FH = ''
            }
        }
    }
    const nextDueFCValue = () => {
        if (data.lastDone_FC > 0 && data.interval_FC > 0) {
            if (data.lastDone_FC === '' || null) {
                data.nextDue_FC = null
            } else {
                data.nextDue_FC = (+data.lastDone_FC + +data.interval_FC).toFixed(0)
            }
        } else {
            if (data.threshold_FC?.length > 0) {
                data.nextDue_FC = (+data.threshold_FC + +basicdetail?.csn).toFixed(0)
            } else {
                data.nextDue_FC = ''
            }
        }
        return data.nextDue_FC
    }
    const remainingDaysValues = () => {
        const calDate = new Date()
        const todayD = moment(calDate).format(backendDateFormat)
        const lDate = moment(data.nextDue_D)
        if (data.nextDue_D) {
            data.remainingValues_D = lDate.diff(todayD, "days") ? lDate.diff(todayD, "days") : null
        }
        return data.remainingValues_D
    }
    var date = new Date()
    const lDate = moment(data.last_done_date)
    const todayD = moment(date)
    let remDay = lDate.diff(todayD, "days")

    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Update Modfications" : " Add Modfications"} </DialogTitle>
                <DialogContent dividers>
                    <Grid spacing={1} container>
                        <Grid item xs={3}>
                            <TextField
                                required
                                variant="outlined"
                                label="ATA Chapter"
                                id="ata_chapter"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.ata_chapter}
                                error={error.ata_chapter ? true : false}
                                helperText={error.ata_chapter ? error.ata_chapter : ""}
                                onChange={(e, value) => onFieldChange("ata_chapter", e.target.value)}
                                inputProps={{ maxLength: 15 }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                required
                                variant="outlined"
                                label=" MOD Reference Number"
                                id="mod_ref_number"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.mod_ref_number}
                                error={error.mod_ref_number ? true : false}
                                helperText={error.mod_ref_number ? error.mod_ref_number : ""}
                                onChange={(e, value) => onFieldChange("mod_ref_number", e.target.value)}
                                inputProps={{ maxLength: 15 }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                label="MOD Issued by"
                                id="mod_issued_by"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.mod_issued_by}
                                inputProps={{ maxLength: 20 }}
                                onChange={(e, value) => onFieldChange("mod_issued_by", e.target.value)}
                                error={error.mod_issued_by ? true : false}
                                helperText={error.mod_issued_by ? error.mod_issued_by : ""}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                label="MOD Applicability"
                                id="mod_applicability"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.mod_applicability}
                                inputProps={{ maxLength: 20 }}
                                onChange={(e, value) => onFieldChange("mod_applicability", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                label="MOD Description"
                                id="description"
                                multiline
                                minRows={5}
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.description}
                                error={error.description ? true : false}
                                helperText={error.description ? error.description : ""}
                                onChange={(e, value) => onFieldChange("description", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={4}>
                            <Autocomplete
                                id="type"
                                options={['STC', 'LOCALMODIFICATION', 'OTHERS']}
                                getOptionLabel={option => option}
                                value={data?.type || null}
                                margin='normal'
                                onChange={(e, value) => onFieldChange("type", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="MOD Type"
                                        margin='normal'
                                        placeholder="Select Mod Type"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label="MOD Effectivity"
                                id="effectivity"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.effectivity}
                                inputProps={{ maxLength: 25 }}
                                onChange={(e, value) => onFieldChange("effectivity", e.target.value)}
                                error={error.effectivity ? true : false}
                                helperText={error.effectivity ? error.effectivity : ""}
                            />
                        </Grid>
                        <Grid item xs={4} className="input-calender">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    id="effectivity_date"
                                    label="MOD Effective Date"
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    fullWidth
                                    disableFuture
                                    margin='normal'
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.effectivity_date}
                                    error={error.effectivity_date ? true : false}
                                    helperText={error.effectivity_date ? error.effectivity_date : ""}
                                    onChange={(data, value) => { onFieldChange("effectivity_date", moment(data).format(backendDateFormat)); onRestErrorKey("effectivity_date") }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={4} className="input-calender">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    id="last_done_date"
                                    label="Last Done Date"
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    disableFuture
                                    fullWidth
                                    margin='normal'
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.last_done_date}
                                    error={error.last_done_date ? true : false}
                                    helperText={error.last_done_date ? error.last_done_date : ""}
                                    onChange={(data, value) => {
                                        onFieldChange("last_done_date", moment(data).format(backendDateFormat))
                                        onRestErrorKey("last_done_date")
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label="Last Done FH"
                                id="last_done_fh"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.last_done_fh}
                                onChange={(e, value) => onFieldChange("last_done_fh", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label="Last Done FC"
                                id="last_done_fc"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.last_done_fc}
                                onChange={(e, value) => onFieldChange("last_done_fc", e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                       MOD Compliance TSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="mod_compliance_tsn"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={data.mod_compliance_tsn}
                                inputProps={{ maxLength: 20 }}
                                onChange={(e) => {onFieldChange("mod_compliance_tsn", e.target.value);onRestErrorKey("mod_compliance_tsn")}}
                                error={error.mod_compliance_tsn ? true : false}
                                helperText={error.mod_compliance_tsn ? error.mod_compliance_tsn : ""}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                       MOD Compliance CSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="mod_compliance_csn"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={data.mod_compliance_csn}
                                error={error.mod_compliance_csn ? true : false}
                                helperText={error.mod_compliance_csn ? error.mod_compliance_csn : ""}
                                onChange={(e) => {onFieldChange("mod_compliance_csn", e.target.value);onRestErrorKey("mod_compliance_csn")}}
                            />
                        </Grid>
                        <Grid item xs={4} className="input-calender">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    id="mod_compliance_date"
                                    label="MOD Compliance Date"
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    disableFuture
                                    margin='normal'
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.mod_compliance_date}
                                    error={error.mod_compliance_date ? true : false}
                                    helperText={error.mod_compliance_date ? error.mod_compliance_date : ""}
                                    onChange={(data, value) => {
                                        onFieldChange("mod_compliance_date", moment(data).format(backendDateFormat))
                                        onRestErrorKey("mod_compliance_date")
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label="Document Number"
                                id="document_number"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.document_number}
                                inputProps={{ maxLength: 25 }}
                                onChange={(e, value) => onFieldChange("document_number", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                variant="outlined"
                                label="Workorder"
                                id="workorder"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ maxLength: 25 }}
                                value={data.workorder}
                                onChange={(e, value) => onFieldChange("workorder", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} sm={4}>
                            <Autocomplete
                                id="mod_compliance_status"
                                options={['OPEN', 'COMPLIED', 'PARTIALLY COMPLIED', 'CLOSED', 'NOT APPLICABLE']}
                                getOptionLabel={option => option}
                                value={data?.mod_compliance_status || null}
                                margin='normal'
                                onChange={(e, value) => onFieldChange("mod_compliance_status", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="MOD Compliance Status"
                                        margin='normal'
                                        placeholder="Select Mod Compliance Status"
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="mod_interval_type"
                                options={['D', 'FH', 'FC']}
                                getOptionLabel={option => option}
                                value={data?.mod_interval_type || null}
                                margin='normal'
                                onChange={(e, value) => onFieldChange("mod_interval_type", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="MOD Compliance Period "
                                        margin='normal'
                                        placeholder="Select Mod Compliance Period "
                                        variant="outlined"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label="MOD Compliance"
                                id="mod_interval"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.mod_interval}
                                onChange={(e, value) => onFieldChange("mod_interval", e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={data.mod_interval_type === "FH" ? "FH" : data.mod_interval_type === "FC" ? "FC" : "Days"}
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                id="ad_due"
                                value={
                                    data.mod_interval_type === "FH"
                                        ? (data.mods_DUE = Number.isInteger(+data.last_done_fh + +data.mod_interval)
                                            ? +data.last_done_fh + +data.mod_interval
                                            : (+data.last_done_fh + +data.mod_interval).toFixed(2))
                                        : data.mod_interval_type === "FC"
                                            ? (data.mods_DUE = Number.isInteger(+data.last_done_fc + +data.mod_interval) ? +data.last_done_fc + +data.mod_interval
                                                : (+data.last_done_fc + +data.mod_interval).toFixed(2))
                                            : data.mod_interval_type === "D"
                                                ? (data.mods_DUE = moment(data.last_done_date).add(data.mod_interval, "days")).format("DD-MM-YYYY") : ""
                                }
                                onChange={(e, value) => onFieldChange("ad_due", e.target.value)}
                                inputProps={{ readOnly: true }}
                                disabled
                                className="disabled-input"
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={data.mod_interval_type === "FH" ? "FH" : data.mod_interval_type === "FC" ? "FC" : "Days"}
                                name="remaining_val"
                                id="remaining_val"
                                value={
                                    data.mod_interval_type === "FH"
                                        ? (data.remaining_val = Number.isInteger(data.mods_DUE - basicdetail?.tsn)
                                            ? data.mods_DUE - basicdetail?.tsn
                                            : (data.mods_DUE - basicdetail?.tsn).toFixed(2))
                                        : data.mod_interval_type === "FC"
                                            ? (data.remaining_val = Number.isInteger(data.mods_DUE - basicdetail?.tsn)
                                                ? data.mods_DUE - basicdetail?.tsn
                                                : (data.mods_DUE - basicdetail?.tsn).toFixed(2))
                                            : data.mod_interval_type === "D"
                                                ? (data.remaining_val = moment(remDay).add(data.mod_interval))
                                                : null
                                }
                                onChange={(e, value) => onFieldChange("remaining_val", e.target.value)}
                                inputProps={{ readOnly: true }}
                                disabled
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                className="disabled-input"
                            />
                        </Grid>
                    </Grid>
                    <Grid id="data-container" spacing={1} container>
                        <Grid item xs={3}>
                            <label>MOD Compliance DFP</label>
                            <FormControl>
                                <RadioGroup
                                    required
                                    row
                                    aria-label="mod_compliance_dfp"
                                    name="mod_compliance_dfp"
                                    defaultValue="top"
                                    value={data.mod_compliance_dfp}
                                    onChange={(e, value) => onFieldChange("mod_compliance_dfp", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                                {error.mod_compliance_dfp && (
                                    <FormLabel component="mod_compliance_dfp">
                                        <span style={{ color: "red" }}> {error.mod_compliance_dfp} </span>
                                    </FormLabel>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <label>Release Certificate </label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="release_certificate"
                                    name="release_certificate"
                                    defaultValue="top"
                                    value={data.release_certificate}
                                    onChange={(e, value) => onFieldChange("release_certificate", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <label>Certificate of Conformity</label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="certificate_of_conformity"
                                    name="certificate_of_conformity"
                                    defaultValue="top"
                                    value={data.certificate_of_conformity}
                                    onChange={(e, value) => onFieldChange("certificate_of_conformity", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                label="Remarks"
                                margin='normal'
                                variant='outlined'
                                fullWidth
                                value={data.remarks}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("remarks", e.target.value)}
                                rows={3}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
                                <span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
                                <span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
                            </div>
                        </Grid>
                        <div className="checklist-file" style={{ overflow: "auto" }}>
                        {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
                            <ul className='list-inline'>
                                {(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
                                    return (
                                        <li className='list-inline-item'>
                                            <span className='file-name'>{attachments.name}</span>
                                            <span className='file-remove'>
                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
                                                <CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                            </span>
                                        </li>)
                                }

                                )}
                                {(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
                                    data.checklist?.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        {data?.checklist.some(item => item.type === 'folder') ? null
                                                            : <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.files?.length > 0) &&
                                    data.checklist?.files.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.folders?.length > 0) &&
                                    data.checklist?.folders.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                    <a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                </Tooltip>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {uploadFile.modal ?
                <UploadFile
                    modType="mods"
                    type="upload"
                    list={list}
                    actionType={modeType}
                    editFormId={data?.id}
                    handleClose={() => setUploadFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

                />
                : null}
            {linkFile.modal ?
                <LinkFiles
                    modType="mods"
                    type="link"
                    list={list}
                    editFormId={data?.id}
                    actionType={modeType}
                    uploadFileInfo={data}
                    toggleModalFn={() => setlinkFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    onLinkFile={getData}
                />
                : null
            }
            {isLoading ? <PageLoader /> : null}
        </>
    )

}
export default withRouter(AddEditMods)