import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { globalGetService, globalPutService, globalPostService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus, getLocalStorageInfo } from '../../../../utils';
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { assetType, imgStoragePath } from "../../../../constants";
import { utilizationObj } from '.';


function AddEditUtilization({ match, toggleModalFn, UtilData, basicdetail, modeType, getResponseBack, list, moduleType }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [positionList, setPositionList] = useState([])
	const [data, setData] = useState(UtilData ? UtilData : {
		...utilizationObj,
		location_reg_aircraft: list?.sort((a, b) => b - a).map(filterId => filterId?.location_reg_aircraft)[0] ? list?.sort((a, b) => b - a).map(filterId => filterId?.location_reg_aircraft)[0] : utilizationObj.location_reg_aircraft,
		location_country: list?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] ? list?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] : basicdetail?.country,
		operating_environment: list?.sort((a, b) => b - a).map(filterId => filterId?.operating_environment)[0] ? list?.sort((a, b) => b - a).map(filterId => filterId?.operating_environment)[0] : utilizationObj?.operating_environment,
		location_position: list?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] ? list?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] : utilizationObj.location_position,

	})
	const [error, setError] = useState({})
	const [isLoading, setLoading] = useState(false)
	const [utilPeriod, setUtilPeriod] = useState([])
	const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
	const [linkFile, setlinkFile] = useState({ modal: false, data: null })
	const [opThrustList, setOpThrustList] = useState([])

	const latestUtilMonth = moment(list?.sort((a, b) => b - a).map(filterId => filterId?.utilization_period)[0]?.value)

	useEffect(() => {
		utilPeriodApi()
		OpThrustList()
		postionList()
	}, [])

	const onFieldChange = (keyParam, value) => {
		setData({ ...data, [keyParam]: value });
		if (keyParam === "utilization_period") {
			if (moment(value.value).diff(latestUtilMonth, 'days') > 31) {
				enqueueSnackbar("Previous Month Utilisation Not Entered", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
			}
		}

	}
	const onRestErrorKey = (keyParam) => {
		setError({ [keyParam]: '' })
	}

	const uploadFileData = (file) => {
		setData({ ...data, checklist: file })
	}

	const getData = (selectedFile, type) => {
		setData({ ...data, checklist: selectedFile, linkFile: type });
	}

	const uploadFileFn = (file, uuid, type) => {
		if (uuid) {
			let storeChecklistData = [];
			let folderUuid = [];

			const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
			const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

			for (const arr of updatedChecklist) {
				storeChecklistData = storeChecklistData.concat(arr);
			}
			for (const arr of folderUUID) {
				folderUuid = folderUuid.concat(arr);
			}
			setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
		} else {
			if (Array.isArray(file)) {
				const updatedAttachments = [...(data?.attachments || []), ...file];
				setData({ ...data, 'attachments': updatedAttachments });
			} else {
				console.error("Value for attachments is not an array:", file);
			}
		}
	}
	const addUploadFile = (item) => {
		if (data?.linkFile === "link") {
			let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
			globalPutService(`camo/util/${item?.id}/document-checklists/`, payload)
				.then(response => {
					if (checkApiStatus(response)) {
						toggleModalFn()
						getResponseBack(response.data.data);
						enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					} else {
						enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
		if (data?.folder_uuid?.length > 0) {
			if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
				let formData = new FormData();
				formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
				for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
					formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
				}
				globalPutService(`camo/util/${item?.id}/document-checklists/`, formData)
					.then(response => {
						if (checkApiStatus(response)) {
							getResponseBack()
						} else {
							enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
						}
					});
			}
		}

	}
	const importFile = (file) => {
		var formData = new FormData();
		Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
			formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
			formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
			formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
			formData.append("[" + key + "]module_id", file?.id)
			formData.append("[" + key + "]module_type_id", 11)
			formData.append("[" + key + "]asset_type_id", match.params.assetType)
			formData.append("[" + key + "]asset_id", match.params.asset)
		})
		globalFileUploadService(`camo/attachment/upload/`, formData)
			.then(response => {
				toggleModalFn(); getResponseBack()
				if (checkApiStatus(response)) {
				} else {
					enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				}
			})

	}
	const onRemoveFile = (file, type, fileId, folder) => {
		if (modeType === "add") {
			if (type === "checklist") {
				setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
			} else {
				setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
			}
		} else {
			if (type === "checklist") {
				let payload = {};
				payload.delete = true;
				payload.folder_ids = data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
				payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
				globalPutService(`camo/util/${data.id}/document-checklists/`, payload)
					.then((response) => {
						if (checkApiStatus(response)) {
							const updatedChecklist = { ...data.checklist };
							updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
							updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
							setData({ ...data, checklist: updatedChecklist });
							getResponseBack()
						}
					});
			} else {
				if (file.id) {
					globalDeleteService(`camo/attachment/${file.id}/delete/`)
						.then((response) => {
							if (checkApiStatus(response)) {
								setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
								getResponseBack()
							}
						});
				} else {
					setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
				}
			}
		}
	}
	const utilPeriodApi = () => {
		globalGetService(`camo/util/${match.params.asset}/${match.params.assetType}/period_list/`).then((response) => {
			if (checkApiStatus(response)) {
				setUtilPeriod(response?.data || []);
			}
		});
	}
	const OpThrustList = () => {
		globalGetService(`camo/current_thrust/${match.params.assetType}/?engine_family_engine_type=${basicdetail?.engine_type?.engine_family_type_id}`,)
			.then((response) => {
				if (checkApiStatus(response)) {
					setOpThrustList(response?.data)
				}
			});
	}
	const postionList = () => {
		globalGetService(`camo/all_dropdown_list/`)
			.then((response) => {
				if (checkApiStatus(response)) {
					setPositionList(response?.data?.position_list)
				}
			});
	}




	const onAddNewAsset = () => {
		let validationInputs = {}
		if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')) {
			validationInputs = {
				...validationInputs,
				utilization_period: data.utilization_period ? '' : "Please Select utilization Period",
				monthly_FH: data.monthly_FH ? '' : "Please Enter Monthly FH",
				monthly_FC: data.monthly_FC ? '' : "Please Enter Monthly FC",
			}
		}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
			setLoading(true)
			let payload = Object.assign({}, data);
			if (data?.id) {
				globalPutService(`camo/util/${payload.id}/`, { ...payload, monthly_FC: parseInt(payload.monthly_FC), monthly_FH: parseInt(payload.monthly_FH), tsn: parseInt(payload?.tsn), location_position: payload.location_position })
					.then(response => {
						setLoading(false)
						if (checkApiStatus(response)) {
							enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
							toggleModalFn(); getResponseBack()
							importFile(response?.data?.data)
						} else {
							enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

						}
					})
			} else {
				setLoading(false)
				if (moment(payload.utilization_period?.value).diff(latestUtilMonth, 'days') > 31) {
					enqueueSnackbar("Previous Month Utilisation Not Entered", { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
				} else {
					globalPostService(`camo/util/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType, monthly_FC: parseInt(payload.monthly_FC), monthly_FH: parseInt(payload.monthly_FH), tsn: parseInt(payload?.tsn), location_position: payload.location_position })
						.then(response => {
							setLoading(false)
							if (checkApiStatus(response)) {
								enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
								toggleModalFn(); getResponseBack()
								addUploadFile(response?.data?.data)
								importFile(response?.data?.data)
							} else {
								enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });
							}
						})

				}
			}
		} else {
			setError(validationInputs)
		}
	}
	const tsnCalc = () => {
		const filterdata = list?.filter(filterId => filterId);
		const currentIndex = filterdata?.findIndex(item => item.id === data?.id);
		const indexLength = list?.filter(filterId => filterId)?.length - 1
		if (modeType === "add") {
			data.tsn = list?.sort((a, b) => b - a).filter(item => item)[0]?.tsn + parseInt(data?.monthly_FH) || ""
		} else {
			if (currentIndex >= 0 && currentIndex !== indexLength) {
				const previousEntry = filterdata[currentIndex + 1];
				data.tsn = previousEntry?.tsn + parseInt(data?.monthly_FH) || '';
			} else {
				data.tsn = list?.find(item => item.id === data?.id)?.tsn || '';
			}
		}
		return typeof data?.tsn === 'string' || data.tsn === '' || data.tsn ? data?.tsn > 0 ? parseFloat(data?.tsn).toFixed(2) : '' : ''
	}

	const csnCalc = () => {
		const filterdata = list.filter(filterId => filterId);
		const currentIndex = filterdata.findIndex(item => item.id === data?.id);
		const indexLength = list.filter(filterId => filterId)?.length - 1
		if (modeType === "add") {
			data.csn = list.sort((a, b) => b - a).filter(item => item)[0]?.csn + parseInt(data?.monthly_FC) || ""
		} else {
			if (currentIndex >= 0 && currentIndex !== indexLength) {
				const previousEntry = filterdata[currentIndex + 1];
				data.csn = previousEntry?.csn + parseInt(data?.monthly_FC) || '';
			} else {
				data.csn = list?.find(item => item.id === data?.id)?.csn || '';
			}
		}
		return data?.csn;
	}
	const filteredOptions = Array.isArray(utilPeriod) && utilPeriod.length > 0
		? utilPeriod.filter((option) => {
			const maxPeriod = list.sort((a, b) => b - a).map(filterId => filterId.utilization_period)[0]?.value;
			return option.value > maxPeriod;
		})
		: [];
	const indexing = list?.map((item, index) => item.id).sort((b, a) => b - a)[0] === data?.id

	return (
		<>
			<Dialog open={true} onClose={toggleModalFn} className='camo-mangement-modal' >
				<DialogTitle> {modeType === "edit" ? "Edit Utilization" : "Add Utilization"} </DialogTitle>
				<DialogContent dividers>
					<Grid spacing={1} container>
						<Grid item sm={3} md={3} xs={12} >
							<Autocomplete
								options={filteredOptions?.length > 0 ? filteredOptions : utilPeriod || []}
								getOptionLabel={(option) => option?.data || ''}
								id="utilization_period"
								value={data?.utilization_period}
								disabled={modeType === "edit"}
								getOptionDisabled={(option) => !!data?.utilization_period && option.value === data?.utilization_period.value}
								onChange={(e, value) => onFieldChange("utilization_period", value)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Utilization Period"
										margin="normal"
										fullWidth
										required
										InputLabelProps={{ shrink: true }}
										placeholder="Select Utilization Period"
										variant="outlined"
										error={error.utilization_period ? true : false}
										helperText={error.utilization_period ? error.utilization_period : ""}
									/>
								)}
							/>
						</Grid>
						<Grid item sm={3} md={3} xs={12} >
							<TextField
								required
								variant="outlined"
								label="Monthly FH"
								id="monthly_FH"
								margin="normal"
								fullWidth
								value={data?.monthly_FH}
								InputLabelProps={{ shrink: true }}
								error={error.monthly_FH ? true : false}
								helperText={error.monthly_FH ? error.monthly_FH : ""}
								onChange={(e) => {
									const value = e.target.value;
									const numericValue = value.replace(/[^\d.]/g, '');
									const dotCount = (numericValue.match(/\./g) || []).length;
									const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 4 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 3 && regexConstants.numberWithDot.test(numericValue);
									if (isValid || value === "") {
										onFieldChange("monthly_FH", numericValue);
									}
									onRestErrorKey("monthly_FH");
								}}
							/>
						</Grid>
						<Grid item sm={3} md={3} xs={12} >
							<TextField
								required
								variant="outlined"
								label="Monthly FC"
								id="monthly_FC"
								margin="normal"
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={data?.monthly_FC}
								error={error.monthly_FC ? true : false}
								helperText={error.monthly_FC ? error.monthly_FC : ""}
								onChange={(e) => { e.target.value.length <= 3 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange('monthly_FC', e.target.value); onRestErrorKey("monthly_FC") }}
							/>
						</Grid>
						<Grid item sm={3} md={3} xs={12} >
							<TextField
								variant="outlined"
								label="TSN"
								id="tsn"
								margin="normal"
								fullWidth
								InputLabelProps={{ shrink: true }}
								disabled={modeType === "edit" ? indexing ? false : list?.length > 1 : list?.length > 0}
								value={
									list?.length === 1 && modeType === "edit" ?
										data.tsn : indexing ? data.tsn : list?.length > 1 && modeType === "edit" ? tsnCalc() :
											list?.length > 0 && modeType === "add" ?
												tsnCalc() : data.tsn}
								onChange={(e) => {
									const value = e.target.value;
									const numericValue = value.replace(/[^\d.]/g, '');
									const dotCount = (numericValue.match(/\./g) || []).length;
									const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
									if (isValid || value === "") {
										onFieldChange("tsn", numericValue);
									}
								}}

							/>
						</Grid>
					</Grid>
					<Grid spacing={1} container>
						<Grid item sm={3} md={3} xs={12} >
							<TextField
								variant="outlined"
								label="CSN"
								fullWidth
								id="csn"
								margin="normal"
								InputLabelProps={{ shrink: true }}
								disabled={modeType === "edit" ? indexing ? false : list?.length > 1 : list?.length > 0}
								value={
									list?.length === 1 && modeType === "edit" ?
										data.csn : indexing ? data.csn : list?.length > 1 && modeType === "edit" ? csnCalc() :
											list?.length > 0 && modeType === "add" ?
												csnCalc() : data.csn}
								onChange={(e) => e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange('csn', e.target.value)}
							/>
						</Grid>
						<Grid item sm={3} md={3} xs={12} >
							<TextField
								variant="outlined"
								label="Monthly Derate"
								id="monthly_derate"
								margin="normal"
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={data.monthly_derate ? data.monthly_derate : ''}
								onChange={(e) => {
									const value = e.target.value;
									const numericValue = value.replace(/[^\d.]/g, '');
									const dotCount = (numericValue.match(/\./g) || []).length;
									const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 3 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 2 && regexConstants.numberWithDot.test(numericValue);
									if (isValid || value === "") {
										onFieldChange("monthly_derate", parseInt(numericValue));
									}
								}}
							/>
						</Grid>
						<Grid item sm={3} md={3} xs={12} >
							<Autocomplete
								options={opThrustList ? opThrustList : []}
								getOptionLabel={(option) => option?.name}
								id="current_operating_thrust"
								value={data?.current_operating_thrust}
								onChange={(e, value) => onFieldChange("current_operating_thrust", value)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Op.Thrust (Current)"
										margin="normal"
										fullWidth
										InputLabelProps={{ shrink: true }}
										placeholder="Select Op.Thrust (Current)"
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item sm={3} md={3} xs={12} >
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								InputLabelProps={{ shrink: true }}
								label="Location Reg Aircraft"
								id="location_reg_aircraft"
								value={data?.location_reg_aircraft || ""}
								onChange={(e) => onFieldChange('location_reg_aircraft', e.target.value)}
							/>
						</Grid>
					</Grid>
					<Grid spacing={1} container>
						<Grid item sm={3} md={3} xs={12} >
							<Autocomplete
								options={positionList ? positionList : []}
								option={[]}
								getOptionLabel={(option) => option?.label}
								id="location_position"
								value={data?.location_position || null}
								onChange={(e, value) => onFieldChange("location_position", value)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Location Position"
										margin="normal"
										fullWidth
										InputLabelProps={{ shrink: true }}
										placeholder="Select Position"
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item sm={3} md={3} xs={12} >
							<TextField
								variant="outlined"
								label="Location Country"
								id="location_country"
								fullWidth
								margin="normal"
								InputLabelProps={{ shrink: true }}
								value={data.location_country || ''}
								onChange={(e) => onFieldChange('location_country', e.target.value)}
							/>
						</Grid>
						<Grid item sm={3} md={3} xs={12} >
						<Autocomplete
								options={['Neutral', 'Middle East/North Africa', 'India', 'China']}
								getOptionLabel={(option) => option}
								id="operating_environment"
								value={data?.operating_environment || null}
								onChange={(e, value) => onFieldChange("operating_environment", value)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Operating Enviroment"
										margin="normal"
										fullWidth
										InputLabelProps={{ shrink: true }}
										placeholder="Select Operating Enviroment"
										variant="outlined"
									/>
								)}
							/>
						</Grid>
						<Grid item sm={3} md={3} xs={12} >
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<KeyboardDatePicker
									id="date_of_installation"
									label="Date Of Installation"
									format={fieldDateFormat}
									inputVariant="outlined"
									fullWidth
									margin="normal"
									disableFuture
									InputLabelProps={{ shrink: true }}
									minDate={moment().subtract(25, "years")}
									value={data.date_of_installation}
									error={false}
									helperText={""}
									onChange={(data) => onFieldChange("date_of_installation", moment(data).format(backendDateFormat))}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
					</Grid>
					<Grid spacing={1} container>
					<Grid item sm={3} md={3} xs={12} >
							<MuiPickersUtilsProvider utils={MomentUtils}>
								<KeyboardDatePicker
									id="date_of_removal"
									label="Date of Removal"
									format={fieldDateFormat}
									inputVariant="outlined"
									fullWidth
									margin="normal"
									disableFuture
									InputLabelProps={{ shrink: true }}
									minDate={moment().subtract(25, "years")}
									value={data.date_of_removal}
									error={error.date_of_removal ? true : false}
									helperText={error.date_of_removal ? error.date_of_removal : ""}
									onChange={(data, value) => onFieldChange("date_of_removal", moment(data).format(backendDateFormat))}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item md={3}>
							<TextField
								variant="outlined"
								label="Engine Stand Location"
								fullWidth
								margin="normal"
								id="engine_stand_location"
								InputLabelProps={{ shrink: true }}
								value={data.engine_stand_location ? data.engine_stand_location : ''}
								onChange={(e) => onFieldChange('engine_stand_location', e.target.value)}
							/>
						</Grid>
						<Grid item md={6}>
							<TextField
								variant="outlined"
								label="Removal Reason"
								fullWidth
								margin="normal"
								id="reason_of_removal"
								InputLabelProps={{ shrink: true }}
								value={data.reason_of_removal ? data.reason_of_removal : ''}
								onChange={(e) => onFieldChange('reason_of_removal', e.target.value)}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6} sm={6}>
							<TextField
								label="Remarks"
								margin='normal'
								variant='outlined'
								fullWidth
								value={data.remarks}
								InputLabelProps={{ shrink: true }}
								onChange={(e) => onFieldChange("remarks", e.target.value)}
								rows={3}
								multiline
							/>
						</Grid>
						<Grid item xs={6}>
							<div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
								<span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
								<span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
							</div>
						</Grid>
						<div className="checklist-file" style={{ overflow: "auto" }}>
							{data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
							<ul className='list-inline'>
								{(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
									return (
										<li className='list-inline-item'>
											<span className='file-name'>{attachments.name}</span>
											<span className='file-remove'>
												<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
												<CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
											</span>
										</li>)
								}

								)}
								{(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
									data.checklist?.map((file) => {
										return (
											<li className='list-inline-item' key={file.id}>
												<span className='file-name'>{file.name}</span>
												<span className='file-remove'>
													<a href={decodeURIComponent(file.path)} download={file.name}>
														{data?.checklist.some(item => item.type === 'folder') ? null
															: <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
													</a>
													<CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
												</span>
											</li>
										);
									})}
								{(data.checklist?.files?.length > 0) &&
									data.checklist?.files.map((file) => {
										return (
											<li className='list-inline-item' key={file.id}>
												<span className='file-name'>{file.name}</span>
												<span className='file-remove'>
													<a href={decodeURIComponent(file.path)} download={file.name}>
														<GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
													</a>
													<CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
												</span>
											</li>
										);
									})}
								{(data.checklist?.folders?.length > 0) &&
									data.checklist?.folders.map((file) => {
										return (
											<li className='list-inline-item' key={file.id}>
												<Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
													<a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
												</Tooltip>
												<span className='file-name'>{file.name}</span>
												<span className='file-remove'>
													<CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
												</span>
											</li>
										);
									})}
							</ul>
						</div>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
					<Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
				</DialogActions>
			</Dialog>
			{uploadFile.modal ?
				<UploadFile
					modType="util"
					type="upload"
					list={list}
					actionType={modeType}
					editFormId={data?.id}
					handleClose={() => setUploadFile({ modal: false })}
					uuid={basicdetail?.record_folders?.technical_records?.uuid}
					getResponseBack={(file) => uploadFileData(file)}
					uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

				/>
				: null}
			{linkFile.modal ?
				<LinkFiles
					modType="util"
					type="link"
					list={list}
					editFormId={data?.id}
					actionType={modeType}
					uploadFileInfo={data}
					toggleModalFn={() => setlinkFile({ modal: false })}
					uuid={basicdetail?.record_folders?.technical_records?.uuid}
					getResponseBack={(file) => uploadFileData(file)}
					onLinkFile={getData}
				/>
				: null
			}
			{isLoading ? <PageLoader /> : null}
		</>
	)

}
export default withRouter(AddEditUtilization)