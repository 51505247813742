import { regexConstants } from "../../../../constants/regEx"

export const formValues = {
	asset_id: null,
	asset_type_id: null,
	ata_chapter: null,
	repair_ref_number: null,
	repair_type: "DENT",
	repair_description: "",
	repair_inspection: null,
	repair_location: null,
	repair_detail: null,
	repair_compliance_tsn: null,
	repair_compliance_csn: null,
	repair_compliance_date: null,
	repair_compliance_ref: null,
	repair_status: "OPEN",
	repair_DUE: "",
	last_done_date: null,
	last_done_fh: null,
	last_done_fc: null,
	dnb_compliance_dfp: "",
	release_certificate: "",
	certificate_of_conformity: "",
	srm_ref_document: "",
	oem_repair_scheme: "",
	faa_equivalent: "",
	remarks: null,
	attachments: [],
	checklist: [],
	repair_interval_type: "D",
	repair_interval: null,
	folder_uuid: ''
}

export const taskErrorCode = {
	ata_chapter: {
		0: "",
		1: "Required",
	},
	ata_chapter_obj: {
		required: true,
	},
	repair_ref_number: {
		0: "",
		1: "Required",
	},
	repair_ref_number_obj: {
		required: true,
	},
	repair_type: {
		0: "",
		1: "Required",
	},
	repair_type_obj: {
		required: true,
	},
	repair_description: {
		0: "",
		1: "Required",
	},
	repair_description_obj: {
		required: true,
	},
	srm_ref_document: {
		0: "",
		1: "Required",
	},
	srm_ref_document_obj: {
		required: true,
		radioGroup: true,
	},

}
