import { React, Fragment } from 'react'
import { Link } from "react-router-dom";
import config from '../../config';
import RefreshIcon from "@material-ui/icons/Refresh";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import PieChartOutlinedIcon from "@material-ui/icons/PieChartOutlined";
import { Paper, Grid, IconButton, Tooltip } from "@material-ui/core";
import { assetType } from '../../constants';
import FlightIcon from '@material-ui/icons/Flight';
import ExportMenu from './ExportMenu';
import { smbcInstance } from '../../application/camo';
function BasicTopHeader({ match, slug, heading, onMasterExportFn, refreshData,analytic={} }) {
	return (
		<Fragment>
			<Paper square style={{ paddingLeft: "4px" }} variant='elevation'>
				<Grid container spacing={2} className='basic-header-container'>
					<Grid item md={8} sm={8} xs={12} >
						<ul className='list-inline'>
							<li className='list-inline-item'><h4 style={{ marginTop: "4px" }}>{heading}</h4></li>
							<li className='list-inline-item'>
								<a href={`${config.domain.subDomian}technical-specification/${assetType[match?.params?.assetType]}/${slug}`} target="_blank">
									<FlightIcon fontSize='small' color='primary' style={{ marginLeft: "10px", marginTop: "5px", fontSize: '20px' }} />
									<span style={{ verticalAlign: "super" }}>Technical Specification</span>
								</a>
							</li>
						</ul>
					</Grid>
					<Grid item md={4} sm={4} xs={12} >
						<ul className='list-inline' style={{ float: "right" }}>
							{smbcInstance ?<li className='list-inline-item'>
								<ExportMenu
									exportReportFn={(file) => onMasterExportFn(file.extension)}
									files={[{ title: 'EXCEL', extension: 'xls' }]}
									title="Master Export"
								/>
							</li> : null}
							<li className='list-inline-item'>
								<Link to={analytic?.length > 0  ? `/dashboard?${analytic}` : '/dashboard'}>
									<Tooltip title="Analytics">
										<IconButton color="primary" size='small'> <PieChartOutlinedIcon /> </IconButton>
									</Tooltip>
								</Link>
							</li>
							<li className='list-inline-item'>
								<Tooltip title="Refresh">
									<IconButton color="primary" size='small' onClick={refreshData}> <RefreshIcon /> </IconButton>
								</Tooltip>
							</li>
						</ul>
					</Grid>
				</Grid>
			</Paper>
		</Fragment>
	)
}

export default withRouter(BasicTopHeader)