// import Home from "../home/containers/index";
// import AssetListing from "../home/containers/AssetListing";
// import pageLayoutHoc from "../../hocs/pageLayoutHoc";
// import userRoleBaseAccessHoc from "../../hocs/userRoleBaseAccessHoc";
// import Profile from "../home/containers/profile";
// import FleetMainAd from "../fleet/containers/FleetMainAd";
// import FleetMainSB from "../fleet/containers/FleetMainSB";
// import PreservationStatus from "../fleet/containers/PreservationStatus";
// import ShopVisitOverview from "../fleet/containers/ShopVisitOverview";
// import Inventories from "../fleet/containers/Inventories";
// export const assetRoutes = [
// 	{
// 		path: "/",
// 		component: pageLayoutHoc(userRoleBaseAccessHoc(AssetListing, []), { apps: "AssetListing", layoutPhase: 1 }),
// 		key: "MntAssetList",
// 	},
// 	{
// 		path: "/home",
// 		component: pageLayoutHoc(userRoleBaseAccessHoc(Home, []), { apps: "Assets", layoutPhase: 1, }),
// 		key: "MntAssetList",
// 	},
// 	{
// 		path: "/assets-listing",
// 		component: pageLayoutHoc(userRoleBaseAccessHoc(AssetListing, []), { apps: "AssetListing", layoutPhase: 1 }),
// 		key: "MntAssetList",
// 	},
// 	{
// 		path: "/fleet/ad",
// 		component: pageLayoutHoc(userRoleBaseAccessHoc(FleetMainAd, []), { apps: "AD", layoutPhase: 1 }),
// 		key: "AD",
// 	},
// 	{
// 		path: "/fleet/sb",
// 		component: pageLayoutHoc(userRoleBaseAccessHoc(FleetMainSB, []), { apps: "SB", layoutPhase: 1 }),
// 		key: "SB",
// 	},
// 	{
// 		path: "/profile",
// 		component: pageLayoutHoc(userRoleBaseAccessHoc(Profile, []), { apps: "Profile", layoutPhase: 1 }),
// 		key: "MntAssetList",
// 	},
// 	// {
// 	// 	path: "/reports/preservation",
// 	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(PreservationStatus, []), { apps: "Preservation Status", layoutPhase: 2 }),
// 	// 	key: "Preservation",
// 	// },
// 	// {
// 	// 	path: "/camo/inventories",
// 	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(Inventories, []), { apps: "inventories", layoutPhase: 1 }),
// 	// 	key: "Inventories",
// 	// },
// 	// {
// 	// 	path: "/reports/svOverview",
// 	// 	component: pageLayoutHoc(userRoleBaseAccessHoc(ShopVisitOverview, []), { apps: "Shop Visit Overview", layoutPhase: 2 }),
// 	// 	key: "svOverview",
// 	// },
// ];



import Home from "../home/containers/index";
import AssetListing from "../home/containers/assets";
import pageLayoutHoc from "../../hocs/pageLayoutHoc";
import userRoleBaseAccessHoc from "../../hocs/userRoleBaseAccessHoc";
import Profile from "../home/containers/profile";
// import ApplicationsAccess from "./containers/ApplicationsAccess";
import ApplicationsAccess from "../../shared_elements/ApplicationsAccess";
import FleetMainAd from "../fleet/containers/FleetMainAd";
import FleetMainSB from "../fleet/containers/FleetMainSB";
import Configure from "../fleet/containers/Configure";

export const assetRoutes = [
	{
		path: "/",
		component: pageLayoutHoc(userRoleBaseAccessHoc(AssetListing, []), { apps: "AssetListing", layoutPhase: 1 }),
		key: "MntAssetList",
	},
	{
		path: "/apps-switcher",
		component: pageLayoutHoc(userRoleBaseAccessHoc(ApplicationsAccess, []), { apps: "Switcher", layoutPhase: 1 }),
		key: "MntAssetList",
	},
	{
		path: "/home",
		component: pageLayoutHoc(userRoleBaseAccessHoc(Home, []), { apps: "Assets", layoutPhase: 1, }),
		key: "MntAssetList",
	},
	{
		path: "/assets-listing",
		component: pageLayoutHoc(userRoleBaseAccessHoc(AssetListing, []), { apps: "AssetListing", layoutPhase: 1 }),
		key: "MntAssetList",
	},
	{
		path: "/fleet/ad",
		component: pageLayoutHoc(userRoleBaseAccessHoc(FleetMainAd, []), { apps: "AD", layoutPhase: 1 }),
		key: "AD",
	},
	{
		path: "/fleet/sb",
		component: pageLayoutHoc(userRoleBaseAccessHoc(FleetMainSB, []), { apps: "SB", layoutPhase: 1 }),
		key: "SB",
	},
	{
		path: "/configure",
		component: pageLayoutHoc(userRoleBaseAccessHoc(Configure, []), { apps: "Configure", layoutPhase: 1 }),
		key: "Configure",
	},
	
	{
		path: "/profile",
		component: pageLayoutHoc(userRoleBaseAccessHoc(Profile, []), { apps: "Profile", layoutPhase: 1 }),
		key: "MntAssetList",
	},
];

