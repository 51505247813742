import React from 'react';
import { Button } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
const DownloadXlsx = ({ exportReportFn, files }) => {

	const anchorRef = React.useRef(null);

	const handleClose = (event, files, triggerExport) => {
		if (triggerExport) {
			exportReportFn(files);
		}
	};


	return (
		<>

			<Button
				color="primary"
				onClick={(event) => handleClose(event, files, 'triggerExport')}
				ref={anchorRef}
			>
				<>
					<InsertDriveFileIcon />
					{files.extension === 'xls'}
					{files.title}
				</>
			</Button>

		</>
	)
}

export default DownloadXlsx;