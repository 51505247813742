import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, InputLabel, Select, MenuItem, Tooltip, CircularProgress, TextareaAutosize } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import { DropzoneArea } from "material-ui-dropzone";
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { assetType, imgStoragePath } from "../../../../constants";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { getLocalStorageInfo } from "../../../../utils";
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus } from '../../../../utils';
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { htObj } from '.';
import { smbcInstance,camoLeads, specificKey, specificMessTsn, specificMessCsn } from '../..';


function AddEditHt({ match, toggleModalFn, htData, basicdetail, modeType, getResponseBack, list }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(htData ? htData : htObj)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
    const [linkFile, setlinkFile] = useState({ modal: false, data: null })

   console.log("setDatadata",data)
    const onFieldChange = (keyParam, value) => {
        setData({ ...data, [keyParam]: value });
    }
    const onRestErrorKey = (keyParam) => {
        setError({ [keyParam]: '' })
    }

    const uploadFileData = (file) => {
        setData({ ...data, checklist: file })
    }

    const getData = (selectedFile, type) => {
        setData({ ...data, checklist: selectedFile, linkFile: type });
    }

    const uploadFileFn = (file, uuid, type) => {
        if (uuid) {
            let storeChecklistData = [];
            let folderUuid = [];

            const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
            const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

            for (const arr of updatedChecklist) {
                storeChecklistData = storeChecklistData.concat(arr);
            }
            for (const arr of folderUUID) {
                folderUuid = folderUuid.concat(arr);
            }
            setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
        } else {
            if (Array.isArray(file)) {
                const updatedAttachments = [...(data?.attachments || []), ...file];
                setData({ ...data, 'attachments': updatedAttachments });
            } else {
                console.error("Value for attachments is not an array:", file);
            }
        }
    }
    const addUploadFile = (item) => {
        if (data?.linkFile === "link") {
            let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
            globalPutService(`camo/ht/${item?.id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        toggleModalFn()
                        getResponseBack(response.data.data);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0) {
            if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
                let formData = new FormData();
                formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
                for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
                    formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
                }
                globalPutService(`camo/ht/${item?.id}/document-checklists/`, formData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    });
            }
        }

    }
	const importFile = (file) => {
        var formData = new FormData();
        Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
            // formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
            // formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
            // formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
            // formData.append("[" + key + "]module_id", file?.id)
            // formData.append("[" + key + "]module_type_id", 2)
            // formData.append("[" + key + "]asset_type_id", match.params.assetType)
            // formData.append("[" + key + "]asset_id", match.params.asset)
            // var formData = new FormData();
            formData.append('files', data?.attachments.filter(item => item instanceof File)[key]);
    })
        globalFileUploadService(`camo/attachments/htcomponent/${file?.id}/`, formData)
            .then(response => {
                toggleModalFn(); getResponseBack()
                if (checkApiStatus(response)) {
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })

    }
    const onRemoveFile = (file, type, fileId,folder) => {
        if (modeType === "add") {
            if (type === "checklist") {
                setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
            } else {
                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
            }
        } else {
            if (type === "checklist") {
                let payload = {};
                payload.delete = true;
				payload.folder_ids =data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
			    payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
                globalPutService(`camo/ht/${data.id}/document-checklists/`, payload)
                    .then((response) => {
                        if (checkApiStatus(response)) {
                            const updatedChecklist = { ...data.checklist };
                            updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                            updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
                            setData({ ...data, checklist: updatedChecklist });
                            getResponseBack()
                        }
                    });
            } else {
                if (file.id) {
                    globalDeleteService(`camo/attachment/${file.id}/delete/`)
                        .then((response) => {
                            if (checkApiStatus(response)) {
                                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                                getResponseBack()
                            }
                        });
                } else {
                    setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                }
            }
        }
    }

    const onAddNewAsset = () => {
        let validationInputs = {}
        if (!camoLeads) {
            validationInputs = {
                ...validationInputs,
                ata_chapter: data.ata_chapter ? '' : "Please Enter ATA Chapter",
                part_number: data.part_number ? '' : "Please Enter Part NUmber",
                part_serial_number: data.part_serial_number ? '' : "Please Enter Serial NUmber",
                ht_requirement: data.ht_requirement ? '' : "Please Enter HT Requirements",
                task_card: data.task_card ? '' : "Please Enter Task Card",
                component: data.component ? '' : "Please Enter Components",
                position: data.position ? '' : "Please Enter Position",
                faa_easa: data.faa_easa ? '' : "Please Select FAA/EASA Certificate",
                installation_wo: data.installation_wo ? '' : "Please Instalation",
                part_description: data.part_description ? '' : "Please Enter Part Decsription",
                part_installation_date: data.part_installation_date ? '' : "Please Select Part Installation Date",
                crs_date: data.crs_date ? '' : "Please Select CRS Date",
            }
        }

        if (typeof data.tso === 'string' && !specificKey.includes(data.tso.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.tso))  {
            validationInputs.tso = specificMessTsn;
        }
        if (typeof data.cso === 'string' && !specificKey.includes(data.cso.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.cso))  {
            validationInputs.cso = specificMessCsn;
        }
        if (data.dimension_type == "FC" && !camoLeads) {
            validationInputs = {
                ...validationInputs,
                cso: data.cso ? '' : "Please Enter CSO",
                dimension_interval_fc: data.dimension_interval_fc ? '' : "Please Enter FC"
            }
        } else if (data.dimension_type == "FC,D" && !camoLeads) {
            validationInputs = {
                ...validationInputs,
                tso: data.tso ? '' : "Please Enter TSO",
                cso: data.cso ? '' : "Please Enter CSO",
                dimension_interval_fc: data.dimension_interval_fc ? '' : "Please Enter FC",
                dimension_interval_days: data.dimension_interval_days ? '' : "Please Enter Days"
            }
        } else if (data.dimension_type == "FH" && !camoLeads) {
            validationInputs = {
                ...validationInputs,
                tso: data.tso ? '' : "Please Enter TSO",
                dimension_interval: data.dimension_interval ? '' : "Please Enter Days"
            }
        } else if (data.dimension_type == "D" && !camoLeads) {
            validationInputs = {
                ...validationInputs,
                tso: data.tso ? '' : "Please Enter TSO",
                dimension_interval_days: data.dimension_interval_days ? '' : "Please Enter Days"
            }
        } else if (data.dimension_type == "FH,D" && !camoLeads) {
            validationInputs = {
                ...validationInputs,
                tso: data.tso ? '' : "Please Enter TSO",
                dimension_interval: data.dimension_interval ? '' : "Please Enter Days",
                dimension_interval_days: data.dimension_interval_days ? '' : "Please Enter Days"
            }
        } else if (data.dimension_type == "FC,FH" && !camoLeads) {
            validationInputs = {
                ...validationInputs,
                tso: data.tso ? '' : "Please Enter TSO",
                cso: data.cso ? '' : "Please Enter CSO",
                dimension_interval_fc: data.dimension_interval_fc ? '' : "Please Enter FC",
                dimension_interval: data.dimension_interval ? '' : "Please Enter Days",
            }
        } else if (data.dimension_type == "FC,FH,D" && !camoLeads) {
            validationInputs = {
                ...validationInputs,
                tso: data.tso ? '' : "Please Enter TSO",
                cso: data.cso ? '' : "Please Enter CSO",
                dimension_interval_fc: data.dimension_interval_fc ? '' : "Please Enter FC",
                dimension_interval: data.dimension_interval ? '' : "Please Enter Days",
                dimension_interval_days: data.dimension_interval_days ? '' : "Please Enter Days"
            }
        }

        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/ht/${payload.id}/`, payload)
                    .then(response => {
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response?.data?.data)

                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                globalPostService(`camo/ht/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType })
                    .then(response => {
                        setLoading(false)
                        if (checkApiStatus(response)) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                             addUploadFile(response?.data?.data)
                             importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })

            }
        } else {
            setError(validationInputs)
        }
    }

    const remainingVal = () => {
        let todayDate = new Date()
        const today = moment()
        const targetDate = moment(data.crs_date)
        if (data.dimension_type === "D") {
            const daysDifference = today.diff(targetDate, "days")
            data.remaining_val = data.dimension_interval_days - daysDifference // daysDifference => (today date - crs date)
        } else if (data.dimension_type === "FC") {
            data.remaining_val = Number.isInteger(data.dimension_interval_fc - data.cso) ? data.dimension_interval_fc - data.cso : (data.dimension_interval_fc - data.cso).toFixed(2)
        } else if (data.dimension_type === "FH") {
            data.remaining_val = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)
        } else if (data.dimension_type === "FC,D") {
            const fcRemainingVal = Number.isInteger(data.dimension_interval - data.cso) ? data.dimension_interval - data.cso : (data.dimension_interval - data.cso).toFixed(2)
            const daysDifference = today.diff(targetDate, "days")
            const dRemainingVal = data.dimension_interval_fc - daysDifference
            const fcRemainingDueAt = Number.isInteger(+fcRemainingVal + +basicdetail?.csn) ? +fcRemainingVal + +basicdetail?.csn : (+fcRemainingVal + +basicdetail?.csn).toFixed(2)
            //dueDateWithFC
            const finalFcRemDueDate = moment(todayDate, "DD-MM-YYYY").add(fcRemainingDueAt, "days")
            //dueDateWithDate
            const finalDRemDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
            if (finalDRemDueDate < finalFcRemDueDate) {
                data.remaining_val = dRemainingVal
                return data.remaining_val
            } else {
                data.remaining_val = fcRemainingVal
                return data.remaining_val
            }
        } else if (data.dimension_type === "FH,D") {
            const fhRemainingVal = Number.isInteger(
                data.dimension_interval - data.tso
            )
                ? data.dimension_interval - data.tso
                : (data.dimension_interval - data.tso).toFixed(2)

            const daysDifference = today.diff(targetDate, "days")
            const dRemainingVal = data.dimension_interval_days - daysDifference

            const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +basicdetail?.tsn) ? +fhRemainingVal + +basicdetail?.tsn : (+fhRemainingVal + +basicdetail?.tsn).toFixed(2)
            const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
            const dDueAtDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days").format("DD-MM-YYYY")
            //dueDateWithFH
            const finalFhRemDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
            //dueDateWithDate
            const finalDRemDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
            if (finalDRemDueDate < finalFhRemDueDate) {
                data.remaining_val = dRemainingVal
                return data.remaining_val
            } else {
                data.remaining_val = fhRemainingVal
                return data.remaining_val
            }
        }
        else if (data.dimension_type === "FC,FH,D") {
            const fhRemainingVal = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)
            const daysDifference = today.diff(targetDate, "days")
            const dRemainingVal = data.dimension_interval_days - daysDifference
            const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +basicdetail?.tsn) ? +fhRemainingVal + +basicdetail?.tsn : (+fhRemainingVal + +basicdetail?.tsn).toFixed(2)
            const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
            const dDueAtDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days").format("DD-MM-YYYY")
            //dueDateWithFH
            const finalFhRemDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
            //dueDateWithDate
            const finalDRemDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
            if (finalDRemDueDate < finalFhRemDueDate) {
                data.remaining_val = dRemainingVal
                return data.remaining_val
            } else {
                data.remaining_val = fhRemainingVal
                return data.remaining_val
            }
        }
        else if (data.dimension_type === "FC,FH") {
            const fhRemainingVal = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)
            const daysDifference = today.diff(targetDate, "days")
            const dRemainingVal = data.dimension_interval_days - daysDifference
            const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +basicdetail?.tsn) ? +fhRemainingVal + +basicdetail?.tsn : (+fhRemainingVal + +basicdetail?.tsn).toFixed(2)
            const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
            const dDueAtDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days").format("DD-MM-YYYY")
            //dueDateWithFH
            const finalFhRemDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
            //dueDateWithDate
            const finalDRemDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
            if (finalDRemDueDate < finalFhRemDueDate) {
                data.remaining_val = dRemainingVal
                return data.remaining_val
            } else {
                data.remaining_val = fhRemainingVal
                return data.remaining_val
            }
        }
        return data.remaining_val
    }

    const nextDueValue = () => {
        let todayDate = new Date()
        const today = moment()
        const targetDate = moment(data.crs_date)
        if (data.remaining_val === 0) {
            data.ht_DUE = ""
        } else {
            if (data.dimension_type === "D") {
                data.ht_DUE = data.remaining_val ? moment(todayDate, "DD-MM-YYYY").add(data.remaining_val, "days").format("DD-MM-YYYY") : null
            } else if (data.dimension_type === "FH") {
                data.ht_DUE = Number.isInteger(+data.remaining_val + +basicdetail?.tsn) ? +data.remaining_val + +basicdetail?.tsn : (+data.remaining_val + +basicdetail?.tsn).toFixed(2)
            } else if (data.dimension_type === "FC") {
                data.ht_DUE = Number.isInteger(+data.remaining_val + +basicdetail?.csn) ? +data.remaining_val + +basicdetail?.csn : (+data.remaining_val + +basicdetail?.csn).toFixed(2)
            }
            if (data.dimension_type === "FC,D") {
                const fcRemainingVal = Number.isInteger(data.dimension_interval - data.cso) ? data.dimension_interval - data.cso : (data.dimension_interval - data.cso).toFixed(2)
                const daysDifference = today.diff(targetDate, "days")
                const dRemainingVal = data.dimension_interval_days - daysDifference

                const fcRemainingDueAt = Number.isInteger(+fcRemainingVal + +basicdetail?.csn) ? +fcRemainingVal + +basicdetail?.csn : (+fcRemainingVal + +basicdetail?.csn).toFixed(2)

                //dueDateWithFC
                const finalFcDueDate = moment(todayDate, "DD-MM-YYYY").add(fcRemainingDueAt, "days")
                //dueDateWithDate
                const finalDDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")

                if (finalDDueDate < finalFcDueDate) {
                    data.ht_DUE = moment(finalDDueDate).format("DD-MM-YYYY")
                    return data.ht_DUE
                } else {
                    data.ht_DUE = fcRemainingDueAt
                    return data.ht_DUE
                }
            } else if (data.dimension_type === "FH,D") {
                const fhRemainingVal = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)

                const daysDifference = today.diff(targetDate, "days")
                const dRemainingVal = data.dimension_interval_days - daysDifference

                //dueDateWithDate
                const finalDDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
                //remaining+tsn=nowdate cal
                const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +basicdetail?.tsn) ? +fhRemainingVal + +basicdetail?.tsn : (+fhRemainingVal + +basicdetail?.tsn).toFixed(2)
                //dueDateWithFH
                const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
                if (finalDDueDate < finalFhDueDate) {
                    data.ht_DUE = moment(finalDDueDate).format("DD-MM-YYYY")
                    return data.ht_DUE
                } else {
                    data.ht_DUE = fhRemainingDueAt
                    return data.ht_DUE
                }
            }
            else if (data.dimension_type === "FC,FH,D") {
                const fhRemainingVal = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)

                const daysDifference = today.diff(targetDate, "days")
                const dRemainingVal = data.dimension_interval_days - daysDifference

                //dueDateWithDate
                const finalDDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
                //remaining+tsn=nowdate cal
                const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +basicdetail?.tsn) ? +fhRemainingVal + +basicdetail?.tsn : (+fhRemainingVal + +basicdetail?.tsn).toFixed(2)
                //dueDateWithFH
                const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
                if (finalDDueDate < finalFhDueDate) {
                    data.ht_DUE = moment(finalDDueDate).format("DD-MM-YYYY")
                    return data.ht_DUE
                } else {
                    data.ht_DUE = fhRemainingDueAt
                    return data.ht_DUE
                }
            }
            else if (data.dimension_type === "FC,FH,D") {
                const fhRemainingVal = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)
                const daysDifference = today.diff(targetDate, "days")
                const dRemainingVal = data.dimension_interval_days - daysDifference
                //dueDateWithDate
                const finalDDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
                //remaining+tsn=nowdate cal
                const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +basicdetail?.tsn) ? +fhRemainingVal + +basicdetail?.tsn : (+fhRemainingVal + +basicdetail?.tsn).toFixed(2)
                //dueDateWithFH
                const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
                if (finalDDueDate < finalFhDueDate) {
                    data.ht_DUE = moment(finalDDueDate).format("DD-MM-YYYY")
                    return data.ht_DUE
                } else {
                    data.ht_DUE = fhRemainingDueAt
                    return data.ht_DUE
                }
            }
        }
        return data.ht_DUE
    }


    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Update Hard Time (HT)" : " Add Hard Time (HT)"} </DialogTitle>
                <DialogContent dividers>
                    <Grid spacing={1} container>
                        <Grid item xs={4}>
                            <TextField
                                required={!camoLeads}
                                variant="outlined"
                                label="ATA Chapter"
                                id="ata_chapter"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.ata_chapter}
                                error={error.ata_chapter ? true : false}
                                helperText={error.ata_chapter ? error.ata_chapter : ""}
                                onChange={(e) => { onFieldChange("ata_chapter", e.target.value); setError({ ...error, 'ata_chapter': '' }) }}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <TextField
                                required={!camoLeads}
                                variant="outlined"
                                label="Part Number"
                                id="part_number"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.part_number}
                                error={error.part_number ? true : false}
                                helperText={error.part_number ? error.part_number : ""}
                                onChange={(e) => { onFieldChange("part_number", e.target.value); setError({ ...error, 'part_number': '' }) }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                required={!camoLeads}
                                variant="outlined"
                                label="Part Serial Number"
                                id="part_serial_number"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.part_serial_number}
                                onChange={(e) => { onFieldChange("part_serial_number", e.target.value); setError({ ...error, 'part_serial_number': '' }) }}
                                error={error.part_serial_number ? true : false}
                                helperText={error.part_serial_number ? error.part_serial_number : ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12}>
                            <TextField
                                required={!camoLeads}
                                variant="outlined"
                                label="Part Description"
                                id="part_description"
                                multiline
                                minRows={5}
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.part_description}
                                error={error.part_description ? true : false}
                                helperText={error.part_description ? error.part_description : ""}
                                onChange={(e) => { onFieldChange("part_description", e.target.value); setError({ ...error, 'part_description': '' })  }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container></Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={3}>
                            <TextField
                                required={!camoLeads}
                                variant="outlined"
                                label="HT Requirement"
                                id="ht_requirement"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.ht_requirement}
                                onChange={(e) => { onFieldChange("ht_requirement", e.target.value); setError({ ...error, 'ht_requirement': '' }) }}
                                error={error.ht_requirement ? true : false}
                                helperText={error.ht_requirement ? error.ht_requirement : ""}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                required={!camoLeads}
                                variant="outlined"
                                label="Task Card"
                                id="task_card"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.task_card}
                                onChange={(e) => { onFieldChange("task_card", e.target.value); setError({ ...error, 'task_card': '' }) }}
                                error={error.task_card ? true : false}
                                helperText={error.task_card ? error.task_card : ""}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                required={!camoLeads}
                                variant="outlined"
                                label="Component"
                                id="component"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.component}
                                onChange={(e) => { onFieldChange("component", e.target.value); setError({ ...error, 'component': '' }) }}
                                error={error.component ? true : false}
                                helperText={error.component ? error.component : ""}
                            />
                        </Grid>
                        <Grid item xs={3} className="input-calender">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required={!camoLeads}
                                    id="part_installation_date"
                                    label="Part Installation Date"
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    fullWidth
                                    margin='normal'
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, "years")}
                                    value={data.part_installation_date}
                                    error={error.part_installation_date ? true : false}
                                    helperText={error.part_installation_date ? error.part_installation_date : ""}
                                    onChange={(data, value) => { onFieldChange("part_installation_date", moment(data).format(backendDateFormat)); setError({ ...error, 'part_installation_date': '' }) }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        <span>
                                            {match.params.assetType == 1 ? " Aircraft TSN Installation" : "Engine TSN Installation"}
                                        </span>
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                id="aircraft_tsn_installation"
                                fullWidth
                                margin='normal'
                                value={data.aircraft_tsn_installation}
                                error={error.aircraft_tsn_installation ? true : false}
                                helperText={error.aircraft_tsn_installation ? error.aircraft_tsn_installation : ""}
                                onChange={(e) => { onFieldChange("aircraft_tsn_installation", e.target.value); setError({ ...error, 'aircraft_tsn_installation': '' }) }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        <span>
                                            {match.params.assetType == 1 ? "Aircraft CSN Installation" : "Engine CSN Installation"}
                                        </span>
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                id="aircraft_csn_installation"
                                fullWidth
                                margin='normal'
                                value={data.aircraft_csn_installation}
                                error={error.aircraft_csn_installation ? true : false}
                                helperText={error.aircraft_csn_installation ? error.aircraft_csn_installation : ""}
                                onChange={(e) => { onFieldChange("aircraft_csn_installation", e.target.value); setError({ ...error, 'aircraft_csn_installation': '' }) }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                label="Part TSN Installation"
                                id="part_tsn_installation"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.part_tsn_installation}
                                onChange={(e) => { onFieldChange("part_tsn_installation", e.target.value); setError({ ...error, 'part_tsn_installation': '' })  }}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                label="Part CSN Installation"
                                id="part_tsn_installation"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.part_csn_installation}
                                onChange={(e) => { onFieldChange("part_csn_installation", e.target.value); setError({ ...error, 'part_csn_installation': '' }) }}
                            />
                        </Grid>
                    </Grid>

                    <Grid spacing={1} container>
                        <Grid item xs={3}>
                            <TextField
                                required={!camoLeads}
                                variant="outlined"
                                label="Position"
                                id="position"
                                fullWidth
                                margin='normal'
                                InputLabelProps={{ shrink: true }}
                                value={data.position}
                                onChange={(e) => { onFieldChange("position", e.target.value); setError({ ...error, 'position': '' }) }}
                                error={error.position ? true : false}
                                helperText={error.position ? error.position : ""}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        <span> TSO {data.dimension_type == "FH" || data.dimension_type == "D" || data.dimension_type == "FH,D" || data.dimension_type == "FC,D" ? (<span style={{ color: "red" }}> *</span>) : null} </span>
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                id="tso"
                                fullWidth
                                margin='normal'
                                value={data.tso}
                                onChange={(e) => { onFieldChange("tso", e.target.value); setError({ ...error, 'tso': '' }) }}
                                error={error.tso ? true : false}
                                helperText={error.tso ? error.tso : ""}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        <span> CSO {data.dimension_type == "FC" || data.dimension_type == "FC,D" ? (<span style={{ color: "red" }}> *</span>) : null} </span>
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                id="cso"
                                fullWidth
                                margin='normal'
                                value={data.cso}
                                onChange={(e) => { onFieldChange("cso", e.target.value); setError({ ...error, 'cso': '' }) }}
                                error={error.cso ? true : false}
                                helperText={error.cso ? error.cso : ""}
                            />
                        </Grid>
                        <Grid item xs={3} className="input-calender">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    required={!camoLeads}
                                    id="crs_date"
                                    label="CRS Date"
                                    format={fieldDateFormat}
                                    inputVariant="outlined"
                                    margin='normal'
                                    fullWidth
                                    disableFuture
                                    InputLabelProps={{ shrink: true }}
                                    value={data.crs_date}
                                    error={error.crs_date ? true : false}
                                    helperText={error.crs_date ? error.crs_date : ""}
                                    onChange={(data, value) => { onFieldChange("crs_date", moment(data).format(backendDateFormat)); setError({ ...error, 'crs_date': '' }) }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={6} sm={6} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>INTERVAL</Grid>
                        <Grid item xs={12} md={3} sm={3} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>Remaining</Grid>
                        <Grid item xs={12} md={3} sm={3} style={{ margin: '5px 0px', fontFamily: 'Conv_IBMPlexSans-Medium', fontSize: '13px' }}>Next Due</Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item xs={12} md={3} sm={3}>
                            <Autocomplete
                                id="dimension_type"
                                options={['None', 'D', 'FH', 'FC', 'FC,D', 'FH,D', 'FC,FH,D', 'FC,FH']}
                                getOptionLabel={option => option}
                                margin='normal'
                                value={data?.dimension_type || null}
                                onChange={(e, value) => onFieldChange("dimension_type", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Dimension"
                                        placeholder="Select Dimension Interval"
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <Grid spacing={2} container>
                                {data.dimension_type == "FC" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH" || data.dimension_type == "FC,FH,D" ? <Grid
                                    item
                                    xs={data.dimension_type == "FC" ? 12 : data.dimension_type == "FC,D" || data.dimension_type == "FC,FH" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}
                                >
                                    <TextField
                                        variant="outlined"
                                        label={<span> {data.dimension_type == "FC" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH,D" || data.dimension_type == "FC,FH" ? "FC Interval" : "FC, Interval"} {smbcInstance  || camoLeads ? null : <span style={{ color: "red" }}> *</span>} </span>}
                                        name="dimension_interval"
                                        fullWidth
                                        margin='normal'
                                        value={data.dimension_interval_fc}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 5 && regexConstants.onlyNumeric.test(e.target.value)) {
                                                onFieldChange("dimension_interval_fc", input)
                                                setError({ ...error, 'dimension_interval_fc': '' })
                                            } else if (input.length > 5 && regexConstants.onlyNumeric.test(input)) {
                                                const truncatedInput = input.slice(0, 5)
                                                onFieldChange("dimension_interval_fc", truncatedInput)
                                                setError({ ...error, 'dimension_interval_fc': '' })
                                            } else {
                                                e.preventDefault()
                                            }
                                        }}
                                        InputLabelProps={{ shrink: true }}
                                        error={error.dimension_interval_fc ? true : false}
                                        helperText={error.dimension_interval_fc ? error.dimension_interval_fc : ""}
                                    />
                                </Grid> : null}
                                {data.dimension_type == "FH" || data.dimension_type == "FH,D" || data.dimension_type == "FC,FH" || data.dimension_type == "FC,FH,D" ? <Grid
                                    item
                                    xs={data.dimension_type == "FH" ? 12 : data.dimension_type == "FH,D" || data.dimension_type == "FC,FH" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}
                                >
                                    <TextField
                                        variant="outlined"
                                        label={<span> {data.dimension_type == "FH" || data.dimension_type == "FH,D" || data.dimension_type == "FC,FH,D" || data.dimension_type == "FC,FH" ? "FH Interval" : "FH, Interval"} {smbcInstance || camoLeads ? null : <span style={{ color: "red" }}> *</span>} </span>}
                                        name="dimension_interval"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                        value={data.dimension_interval}
                                        onChange={(e, value) => {
                                            const input = e.target.value
                                            if (input.length <= 5 && regexConstants.onlyNumeric.test(e.target.value)) {
                                                onFieldChange("dimension_interval", input)
                                                setError({ ...error, 'dimension_interval': '' })
                                            } else if (input.length > 5 && regexConstants.onlyNumeric.test(input)) {
                                                const truncatedInput = input.slice(0, 5)
                                                onFieldChange("dimension_interval", truncatedInput)
                                                setError({ ...error, 'dimension_interval': '' })
                                            } else {
                                                e.preventDefault()
                                            }
                                        }}
                                        error={error.dimension_interval ? true : false}
                                        helperText={error.dimension_interval ? error.dimension_interval : ""}
                                    />
                                </Grid> : null}
                                {data.dimension_type == "D" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH,D" ||
                                    data.dimension_type == "FH,D" ? (
                                    <Grid item xs={data.dimension_type == "D" ? 12 : data.dimension_type == "FC,D" || data.dimension_type == "FH,D" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}>
                                        <TextField
                                            variant="outlined"
                                            required={camoLeads ? false : smbcInstance ? false : true }
                                            label="Days"
                                            name="dimension_interval_days"
                                            fullWidth
                                            margin='normal'
                                            value={data.dimension_interval_days}
                                            onChange={(e, value) => {
                                                const input = e.target.value
                                                if (
                                                    input.length <= 5 &&
                                                    regexConstants.onlyNumeric.test(e.target.value)
                                                ) {
                                                    onFieldChange("dimension_interval_days", input)
                                                    setError({ ...error, 'dimension_interval_days': '' })
                                                } else if (
                                                    input.length > 5 &&
                                                    regexConstants.onlyNumeric.test(input)
                                                ) {
                                                    const truncatedInput = input.slice(0, 5)
                                                    onFieldChange("dimension_interval_days", truncatedInput)
                                                    setError({ ...error, 'dimension_interval_days': '' })
                                                } else {
                                                    e.preventDefault()
                                                }
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            error={error.dimension_interval_days ? true : false}
                                            helperText={error.dimension_interval_days ? error.dimension_interval_days : ""}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            <TextField
                                variant="outlined"
                                label={data.dimension_type === "D" ? "Days" : data.dimension_type === "FC" ? "FC" : data.dimension_type === "FH" ? "FH" :
                                    data.dimension_type === "FH,D" ? "FH/Days" : data.dimension_type === "FC,D" ? "FC/Days" : data.dimension_type === "FC,FH,D" ? "FC/FH/Days" : data.dimension_type === "FC,FH" ? "FC/FH" : "Days"}
                                name="remaining_val"
                                fullWidth
                                margin='normal'
                                value={remainingVal()}
                                inputProps={{ readOnly: true }}
                                InputLabelProps={{ shrink: true }}
                                disabled
                                className="disabled-input"
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sm={3}>
                            {data.dimension_type === '' ?
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        label='Date'
                                        keyParam='due_date'
                                        format={fieldDateFormat}
                                        inputVariant="outlined"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                        value={data.due_date ? data.due_date : null}
                                        onChange={(value) => { onFieldChange("due_date", moment(value).format(backendDateFormat)) }}
                                    />
                                </MuiPickersUtilsProvider> :
                                <TextField
                                    variant="outlined"
                                    label={data.dimension_type === "D" ? "Date" : data.dimension_type === "FC" ? "FC" : data.dimension_type === "FH" ? "FH" : data.dimension_type === "FH,D" ? "FH/Date" :
                                        data.dimension_type === "FC,D" ? "FC/Date" : data.dimension_type === "FC,FH,D" ? "FC/FH/Date" : data.dimension_type === "FC,FH" ? "FC/FH " : "Date"}
                                    fullWidth
                                    margin='normal'
                                    id="ad_due"
                                    value={nextDueValue()}
                                    onChange={(e, value) => onFieldChange("ad_due", e.target.value)}
                                    inputProps={{ readOnly: true }}
                                    InputLabelProps={{ shrink: true }}
                                    disabled
                                    className="disabled-input"
                                />
                            }
                        </Grid>
                    </Grid>
                    <Grid id="data-container" spacing={1} container>
                        <Grid item xs={2}>
                            <label> FAA / EASA Certificate { camoLeads ? null : <span style={{ color: "red" }}> *</span>} </label>
                            <FormControl
                                error={error.faa_easa ? true : false}
                                helperText={error.faa_easa ? error.faa_easa : ""}
                                // onFocus={() => onRestErrorKey("faa_easa")}
                            >
                                <RadioGroup
                                    row
                                    aria-label="faa_easa"
                                    name="faa_easa"
                                    defaultValue="top"
                                    value={data.faa_easa}
                                    onChange={(e, value) => onFieldChange("faa_easa", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                                {error.faa_easa && (
                                    <FormLabel component="faa_easa"> <span style={{ color: "red" }}>{error.faa_easa}</span> </FormLabel>
                                )}
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <label>Shop Report </label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="shop_report"
                                    name="shop_report"
                                    defaultValue="top"
                                    value={data.shop_report}
                                    onChange={(e, value) => onFieldChange("shop_report", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <label>Certificate of Conformity</label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="conformity_crt"
                                    name="conformity_crt"
                                    defaultValue="top"
                                    value={data.conformity_crt}
                                    onChange={(e, value) => onFieldChange("conformity_crt", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <label>Goods receipt note</label>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"></FormLabel>
                                <RadioGroup
                                    row
                                    aria-label="goods_rec_note"
                                    name="goods_rec_note"
                                    defaultValue="top"
                                    value={data.goods_rec_note}
                                    onChange={(e, value) => onFieldChange("goods_rec_note", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>

                        <Grid item xs={3}>
                            <label>
                                Installation Work order
                                {camoLeads ? null :<span style={{ color: "red" }}> *</span>}
                            </label>
                            <FormControl
                                error={error.installation_wo ? true : false}
                                helperText={error.installation_wo ? error.installation_wo : ""}
                                // onFocus={() => onRestErrorKey("installation_wo")}
                            >
                                <RadioGroup
                                    required={!camoLeads}
                                    row
                                    aria-label="installation_wo"
                                    name="installation_wo"
                                    defaultValue="top"
                                    value={data.installation_wo}
                                    onChange={(e, value) => onFieldChange("installation_wo", e.target.value)}
                                >
                                    <FormControlLabel
                                        value="yes"
                                        control={<Radio size="small" color="primary" />}
                                        label="Yes"
                                    />
                                    <FormControlLabel
                                        value="no"
                                        control={<Radio size="small" color="primary" />}
                                        label="No"
                                    />
                                    <FormControlLabel
                                        value="N/A"
                                        control={<Radio size="small" color="primary" />}
                                        label="N/A"
                                    />
                                </RadioGroup>
                                {error.installation_wo && (
                                    <FormLabel component="installation_wo">
                                        <span style={{ color: "red" }}> {error.installation_wo} </span>
                                    </FormLabel>
                                )}
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
						<Grid item xs={12} md={6} sm={6}>
							<TextField
								label="Remarks"
								margin='normal'
								variant='outlined'
								fullWidth
								value={data.remark}
								InputLabelProps={{ shrink: true }}
								onChange={(e) => onFieldChange("remark", e.target.value)}
								rows={3}
								multiline
							/>
						</Grid>
						<Grid item xs={6}>
							<div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
								<span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
								<span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
							</div>
						</Grid>
                        <div className="checklist-file" style={{ overflow: "auto" }}>
                        {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
                            <ul className='list-inline'>
                                {(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
                                    return (
                                        <li className='list-inline-item'>
                                            <span className='file-name'>{attachments.name}</span>
                                            <span className='file-remove'>
                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
                                                <CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                            </span>
                                        </li>)
                                }

                                )}
                                {(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
                                    data.checklist?.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        {data?.checklist.some(item => item.type === 'folder') ? null
                                                            : <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.files?.length > 0) &&
                                    data.checklist?.files.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.folders?.length > 0) &&
                                    data.checklist?.folders.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                    <a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                </Tooltip>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
					</Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {uploadFile.modal ?
                <UploadFile
                    modType="ht"
                    type="upload"
                    list={list}
                    actionType={modeType}
                    editFormId={data?.id}
                    handleClose={() => setUploadFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

                />
                : null}
            {linkFile.modal ?
                <LinkFiles
                    modType="ht"
                    type="link"
                    list={list}
                    editFormId={data?.id}
                    actionType={modeType}
                    uploadFileInfo={data}
                    toggleModalFn={() => setlinkFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    onLinkFile={getData}
                />
                : null
            }
            {isLoading ? <PageLoader /> : null}
        </>
    )

}
export default withRouter(AddEditHt)
