import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { colors, Grid, Paper } from '@material-ui/core';
import { globalGetService } from '../../../utils/globalApiServices';
import Highcharts from 'highcharts';
import { Skeleton } from '@material-ui/lab';
import { PageLoader} from "../../../shared_elements"
import STableLoader from '../../../shared_elements/components/skeleton_loader/STableLoader';
import HighchartsReact from 'highcharts-react-official';
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/no-data-to-display')(Highcharts);

const DashboardAnalytics = () => {
    const [pageLoader, setPageLoader] = useState(false);
    const [skeltonLoader, setSkeltonLoader] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [llpData, setLLPData] = useState(null); // Track chart data

    useEffect(() => {
        smbcChartList();
        smbcChartLLPLimiter()
    }, []);

    const smbcChartList = (query, loader) => {
        loader === "pageLoader" ? setPageLoader(true) : setSkeltonLoader(true);
        globalGetService(`/camo/smbc_dashboard/analytics/`, { ...query })
            .then((response) => {
                setChartData(response.data); // Assume response contains the data
                loader === "pageLoader" ? setPageLoader(false) : setSkeltonLoader(false);
            }).catch((error) => {
                console.error('Error fetching chart data:', error);
                setPageLoader(false);
                setSkeltonLoader(false);
            });
    }
    const smbcChartLLPLimiter = (query, loader) => {
        loader === "pageLoader" ? setPageLoader(true) : setSkeltonLoader(true);
        globalGetService(`camo/smbc_dashboard/limiter/`, { ...query })
            .then((response) => {
                setLLPData(response.data);
                loader === "pageLoader" ? setPageLoader(false) : setSkeltonLoader(false);
            }).catch((error) => {
                console.error('Error fetching chart data:', error);
                setPageLoader(false);
                setSkeltonLoader(false);
            });
    }
    console.log("llpData--", llpData?.asset_limiter)
    const options = {
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 45,
            },
        },
        title: {
            text: 'Lease Status of the Assets',
        },
        colors: ["#ffb98d", "#beed87", "#89bfff", "#d7d7d7", "#729cf1"],
        plotOptions: {
            pie: {
                innerSize: 100,
                depth: 45,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return `${this.point.name}: (${this.y})`;
                    },
                },
                showInLegend: true,
            },
        },
        series: [
            {
                name: 'Count',
                data: chartData?.engine_status.map(item => [item?.status, item?.count]),
                colorByPoint: true,
            },
        ],
        credits: {
            enabled: false,
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: ['downloadPNG', 'downloadPDF'],
                },
            },
        },
    }
    const option = {
        chart: {
            type: 'column'
        },
        colors:['#7CB5EC','#E4D00A'],
        title: {
            text: ' Engines and HPT Limiter,LLP Limiter',
            align: 'center'
        },
        xAxis: {
            categories: llpData?.asset_limiter.map(item => [item?.engine_type]),
            crosshair: true,
            accessibility: {
                description: 'Engines'
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [
            {
                name: 'HPT Blade Limiter',
                data: llpData?.asset_limiter.map(item => [item?.hpt_blade_limit])
            },
            {
                name: 'Current Limiter',
                data: llpData?.asset_limiter.map(item => [item?.current_limiter])
            }
        ],
        credits: {
            enabled: false,
        },
    };

    return (
        <section>
            <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                    {skeltonLoader ? (
                        <Skeleton variant="rect" animation='wave' height={304} />
                    ) :
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    }
                </Grid>
                <Grid item xs={12} sm={6}>
                    {skeltonLoader ? (
                        <Skeleton variant="rect" animation='wave' height={304} />
                    ) :
                        llpData?.asset_limiter.length > 0 ?
                            <HighchartsReact highcharts={Highcharts} options={option} />
                            : <PageLoader />
                    }
                </Grid>
            </Grid>
        </section >
    );
};

export default withRouter(DashboardAnalytics);
