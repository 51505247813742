import DesktopSideNav from './components/DesktopSideNav';
import DesktopHeader from './components/DesktopHeader';
import MobileHeader from './components/MobileHeader';
import MobileSecMenu from './components/MobileSecMenu';
import MntSideNav from './components/secondary_sidenav/MntSideNav';
import TableListComp from './components/TableListComp';
import LabelValueCard from './components/LabelValueCard';
import TableRowActions from './components/TableRowActions';
import EmptyCollection from './components/EmptyCollection';
import FilterUIComp from './components/FilterUIComp';
import ExportManu from './components/ExportManu';
import PageLoader from './components/PageLoader';
import DeletePopUp from './components/DeletePopUp';
import AutocompleteCreatable from './components/AutocompleteCreatable';
import WorksScopeTable from './components/WorksScopeTable';
import AssetSwitcher from './components/AssetSwitcher';
import PageNotFound from './components/PageNotFound';
import AccessDenied from './components/AccessDenied';
import LicenseFailModal  from './components/LicenseFailModal';
import SideNav from './components/SideNav';
import ExportMenu from './components/ExportMenu';
import { getLocalStorageInfo } from '../utils';
import appConfig from '../config';
export {
  DesktopSideNav,
  DesktopHeader,
  MobileHeader,
  MobileSecMenu,
  MntSideNav,
  TableListComp,
  LabelValueCard,
  TableRowActions,
  EmptyCollection,
  FilterUIComp,
  ExportManu,
  PageLoader,
  DeletePopUp,
  AutocompleteCreatable,
  WorksScopeTable,
  AssetSwitcher,
  PageNotFound,
  AccessDenied,
  LicenseFailModal,
  SideNav,
  ExportMenu
}
export const halcyonInstance = appConfig.env.key === "api" ? [483,484].includes(getLocalStorageInfo()?.defaultLessor?.id) :  [539,540].includes(getLocalStorageInfo()?.defaultLessor?.id);