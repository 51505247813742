import React,{useState} from 'react'
import { TableBody, TableCell, TableRow, Tooltip, IconButton } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import RevisionHistory from '../../../../shared_elements/components/RevisionHistory';
import AttachFileIcon from "@material-ui/icons/AttachFile";
const ShopVisitListing = ({ item, toggleModalFn, toggleDelteModalFn, handleOpenAttachments }) => {
	const [lessMore, setLessMore] = useState({});

	const toggleLessMore = (id) => {
		setLessMore({
			...lessMore,
			[id]: !lessMore[id],
		});
	};

	const lessMoreFn = (name) => {
		return <>
			{name && name.length > 22 ? (
				<>
					{lessMore[name] ?
						(<> {name} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..less </span> </>)
						:
						(<>{name.substring(0, 22)} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..more </span> </>)
					}
				</>
			) : name || '--'
			}
		</>
	}
	let svUrl = `audit/camo/shopvisit/?object_id=${item.id}&action=0,1&timezone=${moment().format()}&timestamp=${Math.floor(Date.now() / 1000)}`
	function getAttachmentCount(item) {
        const { attachments, checklist } = item;
        const { files, folders } = checklist || {};
      
        const attachmentCount = attachments?.length || 0;
        const fileCount = files?.length || 0;
        const folderCount = folders?.length || 0;
      
        if (attachmentCount && fileCount && folderCount) {
          return attachmentCount + fileCount + folderCount;
        } else if (fileCount && folderCount) {
          return fileCount + folderCount;
        } else if (attachmentCount && folderCount) {
          return attachmentCount + folderCount;
        } else if (attachmentCount && fileCount) {
          return attachmentCount + fileCount;
        } else {
          return folderCount || fileCount || attachmentCount || '--';
        }
      }
    const attachmentCount = getAttachmentCount(item);
	return (
		<TableRow>
			<TableCell>
				<Tooltip title="Edit" placement='top'>
					<IconButton style={{ padding: 'unset' }} color='primary' onClick={toggleModalFn} > <CreateOutlinedIcon /> </IconButton>
				</Tooltip>
				<Tooltip title="Delete" placement='top' >
					<IconButton style={{ padding: 'unset' }} color='secondary' onClick={toggleDelteModalFn}> <DeleteOutlinedIcon /> </IconButton>
				</Tooltip>
				<RevisionHistory buttonType={true} url={svUrl} />
			</TableCell>
			<TableCell>{item.removal_date ? item.removal_date : "--"}</TableCell>
			<TableCell>{lessMoreFn(item.removal_reason)} </TableCell>
			<TableCell>{item.tsn_at_removal ? item.tsn_at_removal : "--"}</TableCell>
			<TableCell>{item.csn_at_removal ? item.csn_at_removal : "--"}</TableCell>
			<TableCell>{item.sv_completion_date ? item.sv_completion_date : "--"} </TableCell>
			<TableCell>{item.sv_location_mro ? item.sv_location_mro : "--"}</TableCell>
			<TableCell>{item.sv_workscope ? item.sv_workscope : "--"} </TableCell>
			<TableCell>{item.sv_type ? item.sv_type : "--"} </TableCell>
			<TableCell>{lessMoreFn(item.remarks)} </TableCell>
			<TableCell>
                {attachmentCount && (
                    <Tooltip title="View Attachments">
                        <span className="center">
                            <AttachFileIcon className="attach-icon" onClick={handleOpenAttachments} />
                            <a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
                        </span>
                    </Tooltip>
                )}
            </TableCell>
		</TableRow>
	)
}

export default withRouter(ShopVisitListing)