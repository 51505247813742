import React from "react";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import noRecodImage from "../../assets/images/no-results.png";
const EmptyCollection = ({ icon, title, description, buttonText, onAddClick,
}) => {
	return (
		<div className="empty-collection-ui">
			<img src={noRecodImage} alt="" />
			{title ? <h4>{title}</h4> : null}
			{description ? <p>{description}</p> : null}
			{buttonText ? (
				<Button
					variant="contained"
					color="primary"
					startIcon={<AddIcon />}
					onClick={onAddClick}
				>
					{buttonText}
				</Button>
			) : null}
		</div>
	);
};

export default EmptyCollection;
