import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
const OccmHeader = ({ sortInfo, createSortHandler }) => {
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }
    return (
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
                <TableCell>Action </TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('ata_chapter', 'ATA Chapter')} </TableCell>
                <TableCell style={{ minWidth: "250px" }}>{tableSorting('part_number', 'Part Number')}</TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('part_serial_number', 'Serial Number')} </TableCell>
                <TableCell style={{ minWidth: "250px" }}>{tableSorting('part_description', 'Description')} </TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('part_installation_date', 'Installation Date')} </TableCell>
                <TableCell style={{ minWidth: "200px" }}>{tableSorting('aircraft_tsn', 'Aircraft TSN at Installation')}</TableCell>
                <TableCell style={{ minWidth: "200px" }}>{tableSorting('aircraft_csn', 'Aircraft CSN at Installation')}</TableCell>
                <TableCell style={{ minWidth: "200px" }}>{tableSorting('part_tsn', 'Part TSN at Installation')}</TableCell>
                <TableCell style={{ minWidth: "200px" }}>{tableSorting('part_csn', 'Part CSN at Installation')}</TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('occm_requirement', 'OCCM Requirement')}</TableCell>
                <TableCell style={{ minWidth: "250px" }}>{tableSorting('remark', 'Remarks')}</TableCell>
                <TableCell>Attachments </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default OccmHeader