import AmpListing from './AmpListing'
import AddEditAmp from './AddEditAmp'
import AmpTableHeader from './AmpTableHeader'
export {
    AmpListing,
    AddEditAmp,
    AmpTableHeader
}

export const ampObj = {
    asset_id: null,
    asset_type_id: null,
    MPD_item_no: null,
    task_card_no: null,
    previous_LDND_Taskcard: null,
    Task_Description: "",
    threshold_D: null,
    threshold_FH: null,
    threshold_FC: null,
    interval_D: null,
    interval_FH: null,
    interval_FC: null,
    lastDone_D: null,
    lastDone_FH: null,
    lastDone_FC: null,
    remainingValues_D: null,
    remainingValues_FH: null,
    remainingValues_FC: null,
    remarks: null,
    workOrder: "",
    attachments: [],
    nextDue_D: null,
    nextDue_FH: null,
    nextDue_FC: null,
    checklist: [],
    folder_uuid: '',
    linkFile:''
}