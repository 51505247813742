import AccessDenied from './AccessDenied'
import AppliedFilter from './AppliedFilter'
import AssetSwitcher from './AssetSwitcher'
import AutocompleteCreatable from './AutocompleteCreatable'
import CamoMgmtDropdown from './CamoMgmtDropdown'
import CkEditorText from './CkEditorText'
import DangerButton from './DangerButton'
import DeletePopUp from './DeletePopUp'
import DesktopHeader from './DesktopHeader'
import DesktopSideNav from './DesktopSideNav'
import DownloadPdf from './DownloadPdf'
import DownloadPDFAssetDetail from './DownloadPDFAssetDetail'
import DownloadXlsx from './DownloadXlsx'
import EmptyCollection from './EmptyCollection'
import ExportManu from './ExportManu'
import ExportMenu from './ExportMenu'
import FAQsList from './FAQsList'
import FilterUIComp from './FilterUIComp'
import HeaderNotifyMenu from './HeaderNotifyMenu'
import HtIntervalDropdown from './HtIntervalDropdown'
import LabelValueCard from './LabelValueCard'
import LicenseFailModal from './LicenseFailModal'
import LinkFiles from './LinkFiles'
import MainNavLink from './MainNavLink'
import ImportFileFormat from './ImportFileFormat'
import BasicTopHeader from './BasicTopHeader'

import appConfig from "../../config";
import { getLocalStorageInfo } from "../../utils";

export {
	AccessDenied,
	AppliedFilter,
	AssetSwitcher,
	AutocompleteCreatable,
	CamoMgmtDropdown,
	CkEditorText,
	DangerButton,
	DeletePopUp,
	DesktopHeader,
	DesktopSideNav,
	DownloadPdf,
	DownloadPDFAssetDetail,
	DownloadXlsx,
	EmptyCollection,
	ExportManu,
	ExportMenu,
	FAQsList,
	FilterUIComp,
	HeaderNotifyMenu,
	HtIntervalDropdown,
	LabelValueCard,
	LicenseFailModal,
	LinkFiles,
	MainNavLink,
	ImportFileFormat,
	BasicTopHeader


}


export const halcyonInstance = ['api','uat'].includes(appConfig.env.key) ? [483,484].includes(getLocalStorageInfo()?.defaultLessor?.id) :  [539,540].includes(getLocalStorageInfo()?.defaultLessor?.id);
