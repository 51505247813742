import SoftTimeHeader from './SoftTimeHeader'
import SoftTimeListing from './SoftTimeListing'
import AddEditSoftTIme from './AddEditSoftTIme'
export {
    SoftTimeHeader,
    SoftTimeListing,
    AddEditSoftTIme

}
export const softTimeObj = {
    part_number: '',
    serial_number: '',
    description: '',
    life_limit: '',
    tsn: '',
    csn: '',
    installation_date: null,
    tsn_installation: '',
    csn_installation: '',
    engine_tsn_installation: '',
    engine_csn_installation: '',
    remarks: '',
    folder_uuid: '',
    attachments: [],
    checklist: [],
    linkFile:''
}