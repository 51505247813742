import UtilizationHeader from "./UtilizationHeader";
import UtilizationListing from "./UtilizationListing";
import AddEditUtilization from "./AddEditUtilization";
export {
    UtilizationHeader,
    UtilizationListing,
    AddEditUtilization

}
export const utilizationObj={
    utilization_period : null, 
    tsn: null, 
    csn: null, 
    monthly_FH : '', 
    monthly_FC :'',
    monthly_derate:null,
    engine_stand_location:'',
    current_operating_thrust:null,
    operating_thrust_current :'', 
    location_reg_aircraft :'',
    location_position :'', 
    location_country :'', 
    date_of_installation : null, 
    date_of_removal : null, 
    reason_of_removal : '', 
    attachments:[], 
    linkFile:'',
    operating_environment:''
}