import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
const ConfigureTableHeader = ({ sortInfo, createSortHandler }) => {
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }
    return (

        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }} className="fleet-ad-header">
            <TableRow>
                <TableCell> {tableSorting('revision_number', 'Revision Number')} </TableCell>
                <TableCell> {tableSorting('engine_family', 'Engine Family')} </TableCell>
                <TableCell> {tableSorting('engine_type', 'Engine Type')} </TableCell>
                <TableCell> {tableSorting('disclemer_message', 'Disclaimer Message')} </TableCell>
                <TableCell> {tableSorting('status', 'Status')} </TableCell>
                <TableCell>Action </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default ConfigureTableHeader