import { globalDeleteService, globalGetService, globalExportService, globalPostService, globalFileUploadService, globalPutService, } from "../../../utils/globalApiServices"
import { getLocalStorageInfo, downloadFileType, checkApiStatus, permissionCheckFnforCamo, } from "../../../utils"
import { trackActivity } from "../../../utils/mixpanel"
import moment from "moment"
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

let timeoutId

export function getSrApi(
	queryParams = {},
	loaderType,
	props = {},
	searchString = null
) {
	if (
		permissionCheckFnforCamo({
			primaryKey: "repair",
			keyIndex: "R",
		})
	) {
		this.setState({ pageLoader: true })
		const { asset, assetType } = props.match.params

		// Clear the previous timeout to cancel the previous API request
		clearTimeout(timeoutId)

		// Set a new timeout to call the API after 500ms
		timeoutId = setTimeout(() => {
			globalGetService(`camo/repair/list/`, {
				...queryParams,
				per_page: queryParams.per_page ? queryParams.per_page : 50,
				asset: asset,
				asset_type: assetType,
				search: searchString,
			}).then((response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					})
				} else {
					this.setState({
						pageLoader: false,
						assetsDetail: {
							list: response.data.results,
						},
					})
					delete queryParams.page
					delete queryParams.per_page
					delete queryParams.asset_type
					delete queryParams.sort
					delete queryParams.sort_by
					this.setState((prevState) => ({
						...prevState,
						filter: queryParams,
						assetsDetail: {
							...prevState.assetsDetail,
							//list: response.data.results,
							pagination: {
								...prevState.assetsDetail.pagination,
								total: response.data.pagination.total,
								per_page: response.data.pagination.per_page,
								page: parseInt(response.data.pagination.current_page),
							},
							new_task_last_used_folder_uuid: response.data.new_task_last_used_folder_uuid,
							last_used_folder_uuid_link_document: response.data.last_used_folder_uuid_link_document,
							checkbox_last_used_folder_uuid: response.data.checkbox_last_used_folder_uuid,
							checkbox_last_used_folder_name: response.data.checkbox_last_used_folder_name
						},
					}))
				}
			})
		}, 500)
	}
}

export function deleteAssetDetailRow(item, props, per_page) {
	globalDeleteService(`camo/repair/${item.id}/delete/`).then((response) => {
		if (response.status === 200) {
			props.enqueueSnackbar("Record Deleted Successfully", {
				variant: "success",
				anchorOrigin: { vertical: "top", horizontal: "right" },
			})
			trackActivity('CAMO', {
				event_type: 'Item Deleted',
				page_title: 'Asset Detail Row',
				item_id: item.id ? item.id : ''
			})
		}
		if (response.status === 500) {
			props.enqueueSnackbar("Something went wrong.", {
				variant: "error",
				anchorOrigin: { vertical: "top", horizontal: "right" },
			})
		}
		this.getSrApi({ per_page: per_page }, "skeletonLoader", props)
	})
}

export function addTaskFormApi(data, props) {
	if (
		permissionCheckFnforCamo({
			primaryKey: "repair",
			keyIndex: "C",
		})
	) {
		return new Promise(function (resolve, reject) {
			globalPostService(`camo/repair/create/`, data).then((response) => {
				// if (response.status === 500) {
				if (checkApiStatus(response)) {
					resolve(response)
				} else {
					props.enqueueSnackbar("Something went wrong.", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					})
				}
			})
		})
	}
}

export function fillEditFromApi(item, props) {
	return new Promise(function (resolve, reject) {
		globalGetService(`camo/repair/view/${item.id}/`).then((response) => {
			if (response.status === 500) {
				props.enqueueSnackbar("Something went wrong.", {
					variant: "error",
					anchorOrigin: { vertical: "top", horizontal: "right" },
				})
			} else {
				resolve(response)
			}
		})
	})
}

export function UpdateTaskFormApi(updateId, updatedData, props, per_page) {
	if (
		permissionCheckFnforCamo({
			primaryKey: "repair",
			keyIndex: "U",
		})
	) {
		this.setState({ pageLoader: true })
		globalPutService(`camo/repair/${updateId}/update/`, updatedData).then(
			(response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					})
				} else {
					if (response) {
						this.setState({ pageLoader: false })
						props.enqueueSnackbar("Record Updated Successfully", {
							variant: "success",
							anchorOrigin: { vertical: "top", horizontal: "right" },
						})
					}
					this.getSrApi({ per_page: per_page }, "skeletonLoader", props)
				}
			}
		)
	}
}

export function uploadFileApi(
	uploadId,
	formdata,
	props,
	per_page,
	haveAttachments
) {
	this.setState({ pageLoader: true })
	if (haveAttachments) {
		globalFileUploadService(`camo/attachment/upload/`, formdata)
			.then((response) => {
				if (response.status === 500) {
					props.enqueueSnackbar("Something went wrong.", {
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					})
				} else {
					if (response.status === 200) {
						this.setState({ pageLoader: false })
						if (response.data.data.length > 0) {
							//   props.enqueueSnackbar( "Data and Attachment Submitted Successfully", { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, } )
						}
					}
					this.getSrApi({ per_page: per_page }, "skeletonLoader", props)
				}
			})
			.catch((err) => {
				console.error(err)
			})
	} else {
		props.enqueueSnackbar("Data Submitted Successfully", {
			variant: "success",
			anchorOrigin: { vertical: "top", horizontal: "right" },
		})
		this.getSrApi({ per_page: per_page }, "skeletonLoader", props)
	}
}

export function exportAssetDetailListApi(
	queryParams = {},
	loaderType,
	props = {}
) {
	this.setState({ pageLoader: true })
	const date = new Date()
	const todayeDate = moment(String(date)).format("DD-MM-YYYY")

	const { asset, assetType } = this.props.match.params
	delete queryParams.asset_type
	globalExportService(`/camo/repair/${asset}/${assetType}/export/`, {
		...queryParams,
	}).then((response) => {
		if (response.status === 500) {
			props.enqueueSnackbar("Something went wrong.", {
				variant: "error",
				anchorOrigin: { vertical: "top", horizontal: "right" },
			})
		} else {
			this.setState({ pageLoader: false })
			downloadFileType(response.data, `SR_${todayeDate}`, queryParams.download)
			this.props.enqueueSnackbar("Download of SR List successful", {
				variant: "success",
				anchorOrigin: { vertical: "top", horizontal: "right" },
			})
			trackActivity("CAMO", { event_type: "Item Exported", page_title: "Asset Listing", extension: queryParams.download })
		}
	})
}

export function deleteAttachment(id, props) {
	//this.setState({ pageLoader: true })
	globalDeleteService(`camo/attachment/${id}/delete/`).then((response) => {
		if (response.status === 500) {
			props.enqueueSnackbar("Something went wrong.", {
				variant: "error",
				anchorOrigin: { vertical: "top", horizontal: "right" },
			})
		} else {
			if (response) {
				//this.setState({ pageLoader: false })
				props.enqueueSnackbar("Attachment Deleted Successfully", {
					variant: "success",
					anchorOrigin: { vertical: "top", horizontal: "right" },
				})
				trackActivity("CAMO", { event_type: "Attachment Deleted", page_title: "Service Repairs(SR)", item_id: id })
			}
		}
	})
}

export function importFileApi(formData, props, isXLSX, per_page) {
	if (isXLSX) {
		this.setState({ pageLoader: true })
		const { asset, assetType } = this.props.match.params
		globalFileUploadService(
			`camo/repair/${asset}/${assetType}/import/`,
			formData
		)
			.then((response) => {
				this.setState({ pageLoader: false })
				if (response?.data?.statusCode === 200) {
					props.enqueueSnackbar(response?.data?.message, { variant: "success", anchorOrigin: { vertical: "top", horizontal: "right" }, })
					trackActivity("CAMO", { event_type: 'File Imported', page_title: "Service Repairs(SR)", asset_type: assetType ? assetType : '', asset: asset ? asset : '', form_data: formData ? formData : {} })
				} else {
					props.enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				}
				this.getSrApi({ per_page: per_page }, "skeletonLoader", props)

			})
			.catch((err) => {
				console.error(err)
				props.enqueueSnackbar("Please import proper file", {
					variant: "error",
					anchorOrigin: { vertical: "top", horizontal: "right" },
				})
			})
	} else {
		props.enqueueSnackbar("Please Import only .XLSX File", {
			variant: "error",
			anchorOrigin: { vertical: "top", horizontal: "right" },
		})
	}
}

export function DownloadSampleFile(queryParams = {}, props = {}) {
	globalExportService(`camo/repair/sample-file/`).then((response) => {
		if (response.status === 500) {
			props.enqueueSnackbar("Something went wrong.", {
				variant: "error",
				anchorOrigin: { vertical: "top", horizontal: "right" },
			})
		} else {
			downloadFileType(response.data, `SampleFile_SR`, queryParams.download)
			trackActivity("CAMO", { event_type: 'Sample File Downloaded', page_title: "Service Repairs(SR)", extension: queryParams.download })
		}
	})
}
export async function DownloadAllApi(queryParams = {}, loaderType, props = {}) {
	this.setState({ pageLoader: true })
	const attachment_list = queryParams.item.download_attachments;
	for (var i = 0; i < attachment_list.length; i++) {
		var encodedURL = attachment_list[i][1];
		var decodedURL = decodeURIComponent(encodedURL);
		attachment_list[i][1] = decodedURL;
	}
	const zip = new JSZip();
	const folder = zip.folder('DownloadedFiles');
	try {
		const downloadPromises = attachment_list.map(async (entry) => {
			const name = entry[0];
			const url = entry[1] || '';

			const res = await fetch(url);

			if (!res.ok) {
				throw new Error(`HTTP error! Status: ${res.status}`);
			}

			const blob = await res.blob();
			folder.file(name, blob);
		});

		await Promise.all(downloadPromises);

		const content = await zip.generateAsync({ type: 'blob' });
		saveAs(content, 'SR.zip');
		this.setState({ pageLoader: false })
	} catch (error) {
		console.error('Error:', error);
	}
}