import React, { useState, useEffect } from 'react';
import { EditBasicDetail, BasicDetailsList, TechSpecList, EditTechSpec } from '../components'
import { globalGetService, globalPostService, } from '../../../../utils/globalApiServices';
import { checkApiStatus } from '../../../../utils';
import { withRouter } from "react-router-dom";

const BasicDetail = ({ match, moduleType,BasicDetailData ,basicdetail ,techSpec_Data,utilisationData}) => {
	const [basicDetailData, setBasicDetailData] = useState()
	const [techSpecData, setTechSpecData] = useState({})
	const [editBasicDetail, setEditBasicDetail] = useState({ modal: false, asset_type: null })
	const [editTechSpec, setEditTechSpec] = useState({ modal: false, asset_type: null })
	const [aircraftTypes, setAircraftTypes] = useState([]);
	const [engineTypes, setEngineTypes] = useState([]);
	const [lessee, setLessee] = useState([]);
	const [operators, setOperators] = useState([]);
	const [owners, setOwners] = useState([]);
	const [pltConstants, setPltConstants] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				await getBasicDetailApi();
				getAircraftTypes();
				getEngineTypes();
				getLessee();
				getPlatformConstants();
				getOperatorsList();
				getOwnersPortfolioList();
			} catch (error) {
				console.error("Error in useEffect fetchData:", error);
			}
		};
		fetchData();
	}, []);


	const getTechSpecDetailApi = (props) => {
		globalGetService(`camo/engine_tech_spec/${props?.slug ? props?.slug : basicDetailData?.slug}/details/`)
			.then(response => {
				if (checkApiStatus(response)) {
					setTechSpecData(response.data.data);
				}
			})
	}
	const getBasicDetailApi = async () => {
		try {
			const response = await globalGetService(`camo/asset/${match.params.asset}/${match.params.assetType}/view/?module=${moduleType}`);
			if (checkApiStatus(response)) {
				setBasicDetailData(response.data.data);
				BasicDetailData(response.data.data)
				if (match?.params?.assetType == 2) {
					await getTechSpecDetailApi(response.data.data);
					
				}
			}
		} catch (error) {
			console.error("Error in getBasicDetailApi:", error);
		}
	};
	const getAircraftTypes = () => {
		globalGetService(`console/aircraft-types/`)
			.then(response => {
				if (checkApiStatus(response)) {
					setAircraftTypes(response.data.data.aircraftTypes);
				}
			})
	}
	const getEngineTypes = () => {
		globalGetService(`console/engine-types/`)
			.then(response => {
				if (checkApiStatus(response)) {
					setEngineTypes(response.data.data.engineTypes)
				}
			})
	}
	const getLessee = () => {
		globalGetService(`console/lessees/?distinct=1`)
			.then(response => {
				if (checkApiStatus(response)) {
					setLessee(response.data.data.lessees)
				}
			})
	}

	const getOperatorsList = () => {
		globalGetService('console/operators/')
			.then(response => {
				if (checkApiStatus(response)) {
					setOperators(response.data.data.operators);
				}
			})
	}
	const getOwnersPortfolioList = () => {
		globalGetService('console/owner/')
			.then(response => {
				if (checkApiStatus(response)) {
					setOwners(response.data.data);
				}
			})
	}
	const getPlatformConstants = () => {
		globalPostService('console/get-constants/', { constant_types: ['asset_status', 'lease_status', 'asset_sub_status', 'credit_classification', 'engine_position', 'lg_position'] })
			.then(response => {
				if (checkApiStatus(response)) {
					setPltConstants(response.data.data)
				}
			})
	}

	return (
		<section className='basic-detail-components'>
			<BasicDetailsList
				basicDetailData={ basicdetail ? basicdetail : basicDetailData}
				toggleModalFn={() => setEditBasicDetail({ modal: true, asset_type: null })}

			/>
			{match.params.assetType == 2 ?
				<TechSpecList
					item={techSpec_Data ? techSpec_Data :techSpecData}
					toggleModalFn={() => setEditTechSpec({ modal: true })}
					utilisationData={utilisationData}
				/>
				: null
			}
			{editBasicDetail.modal ?
				<EditBasicDetail
					basicDetailData={basicDetailData}
					aircraftTypes={aircraftTypes}
					pltConstants={pltConstants}
					lessee={lessee}
					operators={operators}
					owners={owners}
					engineTypes={engineTypes}
					toggleModalFn={() => setEditBasicDetail({ modal: false, asset_type: null })}
					getResponseBack={() => getBasicDetailApi()}
				/> : null
			}
			{editTechSpec.modal && match.params.assetType == 2 ?
				<EditTechSpec
					toggleModalFn={() => setEditTechSpec({ modal: false })}
					basicDetailData={basicDetailData}
					techSpecData={techSpecData}
					getResponseBack={() => getTechSpecDetailApi()}
					utilisationData={utilisationData}

				/> : null
			}

		</section>
	)
}

export default withRouter(BasicDetail)