import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Tooltip, } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import Autocomplete from "@material-ui/lab/Autocomplete"
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import config from "../../../../config";
import { getLocalStorageInfo } from "../../../../utils";
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus } from '../../../../utils';
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { mcObj } from '.';
import { assetType, imgStoragePath } from "../../../../constants";
import { specificKey, specificMessTsn, specificMessCsn } from '../..';

function AddEditMasterComp({ match, toggleModalFn, mcData, basicdetail, modeType, getResponseBack, list }) {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [data, setData] = useState(mcData ? mcData : mcObj)
	const [error, setError] = useState({})
	const [isLoading, setLoading] = useState(false)
	const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
	const [linkFile, setlinkFile] = useState({ modal: false, data: null })

	const onFieldChange = (keyParam, value) => {
		setData({ ...data, [keyParam]: value });
	}
	const uploadFileData = (file) => {
		setData({ ...data, checklist: file })
	}

	const getData = (selectedFile, type) => {
		setData({ ...data, checklist: selectedFile, linkFile: type });
	}

	const uploadFileFn = (file, uuid, type) => {
		if (uuid) {
			let storeChecklistData = [];
			let folderUuid = [];

			const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
			const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

			for (const arr of updatedChecklist) {
				storeChecklistData = storeChecklistData.concat(arr);
			}
			for (const arr of folderUUID) {
				folderUuid = folderUuid.concat(arr);
			}
			setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
		} else {
			if (Array.isArray(file)) {
				const updatedAttachments = [...(data?.attachments || []), ...file];
				setData({ ...data, 'attachments': updatedAttachments });
			} else {
				console.error("Value for attachments is not an array:", file);
			}
		}
	}
	const addUploadFile = (item) => {
		if (data?.linkFile === "link") {
			let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
			globalPutService(`camo/mc/${item?.id}/document-checklists/`, payload)
				.then(response => {
					if (checkApiStatus(response)) {
						toggleModalFn()
						getResponseBack(response.data.data);
						enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					} else {
						enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
		if (data?.folder_uuid?.length > 0) {
			if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
				let formData = new FormData();
				formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
				for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
					formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
				}
				globalPutService(`camo/mc/${item?.id}/document-checklists/`, formData)
					.then(response => {
						if (checkApiStatus(response)) {
							getResponseBack()
						} else {
							enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
						}
					});
			}
		}

	}
	const importFile = (file) => {
		var formData = new FormData();
		Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
			formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
			formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
			formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
			formData.append("[" + key + "]module_id", file?.id)
			formData.append("[" + key + "]module_type_id", 16)
			formData.append("[" + key + "]asset_type_id", match.params.assetType)
			formData.append("[" + key + "]asset_id", match.params.asset)
		})
		globalFileUploadService(`camo/attachment/upload/`, formData)
			.then(response => {
				toggleModalFn(); getResponseBack()
				if (checkApiStatus(response)) {
				} else {
					enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
				}
			})

	}
	const onRemoveFile = (file, type, fileId, folder) => {
		if (modeType === "add") {
			if (type === "checklist") {
				setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
			} else {
				setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
			}

		} else {
			if (type === "checklist") {
				let payload = {};
				payload.delete = true;
				payload.folder_ids =data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
			    payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
				globalPutService(`camo/mc/${data.id}/document-checklists/`, payload)
					.then((response) => {
						if (checkApiStatus(response)) {
							const updatedChecklist = { ...data.checklist };
							updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                            updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
							setData({ ...data, checklist: updatedChecklist });
							getResponseBack()
						}
					});
			} else {
				if (file.id) {
					globalDeleteService(`camo/attachment/${file.id}/delete/`)
						.then((response) => {
							if (checkApiStatus(response)) {
								setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
								getResponseBack()
							}
						});
				} else {
					setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
				}
			}
		}
	}

	const onAddNewAsset = () => {
		let validationInputs = {}
		if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')) {
			validationInputs = {
				...validationInputs,
				serial_number: data?.serial_number ? '' : "Please Enter Serial Number",
			}
		}
		if (data.tsn && !specificKey.includes(data?.tsn?.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.tsn)) {
			validationInputs.tsn = specificMessTsn;
		}
		if (data.csn && !specificKey.includes(data?.csn?.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.csn)) {
			validationInputs.csn = specificMessCsn;
		}
		if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
			setLoading(true)
			let payload = Object.assign({}, data);
			if (data?.id) {
				globalPutService(`camo/mc/${payload.id}/`, payload)
					.then(response => {
						setLoading(false)
						if (response.data?.statusCode === 200) {
							enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
							toggleModalFn(); getResponseBack()
							importFile(response?.data?.data)

						} else {
							enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

						}
					})
			} else {
				globalPostService(`camo/mc/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType, })
					.then(response => {
						setLoading(false)
						if (response.data?.statusCode === 200) {
							enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
							toggleModalFn(); getResponseBack()
							importFile(response?.data?.data)
							addUploadFile(response?.data?.data)
						} else {
							enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

						}
					})

			}
		} else {
			setError(validationInputs)
		}
	}
	return (
		<>
			<Dialog open={true} onClose={toggleModalFn} className='camo-mangement-modal' >
				<DialogTitle> {modeType === "edit" ? "Edit Master Components" : "Add  Master Components"} </DialogTitle>
				<DialogContent dividers>
					<Grid spacing={1} container>
						<Grid item xs={12} sm={6} md={3} >
							<TextField
								variant="outlined"
								label="Part Number"
								id="part_number"
								margin='normal'
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={data.part_number ? data.part_number : ''}
								onChange={(e) => { e.target.value.length <= 25 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && onFieldChange('part_number', e.target.value); setError({ ...error, 'part_number': '' })  }}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={3} >
							<TextField
								required
								variant="outlined"
								label="Serial Number"
								id="serial_number"
								margin='normal'
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={data.serial_number}
								error={error.serial_number ? true : false}
								helperText={error.serial_number ? error.serial_number : ""}
								onChange={(e) => { e.target.value.length <= 25 && (regexConstants.alphanumericWithHyphen.test(e.target.value) || e.target.value === "") && onFieldChange('serial_number', e.target.value);  setError({ ...error, 'serial_number': '' }) }}
							/>
						</Grid>
						<Grid item xs={12} sm={3} md={3} >
							<Autocomplete
								options={['Yes', 'No']}
								getOptionLabel={(option) => option}
								id="life_limited_part"
								value={data?.life_limited_part || null}
								onChange={(e, value) => onFieldChange("life_limited_part", value)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Life Limit Part"
										margin="normal"
										fullWidth
										InputLabelProps={{ shrink: true }}
										placeholder="Select Life Limit Part"
										variant="outlined"

									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={3} md={3} >
							<TextField
								variant="outlined"
								label={
									<span style={{ verticalAlign: 'super' }}>
										TSN
										<Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
											<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
										</Tooltip>
									</span>
								}
								name="tsn"
								id="tsn"
								margin='normal'
								fullWidth
								InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
								value={data.tsn ? data.tsn : ''}
								error={error.tsn ? true : false}
								helperText={error.tsn ? error.tsn : ""}
								onChange={(e) => onFieldChange('tsn', e.target.value)}
							/>
						</Grid>
					</Grid>
					<Grid spacing={1} container>
						<Grid item xs={12} sm={9} md={9} >
							<TextField
								multiline
								minRows={5}
								variant="outlined"
								label="Description"
								id="description"
								margin='normal'
								fullWidth
								InputLabelProps={{ shrink: true }}
								value={data.description}
								inputProps={{ maxLength: 250 }}
								onChange={(e) => onFieldChange("description", e.target.value)}
							/>
						</Grid>

						<Grid item xs={12} sm={3} md={3} >
							<TextField
								variant="outlined"
								label={
									<span style={{ verticalAlign: 'super' }}>
										CSN
										<Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
											<InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
										</Tooltip>
									</span>
								}
								id="csn"
								margin='normal'
								fullWidth
								InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
								value={data.csn ? data.csn : ''}
								error={error.csn ? true : false}
								helperText={error.csn ? error.csn : ""}
								onChange={(e) => { onFieldChange('csn', e.target.value); setError({ ...error, 'csn': '' }) }}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item xs={12} md={6} sm={6}>
							<TextField
								label="Remarks"
								margin='normal'
								variant='outlined'
								fullWidth
								value={data.remarks}
								InputLabelProps={{ shrink: true }}
								onChange={(e) => onFieldChange("remarks", e.target.value)}
								rows={3}
								multiline
								inputProps={{ maxLength: 250 }}
							/>
						</Grid>
						<Grid item xs={6}>
							<div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
								<span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
								<span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
							</div>
						</Grid>
						<div className="checklist-file" style={{ overflow: "auto" }}>
                        {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
							<ul className='list-inline'>
								{(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
									return (
										<li className='list-inline-item'>
											<span className='file-name'>{attachments.name}</span>
											<span className='file-remove'>
												<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
												<CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
											</span>
										</li>)
								}

								)}
								{(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
									data.checklist?.map((file) => {
										return (
											<li className='list-inline-item' key={file.id}>
												<span className='file-name'>{file.name}</span>
												<span className='file-remove'>
													<a href={decodeURIComponent(file.path)} download={file.name}>
														{data?.checklist.some(item => item.type === 'folder') ? null
															: <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
													</a>
													<CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
												</span>
											</li>
										);
									})}
								{(data.checklist?.files?.length > 0) &&
									data.checklist?.files.map((file) => {
										return (
											<li className='list-inline-item' key={file.id}>
												<span className='file-name'>{file.name}</span>
												<span className='file-remove'>
													<a href={decodeURIComponent(file.path)} download={file.name}>
														<GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
													</a>
													<CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
												</span>
											</li>
										);
									})}
								{(data.checklist?.folders?.length > 0) &&
									data.checklist?.folders.map((file) => {
										return (
											<li className='list-inline-item' key={file.id}>
												<Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
													<a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
												</Tooltip>
												<span className='file-name'>{file.name}</span>
												<span className='file-remove'>
													<CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
												</span>
											</li>
										);
									})}
							</ul>
						</div>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
					<Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
				</DialogActions>
			</Dialog>
			{uploadFile.modal ?
				<UploadFile
					modType="mc"
					type="upload"
					list={list}
					actionType={modeType}
					editFormId={data?.id}
					handleClose={() => setUploadFile({ modal: false })}
					uuid={basicdetail?.record_folders?.technical_records?.uuid}
					getResponseBack={(file) => uploadFileData(file)}
					uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

				/>
				: null}
			{linkFile.modal ?
				<LinkFiles
					modType="mc"
					type="link"
					list={list}
					editFormId={data?.id}
					actionType={modeType}
					uploadFileInfo={data}
					toggleModalFn={() => setlinkFile({ modal: false })}
					uuid={basicdetail?.record_folders?.technical_records?.uuid}
					getResponseBack={(file) => uploadFileData(file)}
					onLinkFile={getData}
				/>
				: null
			}
			{isLoading ? <PageLoader /> : null}
		</>
	)

}
export default withRouter(AddEditMasterComp)
