import React, { useState } from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import RevisionHistory from '../../../../shared_elements/components/RevisionHistory';
import AttachFileIcon from "@material-ui/icons/AttachFile";
const LLPListing = ({ item, toggleModalFn, toggleDelteModalFn, handleOpenAttachments, basicdetail }) => {
    const [lessMore, setLessMore] = useState({});

    const toggleLessMore = (id) => {
        setLessMore({
            ...lessMore,
            [id]: !lessMore[id],
        });
    };

    const lessMoreFn = (name) => {
        return <>
            {name && name.length > 22 ? (
                <>
                    {lessMore[name] ?
                        (<> {name} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..less </span> </>)
                        :
                        (<>{name.substring(0, 22)} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..more </span> </>)
                    }
                </>
            ) : name || '--'
            }
        </>
    }
    function getAttachmentCount(item) {
        const { attachments, checklist } = item;
        const { files, folders } = checklist || {};

        const attachmentCount = attachments?.length || 0;
        const fileCount = files?.length || 0;
        const folderCount = folders?.length || 0;

        if (attachmentCount && fileCount && folderCount) {
            return attachmentCount + fileCount + folderCount;
        } else if (fileCount && folderCount) {
            return fileCount + folderCount;
        } else if (attachmentCount && folderCount) {
            return attachmentCount + folderCount;
        } else if (attachmentCount && fileCount) {
            return attachmentCount + fileCount;
        } else {
            return folderCount || fileCount || attachmentCount || '--';
        }
    }
    const attachmentCount = getAttachmentCount(item);
    let llpUrl = `audit/camo/lifelimitedpart/?object_id=${item.id}&action=0,1&timezone=${moment().format()}&timestamp=${Math.floor(Date.now() / 1000)}`

    return (
        <TableRow>
            <TableCell>
                <Tooltip title="Edit" placement='top'>
                    <IconButton style={{ padding: 'unset' }} color='primary' onClick={toggleModalFn} > <CreateOutlinedIcon /> </IconButton>
                </Tooltip>
                <Tooltip title="Delete" placement='top' >
                    <IconButton style={{ padding: 'unset' }} color='secondary' onClick={toggleDelteModalFn}> <DeleteOutlinedIcon /> </IconButton>
                </Tooltip>
                <RevisionHistory buttonType={true} url={llpUrl} />
            </TableCell>
            <TableCell>{item.module_type || "--"}</TableCell>
            <TableCell>{lessMoreFn(item.description)} </TableCell>
            <TableCell>{item.part_number || "--"}</TableCell>
            <TableCell>{item.serial_number || "--"}</TableCell>
            <TableCell>{item.tsn || "--"}</TableCell>
            <TableCell>{item.csn || "--"}</TableCell>
            <TableCell>{item.COOTR_thrust_a}</TableCell>
            <TableCell>{item.COOTR_thrust_b}</TableCell>
            {basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? null : <TableCell>{item.COOTR_thrust_c}</TableCell>}
            {basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? null : <TableCell>{item.COOTR_thrust_d}</TableCell>}
            {basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? null : <TableCell>{item.COOTR_thrust_e}</TableCell>}
            <TableCell>{item.life_limit_thrust_a}</TableCell>
            <TableCell>{item.life_limit_thrust_b}</TableCell>
            {basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? null : <TableCell>{item.life_limit_thrust_c}</TableCell>}
            {basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? null : <TableCell>{item.life_limit_thrust_d}</TableCell>}
            {basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? null : <TableCell>{item.life_limit_thrust_e}</TableCell>}
            <TableCell>{item.remaining_cycle_thrust?.remaining_cycle_thrust_a}</TableCell>
            <TableCell>{item.remaining_cycle_thrust?.remaining_cycle_thrust_b}</TableCell>
            {basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? null : <TableCell>{item.remaining_cycle_thrust?.remaining_cycle_thrust_c}</TableCell>}
            {basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? null : <TableCell>{item.remaining_cycle_thrust?.remaining_cycle_thrust_d}</TableCell>}
            {basicdetail?.thrust_details && Object.values(basicdetail?.thrust_details)?.length === 2 ? null : <TableCell>{item.remaining_cycle_thrust?.remaining_cycle_thrust_e}</TableCell>}
            <TableCell>{item.remarks || '--'}</TableCell>
            <TableCell>
                {attachmentCount && (
                    <Tooltip title="View Attachments">
                        <span className="center">
                            <AttachFileIcon className="attach-icon" onClick={handleOpenAttachments} />
                            <a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
                        </span>
                    </Tooltip>
                )}
            </TableCell>
        </TableRow>
    )
}

export default withRouter(LLPListing)