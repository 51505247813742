import React, { Component, Fragment } from "react"
import { Button, DialogTitle, DialogContent, TextField, DialogActions, Grid, FormControl, InputLabel, MenuItem, Select, FormLabel, FormControlLabel, Radio, Dialog, TextareaAutosize, Tooltip } from "@material-ui/core"
import RadioGroup from "@material-ui/core/RadioGroup"
import CloseIcon from "@material-ui/icons/Close"
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import { fieldValidation } from "../../../../utils/formValidation"
import { formValues, taskErrorCode } from "../containers/HtTaskFormData"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import moment from "moment"
import axios from 'axios';
import { getLocalStorageInfo } from "../../../../utils";
import MomentUtils from "@date-io/moment"
import { backendDateFormat, dayMonthDateFormat } from "../../../../constants"
import { addHTFormApi, deleteAdAttachment } from "../apiService"
import HtConfirmDeleteAttachment from "./HtConfirmDeleteAttachment"
import { regexConstants } from "../../../../constants/regEx"
import { globalPutService } from "../../../../utils/globalApiServices"
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { checkApiStatus } from "../../../../utils";
import { trackActivity } from '../../../../utils/mixpanel';
import { assetType, imgStoragePath } from "../../../../constants";
import config from "../../../../config";
class HtTaskForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			error: {},
			data: formValues,
			regex: "^[0-9]+$",
			numberError: false,
			selectLastDDate: null,
			selectNextDueDate: null,
			attachments: [],
			newUploadedAttachments: [],
			remainingValue: "",
			htDue: "",
			btnStatus: "",
			open: false,
			deleteAttachmentId: 0,
			showSuccessUpload: false,
			globalValidationInputs: {},
			validatedFields: [],
			linkFile: false,
			uploadFile: false
		}

		this.addHTFormApi = addHTFormApi.bind(this)
		this.dropDownChange = this.dropDownChange.bind(this)
	}

	componentDidMount = () => {
		if (this.props.editFormId) {
			this.fillEditData()
		}
		trackActivity('CAMO', {
			event_type: 'Modal Opened',
			page_title: 'Hard Time Add Task Form',
		})
	}
	handleOpenFile = () => {
		this.setState({ linkFile: true })
	};
	handleCloseFile = () => {
		this.setState({ linkFile: false })
	};
	handleOpenUploadFile = () => {
		this.setState({ uploadFile: true })
	};
	handleUploadFileCloseFile = () => {
		this.setState({ uploadFile: false })
	};

	dropDownChange(e, index, value) {
		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				dimension_type: e.target.value,
			},
		}))
	}

	onFieldChange = (keyParam, value) => {
		const { data } = this.state
		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				[keyParam]: value,
			},
		}))

		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				remaining_val: this.state.remainingValue,
			},
		}))

		this.setState((prevState) => ({
			...prevState,
			data: {
				...prevState.data,
				ht_DUE: this.state.htDue,
			},
		}))
	}

	onRestErrorKey = (keyParam) => {
		this.setState((prevState) => ({
			...prevState,
			error: {
				...prevState.error,
				[keyParam]: "",
			},
		}))
	}
	handleSubmit = (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data } = this.state
		validationInputs = {
			...validationInputs,
			ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
			part_number: taskErrorCode["part_number"][fieldValidation({ ...taskErrorCode["part_number_obj"], fieldval: data.part_number, })],
			part_serial_number: taskErrorCode["part_serial_number"][fieldValidation({ ...taskErrorCode["part_serial_number_obj"], fieldval: data.part_serial_number, })],
			ht_requirement: taskErrorCode["ht_requirement"][fieldValidation({ ...taskErrorCode["ht_requirement_obj"], fieldval: data.ht_requirement, })],
			task_card: taskErrorCode["task_card"][fieldValidation({ ...taskErrorCode["task_card_obj"], fieldval: data.task_card, })],
			component: taskErrorCode["component"][fieldValidation({ ...taskErrorCode["component_obj"], fieldval: data.component, })],
			position: taskErrorCode["position"][fieldValidation({ ...taskErrorCode["position_obj"], fieldval: data.position, })],
			faa_easa: taskErrorCode["faa_easa"][fieldValidation({ ...taskErrorCode["faa_easa_obj"], fieldval: data.faa_easa, })],
			installation_wo: taskErrorCode["installation_wo"][fieldValidation({ ...taskErrorCode["installation_wo_obj"], fieldval: data.installation_wo, })],
			part_description: taskErrorCode["part_description"][fieldValidation({ ...taskErrorCode["part_description_obj"], fieldval: data.part_description, })],
			part_installation_date: taskErrorCode["part_installation_date"][fieldValidation({ ...taskErrorCode["part_installation_date_obj"], fieldval: data.part_installation_date, })],
			crs_date: taskErrorCode["crs_date"][fieldValidation({ ...taskErrorCode["crs_date_obj"], fieldval: data.crs_date, })],
		}
		if (data.dimension_type === "FC") {
			validationInputs = {
				...validationInputs,
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
			}
		} else if (data.dimension_type === "FC,D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type === "FH") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
			}
		} else if (data.dimension_type === "D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type === "FH,D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type === "FC,FH") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
			}
		} else if (data.dimension_type === "FC,FH,D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		}

		if (
			Object.keys(validationInputs).every((k) => {
				return validationInputs[k] === ""
			})
		) {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
				},
			}))
			const props = this.props.props
			const htData = data
			htData.asset_id = props.match.params.asset
			htData.asset_type_id = props.match.params.assetType
			if (htData.dimension_type == "FC") {
				htData.tso = null
			} else if (htData.dimension_type == "FH") {
				htData.cso = null
			}
			delete htData.ad_due
			delete htData.remaining_val
			delete htData.ht_DUE
			addHTFormApi(htData, props).then(async (response) => {
				const uploadId = response.data.data.id
				if (uploadId) {
					await this.uploadFileTest(uploadId, props, false)
					trackActivity('CAMO', {
						event_type: 'Task Added Successfully',
						page_title: 'Hard Time(HT)',
						item_id: uploadId ? uploadId : '',
						request_body: htData ? htData : '',
						response_msg: response.data.message ? response.data.message : ''
					})
				}
			})
			if (this.state.btnStatus !== "addAnother") {
				this.setState((prevState) => ({
					error: {
						...prevState.error,
						dimension_interval: "",
					},
				}))
				this.props.closeAddForm()
			} else {
				this.setState((prevState) => ({
					error: {
						...prevState.error,
						dimension_interval: "",
					},
				}))
				return false
			}
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Add TASK Failed',
				page_title: 'Hard Time(HT)',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			})
		}
	}

	fillEditData = () => {
		const updateId = this.props.editFormId
		this.setState({ attachments: this.props.attachments })
		if (updateId === undefined || updateId === null) {
			this.setState({
				data: {},
			})
		} else {
			this.setState({
				data: this.props.editFormData,
			})
		}
	}

	onFocusValidation = (keyParam) => {
		let validationInputs = {}
		const { data } = this.state
		validationInputs = {
			...validationInputs,
			ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
			part_number: taskErrorCode["part_number"][fieldValidation({ ...taskErrorCode["part_number_obj"], fieldval: data.part_number, })],
			part_serial_number: taskErrorCode["part_serial_number"][fieldValidation({ ...taskErrorCode["part_serial_number_obj"], fieldval: data.part_serial_number, })],
			ht_requirement: taskErrorCode["ht_requirement"][fieldValidation({ ...taskErrorCode["ht_requirement_obj"], fieldval: data.ht_requirement, })],
			task_card: taskErrorCode["task_card"][fieldValidation({ ...taskErrorCode["task_card_obj"], fieldval: data.task_card, })],
			component: taskErrorCode["component"][fieldValidation({ ...taskErrorCode["component_obj"], fieldval: data.component, })],
			position: taskErrorCode["position"][fieldValidation({ ...taskErrorCode["position_obj"], fieldval: data.position, })],
			faa_easa: taskErrorCode["faa_easa"][fieldValidation({ ...taskErrorCode["faa_easa_obj"], fieldval: data.faa_easa, })],
			installation_wo: taskErrorCode["installation_wo"][fieldValidation({ ...taskErrorCode["installation_wo_obj"], fieldval: data.installation_wo, })],
			part_description: taskErrorCode["part_description"][fieldValidation({ ...taskErrorCode["part_description_obj"], fieldval: data.part_description, })],
			part_installation_date: taskErrorCode["part_installation_date"][fieldValidation({ ...taskErrorCode["part_installation_date_obj"], fieldval: data.part_installation_date, })],
			crs_date: taskErrorCode["crs_date"][fieldValidation({ ...taskErrorCode["crs_date_obj"], fieldval: data.crs_date, })],
		}
		if (data.dimension_type === "FC") {
			validationInputs = {
				...validationInputs,
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
			}
		} else if (data.dimension_type === "FC,D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type === "FH") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
			}
		} else if (data.dimension_type === "D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type === "FH,D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type === "FC,FH") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
			}
		} else if (data.dimension_type === "FC,FH,D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		}
		if (
			Object.keys(validationInputs).every((k) => {
				return validationInputs[k] === ""
			})
		) {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
				},
			}))
			this.setState({ validationInputs: {} })
		} else {
			this.setState({ error: validationInputs })
		}
	}

	updateData = async (e) => {
		e.preventDefault()
		let validationInputs = {}
		const { data } = this.state
		this.setState({ file: "" })
		if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')) {
			validationInputs = {
				...validationInputs,
				ata_chapter: taskErrorCode["ata_chapter"][fieldValidation({ ...taskErrorCode["ata_chapter_obj"], fieldval: data.ata_chapter, })],
				part_number: taskErrorCode["part_number"][fieldValidation({ ...taskErrorCode["part_number_obj"], fieldval: data.part_number, })],
				part_serial_number: taskErrorCode["part_serial_number"][fieldValidation({ ...taskErrorCode["part_serial_number_obj"], fieldval: data.part_serial_number, })],
				ht_requirement: taskErrorCode["ht_requirement"][fieldValidation({ ...taskErrorCode["ht_requirement_obj"], fieldval: data.ht_requirement, })],
				task_card: taskErrorCode["task_card"][fieldValidation({ ...taskErrorCode["task_card_obj"], fieldval: data.task_card, })],
				component: taskErrorCode["component"][fieldValidation({ ...taskErrorCode["component_obj"], fieldval: data.component, })],
				position: taskErrorCode["position"][fieldValidation({ ...taskErrorCode["position_obj"], fieldval: data.position, })],
				faa_easa: taskErrorCode["faa_easa"][fieldValidation({ ...taskErrorCode["faa_easa_obj"], fieldval: data.faa_easa, })],
				installation_wo: taskErrorCode["installation_wo"][fieldValidation({ ...taskErrorCode["installation_wo_obj"], fieldval: data.installation_wo, })],
				part_description: taskErrorCode["part_description"][fieldValidation({ ...taskErrorCode["part_description_obj"], fieldval: data.part_description, })],
				part_installation_date: taskErrorCode["part_installation_date"][fieldValidation({ ...taskErrorCode["part_installation_date_obj"], fieldval: data.part_installation_date, })],
				crs_date: taskErrorCode["crs_date"][fieldValidation({ ...taskErrorCode["crs_date_obj"], fieldval: data.crs_date, })],
			}
		}
		if (data.dimension_type === "FC") {
			validationInputs = {
				...validationInputs,
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
			}
		} else if (data.dimension_type === "FC,D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type === "FH") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
			}
		} else if (data.dimension_type === "D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type === "FH,D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		} else if (data.dimension_type === "FC,FH") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
			}
		} else if (data.dimension_type === "FC,FH,D") {
			validationInputs = {
				...validationInputs,
				tso: taskErrorCode["tso"][fieldValidation({ ...taskErrorCode["tso_obj"], fieldval: data.tso, })],
				cso: taskErrorCode["cso"][fieldValidation({ ...taskErrorCode["cso_obj"], fieldval: data.cso, })],
				dimension_interval_fc: taskErrorCode["dimension_interval_fc"][fieldValidation({ ...taskErrorCode["dimension_interval_fc_obj"], fieldval: data.dimension_interval_fc, })],
				dimension_interval: taskErrorCode["dimension_interval"][fieldValidation({ ...taskErrorCode["dimension_interval_obj"], fieldval: data.dimension_interval, })],
				dimension_interval_days: taskErrorCode["dimension_interval_days"][fieldValidation({ ...taskErrorCode["dimension_interval_days_obj"], fieldval: data.dimension_interval_days, })],
			}
		}

		if (
			Object.keys(validationInputs).every((k) => {
				return validationInputs[k] === ""
			})
		) {
			this.setState((prevState) => ({
				...prevState,
				data: {
					...prevState.data,
				},
			}))
			const updateId = this.props.editFormId
			const updatedData = this.state.data
			delete updatedData.ad_due
			delete updatedData.remaining_val
			const props = this.props.props
			this.setState({
				data: this.props.editFormData,
			})
			if (updateId) {
				await this.uploadFileTest(updateId, props, true)
			}
			delete updatedData.ad_due
			delete updatedData.remaining_val
			delete updatedData.ht_DUE
			await this.props.updateFormData(updateId, updatedData, props)
			this.setState((prevState) => ({
				error: {
					...prevState.error,
					dimension_interval: "",
				},
			}))
			this.props.closeAddForm()
			trackActivity('CAMO', {
				event_type: 'Task Updated Successfully',
				page_title: 'Hard Time(HT)',
				item_id: updateId ? updateId : '',
				request_body: updatedData ? updatedData : ''
			})
		} else {
			this.setState({ error: validationInputs })
			trackActivity('CAMO', {
				event_type: 'Update TASK Failed',
				page_title: 'Hard Time(HT)',
				error_source: 'Frontend',
				error_type: validationInputs ? validationInputs : ''
			})
		}
	}
	uploadFileTest = async (uploadId, props, isUpdate) => {
		if (this.state.newUploadedAttachments.length > 0) {
			let newAttachmentObj = []
			let formdata = new FormData()
			const module_type_id = 2
			Object.keys(this.state.newUploadedAttachments).map((key, index) => {
				newAttachmentObj.push({
					file: this.state.newUploadedAttachments[key],
					file_name: this.state.newUploadedAttachments[key].name,
					file_type: this.state.newUploadedAttachments[key].type,
					module_type_id,
					module_id: uploadId,
					asset_id: this.props.props.match.params.asset,
					asset_type_id: this.props.props.match.params.assetType

				})
				formdata.append(
					"[" + key + "]file",
					this.state.newUploadedAttachments[key]
				)
				formdata.append(
					"[" + key + "]file_type",
					this.state.newUploadedAttachments[key].type
				)
				formdata.append(
					"[" + key + "]file_name",
					this.state.newUploadedAttachments[key].name
				)
				formdata.append("[" + key + "]module_id", uploadId)
				formdata.append("[" + key + "]module_type_id", module_type_id)
				formdata.append("[" + key + "]asset_id", this.props.props.match.params.asset)
				formdata.append("[" + key + "]asset_type_id", this.props.props.match.params.assetType)
			})
			// uploadOccmFile(uploadId, formdata, props)
			this.props.fileUploadData(uploadId, formdata, props, true)
			if (this.state.btnStatus === "addAnother") {
				this.resetBothAttachmentArray()
			}
			if (!isUpdate) {
				this.onSubmitFileLink(uploadId)
				this.props.fileUploadData(uploadId, null, props, false)
			}
		} else {
			if (this.state.btnStatus === "addAnother") {
				this.setState((prevState) => ({
					...prevState,
					data: formValues,
					attachments: [],
				}))
				document.getElementById("asset-form").reset()
				this.props.fileUploadData(uploadId, null, props, false)
			} else {
				if (!isUpdate) {
					this.onSubmitFileLink(uploadId)
					this.props.fileUploadData(uploadId, null, props, false)
				}
			}
		}
	}

	resetBothAttachmentArray = () => {
		this.setState((prevState) => ({
			...prevState,
			data: formValues,
			attachments: [],
			newUploadedAttachments: [],
			open: false,
			deleteAttachmentId: 0,
		}))
		document.getElementById("asset-form").reset()
	}

	onUpload = (files) => {
		const chosenFiles = Array.prototype.slice.call(files)
		for (let i = 0; i < chosenFiles.length; i++) {
			if (chosenFiles[i].size > 52428800) {
				this.props.props.enqueueSnackbar(
					`${chosenFiles[i].name} Maximum file size should be 50MB.`,
					{
						variant: "error",
						anchorOrigin: { vertical: "top", horizontal: "right" },
					}
				)
				delete chosenFiles[i]
				continue
			} else {
				this.handleUploadFiles(chosenFiles)
				this.setState({
					showSuccessUpload: true,
				})
				setTimeout(() => {
					if (this.state.attachments) {
						this.setState({
							showSuccessUpload: false,
						})
					}
				}, 2500)
			}
		}
	}

	handleUploadFiles = (files) => {
		const uploaded = [...this.state.newUploadedAttachments]
		const existingAttachments = [...this.state.attachments]
		files.some((file) => {
			uploaded.push(file)
		})
		files.map((file) => {
			var objc = {
				id: 0,
				module_id: 0,
				module_type_id: 2,
				file_name: file.name,
				file_type: file.type,
				file: "",
				name: file.name,
				asset_id: this.props.props.match.params.asset,
				asset_type_id: this.props.props.match.params.assetType
			}
			existingAttachments.push(objc)
		})

		this.setState({
			newUploadedAttachments: uploaded,
			attachments: existingAttachments,
		})
	}

	handleClickAttachment = (e, id) => {
		this.setState({ open: true })
		this.setState({ deleteAttachmentId: id })
	}

	handleCloseAttachment = (e) => {
		this.setState({ open: false })
		this.setState({ deleteAttachmentId: 0 })
	}

	deleteAttachmentFile = async (id, file) => {
		if (id !== undefined && id != 0) {
			const props = this.props.props
			deleteAdAttachment(id, props)
			var array = [...this.state.attachments]
			array = array.filter((item) => item.id !== id)
			this.setState({ attachments: array, open: false, deleteAttachmentId: 0 })
		} else {
			this.onRemoveCamoFile(file)
		}
	}

	removeAttachment = (e, obj) => {
		var array = [...this.state.attachments]
		var newuploadarray = [...this.state.newUploadedAttachments]
		var filteredArray = []
		newuploadarray.map((file) => {
			if (file.name == obj.file_name && file.type == obj.file_type) {
			} else {
				filteredArray.push(file)
			}
		})
		array = array.filter((item) => item !== obj)
		this.setState({
			newUploadedAttachments: filteredArray,
			attachments: array,
		})
	}

	saveAndAddAnother = async (e) => {
		await this.setState({ btnStatus: "addAnother" })
		await this.handleSubmit(e)
	}

	addFormData = async (e) => {
		await this.setState({ btnStatus: "add" })
		this.handleSubmit(e)
	}

	remainingVal = () => {
		const { data } = this.state
		let todayDate = new Date()
		const today = moment()
		const targetDate = moment(data.crs_date)
		if (this.state.data.dimension_type === "D") {
			const daysDifference = today.diff(targetDate, "days")
			data.remaining_val = data.dimension_interval_days - daysDifference // daysDifference => (today date - crs date)
		} else if (this.state.data.dimension_type === "FC") {
			data.remaining_val = Number.isInteger(data.dimension_interval_fc - data.cso) ? data.dimension_interval_fc - data.cso : (data.dimension_interval_fc - data.cso).toFixed(2)
		} else if (this.state.data.dimension_type === "FH") {
			data.remaining_val = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)
		} else if (data.dimension_type === "FC,D") {
			const fcRemainingVal = Number.isInteger(data.dimension_interval - data.cso) ? data.dimension_interval - data.cso : (data.dimension_interval - data.cso).toFixed(2)
			const daysDifference = today.diff(targetDate, "days")
			const dRemainingVal = data.dimension_interval_fc - daysDifference
			const fcRemainingDueAt = Number.isInteger(+fcRemainingVal + +this.props.headerCsn) ? +fcRemainingVal + +this.props.headerCsn : (+fcRemainingVal + +this.props.headerCsn).toFixed(2)
			//dueDateWithFC
			const finalFcRemDueDate = moment(todayDate, "DD-MM-YYYY").add(fcRemainingDueAt, "days")
			//dueDateWithDate
			const finalDRemDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
			if (finalDRemDueDate < finalFcRemDueDate) {
				data.remaining_val = dRemainingVal
				return data.remaining_val
			} else {
				data.remaining_val = fcRemainingVal
				return data.remaining_val
			}
		} else if (data.dimension_type === "FH,D") {
			const fhRemainingVal = Number.isInteger(
				data.dimension_interval - data.tso
			)
				? data.dimension_interval - data.tso
				: (data.dimension_interval - data.tso).toFixed(2)

			const daysDifference = today.diff(targetDate, "days")
			const dRemainingVal = data.dimension_interval_days - daysDifference

			const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +this.props.headerTsn) ? +fhRemainingVal + +this.props.headerTsn : (+fhRemainingVal + +this.props.headerTsn).toFixed(2)
			const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
			const dDueAtDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days").format("DD-MM-YYYY")
			//dueDateWithFH
			const finalFhRemDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
			//dueDateWithDate
			const finalDRemDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
			if (finalDRemDueDate < finalFhRemDueDate) {
				data.remaining_val = dRemainingVal
				return data.remaining_val
			} else {
				data.remaining_val = fhRemainingVal
				return data.remaining_val
			}
		}
		else if (data.dimension_type === "FC,FH,D") {
			const fhRemainingVal = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)
			const daysDifference = today.diff(targetDate, "days")
			const dRemainingVal = data.dimension_interval_days - daysDifference
			const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +this.props.headerTsn) ? +fhRemainingVal + +this.props.headerTsn : (+fhRemainingVal + +this.props.headerTsn).toFixed(2)
			const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
			const dDueAtDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days").format("DD-MM-YYYY")
			//dueDateWithFH
			const finalFhRemDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
			//dueDateWithDate
			const finalDRemDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
			if (finalDRemDueDate < finalFhRemDueDate) {
				data.remaining_val = dRemainingVal
				return data.remaining_val
			} else {
				data.remaining_val = fhRemainingVal
				return data.remaining_val
			}
		}
		else if (data.dimension_type === "FC,FH") {
			const fhRemainingVal = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)
			const daysDifference = today.diff(targetDate, "days")
			const dRemainingVal = data.dimension_interval_days - daysDifference
			const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +this.props.headerTsn) ? +fhRemainingVal + +this.props.headerTsn : (+fhRemainingVal + +this.props.headerTsn).toFixed(2)
			const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
			const dDueAtDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days").format("DD-MM-YYYY")
			//dueDateWithFH
			const finalFhRemDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
			//dueDateWithDate
			const finalDRemDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
			if (finalDRemDueDate < finalFhRemDueDate) {
				data.remaining_val = dRemainingVal
				return data.remaining_val
			} else {
				data.remaining_val = fhRemainingVal
				return data.remaining_val
			}
		}
		return data.remaining_val
	}
	nextDueVal = () => {
		const { data } = this.state
		let todayDate = new Date()
		const today = moment()
		const targetDate = moment(data.crs_date)
		if (data.remaining_val === 0) {
			data.ht_DUE = ""
		} else {
			if (this.state.data.dimension_type === "D") {
				data.ht_DUE = data.remaining_val ? moment(todayDate, "DD-MM-YYYY").add(data.remaining_val, "days").format("DD-MM-YYYY") : null
			} else if (this.state.data.dimension_type === "FH") {
				data.ht_DUE = Number.isInteger(+data.remaining_val + +this.props.headerTsn) ? +data.remaining_val + +this.props.headerTsn : (+data.remaining_val + +this.props.headerTsn).toFixed(2)
			} else if (this.state.data.dimension_type === "FC") {
				data.ht_DUE = Number.isInteger(+data.remaining_val + +this.props.headerCsn) ? +data.remaining_val + +this.props.headerCsn : (+data.remaining_val + +this.props.headerCsn).toFixed(2)
			}
			if (data.dimension_type === "FC,D") {
				const fcRemainingVal = Number.isInteger(data.dimension_interval - data.cso) ? data.dimension_interval - data.cso : (data.dimension_interval - data.cso).toFixed(2)
				const daysDifference = today.diff(targetDate, "days")
				const dRemainingVal = data.dimension_interval_days - daysDifference

				const fcRemainingDueAt = Number.isInteger(+fcRemainingVal + +this.props.headerCsn) ? +fcRemainingVal + +this.props.headerCsn : (+fcRemainingVal + +this.props.headerCsn).toFixed(2)

				//dueDateWithFC
				const finalFcDueDate = moment(todayDate, "DD-MM-YYYY").add(fcRemainingDueAt, "days")
				//dueDateWithDate
				const finalDDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")

				if (finalDDueDate < finalFcDueDate) {
					data.ht_DUE = moment(finalDDueDate).format("DD-MM-YYYY")
					return data.ht_DUE
				} else {
					data.ht_DUE = fcRemainingDueAt
					return data.ht_DUE
				}
			} else if (data.dimension_type === "FH,D") {
				const fhRemainingVal = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)

				const daysDifference = today.diff(targetDate, "days")
				const dRemainingVal = data.dimension_interval_days - daysDifference

				//dueDateWithDate
				const finalDDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
				//remaining+tsn=nowdate cal
				const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +this.props.headerTsn) ? +fhRemainingVal + +this.props.headerTsn : (+fhRemainingVal + +this.props.headerTsn).toFixed(2)
				//dueDateWithFH
				const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
				if (finalDDueDate < finalFhDueDate) {
					data.ht_DUE = moment(finalDDueDate).format("DD-MM-YYYY")
					return data.ht_DUE
				} else {
					data.ht_DUE = fhRemainingDueAt
					return data.ht_DUE
				}
			}
			else if (data.dimension_type === "FC,FH,D") {
				const fhRemainingVal = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)

				const daysDifference = today.diff(targetDate, "days")
				const dRemainingVal = data.dimension_interval_days - daysDifference

				//dueDateWithDate
				const finalDDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
				//remaining+tsn=nowdate cal
				const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +this.props.headerTsn) ? +fhRemainingVal + +this.props.headerTsn : (+fhRemainingVal + +this.props.headerTsn).toFixed(2)
				//dueDateWithFH
				const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
				if (finalDDueDate < finalFhDueDate) {
					data.ht_DUE = moment(finalDDueDate).format("DD-MM-YYYY")
					return data.ht_DUE
				} else {
					data.ht_DUE = fhRemainingDueAt
					return data.ht_DUE
				}
			}
			else if (data.dimension_type === "FC,FH,D") {
				const fhRemainingVal = Number.isInteger(data.dimension_interval - data.tso) ? data.dimension_interval - data.tso : (data.dimension_interval - data.tso).toFixed(2)
				const daysDifference = today.diff(targetDate, "days")
				const dRemainingVal = data.dimension_interval_days - daysDifference
				//dueDateWithDate
				const finalDDueDate = moment(todayDate, "DD-MM-YYYY").add(dRemainingVal, "days")
				//remaining+tsn=nowdate cal
				const fhRemainingDueAt = Number.isInteger(+fhRemainingVal + +this.props.headerTsn) ? +fhRemainingVal + +this.props.headerTsn : (+fhRemainingVal + +this.props.headerTsn).toFixed(2)
				//dueDateWithFH
				const finalFhDueDate = moment(todayDate, "DD-MM-YYYY").add(fhRemainingDueAt, "days")
				if (finalDDueDate < finalFhDueDate) {
					data.ht_DUE = moment(finalDDueDate).format("DD-MM-YYYY")
					return data.ht_DUE
				} else {
					data.ht_DUE = fhRemainingDueAt
					return data.ht_DUE
				}
			}
		}
		return data.ht_DUE
	}

	getResponseBack = (file) => {
		const { data, } = this.state
		this.setState({
			data: {
				...data,
				checklist: file,
			},
		});
	}
	listResponseBack = (file) => {
		if (this.props.actionType === "add") {
			return null
		} else {
			this.props.getResponseBack(file)
		}

	}

	downloadFile = (file) => {
		axios.get(decodeURIComponent(file.path), { timeout: 600000, responseType: 'blob' })
			.then(response => {
				const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', file.name);
				document.body.appendChild(link);
				link.click();
			})
	}
	onUnlinkCheckListFile = (checklist, fileId) => {
		const { data } = this.state;
		let payload = {};
		payload.delete = true;
		payload.folder_ids = checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
		payload.file_ids = checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
		globalPutService(`camo/ht/${this.props.editFormId}/document-checklists/`, payload)
			.then((response) => {
				if (checkApiStatus(response)) {
					const updatedChecklist = { ...checklist };
					updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
					updatedChecklist.folders = updatedChecklist?.folders.filter((file) => file.id !== fileId);
					this.setState({
						data: {
							...data,
							checklist: updatedChecklist,
						},
					});
					trackActivity('CAMO', {
						event_type: 'Checklist File Unlinked',
						page_title: 'Hard Time(HT)',
						file_id: fileId ? fileId : '',
						edited_form_id: this.props.editFormId ? this.props.editFormId : '',
						request_body: payload ? payload : ''
					})
				}
			});
	}
	onRemoveFile = (file) => {
		const { data } = this.state;
		this.setState({
			data: {
				...data,
				checklist: data.checklist.filter(item => item.name !== file.name)
			}
		});
	}
	onRemoveCamoFile = (file) => {
		const { attachments } = this.state;
		this.setState({
			attachments: attachments.filter(item => item.file_name !== file.file_name)
		});
	}

	onLinkFile = (file, uuid, type) => {
		if (type == "link" || uuid) {
			const { data } = this.state;
			const folderUUID = [...data.folder_uuid, uuid];
			const updatedChecklist = [...data.checklist, file];

			let uuidData = [];
			let output = [];

			for (const arr of folderUUID) {
				uuidData = uuidData.concat(arr);
			}

			for (const arr of updatedChecklist) {
				output = output.concat(arr);
			}
			this.setState({
				data: {
					...data,
					checklist: output,
					folder_uuid: uuidData.join("")
				},
			});
		}
		else {
			this.onUpload(file)
		}
	}

	onSubmitFileLink = (id) => {
		const { data } = this.state;
		if (data?.checklist.length > 0 && data?.checklist.map(item => item.id).filter(item => item)) {
			let payload = {};
			if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			} else if (data?.checklist.some(item => item.type === 'folder')) {
				payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
			} else {
				payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
			}
			globalPutService(`camo/ht/${id}/document-checklists/`, payload)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
		if (data?.folder_uuid.length > 0 && data?.folder_uuid) {
			let formData = new FormData();
			formData.append('folder_uuid', data?.folder_uuid);
			for (let i = 0; i < data.checklist.filter(item => item instanceof File).length; i++) {
				formData.append('files[]', data.checklist.filter(item => item instanceof File).length && data.checklist.filter(item => item instanceof File)[i]);
			}
			globalPutService(`camo/ht/${id}/document-checklists/`, formData)
				.then(response => {
					if (checkApiStatus(response)) {
						this.props.getResponseBack()
					} else {
						this.props.props.enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					}
				});
		}
	}
	render() {
		const { data, error, attachments } = this.state
		let todayDate = new Date()
		var date = new Date()
		return (
			<Fragment>
				<div id="AmpTaskForm">
					<div id="HtTaskForm">
						<DialogTitle>
							{this.props.formTitle}
							<CloseIcon onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} className="close-icon" />
						</DialogTitle>
						<form
							id="asset-form"
							onSubmit={this.props.actionType === "add" ? (e) => this.handleSubmit(e) : (e) => this.updateData(e)}
						>
							<DialogContent>
								<Grid spacing={1} container>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label={<React.Fragment> ATA Chapter<span style={{ color: "red" }}> *</span> </React.Fragment>}
											name="ata_chapter"
											id="ata_chapter"
											value={data.ata_chapter ? data.ata_chapter : ''}
											error={error.ata_chapter ? true : false}
											helperText={error.ata_chapter ? error.ata_chapter : ""}
											onChange={(e) => { e.target.value.length <= 20 && (regexConstants.numericWithHyphen.test(e.target.value) || e.target.value === "") && this.onFieldChange('ata_chapter', e.target.value); this.onRestErrorKey("ata_chapter") }}
											onFocus={() => this.onRestErrorKey("ata_chapter")}
											onBlur={() => this.onFocusValidation("ata_chapter")}
										/>
									</Grid>

									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label={<React.Fragment> Part Number<span style={{ color: "red" }}> *</span> </React.Fragment>}
											name="part_number"
											id="part_number"
											value={data.part_number}
											error={error.part_number ? true : false}
											helperText={error.part_number ? error.part_number : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 20) {
													this.onFieldChange("part_number", input)
													this.onRestErrorKey("part_number")
												} else {
													const truncatedInput = input.slice(0, 20)
													this.onFieldChange("part_number", truncatedInput)
													this.onRestErrorKey("part_number")
												}
											}}
											onFocus={() => this.onRestErrorKey("part_number")}
											onBlur={() => this.onFocusValidation("part_number")}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextField
											variant="outlined"
											label={<React.Fragment> Part Serial Number <span style={{ color: "red" }}> *</span> </React.Fragment>}
											name="part_serial_number"
											id="part_serial_number"
											value={data.part_serial_number}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 20) {
													this.onFieldChange("part_serial_number", input)
													this.onRestErrorKey("part_serial_number")
												} else {
													const truncatedInput = input.slice(0, 20)
													this.onFieldChange("part_serial_number", truncatedInput)
													this.onRestErrorKey("part_serial_number")
												}
											}}
											error={error.part_serial_number ? true : false}
											helperText={error.part_serial_number ? error.part_serial_number : ""}
											onFocus={() => this.onRestErrorKey("part_serial_number")}
											onBlur={() => this.onFocusValidation("part_serial_number")}
										/>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={12}>
										<TextField
											variant="outlined"
											label={<React.Fragment> Part Description <span style={{ color: "red" }}> *</span> </React.Fragment>}
											name="part_description"
											id="part_description"
											multiline
											minRows={5}
											value={data.part_description}
											error={error.part_description ? true : false}
											helperText={
												error.part_description ? error.part_description : ""
											}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 255) {
													this.onFieldChange("part_description", input)
													this.onRestErrorKey("part_description")
												} else {
													const truncatedInput = input.slice(0, 255)
													this.onFieldChange("part_description", truncatedInput)
													this.onRestErrorKey("part_description")
												}
											}}
											onFocus={() => this.onRestErrorKey("part_description")}
											onBlur={() => this.onFocusValidation("part_description")}
										/>
									</Grid>
								</Grid>
								<Grid spacing={1} container></Grid>
								<div className="border-grey"></div>
								<Grid spacing={1} container>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={<React.Fragment> HT Requirement<span style={{ color: "red" }}> *</span> </React.Fragment>}
											name="ht_requirement"
											id="ht_requirement"
											value={data.ht_requirement}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 25) {
													this.onFieldChange("ht_requirement", input)
													this.onRestErrorKey("ht_requirement")
												} else {
													const truncatedInput = input.slice(0, 25)
													this.onFieldChange("ht_requirement", truncatedInput)
													this.onRestErrorKey("ht_requirement")
												}
											}}
											error={error.ht_requirement ? true : false}
											helperText={
												error.ht_requirement ? error.ht_requirement : ""
											}
											onFocus={() => this.onRestErrorKey("ht_requirement")}
											onBlur={() => this.onFocusValidation("ht_requirement")}
										/>
									</Grid>

									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={<React.Fragment> Task Card<span style={{ color: "red" }}> *</span> </React.Fragment>}
											name="task_card"
											id="task_card"
											value={data.task_card}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 20) {
													this.onFieldChange("task_card", input)
													this.onRestErrorKey("task_card")
												} else {
													const truncatedInput = input.slice(0, 20)
													this.onFieldChange("task_card", truncatedInput)
													this.onRestErrorKey("task_card")
												}
											}}
											error={error.task_card ? true : false}
											helperText={error.task_card ? error.task_card : ""}
											onFocus={() => this.onRestErrorKey("task_card")}
											onBlur={() => this.onFocusValidation("task_card")}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={<React.Fragment> Component<span style={{ color: "red" }}> *</span> </React.Fragment>}
											name="component"
											id="component"
											value={data.component}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 20) {
													this.onFieldChange("component", input)
													this.onRestErrorKey("component")
												} else {
													const truncatedInput = input.slice(0, 20)
													this.onFieldChange("component", truncatedInput)
													this.onRestErrorKey("component")
												}
											}}
											error={error.component ? true : false}
											helperText={error.component ? error.component : ""}
											onFocus={() => this.onRestErrorKey("component")}
											onBlur={() => this.onFocusValidation("component")}
										/>
									</Grid>
									<Grid item xs={3} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="part_installation_date"
												label={
													<React.Fragment>
														Part Installation Date
														<span style={{ color: "red" }}> *</span>
													</React.Fragment>
												}
												format={dayMonthDateFormat}
												inputVariant="outlined"
												// autoOk={true}
												fullWidth
												disableFuture
												InputLabelProps={{ shrink: true }}
												minDate={moment().subtract(25, "years")}
												value={data.part_installation_date}
												error={error.part_installation_date ? true : false}
												helperText={error.part_installation_date ? error.part_installation_date : ""}
												onChange={(data, value) => {
													this.onFieldChange("part_installation_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("part_installation_date")
												}}
												onBlur={() => this.onFocusValidation("part_installation_date")}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={
												<React.Fragment>
													{this.props.props.match.params.assetType == 1 ? " Aircraft TSN Installation" : "Engine TSN Installation"}
												</React.Fragment>
											}
											name="aircraft_tsn_installation"
											id="aircraft_tsn_installation"
											value={data.aircraft_tsn_installation}
											error={error.aircraft_tsn_installation ? true : false}
											helperText={error.aircraft_tsn_installation ? error.aircraft_tsn_installation : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("aircraft_tsn_installation", input)
													this.onRestErrorKey("aircraft_tsn_installation")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("aircraft_tsn_installation", truncatedInput)
													this.onRestErrorKey("aircraft_tsn_installation")
												} else {
													e.preventDefault()
												}
											}}
											onFocus={() => this.onRestErrorKey("aircraft_tsn_installation")}
											onBlur={() => this.onFocusValidation("aircraft_tsn_installation")}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={
												<React.Fragment>
													{this.props.props.match.params.assetType == 1 ? "Aircraft CSN Installation" : "Engine CSN Installation"}
												</React.Fragment>
											}
											name="aircraft_csn_installation"
											id="aircraft_csn_installation"
											value={data.aircraft_csn_installation}
											error={error.aircraft_csn_installation ? true : false}
											helperText={error.aircraft_csn_installation ? error.aircraft_csn_installation : ""}
											onChange={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("aircraft_csn_installation", input)
													this.onRestErrorKey("aircraft_csn_installation")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("aircraft_csn_installation", truncatedInput)
													this.onRestErrorKey("aircraft_csn_installation")
												} else {
													e.preventDefault()
												}
											}}
											onFocus={() => this.onRestErrorKey("aircraft_csn_installation")}
											onBlur={() => this.onFocusValidation("aircraft_csn_installation")}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="Part TSN Installation"
											name="part_tsn_installation"
											id="part_tsn_installation"
											value={data.part_tsn_installation}
											onChange={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("part_tsn_installation", input)
													this.onRestErrorKey("part_tsn_installation")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("part_tsn_installation", truncatedInput)
													this.onRestErrorKey("part_tsn_installation")
												} else {
													e.preventDefault()
												}
											}}
										/>
									</Grid>

									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label="Part CSN Installation"
											name="part_csn_installation"
											id="part_csn_installation"
											value={data.part_csn_installation}
											onChange={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("part_csn_installation", input)
													this.onRestErrorKey("part_csn_installation")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("part_csn_installation", truncatedInput)
													this.onRestErrorKey("part_csn_installation")
												} else {
													e.preventDefault()
												}
											}}
										/>
									</Grid>
								</Grid>

								<Grid spacing={1} container>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={<React.Fragment> Position<span style={{ color: "red" }}> *</span> </React.Fragment>}
											name="position"
											id="position"
											value={data.position}
											onChange={(e, value) => {
												const input = e.target.value
												if (input.length <= 20) {
													this.onFieldChange("position", input)
													this.onRestErrorKey("position")
												} else {
													const truncatedInput = input.slice(0, 20)
													this.onFieldChange("position", truncatedInput)
													this.onRestErrorKey("position")
												}
											}}
											error={error.position ? true : false}
											helperText={error.position ? error.position : ""}
											onFocus={() => this.onRestErrorKey("position")}
											onBlur={() => this.onFocusValidation("position")}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={
												<React.Fragment>
													TSO
													{data.dimension_type == "FH" ||
														data.dimension_type == "D" ||
														data.dimension_type == "FH,D" ||
														data.dimension_type == "FC,D" ? (
														<span style={{ color: "red" }}> *</span>
													) : null}
												</React.Fragment>
											}
											name="tso"
											id="tso"
											autoComplete="off"
											value={data.tso ? data.tso : ''}
											onChange={(e) => { this.onFieldChange('tso', e.target.value); this.onRestErrorKey("tso") }}
											// onChange={(e, value) => {
											// 	const input = e.target.value
											// 	if (
											// 		input.length <= 10 &&
											// 		regexConstants.onlyNumeric.test(e.target.value)
											// 	) {
											// 		this.onFieldChange("tso", input)
											// 		this.onRestErrorKey("tso")
											// 	} else if (
											// 		input.length > 10 &&
											// 		regexConstants.onlyNumeric.test(input)
											// 	) {
											// 		const truncatedInput = input.slice(0, 10)
											// 		this.onFieldChange("tso", truncatedInput)
											// 		this.onRestErrorKey("tso")
											// 	} else {
											// 		e.preventDefault()
											// 	}
											// }}
											error={error.tso ? true : false}
											helperText={error.tso ? error.tso : ""}
											onFocus={() => this.onRestErrorKey("tso")}
											onBlur={() => this.onFocusValidation("tso")}
										/>
									</Grid>
									<Grid item xs={3}>
										<TextField
											variant="outlined"
											label={
												<React.Fragment>
													CSO
													{data.dimension_type == "FC" ||
														data.dimension_type == "FC,D" ? (
														<span style={{ color: "red" }}> *</span>
													) : null}
												</React.Fragment>
											}
											autoComplete="off"
											name="cso"
											id="cso"
											value={data.cso}
											onChange={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("cso", input)
													this.onRestErrorKey("cso")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("cso", truncatedInput)
													this.onRestErrorKey("cso")
												} else {
													e.preventDefault()
												}
											}}
											onKeyPress={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("cso", input)
													this.onRestErrorKey("cso")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("cso", truncatedInput)
													this.onRestErrorKey("cso")
												} else {
													e.preventDefault()
												}
											}}
											onPaste={(e, value) => {
												const input = e.target.value
												if (
													input.length <= 10 &&
													regexConstants.onlyNumeric.test(e.target.value)
												) {
													this.onFieldChange("cso", input)
													this.onRestErrorKey("cso")
												} else if (
													input.length > 10 &&
													regexConstants.onlyNumeric.test(input)
												) {
													const truncatedInput = input.slice(0, 10)
													this.onFieldChange("cso", truncatedInput)
													this.onRestErrorKey("cso")
												} else {
													e.preventDefault()
												}
											}}
											error={error.cso ? true : false}
											helperText={error.cso ? error.cso : ""}
											onFocus={() => this.onRestErrorKey("cso")}
											onBlur={() => this.onFocusValidation("cso")}
										/>
									</Grid>
									<Grid item xs={3} className="input-calender">
										<MuiPickersUtilsProvider utils={MomentUtils}>
											<KeyboardDatePicker
												id="crs_date"
												label={<React.Fragment> CRS Date <span style={{ color: "red" }}> *</span> </React.Fragment>}
												format={dayMonthDateFormat}
												inputVariant="outlined"
												fullWidth
												disableFuture
												InputLabelProps={{ shrink: true }}
												value={data.crs_date}
												error={error.crs_date ? true : false}
												helperText={error.crs_date ? error.crs_date : ""}
												onChange={(data, value) => {
													this.onFieldChange("crs_date", moment(data).format(backendDateFormat))
													this.onRestErrorKey("crs_date")
												}}
												onBlur={() => this.onFocusValidation("crs_date")}
											/>
										</MuiPickersUtilsProvider>
									</Grid>
								</Grid>
								<div className="border-grey"></div>

								<Grid id="data-container" spacing={1} container>
									<Grid item xs={3}>
										<label>INTERVAL</label>
										<Grid spacing={2} container id="ht-dropdown">
											<Grid item>
												<FormControl>
													<InputLabel id="demo-simple-select-label">
														Dimension
													</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														variant="outlined"
														value={data.dimension_type}
														onChange={this.dropDownChange}
													>
														<MenuItem value={"D"}>Days</MenuItem>
														<MenuItem value={"FH"}>FH</MenuItem>
														<MenuItem value={"FC"}>FC</MenuItem>
														<MenuItem value={"FC,D"}>FC & D</MenuItem>
														<MenuItem value={"FH,D"}>FH & D</MenuItem>
														<MenuItem value={"FC,FH,D"}>FC,FH & D</MenuItem>
														<MenuItem value={"FC,FH"}>FC & FH</MenuItem>
													</Select>
												</FormControl>
											</Grid>
										</Grid>
									</Grid>

									<Grid id="dimensions-input-ht" item xs={3}>
										<Grid spacing={2} container>
											{data.dimension_type == "FC" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH" || data.dimension_type == "FC,FH,D" ? <Grid
												item
												xs={data.dimension_type == "FC" ? 12 : data.dimension_type == "FC,D" || data.dimension_type == "FC,FH" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}                      >
												<TextField
													variant="outlined"
													label={
														<React.Fragment>
															{data.dimension_type == "FC" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH,D" || data.dimension_type == "FC,FH" ? "FC Interval" : "FC, Interval"}
															<span style={{ color: "red" }}> *</span>
														</React.Fragment>
													}
													name="dimension_interval"
													id="dimension_interval"
													value={data.dimension_interval_fc}
													InputLabelProps={{ shrink: true }}
													onChange={(e, value) => {
														const input = e.target.value
														if (
															input.length <= 5 &&
															regexConstants.onlyNumeric.test(e.target.value)
														) {
															this.onFieldChange("dimension_interval_fc", input)
															this.onRestErrorKey("dimension_interval_fc")
														} else if (
															input.length > 5 &&
															regexConstants.onlyNumeric.test(input)
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("dimension_interval_fc", truncatedInput)
															this.onRestErrorKey("dimension_interval_fc")
															this.setState({ error: { dimension_interval_fc: "Please enter a 5 digit integer value.", }, })
														} else {
															e.preventDefault()
															this.setState({ error: { dimension_interval_fc: "Please enter a 5 digit integer value.", }, })
														}
													}}
													onKeyPress={(e, value) => {
														const input = e.target.value
														if (
															input.length <= 5 &&
															regexConstants.onlyNumeric.test(e.target.value)
														) {
															this.onFieldChange("dimension_interval_fc", input)
															this.onRestErrorKey("dimension_interval_fc")
														} else if (
															input.length > 5 &&
															regexConstants.onlyNumeric.test(input)
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("dimension_interval_fc", truncatedInput)
															this.onRestErrorKey("dimension_interval_fc")
															this.setState({ error: { dimension_interval_fc: "Please enter a 5 digit integer value.", }, })
														} else {
															e.preventDefault()
															this.setState({ error: { dimension_interval_fc: "Please enter a 5 digit integer value.", }, })
														}
													}}
													onPaste={(e, value) => {
														const input = e.target.value
														if (
															input.length <= 5 &&
															regexConstants.onlyNumeric.test(e.target.value)
														) {
															this.onFieldChange("dimension_interval", input)
															this.onRestErrorKey("dimension_interval")
														} else if (
															input.length > 5 &&
															regexConstants.onlyNumeric.test(input)
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("dimension_interval", truncatedInput)
															this.onRestErrorKey("dimension_interval")
															this.setState({ error: { dimension_interval: "Please enter a 5 digit integer value.", }, })
														} else {
															this.setState({ error: { dimension_interval: "Please enter a 5 digit integer value.", }, })
															e.preventDefault()
														}
													}}
													autoComplete="off"
													error={error.dimension_interval_fc ? true : false}
													helperText={error.dimension_interval_fc ? error.dimension_interval_fc : ""}
													onFocus={() => this.onRestErrorKey("dimension_interval")}
													onBlur={() => this.onFocusValidation("dimension_interval")}
												/>
											</Grid> : null}
											{data.dimension_type == "FH" || data.dimension_type == "FH,D" || data.dimension_type == "FC,FH" || data.dimension_type == "FC,FH,D" ? <Grid
												item
												xs={data.dimension_type == "FH" ? 12 : data.dimension_type == "FH,D" || data.dimension_type == "FC,FH" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}                      >
												<TextField
													variant="outlined"
													label={
														<React.Fragment>
															{data.dimension_type == "FH" || data.dimension_type == "FH,D" || data.dimension_type == "FC,FH,D" || data.dimension_type == "FC,FH" ? "FH Interval" : "FH, Interval"}
															<span style={{ color: "red" }}> *</span>
														</React.Fragment>
													}
													name="dimension_interval"
													id="dimension_interval"
													value={data.dimension_interval}
													InputLabelProps={{ shrink: true }}
													onChange={(e, value) => {
														const input = e.target.value
														if (
															input.length <= 5 &&
															regexConstants.onlyNumeric.test(e.target.value)
														) {
															this.onFieldChange("dimension_interval", input)
															this.onRestErrorKey("dimension_interval")
														} else if (
															input.length > 5 &&
															regexConstants.onlyNumeric.test(input)
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("dimension_interval", truncatedInput)
															this.onRestErrorKey("dimension_interval")
															this.setState({ error: { dimension_interval: "Please enter a 5 digit integer value.", }, })
														} else {
															e.preventDefault()
															this.setState({ error: { dimension_interval: "Please enter a 5 digit integer value.", }, })
														}
													}}
													onKeyPress={(e, value) => {
														const input = e.target.value
														if (
															input.length <= 5 &&
															regexConstants.onlyNumeric.test(e.target.value)
														) {
															this.onFieldChange("dimension_interval", input)
															this.onRestErrorKey("dimension_interval")
														} else if (
															input.length > 5 &&
															regexConstants.onlyNumeric.test(input)
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("dimension_interval", truncatedInput)
															this.onRestErrorKey("dimension_interval")
															this.setState({ error: { dimension_interval: "Please enter a 5 digit integer value.", }, })
														} else {
															e.preventDefault()
															this.setState({ error: { dimension_interval: "Please enter a 5 digit integer value.", }, })
														}
													}}
													onPaste={(e, value) => {
														const input = e.target.value
														if (
															input.length <= 5 &&
															regexConstants.onlyNumeric.test(e.target.value)
														) {
															this.onFieldChange("dimension_interval", input)
															this.onRestErrorKey("dimension_interval")
														} else if (
															input.length > 5 &&
															regexConstants.onlyNumeric.test(input)
														) {
															const truncatedInput = input.slice(0, 5)
															this.onFieldChange("dimension_interval", truncatedInput)
															this.onRestErrorKey("dimension_interval")
															this.setState({ error: { dimension_interval: "Please enter a 5 digit integer value.", }, })
														} else {
															this.setState({ error: { dimension_interval: "Please enter a 5 digit integer value.", }, })
															e.preventDefault()
														}
													}}
													autoComplete="off"
													error={error.dimension_interval ? true : false}
													helperText={error.dimension_interval ? error.dimension_interval : ""}
													onFocus={() => this.onRestErrorKey("dimension_interval")}
													onBlur={() => this.onFocusValidation("dimension_interval")}
												/>
											</Grid> : null}
											{data.dimension_type == "D" || data.dimension_type == "FC,D" || data.dimension_type == "FC,FH,D" ||
												data.dimension_type == "FH,D" ? (
												<Grid item xs={data.dimension_type == "D" ? 12 : data.dimension_type == "FC,D" || data.dimension_type == "FH,D" ? 6 : data.dimension_type == "FC,FH,D" ? 4 : 12}>
													<TextField
														variant="outlined"
														label={
															<React.Fragment>
																Days<span style={{ color: "red" }}> *</span>
															</React.Fragment>
														}
														name="dimension_interval_days"
														InputLabelProps={{ shrink: true }}
														id="dimension_interval_days"
														value={data.dimension_interval_days}
														onChange={(e, value) => {
															const input = e.target.value
															if (
																input.length <= 5 &&
																regexConstants.onlyNumeric.test(e.target.value)
															) {
																this.onFieldChange("dimension_interval_days", input)
																this.onRestErrorKey("dimension_interval_days")
															} else if (
																input.length > 5 &&
																regexConstants.onlyNumeric.test(input)
															) {
																const truncatedInput = input.slice(0, 5)
																this.onFieldChange("dimension_interval_days", truncatedInput)
																this.onRestErrorKey("dimension_interval_days")
																this.setState({ error: { dimension_interval_days: "Please enter a 5 digit integer value.", }, })
															} else {
																e.preventDefault()
																this.setState({ error: { dimension_interval_days: "Please enter a 5 digit integer value.", }, })
															}
														}}
														onKeyPress={(e, value) => {
															const input = e.target.value
															if (
																input.length <= 5 &&
																regexConstants.onlyNumeric.test(e.target.value)
															) {
																this.onFieldChange("dimension_interval_days", input)
																this.onRestErrorKey("dimension_interval_days")
															} else if (
																input.length > 5 &&
																regexConstants.onlyNumeric.test(input)
															) {
																const truncatedInput = input.slice(0, 5)
																this.onFieldChange("dimension_interval_days", truncatedInput)
																this.onRestErrorKey("dimension_interval_days")
																this.setState({ error: { dimension_interval_days: "Please enter a 5 digit integer value.", }, })
															} else {
																e.preventDefault()
																this.setState({ error: { dimension_interval_days: "Please enter a 5 digit integer value.", }, })
															}
														}}
														onPaste={(e, value) => {
															const input = e.target.value
															if (
																input.length <= 5 &&
																regexConstants.onlyNumeric.test(e.target.value)
															) {
																this.onFieldChange("dimension_interval_days", input)
																this.onRestErrorKey("dimension_interval_days")
															} else if (
																input.length > 5 &&
																regexConstants.onlyNumeric.test(input)
															) {
																const truncatedInput = input.slice(0, 5)
																this.onFieldChange("dimension_interval_days", truncatedInput)
																this.onRestErrorKey("dimension_interval_days")
																this.setState({ error: { dimension_interval_days: "Please enter a 5 digit integer value.", }, })
															} else {
																this.setState({ error: { dimension_interval_days: "Please enter a 5 digit integer value.", }, })
																e.preventDefault()
															}
														}}
														autoComplete="off"
														error={error.dimension_interval_days ? true : false}
														helperText={
															error.dimension_interval_days
																? error.dimension_interval_days
																: ""
														}
														onFocus={() => this.onRestErrorKey("dimension_interval_days")}
														onBlur={() => this.onFocusValidation("dimension_interval_days")}
													/>
												</Grid>
											) : null}
										</Grid>
									</Grid>
									<Grid item xs={3}>
										<label>REMAINING</label>
										<Grid spacing={1} container>
											<Grid className="remaining-input" item xs={6}>
												<TextField
													variant="outlined"
													label={
														data.dimension_type === "D"
															? "Days"
															: data.dimension_type === "FC"
																? "FC"
																: data.dimension_type === "FH"
																	? "FH"
																	: data.dimension_type === "FH,D"
																		? "FH/Days"
																		: data.dimension_type === "FC,D"
																			? "FC/Days"
																			: data.dimension_type === "FC,FH,D"
																				? "FC/FH/Days"
																				: data.dimension_type === "FC,FH"
																					? "FC/FH"
																					: "Days"
													}
													name="remaining_val"
													id="remaining_val"
													// value={data.ht_remaining_days}
													value={this.remainingVal()}
													inputProps={{ readOnly: true }}
													disabled
													className="disabled-input"
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={3}>
										<label>DUE </label>
										<Grid spacing={2} container>
											<Grid className="remaining-input" item xs={6}>
												<TextField
													variant="outlined"
													label={
														data.dimension_type === "D"
															? "Date"
															: data.dimension_type === "FC"
																? "FC"
																: data.dimension_type === "FH"
																	? "FH"
																	: data.dimension_type === "FH,D"
																		? "FH/Date"
																		: data.dimension_type === "FC,D"
																			? "FC/Date"
																			: data.dimension_type === "FC,FH,D"
																				? "FC/FH/Date"
																				: data.dimension_type === "FC,FH"
																					? "FC/FH "
																					: "Date"
													}
													name="ht_DUE"
													id="ht_DUE"
													// value={data.ht_remaining_days}
													value={this.nextDueVal()}
													// onChange={(e, value) =>
													//   this.onFieldChange("ht_DUE", e.target.value)
													// }
													inputProps={{ readOnly: true }}
													InputLabelProps={{ shrink: true }}
													disabled
													className="disabled-input"
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>

								<Grid id="data-container" spacing={1} container>
									<Grid item xs={2}>
										<label>
											FAA / EASA Certificate
											<span style={{ color: "red" }}> *</span>
										</label>
										<FormControl
											error={error.faa_easa ? true : false}
											helperText={error.faa_easa ? error.faa_easa : ""}
											onFocus={() => this.onRestErrorKey("faa_easa")}
										>
											<RadioGroup
												required
												row
												aria-label="faa_easa"
												name="faa_easa"
												defaultValue="top"
												value={data.faa_easa}
												onChange={(e, value) => this.onFieldChange("faa_easa", e.target.value)}
												onBlur={() => this.onFocusValidation("faa_easa")}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
											</RadioGroup>
											{error.faa_easa && (
												<FormLabel component="faa_easa"> <span style={{ color: "red" }}>{error.faa_easa}</span> </FormLabel>
											)}
										</FormControl>
									</Grid>

									<Grid item xs={2}>
										<label>Shop Report </label>
										<FormControl component="fieldset">
											<FormLabel component="legend"></FormLabel>
											<RadioGroup
												row
												aria-label="shop_report"
												name="shop_report"
												defaultValue="top"
												value={data.shop_report}
												onChange={(e, value) => this.onFieldChange("shop_report", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>

									<Grid item xs={3}>
										<label>Certificate of Conformity</label>
										<FormControl component="fieldset">
											<FormLabel component="legend"></FormLabel>
											<RadioGroup
												row
												aria-label="conformity_crt"
												name="conformity_crt"
												defaultValue="top"
												value={data.conformity_crt}
												onChange={(e, value) => this.onFieldChange("conformity_crt", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
												<FormControlLabel
													value="N/A"
													control={<Radio size="small" color="primary" />}
													label="N/A"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>

									<Grid item xs={2}>
										<label>Goods receipt note</label>
										<FormControl component="fieldset">
											<FormLabel component="legend"></FormLabel>
											<RadioGroup
												row
												aria-label="goods_rec_note"
												name="goods_rec_note"
												defaultValue="top"
												value={data.goods_rec_note}
												onChange={(e, value) => this.onFieldChange("goods_rec_note", e.target.value)}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
											</RadioGroup>
										</FormControl>
									</Grid>

									<Grid item xs={3}>
										<label>
											Installation Work order
											<span style={{ color: "red" }}> *</span>
										</label>
										<FormControl
											error={error.installation_wo ? true : false}
											helperText={error.installation_wo ? error.installation_wo : ""}
											onFocus={() => this.onRestErrorKey("installation_wo")}
										>
											<RadioGroup
												required
												row
												aria-label="installation_wo"
												name="installation_wo"
												defaultValue="top"
												value={data.installation_wo}
												onChange={(e, value) => this.onFieldChange("installation_wo", e.target.value)}
												onBlur={() => this.onFocusValidation("installation_wo")}
											>
												<FormControlLabel
													value="yes"
													control={<Radio size="small" color="primary" />}
													label="Yes"
												/>
												<FormControlLabel
													value="no"
													control={<Radio size="small" color="primary" />}
													label="No"
												/>
												<FormControlLabel
													value="N/A"
													control={<Radio size="small" color="primary" />}
													label="N/A"
												/>
											</RadioGroup>
											{error.installation_wo && (
												<FormLabel component="installation_wo">
													<span style={{ color: "red" }}> {error.installation_wo} </span>
												</FormLabel>
											)}
										</FormControl>
									</Grid>
								</Grid>
								<Grid spacing={1} container>
									<Grid item xs={6}>
										<TextField
											label="Remark"
											margin='normal'
											variant='outlined'
											fullWidth
											value={data.remark}
											InputLabelProps={{ shrink: true }}
											onChange={(e) => this.onFieldChange("remark", e.target.value)}
											rows={3}
											multiline
											inputProps={{ maxLength: 255 }}
										/>
									</Grid>
									<Grid item xs={6}>
										<div className="upload-amp-textbox" style={{ border: "1px solid gray", textAlign: 'center' }}>
											<ul className="list-inline spacing-list">
												<li className="list-inline-item" onClick={this.handleOpenUploadFile}>
													<span className="flex-centered"><AttachmentIcon color='primary' fontSize='small' /> Upload File</span>
												</li>
												<li className="list-inline-item" onClick={this.handleOpenFile}>
													<span className="flex-centered"><LinkIcon color='primary' fontSize='small' />Link file from Records Module</span>
												</li>
											</ul>
										</div>
									</Grid>
								</Grid>
								<Grid id="data-container" spacing={1} container>
									<Grid spacing={1} container>
										{this.props.actionType === 'add' ?
											<div className='checklist-files'>
												<ul className='list-inline'>
													{data?.checklist?.map((file) =>
														<li className='list-inline-item'>
															<span className='file-name'>{file.name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onRemoveFile(file)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
													{attachments?.map((attachments) =>
														<li className='list-inline-item'>
															<span className='file-name'>{attachments.file_name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onRemoveCamoFile(attachments)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
												</ul>
											</div>
											:
											<div className='checklist-files'>
												<ul className='list-inline'>
													{data?.checklist?.files?.map((file) =>
														<li className='list-inline-item'>
															<span className='file-name'>{file.name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(file.path)} download={file.name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
													{(data.checklist?.folders?.length > 0) &&
														data.checklist?.folders.map((file) => {
															return (
																<li className='list-inline-item' key={file.id}>
																	<Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
																		<a href={`${config.domain.records}${assetType[this.props.props?.match.params.assetType]}/${file?.asset_slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
																	</Tooltip>
																	<span className='file-name'>{file.name}</span>
																	<span className='file-remove'>
																		<CloseIcon onClick={() => this.onUnlinkCheckListFile(data.checklist, file.id)} color='secondary' fontSize='small' />
																	</span>
																</li>
															);
														})}
													{attachments?.map((attachments) =>
														<li className='list-inline-item'>
															<span className='file-name'>{attachments.file_name}</span>
															<span className='file-remove'>
																<a href={decodeURIComponent(attachments.file)} download={attachments.file_name}>  <GetAppIcon color="primary" /></a>
																<CloseIcon onClick={() => this.deleteAttachmentFile(attachments.id, attachments)} color='secondary' fontSize='small' />
															</span>
														</li>
													)}
												</ul>
											</div>}
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions className="actions-btn">
								<Button
									type="button"
									className="btn btn-primary"
									onClick={this.props.actionType === "add" ? (e) => this.addFormData(e) : (e) => this.updateData(e)}
								>
									{this.props.actionType === "add" ? "Add" : "Update"}
								</Button>

								{this.props.actionType === "add" ? (
									<Button onClick={(e) => this.saveAndAddAnother(e)} type="button" className="btn btn-primary" > Save & Add Another </Button>
								) : (
									""
								)}
								<Button type="button" className="btn btn-primary" onClick={() => { this.props.closeAddForm(); this.listResponseBack() }} > Close </Button>
							</DialogActions>
							<Dialog
								open={this.state.open}
								onClose={this.handleCloseAttachment}
								id="htDialog"
							>
								<HtConfirmDeleteAttachment
									handleCloseAttachment={() => this.handleCloseAttachment()}
									deleteAttachmentFile={() => this.deleteAttachmentFile(this.state.deleteAttachmentId)}
								/>
							</Dialog>
							<Dialog open={this.state.linkFile} onClose={this.handleCloseFile} id="htDialog">
								<LinkFiles
									handleClose={() => this.handleCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, any, type) => this.onLinkFile(file, any, type)}
									modType="ht"
									LinkFileInfo={data}
									last_used_folder_uuid={this.props.last_used_folder_uuid_link_document}
									uploadFileInfo={data}
									attachments={attachments}
									type="link"
								/>
							</Dialog>
							<Dialog open={this.state.uploadFile} onClose={this.handleUploadFileCloseFile} id="htDialog">
								<UploadFile
									handleClose={() => this.handleUploadFileCloseFile()}
									uuid={this.props?.uuid?.uuid}
									editFormId={this.props.editFormId}
									getResponseBack={(file) => this.getResponseBack(file)}
									listResponseBack={(file) => this.listResponseBack(file)}
									actionType={this.props.actionType}
									onLinkFile={(file, uuid) => this.onLinkFile(file, uuid)}
									modType="ht"
									uploadFileInfo={data}
									last_used_folder_uuid={this.props.last_used_folder_uuid}
									onUpload={(file) => this.onUpload(file)}
									type="upload"
									checkbox_last_used_folder_uuid={this.props.checkbox_last_used_folder_uuid}
									checkbox_last_used_folder_name={this.props.checkbox_last_used_folder_name}
								/>
							</Dialog>
						</form>
					</div>
				</div>
			</Fragment>
		)
	}
}

export default HtTaskForm