import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Tooltip, IconButton, Dialog, } from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined"
import { withRouter } from "react-router-dom";
import { smbcInstance} from '../..'; 
const BasicDetailsList = ({ basicDetailData, match, toggleModalFn }) => {
	const lhData = basicDetailData?.engine_detail?.length > 0 ? basicDetailData?.engine_detail.find((x) => x.position.value === 1 || x.position.value === 3) : {};
	const rhData = basicDetailData?.engine_detail?.length > 0 ? basicDetailData?.engine_detail.find((x) => x.position.value === 2 || x.position.value === 4) : {};

	return (
		<div id="AmpTableHeader" className='basic-detail-listing'>
			<Accordion defaultExpanded >
				<AccordionSummary expandIcon={<ExpandMoreIcon className="p-5" />} aria-controls="panel1a-content" id="panel1a-header" >
					<Typography>Basic Details</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container>
						<Grid className="left-panel" item xs={12} style={{ backgroundColor: " #efefef" }} >
							<Table className="table-wrapper">
								<TableHead>
									<TableRow>
										<TableCell>{match.params.assetType == 1 ? "Aircraft Type/Model" : "Engine Type/Model"}</TableCell>
										<TableCell>{match.params.assetType == 1 ? "MSN" : "ESN"}</TableCell>
										{match.params.assetType == 1 && <TableCell>Registration</TableCell>}
										<TableCell>Date of Manufacture</TableCell>
										<TableCell> {match.params.assetType == 1 ? "A/C TSN" : "Engine TSN"}</TableCell>
										<TableCell> {match.params.assetType == 1 ? "A/C CSN" : "Engine CSN"}</TableCell>
										{smbcInstance ? null :<div id="edit-basic-detail" className="d-flex">
											<span class="editBasicDetail">
												<Tooltip title="Edit">
													<IconButton className="edit-asset-icon" onClick={toggleModalFn} > <CreateOutlinedIcon /> </IconButton>
												</Tooltip>
											</span>
										</div>}
									</TableRow>
								</TableHead>
								<TableBody>
									<TableRow>
										<TableCell className="font-b"> {match.params.assetType == 1 ? basicDetailData?.aircraft_type_name : basicDetailData?.engine_type_name} </TableCell>
										<TableCell className="font-b"> {match.params.assetType == 1 ? basicDetailData?.msn : basicDetailData?.esn} </TableCell>
										{match.params.assetType == 1 && <TableCell className="font-b"> {basicDetailData?.current_registration_number || " -- "} </TableCell>}
										<TableCell className="font-b"> {basicDetailData?.date_of_manufacture || " -- "} </TableCell>
										<TableCell className="font-b"> {basicDetailData?.tsn ? (basicDetailData?.tsn).toFixed(2) : "--"} </TableCell>
										<TableCell className="font-b"> {basicDetailData?.csn ? (basicDetailData?.csn).toFixed(2) : "--"} </TableCell>
									</TableRow>
								</TableBody>
								{match.params.assetType == 1 && <TableHead className="table-head">
									<TableRow>
										<TableCell>LH Engine Type(Serial No)</TableCell>
										<TableCell>LH Engine TSN</TableCell>
										<TableCell>LH Engine CSN</TableCell>
										<TableCell>RH Engine Type/Serial No</TableCell>
										<TableCell>RH Engine TSN</TableCell>
										<TableCell>RH Engine CSN</TableCell>
									</TableRow>
								</TableHead>}
								{match.params.assetType == 2 && <TableHead className="table-head">
									<TableRow>
										<TableCell>TSLV</TableCell>
										<TableCell>CSLV</TableCell>
										<TableCell>LLP Limiter(s)</TableCell>
										<TableCell>Soft-time Limiter(s)</TableCell>
										<TableCell>Op. Thrust (Current)</TableCell>
									</TableRow>
								</TableHead>}
								{match.params.assetType == 1 && <TableBody>
									<TableRow>
										<TableCell className="font-b"> {lhData?.engine_type?.name ? `${lhData?.engine_type?.name + " "}(${lhData?.esn})` : " -- "} </TableCell>
										<TableCell className="font-b"> {lhData?.tsn || " -- "} </TableCell>
										<TableCell className="font-b"> {lhData?.csn || " -- "} </TableCell>
										<TableCell className="font-b"> {rhData?.engine_type?.name ? `${rhData?.engine_type?.name + " "}(${rhData?.esn})` : " -- "} </TableCell>
										<TableCell className="font-b"> {rhData?.tsn || " -- "} </TableCell>
										<TableCell className="font-b"> {rhData?.csn || " -- "} </TableCell>
									</TableRow>
								</TableBody>}
								{match.params.assetType == 2 && <TableBody>
									<TableRow>
										<TableCell className="font-b"> {basicDetailData?.tslv ? basicDetailData?.tslv.toFixed(2) : "--"} </TableCell>
										<TableCell className="font-b"> {basicDetailData?.cslv ? basicDetailData?.cslv.toFixed(2) : "--"} </TableCell>
										<TableCell className="font-b"> {basicDetailData?.llp_limiter || "--"} </TableCell>
										<TableCell className="font-b"> {basicDetailData?.Soft_time_limiter || "--"} </TableCell>
										<TableCell className="font-b"> {basicDetailData?.current_operating_thrust?.name || " -- "} </TableCell>
									</TableRow>
								</TableBody>}
								{match.params.assetType == 2 && <TableHead className="table-head">
									<TableRow>
										<TableCell>Reporting Month</TableCell>
									</TableRow>
								</TableHead>}
								{match.params.assetType == 2 && <TableBody>
									<TableRow>
										<TableCell className="font-b"> {basicDetailData?.last_utilization_updated ? basicDetailData?.last_utilization_updated : " -- "} </TableCell>
									</TableRow>
								</TableBody>}
							</Table>
						</Grid>
					</Grid>
				</AccordionDetails>
			</Accordion>
		</div>
	)
}

export default withRouter(BasicDetailsList)