import React, { useState } from "react";
import { TableCell, TableRow, Tooltip, IconButton } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { withRouter } from "react-router-dom";
import RevisionHistory from "../../../../shared_elements/components/RevisionHistory";
import moment from 'moment';
import { smbcInstance } from "../..";
import AttachFileIcon from "@material-ui/icons/AttachFile";

const SbListing = ({ item, toggleModalFn, toggleDelteModalFn,handleOpenAttachments }) => {

	const [lessMore, setLessMore] = useState({});
	const toggleLessMore = (id) => {
		setLessMore({
			...lessMore,
			[id]: !lessMore[id],
		});
	};
    const lessMoreFn = (name) => {
        return <>
            {name && name.length > 22 ? (
                <>
                    {lessMore[name] ?
                        (<> {name} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..less </span> </>)
                        :
                        (<>{name.substring(0, 22)} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..more </span> </>)
                    }
                </>
            ) : name || '--'
            }
        </>
    }
	function getAttachmentCount(item) {
        const { attachments, checklist } = item;
        const { files, folders } = checklist || {};
      
        const attachmentCount = attachments?.length || 0;
        const fileCount = files?.length || 0;
        const folderCount = folders?.length || 0;
      
        if (attachmentCount && fileCount && folderCount) {
          return attachmentCount + fileCount + folderCount;
        } else if (fileCount && folderCount) {
          return fileCount + folderCount;
        } else if (attachmentCount && folderCount) {
          return attachmentCount + folderCount;
        } else if (attachmentCount && fileCount) {
          return attachmentCount + fileCount;
        } else {
          return folderCount || fileCount || attachmentCount || '--';
        }
      }
    const attachmentCount = getAttachmentCount(item);
	const sbUrl = `audit/camo/sbstatus/?object_id=${item.id}&action=0,1&timezone=${moment().format()}&timestamp=${Math.floor(Date.now() / 1000)}`

	return (
		<TableRow>
			<TableCell>
				<Tooltip title="Edit" placement='top'>
					<IconButton color='primary' style={{ padding: 'unset' }} onClick={toggleModalFn} >
						<CreateOutlinedIcon size='small' />
					</IconButton>
				</Tooltip>
				<Tooltip title="Delete" placement='top' >
					<IconButton color='secondary' style={{ padding: 'unset' }} onClick={toggleDelteModalFn}>
						<DeleteOutlinedIcon />
					</IconButton>
				</Tooltip>
				<RevisionHistory buttonType={true} url={sbUrl} />
			</TableCell>
			{smbcInstance ? null : <TableCell>{item.ata_chapter ? item.ata_chapter : "--"}</TableCell>}
			<TableCell>{item.sb_ref_number ? item.sb_ref_number : "--"}</TableCell>
			{smbcInstance ? <TableCell>{item.sb_category ? item.sb_category : "--"}</TableCell> : null}
			{smbcInstance ? null : <TableCell>{item.sb_issued_by ? item.sb_issued_by : "--"}</TableCell>}
			{smbcInstance ? null : <TableCell> {item.sb_applicability ? item.sb_applicability : "--"} </TableCell>}
			<TableCell>{lessMoreFn(item.description) || '--'} </TableCell>
			{smbcInstance ? null : <TableCell>{item.type ? item.type : "--"}</TableCell>}
			{smbcInstance ? null : <TableCell>{item.effectivity ? item.effectivity : "--"}</TableCell>}
			{smbcInstance ? null : <TableCell> {item.effectivity_date ? item.effectivity_date : "--"} </TableCell>}
			{smbcInstance ? null : <TableCell> {item.sb_interval_type ? item.sb_interval_type : "--"} </TableCell>}
			<TableCell> {item.sb_compliance_status ? item.sb_compliance_status : "--"} </TableCell>
			<TableCell> {item.sb_compliance_tsn ? item.sb_compliance_tsn : "--"} </TableCell>
			<TableCell> {item.sb_compliance_csn ? item.sb_compliance_csn : "--"} </TableCell>
			<TableCell> {item.sb_compliance_date ? item.sb_compliance_date : "--"} </TableCell>
			{smbcInstance ? null : <TableCell> {item.remaining.due_at ? item.sb_interval_type === "D" ? item.remaining.due_at : item.remaining.due_at : "--"} </TableCell>}
			{smbcInstance ? null : <TableCell> {item.remaining.remaining ? item.remaining.remaining : "--"} </TableCell>}
			{smbcInstance ? <TableCell>{lessMoreFn(item.remark) || '--'}  </TableCell> : null}
			<TableCell>
                {attachmentCount && (
                    <Tooltip title="View Attachments">
                        <span className="center">
                            <AttachFileIcon className="attach-icon" onClick={handleOpenAttachments} />
                            <a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
                        </span>
                    </Tooltip>
                )}
            </TableCell>
		</TableRow>
	)
}

export default withRouter(SbListing)