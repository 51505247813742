import OccmListing from "./OccmListing";
import OccmHeader from "./OccmHeader";
import AddEditOccm from "./AddEditOccm";
export {
    OccmListing,
    OccmHeader,
    AddEditOccm
}

export const occmObj = {
    asset_id: null,
    asset_type_id: null,
    ata_chapter: null,
    part_description: "",
    part_number: null,
    part_serial_number: null,
    part_installation_date: null,
    aircraft_tsn: null,
    aircraft_csn: null,
    part_tsn: null,
    part_csn: null,
    remark: "",
    component: null,
    position: null,
    occm_requirement: null,
    faa_easa_certificate: null,
    shop_report: null,
    conformity_certificate: null,
    receipt_note: null,
    work_order: null,
    attachments: [],
    checklist: [],
    folder_uuid: '',
    linkFile:''
}