import { React, useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Tooltip, CircularProgress, InputLabel, Select, MenuItem } from "@material-ui/core"
import { KeyboardDatePicker, MuiPickersUtilsProvider, } from "@material-ui/pickers"
import CloseIcon from "@material-ui/icons/Close"
import MomentUtils from "@date-io/moment"
import AttachmentIcon from '@material-ui/icons/Attachment';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import GetAppIcon from '@material-ui/icons/GetApp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { backendDateFormat, fieldDateFormat } from "../../../../constants"
import moment from "moment";
import config from "../../../../config";
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { useSnackbar } from 'notistack';
import { globalPutService, globalPostService, globalGetService, globalFileUploadService, globalDeleteService } from '../../../../utils/globalApiServices';
import { PageLoader } from '../../../../shared_elements';
import { regexConstants } from '../../../../constants/regEx';
import { checkApiStatus, getLocalStorageInfo } from '../../../../utils';
import SectionCreates from "../../../../shared_elements/components/SectionCreates"
import LinkFiles from "../../../../shared_elements/components/LinkFiles"
import UploadFile from "../../../../shared_elements/components/UploadFile"
import { inventoryObj } from '.';
import { assetType, imgStoragePath } from "../../../../constants";
import { specificKey, specificMessTsn, specificMessCsn } from '../..';


function AddEditInventory({ match, toggleModalFn, inventoryData, basicdetail, modeType, getResponseBack, list }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(inventoryData ? inventoryData : inventoryObj)
    const [error, setError] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [uploadFile, setUploadFile] = useState({ modal: false, data: null })
    const [linkFile, setlinkFile] = useState({ modal: false, data: null })
    const [sectionList, setSectionList] = useState([])

    useEffect(() => {
        getInventoryList()
    }, [])

    const onFieldChange = (keyParam, value) => {
        setData({ ...data, [keyParam]: value });
    }
    const dropDownChange = (e, keyParam, value) => {
        setData({ ...data, [keyParam]: value });
    }
 
    const uploadFileData = (file) => {
        setData({ ...data, checklist: file })
    }

    const getData = (selectedFile, type) => {
        setData({ ...data, checklist: selectedFile, linkFile: type });
    }

    const uploadFileFn = (file, uuid, type) => {
        if (uuid) {
            let storeChecklistData = [];
            let folderUuid = [];

            const updatedChecklist = data?.checklist ? [...data.checklist, file] : file;
            const folderUUID = data?.folder_uuid ? [...data?.folder_uuid, uuid] : uuid;

            for (const arr of updatedChecklist) {
                storeChecklistData = storeChecklistData.concat(arr);
            }
            for (const arr of folderUUID) {
                folderUuid = folderUuid.concat(arr);
            }
            setData({ ...data, checklist: storeChecklistData, folder_uuid: folderUuid })
        } else {
            if (Array.isArray(file)) {
                const updatedAttachments = [...(data?.attachments || []), ...file];
                setData({ ...data, 'attachments': updatedAttachments });
            } else {
                console.error("Value for attachments is not an array:", file);
            }
        }
    }
    const addUploadFile = (item) => {
        if (data?.linkFile === "link") {
            let payload = {};
            if (data?.checklist.some(item => item.type === 'folder') && data?.checklist.some(item => item.type === 'file')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            } else if (data?.checklist.some(item => item.type === 'folder')) {
                payload.folder_ids = data?.checklist.filter(item => item.type === 'folder').map(file => file.id).join(',');
            } else {
                payload.file_ids = data?.checklist.filter(item => item.type === 'file').map(file => file.id).join(',');
            }
            globalPutService(`camo/inventory/${item?.id}/document-checklists/`, payload)
                .then(response => {
                    if (checkApiStatus(response)) {
                        toggleModalFn()
                        getResponseBack(response.data.data);
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    } else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                    }
                });
        }
        if (data?.folder_uuid?.length > 0) {
            if (data?.folder_uuid?.length > 0 && data.folder_uuid.filter(item => item !== undefined)) {
                let formData = new FormData();
                formData.append('folder_uuid', data.folder_uuid.filter(item => item !== undefined).join(''));
                for (let i = 0; i < data.checklist?.filter(item => item instanceof File).length; i++) {
                    formData.append('files[]', data.checklist?.filter(item => item instanceof File).length && data.checklist?.filter(item => item instanceof File)[i]);
                }
                globalPutService(`camo/inventory/${item?.id}/document-checklists/`, formData)
                    .then(response => {
                        if (checkApiStatus(response)) {
                            getResponseBack()
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
                        }
                    });
            }
        }

    }
    const importFile = (file) => {
        var formData = new FormData();
        Object.keys(data?.attachments.filter(item => item instanceof File)).map((key) => {
            formData.append("[" + key + "]file", data?.attachments.filter(item => item instanceof File)[key])
            formData.append("[" + key + "]file_type", data?.attachments.filter(item => item instanceof File)[key].type)
            formData.append("[" + key + "]file_name", data?.attachments.filter(item => item instanceof File)[key].name)
            formData.append("[" + key + "]module_id", file?.id)
            formData.append("[" + key + "]module_type_id", 10)
            formData.append("[" + key + "]asset_type_id", match.params.assetType)
            formData.append("[" + key + "]asset_id", match.params.asset)
        })
        globalFileUploadService(`camo/attachment/upload/`, formData)
            .then(response => {
                toggleModalFn(); getResponseBack()
                if (checkApiStatus(response)) {
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" }, })
                }
            })

    }
    const onRemoveFile = (file, type, fileId, folder) => {
        if (modeType === "add") {
            if (type === "checklist") {
                setData({ ...data, checklist: data.checklist?.filter(item => item.name !== file.name) });
            } else {
                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
            }
        } else {
            if (type === "checklist") {
                let payload = {};
                payload.delete = true;
                payload.folder_ids = data.checklist?.folders?.filter(items => items.type === 'folder').map(file => file.id).filter((id) => id !== fileId).join(',');
                payload.file_ids = data.checklist?.files?.filter(items => items.type === 'file').map(file => file.id).filter((id) => id !== fileId).join(',');
                globalPutService(`camo/inventory/${data.id}/document-checklists/`, payload)
                    .then((response) => {
                        if (checkApiStatus(response)) {
                            const updatedChecklist = { ...data.checklist };
                            updatedChecklist.files = updatedChecklist.files.filter((file) => file.id !== fileId);
                            updatedChecklist.folders = updatedChecklist.folders.filter((file) => file.id !== fileId);
                            setData({ ...data, checklist: updatedChecklist });
                            getResponseBack()
                        }
                    });
            } else {
                if (file.id) {
                    globalDeleteService(`camo/attachment/${file.id}/delete/`)
                        .then((response) => {
                            if (checkApiStatus(response)) {
                                setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                                getResponseBack()
                            }
                        });
                } else {
                    setData({ ...data, attachments: data.attachments.filter(item => item.name !== file.name) });
                }
            }
        }
    }
    const getInventoryList = () => {
        setLoading(true)
        globalGetService(`camo/inventory/section/`)
            .then((response) => {
                setLoading(false)
                if (checkApiStatus(response)) {
                    setSectionList(response.data?.data)
                }

            })
    }
    const onAddNewAsset = () => {
        let validationInputs = {}
        if (!getLocalStorageInfo()?.user?.security_groups?.some(item => item?.name === 'CAMO Lead')) {
            validationInputs = {
                ...validationInputs,
                description: data.description ? '' : 'Please Enter Description',
            }
        }
        if (typeof data.csn === 'string' && !specificKey.includes(data.csn.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.csn)) {
            validationInputs.csn = specificMessCsn;
        }
        if (typeof data.tsn === 'string' && !specificKey.includes(data.tsn.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.tsn)) {
            validationInputs.tsn = specificMessTsn;
        }
        if (typeof data.cso === 'string' && !specificKey.includes(data.cso.toUpperCase()) && !/^[0-9]*(\.\d{0,0})?$/.test(data.cso)) {
            validationInputs.cso = specificMessCsn;
        }
        if (typeof data.tso === 'string' && !specificKey.includes(data.tso.toUpperCase()) && !regexConstants.numberWithDotRegex.test(data.tsn)) {
            validationInputs.tso = specificMessTsn;
        }
        if (Object.keys(validationInputs).every((k) => { return validationInputs[k] === '' })) {
            setLoading(true)
            let payload = Object.assign({}, data);
            if (data?.id) {
                globalPutService(`camo/inventory/${payload.id}/`, { ...payload, quantity: data.quantity ? data.quantity : 0 })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            importFile(response?.data?.data)

                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })
            } else {
                globalPostService(`camo/inventory/`, { ...payload, asset_id: match.params.asset, asset_type_id: match.params.assetType, quantity: data.quantity ? data.quantity : 0 })
                    .then(response => {
                        setLoading(false)
                        if (response.data?.statusCode === 200) {
                            enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                            toggleModalFn(); getResponseBack()
                            addUploadFile(response?.data?.data)
                            importFile(response?.data?.data)
                        } else {
                            enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                        }
                    })

            }
        } else {
            setError(validationInputs)
        }
    }


    return (
        <>
            <Dialog
                open={true}
                onClose={toggleModalFn}
            >
                <DialogTitle> {modeType === "edit" ? "Update Inventory" : " Add Inventory"} </DialogTitle>
                <DialogContent dividers>
                    <Grid spacing={1} container>
                        <Grid item md={3} sm={3} sx={12}  >
                            <SectionCreates
                                label='Section'
                                options={sectionList}
                                getOptionLabel={(option) => option}
                                paramsKey='section'
                                optionKey='name'
                                value={data.section}
                                onFieldChange={dropDownChange}
                            />
                        </Grid>
                        <Grid item md={9} sm={9} sx={12}  >
                            <TextField
                                required
                                variant="outlined"
                                label="Description"
                                id="description"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                error={error.description ? true : false}
                                helperText={error.description ? error.description : ""}
                                value={data.description ? data.description : ""}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 250) {
                                        onFieldChange("description", input)
                                        setError({ ...error, 'description': '' })
                                    } else {
                                        const truncatedInput = input.slice(0, 250)
                                        onFieldChange(
                                            "description",
                                            truncatedInput
                                        )
                                        setError({ ...error, 'description': '' })
                                    }
                                }}
                            />
                        </Grid>

                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item md={3} sm={3} sx={12}>
                            <TextField
                                variant="outlined"
                                label="IIN"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                id="iin"
                                value={data.iin ? data.iin : ""}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 20) {
                                        onFieldChange("iin", input)
                                        setError({ ...error, 'iin': '' })
                                    } else {
                                        const truncatedInput = input.slice(0, 20)
                                        onFieldChange(
                                            "iin",
                                            truncatedInput
                                        )
                                        setError({ ...error, 'iin': '' })
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm={3} sx={12}>
                            <TextField
                                variant="outlined"
                                label="Spec"
                                id="spec"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.spec ? data.spec : ""}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 20) {
                                        onFieldChange("spec", input)
                                        setError({ ...error, 'spec': '' })
                                    } else {
                                        const truncatedInput = input.slice(0, 20)
                                        onFieldChange(
                                            "spec",
                                            truncatedInput
                                        )
                                        setError({ ...error, 'spec': '' })
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm={3} sx={12}>
                            <TextField
                                variant="outlined"
                                label="IPC & PPBU FIG. / REF"
                                id="ipc_ppbu_fig_ref"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.ipc_ppbu_fig_ref ? data.ipc_ppbu_fig_ref : ""}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 20) {
                                        onFieldChange("ipc_ppbu_fig_ref", input)
                                        setError({ ...error, 'ipc_ppbu_fig_ref': '' })
                                    } else {
                                        const truncatedInput = input.slice(0, 20)
                                        onFieldChange(
                                            "ipc_ppbu_fig_ref",
                                            truncatedInput
                                        )
                                        setError({ ...error, 'ipc_ppbu_fig_ref': '' })
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm={3} sx={12}>
                            <TextField
                                variant="outlined"
                                label="Quantity"
                                id="quantity"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.quantity ? data.quantity : ""}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^\d.]/g, '');
                                    const dotCount = (numericValue.match(/\./g) || []).length;
                                    const isValid = numericValue.replace(".", "").length - dotCount <= 3 && regexConstants.onlyNumeric.test(numericValue);

                                    if (isValid || value === "") {
                                        onFieldChange("quantity", parseInt(numericValue));
                                    }

                                    setError({ ...error, 'quantity': '' })
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item md={3} sm={3} sx={12}>
                            <TextField
                                variant="outlined"
                                label="Part Number"
                                id="part_number"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.part_number ? data.part_number : ""}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 20) {
                                        onFieldChange("part_number", input)
                                        setError({ ...error, 'part_number': '' })
                                    } else {
                                        const truncatedInput = input.slice(0, 20)
                                        onFieldChange(
                                            "part_number",
                                            truncatedInput
                                        )
                                        setError({ ...error, 'part_number': '' })
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm={3} sx={12}>
                            <TextField
                                variant="outlined"
                                label="Serial Number"
                                id="serial_number"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.serial_number ? data.serial_number : ""}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 20) {
                                        onFieldChange("serial_number", input)
                                        setError({ ...error, 'serial_number': '' })
                                    } else {
                                        const truncatedInput = input.slice(0, 20)
                                        onFieldChange(
                                            "serial_number",
                                            truncatedInput
                                        )
                                        setError({ ...error, 'serial_number': '' })
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm={3} sx={12}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        TSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="tsn"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={data.tsn ? data.tsn : ""}
                                error={error.tsn ? true : false}
                                helperText={error.tsn ? error.tsn : ""}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^\d.]/g, '');
                                    const dotCount = (numericValue.match(/\./g) || []).length;
                                    const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                    if (isValid || value === "") {
                                        onFieldChange("tsn", numericValue);
                                    }
                                    setError({ ...error, 'tsn': '' })
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm={3} sx={12} >
                            <Autocomplete
                                id="installed"
                                options={['Yes', 'No']}
                                getOptionLabel={option => option}
                                margin='normal'
                                value={data?.installed || null}
                                onChange={(e, value) => onFieldChange("installed", value)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Installed"
                                        placeholder="Select Installed"
                                        variant="outlined"
                                        fullWidth
                                        margin='normal'
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid spacing={1} container>
                        <Grid item md={3} sm={3} sx={12}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        CSN
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                margin='normal'
                                fullWidth
                                error={error.csn ? true : false}
                                helperText={error.csn ? error.csn : ""}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={data.csn ? data.csn : ""}
                                onChange={(e) => { onFieldChange('csn', e.target.value);  setError({ ...error, 'csn': '' }) }}
                            />
                        </Grid>
                        <Grid item md={3} sm={3} sx={12}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        TSO
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="tso"
                                margin='normal'
                                fullWidth
                                error={error.tso ? true : false}
                                helperText={error.tso ? error.tso : ""}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={data.tso ? data.tso : ""}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^\d.]/g, '');
                                    const dotCount = (numericValue.match(/\./g) || []).length;
                                    const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 6 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 5 && regexConstants.numberWithDot.test(numericValue);
                                    if (isValid || value === "") {
                                        onFieldChange("tso", numericValue);
                                    }
                                    setError({ ...error, 'tso': '' })
                                }}
                            />
                        </Grid>
                        <Grid item md={3} sm={3} sx={12}>
                            <TextField
                                variant="outlined"
                                label={
                                    <span style={{ verticalAlign: 'super' }}>
                                        CSO
                                        <Tooltip placement='right' title={<span>Allow Keywords - NA, N/A,Unknown,UNK.</span>} arrow>
                                            <InfoIcon fontSize='small' style={{ verticalAlign: 'middle', marginLeft: '2px', fontSize: 'small' }} />
                                        </Tooltip>
                                    </span>
                                }
                                id="cso"
                                margin='normal'
                                fullWidth
                                error={error.cso ? true : false}
                                helperText={error.cso ? error.cso : ""}
                                InputLabelProps={{ shrink: true, style: { pointerEvents: 'auto' } }}
                                value={data.cso ? data.cso : ""}
                                onChange={(e) => { e.target.value.length <= 5 && (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange('cso', e.target.value);  setError({ ...error, 'cso': '' })}}
                            />
                        </Grid>
                        <Grid item md={3} sm={3} sx={12}>
                            <TextField
                                variant="outlined"
                                label="Condition"
                                id="condition"
                                margin='normal'
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.condition ? data.condition : ""}
                                onChange={(e, value) => {
                                    const input = e.target.value
                                    if (input.length <= 20) {
                                        onFieldChange("condition", input)
                                        setError({ ...error, 'condition': '' })
                                    } else {
                                        const truncatedInput = input.slice(0, 20)
                                        onFieldChange(
                                            "condition",
                                            truncatedInput
                                        )
                                        setError({ ...error, 'condition': '' })
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6} sm={6}>
                            <TextField
                                label="Remarks"
                                margin='normal'
                                variant='outlined'
                                fullWidth
                                value={data.remark}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => onFieldChange("remark", e.target.value)}
                                rows={3}
                                multiline
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{ border: "1px solid #cfc1c1", textAlign: 'center', padding: "35px", borderRadius: "3px", marginTop: '9px' }}>
                                <span onClick={() => setUploadFile({ modal: true })} style={{ cursor: 'pointer', padding: "10px" }}><AttachmentIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Upload File</span>
                                <span onClick={() => setlinkFile({ modal: true })} style={{ cursor: 'pointer', }}><LinkIcon color='primary' fontSize='small' style={{ verticalAlign: 'middle' }} /> Link file from Records Module</span>
                            </div>
                        </Grid>
                        <div className="checklist-file" style={{ overflow: "auto" }}>
                            {data?.attachments?.length > 0 || data.checklist?.length > 0 || data.checklist?.files?.length > 0 || data.checklist?.folders?.length > 0 ? <span style={{ fontSize: "13px", marginLeft: "4px" }}>Attachments</span> : null}
                            <ul className='list-inline'>
                                {(Array.isArray(data?.attachments) && data?.attachments?.length > 0 ? data?.attachments : data?.attachments)?.map((attachments) => {
                                    return (
                                        <li className='list-inline-item'>
                                            <span className='file-name'>{attachments.name}</span>
                                            <span className='file-remove'>
                                                <a href={decodeURIComponent(attachments.file)} download={attachments.file_name}><GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} /></a>
                                                <CloseIcon color='secondary' onClick={() => onRemoveFile(attachments, 'attachments')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                            </span>
                                        </li>)
                                }

                                )}
                                {(Array.isArray(data?.checklist) && data.checklist?.length > 0) &&
                                    data.checklist?.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        {data?.checklist.some(item => item.type === 'folder') ? null
                                                            : <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />}
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.files?.length > 0) &&
                                    data.checklist?.files.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <a href={decodeURIComponent(file.path)} download={file.name}>
                                                        <GetAppIcon color="primary" style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                    </a>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id)} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                                {(data.checklist?.folders?.length > 0) &&
                                    data.checklist?.folders.map((file) => {
                                        return (
                                            <li className='list-inline-item' key={file.id}>
                                                <Tooltip title={`Records Data Room :- ${file?.location}`} arrow>
                                                    <a href={`${config.domain.records}${assetType[match.params.assetType]}/${file?.slug}/data-room/technical/${file?.uuid}`} target="_new"><img style={{ width: '15px', float: 'right', marginRight: '-18px', marginTop: '7px' }} src={imgStoragePath + 'phase-2/RecordsIcon.svg'} alt="Reacords Data Room" /></a>
                                                </Tooltip>
                                                <span className='file-name'>{file.name}</span>
                                                <span className='file-remove'>
                                                    <CloseIcon color='secondary' onClick={() => onRemoveFile(file, 'checklist', file.id, 'folder')} style={{ fontSize: 'medium', verticalAlign: 'middle' }} />
                                                </span>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </div>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={toggleModalFn}>Cancel</Button>
                    <Button variant='contained' color='primary' onClick={onAddNewAsset}>SAVE</Button>
                </DialogActions>
            </Dialog>
            {uploadFile.modal ?
                <UploadFile
                    modType="inventory"
                    type="upload"
                    list={list}
                    actionType={modeType}
                    editFormId={data?.id}
                    handleClose={() => setUploadFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    uploadFileFn={(file, uuid, type) => uploadFileFn(file, uuid, type)}

                />
                : null}
            {linkFile.modal ?
                <LinkFiles
                    modType="inventory"
                    type="link"
                    list={list}
                    editFormId={data?.id}
                    actionType={modeType}
                    uploadFileInfo={data}
                    toggleModalFn={() => setlinkFile({ modal: false })}
                    uuid={basicdetail?.record_folders?.technical_records?.uuid}
                    getResponseBack={(file) => uploadFileData(file)}
                    onLinkFile={getData}
                />
                : null
            }
            {isLoading ? <PageLoader /> : null}
        </>
    )

}
export default withRouter(AddEditInventory)