import React, { useState, useEffect, Fragment } from 'react';
import { useSnackbar } from 'notistack';
import { withRouter } from 'react-router';
import { DropzoneArea } from 'material-ui-dropzone';
import { Breadcrumbs, Link, Grid, Button, DialogActions, DialogContent, DialogTitle, Checkbox } from '@material-ui/core';
import PageLoader from './PageLoader';
import { checkApiStatus } from '../../utils';
import { globalGetService, globalPutService } from '../../utils/globalApiServices';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
const UploadAmpFile = ({ handleClose, uuid, actionType, editFormId, getResponseBack, match, onLinkFile, modType, last_used_folder_uuid, onUpload, type, checkbox_last_used_folder_uuid, listResponseBack, checkbox_last_used_folder_name }) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [isLoading, setLoading] = useState(false);
	const [checkListData, setCheckListData] = useState({ navigation: [], folders: [], files_in_current_folder: [] })
	const [selectedUuid, setSelectedUuid] = useState('');
	const [selectedFolders, setSelectedFolders] = useState(checkbox_last_used_folder_uuid ? [checkbox_last_used_folder_uuid] : []);
	const [files, setFiles] = useState([]);
	useEffect(() => {
		getCheckListLinkData(last_used_folder_uuid ? last_used_folder_uuid : uuid, 'firstCall')
	}, []);
	const getCheckListLinkData = (selectedid, callType) => {
		setLoading(last_used_folder_uuid ? last_used_folder_uuid : uuid && true);
		globalGetService(`${actionType === 'add' ? `camo/asset/${match.params.asset}` : `camo/${modType}/${editFormId}`}/record-folders-files/${selectedid ? selectedid : last_used_folder_uuid}/`, { showFiles: true })
			.then(response => {
				setLoading(false);
				if (checkApiStatus(response)) {
					setCheckListData(response.data.data);
				}
			})
	}
	const handleFileUpload = () => {
		if (selectedFolders.length > 0) {
			setLoading(true);
			let formData = new FormData();
			formData.append('folder_uuid', selectedFolders[selectedFolders.length - 1]); // Assuming you want the last selected folder
			for (let i = 0; i < files.length; i++) {
				formData.append('files[]', files[i]);
			}
			globalPutService(`${actionType === 'add' ? `camo/asset/${match.params.asset}` : `camo/${modType}/${editFormId}`}/document-checklists/`, formData)
				.then(response => {
					setLoading(false);
					if (checkApiStatus(response)) {
						listResponseBack(response.data.data)
						getResponseBack(response.data.data);
						handleClose();
						enqueueSnackbar("Files Uploaded Successfully", { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } })
					} else {
						enqueueSnackbar(response?.data?.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } })

					}
				});

		} else {
			onUpload(files)
			handleClose()
		}
	};
	const handleDropzoneChange = (uploadedFiles) => {
		setFiles(uploadedFiles);
	};

	const onSubmitFileLink = () => {
		onLinkFile(files, selectedFolders[selectedFolders.length - 1], type); // Assuming you want the last selected folder
		handleClose();
	}


	const handleCheckboxChange = (folderUuid, name) => {
		if (selectedFolders.includes(folderUuid)) {
			setSelectedFolders([]);
			setSelectedUuid(name)
		} else {
			// Folder is not selected, select it
			setSelectedFolders([folderUuid]);
			setSelectedUuid(name)
		}
	}

	return (
		<div className='upload-document-section'>
			<DialogTitle id="scroll-dialog-title" style={{ padding: "10px 24px" }}>
				Upload Document(s)
			</DialogTitle>
			<DialogContent dividers={true}>
				<div style={{ width: '720px' }}>
					<Grid container spacing={1}>
						<Grid item md={12}>
							<DropzoneArea
								acceptedFiles={[".pdf", ".xlx", ".xlsx", ".png", ".jpeg", ".jpg", ".docx"]}
								filesLimit={10}
								maxFileSize={104857600}
								showPreviewsInDropzone={false}
								useChipsForPreview={true}
								showPreviews={true}
								dropzoneText={<p style={{ fontSize: '13px' }}>Drag & Drop Documents<br /> OR <br /> Click Here<br /> </p>}
								dropzoneClass="drag-drop-cnt"
								maxWidth="sm"
								showAlerts={['info', 'error']}
								alertSnackbarProps={{ anchorOrigin: { vertical: 'top', horizontal: 'right' } }}
								clearOnUnmount={true}
								onChange={handleDropzoneChange}
							/>
							<span className='files-upload-limit' > Note : Max files upload limit is 10 in single time.</span>
							<div className='checklist-file-linking'>
								<div className='linking-navigation'>
									<Breadcrumbs maxItems={3} aria-label="breadcrumb">
										{checkListData.navigation.map((nav, index) =>
											checkListData.navigation.length - 1 == index ?
												<Link onClick={() => getCheckListLinkData(nav.uuid)} className="list-inline-item navigation_modal">{nav.name}</Link>
												:
												<Link onClick={() => getCheckListLinkData(nav.uuid)} className="list-inline-item navigation_modal">{nav.name}</Link>
										)}
									</Breadcrumbs>
								</div>
								<div>
									{checkListData.folders.length ?
										<>
											{checkListData.folders.map((folder, index) =>

												<Grid container spacing={1}>
													<Grid item md={10}>
														<h4 className='flex-centered' onClick={() => getCheckListLinkData(folder.uuid)}>
															<FolderOpenIcon color='primary' fontSize='small' /> <span>{folder.name}</span>

														</h4>
													</Grid>
													<Grid item md={2}>
														<Checkbox
															color='primary'
															size='small'
															checked={selectedFolders && selectedFolders?.includes(folder.uuid)}
															onChange={() => handleCheckboxChange(folder.uuid, folder.name)}
														/>
													</Grid>
												</Grid>
											)}</>
										: <p style={{ textAlign: 'center' }}>No folders found</p>}
								</div>
							</div>
						</Grid>
					</Grid>
				</div>

			</DialogContent>
			<DialogActions>
				<Grid container spacing={1} alignItems='center' style={{ paddingLeft: '10px' }}>
					<Grid item md={9}>
						<Fragment >
							{(selectedFolders.length > 0) ?
								<span>Current file(s) will be stored in Records DataRoom:<b>{selectedUuid ? selectedUuid : checkbox_last_used_folder_name}</b></span> :
								<span>Current file will be stored in CAMO</span>
							}
						</Fragment>
					</Grid>
					<Grid item md={3}>
						<ul className='list-inline' style={{ float: 'right' }}>
							<li className='list-inline-item' style={{ paddingRight: '10px' }}>
								<Button onClick={() => handleClose()} color="primary" size='small' variant='outlined'>Cancel</Button>
							</li>
							<li className='list-inline-item' >
								<Button disabled={files.length ? false : true} onClick={actionType === 'add' ? onSubmitFileLink : handleFileUpload} color="primary" size='small' variant='contained'>Save</Button>
							</li>
						</ul>
					</Grid>
				</Grid>

			</DialogActions>
			{isLoading ? <PageLoader /> : null}
		</div>
	)
}
export default withRouter(UploadAmpFile);