import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Dialog, Button, DialogTitle, DialogContent, DialogActions, TextField, } from "@material-ui/core"
import { globalPutService, globalGetService } from '../../../../utils/globalApiServices';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { fieldDateFormat, backendDateFormat } from '../../../../constants';
import { regexConstants } from '../../../../constants/regEx';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { PageLoader } from '../../../../shared_elements';
import { checkApiStatus } from '../../../../utils';
import MomentUtils from '@date-io/moment';
import { useSnackbar } from 'notistack';
import moment from 'moment';

function EditTechSpec({ toggleModalFn, techSpecData, getResponseBack, basicDetailData, utilisationData }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [data, setData] = useState(techSpecData);
    console.log("data", data)
    const [dropDownList, setDropDownList] = useState([])
    const [pageLoader, setPageLoader] = useState(false)

    const leaseStartDate = data?.new_tec_spec?.lease_start_date;
    const leaseDate = data?.new_tec_spec?.lease_term_date;
    const monthRemaining = moment(leaseDate, backendDateFormat).diff(moment(leaseStartDate, backendDateFormat), 'month');

    useEffect(() => {
        allDropDownList()
    }, [])

    const onFieldChange = (keyParam, value, extraProps) => {
        if (extraProps) {
            setData({
                ...data,
                [extraProps]: {
                    ...data[extraProps],
                    [keyParam]: value
                }

            })
        } else {
            setData({ ...data, [keyParam]: value })
        }
    }

    const onEditTechSpec = () => {
        setPageLoader(true)
        let payload = Object.assign({}, data)
        payload = {
            ...payload,
            location: utilisationData?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] || null,
            position: utilisationData?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] || null,
            average_monthly_hours: utilisationData?.sort((a, b) => b - a).map(filterId => filterId.monthly_FH)[0] || null,
            average_monthly_cycles: utilisationData?.sort((a, b) => b - a).map(filterId => filterId.monthly_FC)[0] || null,
            country: utilisationData?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] || null,
            sub_status: ({ value: payload?.sub_status?.id || payload?.sub_status?.value }) || null,
            status: ({ value: payload?.status?.id || payload?.status?.value, label: payload?.label }) || null,
            new_tec_spec: {
                ...payload.new_tec_spec,
                location_reg_aircraft: utilisationData?.sort((a, b) => b - a).map(filterId => filterId?.location_reg_aircraft)[0] || null,
                month_remaining: monthRemaining ? monthRemaining : 0,
                operating_region: payload?.new_tec_spec?.operating_region || null,
                avg_derate:parseFloat(payload?.new_tec_spec?.avg_derate) || null
            }
        };
        globalPutService(`camo/engine_tech_spec/${basicDetailData?.slug}/details/`, payload)
            .then(response => {
                setPageLoader(false)
                if (checkApiStatus(response)) {
                    enqueueSnackbar(response?.data?.message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' } });
                    toggleModalFn(); getResponseBack()
                } else {
                    enqueueSnackbar(response?.data?.message, { variant: "error", anchorOrigin: { vertical: "top", horizontal: "right" } });

                }
            })
    }
    const allDropDownList = () => {
        globalGetService(`camo/all_dropdown_list/`)
            .then(response => {
                if (response?.status === 200) {
                    setDropDownList(response.data)
                }
            })
    }

    return (
        <Fragment>
            <Dialog
                open={true}
                onClose={() => toggleModalFn()}
                className='camo-management-modal'
                maxWidth="lg"
            >
                <DialogTitle > Update Technical Specification </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                label="A/C model"
                                margin="normal"
                                variant='outlined'
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                                value={data.new_tec_spec?.aircraft_model || ""}
                                onChange={(e, value) => onFieldChange('aircraft_model', e.target.value, "new_tec_spec")}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                options={dropDownList.opertor_lessee_list || []}
                                getOptionLabel={option => option.name}
                                value={data?.lessee}
                                onChange={(e, value) => onFieldChange("lessee", value)}
                                renderInput={params => <TextField  {...params} label="Operator (Lessee)" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                options={dropDownList.operator_sub_Lessee || []}
                                getOptionLabel={option => option.name}
                                value={data?.operator}
                                onChange={(e, value) => onFieldChange("operator", value)}
                                renderInput={params => <TextField {...params} label="Operator ((Sub-Lessee))" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Owner A/C"
                                margin="normal"
                                variant='outlined'
                                fullWidth
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{ shrink: true }}
                                value={data.new_tec_spec?.owner_aircraft || ""}
                                onChange={(e, value) => onFieldChange('owner_aircraft', e.target.value, "new_tec_spec")}


                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <Autocomplete
                                id="lessor_name"
                                options={dropDownList.owner_list || []}
                                getOptionLabel={option => option.name}
                                value={data?.lessor_name}
                                onChange={(e, value) => onFieldChange('lessor_name', value)}
                                renderInput={params => <TextField  {...params} label="Lessor Name" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Delivery Date"
                                    inputVariant='outlined'
                                    fullWidth
                                    error={false}
                                    helperText={''}
                                    disableFuture
                                    format={fieldDateFormat}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(basicDetailData.date_of_manufacture)}
                                    value={data.delivery_date_to_current_operator || null}
                                    onChange={(data, value) => onFieldChange('delivery_date_to_current_operator', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Monthly EFH"
                                margin="normal"
                                variant='outlined'
                                disabled
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                                value={utilisationData?.sort((a, b) => b - a).map(filterId => filterId.monthly_FH)[0] || ""}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Monthly EFC"
                                margin="normal"
                                variant='outlined'
                                disabled
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                                value={utilisationData?.sort((a, b) => b - a).map(filterId => filterId.monthly_FC)[0] || ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Date of Purchase"
                                    inputVariant='outlined'
                                    fullWidth
                                    disableFuture
                                    error={false}
                                    helperText={''}
                                    format={fieldDateFormat}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(basicDetailData.date_of_manufacture).subtract(6, 'months')}
                                    value={data?.purchase_date ? data?.purchase_date : null}
                                    onChange={(data, value) => onFieldChange('purchase_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Next Delivery  Date"
                                    inputVariant='outlined'
                                    fullWidth
                                    error={false}
                                    helperText={''}
                                    format={fieldDateFormat}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(basicDetailData.date_of_manufacture)}
                                    value={data?.next_delivery_date ? data?.next_delivery_date : null}
                                    onChange={(data, value) => onFieldChange('next_delivery_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Locations (A/C Reg.)"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                disabled
                                InputLabelProps={{ shrink: true }}
                                value={data?.location_reg_aircraft ? data?.location_reg_aircraft : data.location_reg_aircraft || utilisationData?.sort((a, b) => b - a).map(filterId => filterId.location_reg_aircraft)[0] || ""}
                                inputProps={{ maxLength: 25 }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                id="position"
                                disabled
                                options={dropDownList.position_list || []}
                                getOptionLabel={option => option.label}
                                value={utilisationData?.sort((a, b) => b - a).map(filterId => filterId?.location_position)[0] || null}
                                onChange={(e, value) => onFieldChange('position', value)}
                                renderInput={params => <TextField  {...params} label="Locations (Position)" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                label="Locations (Country)"
                                variant='outlined'
                                margin="normal"
                                disabled
                                fullWidth
                                value={utilisationData?.sort((a, b) => b - a).map(filterId => filterId?.location_country)[0] || "--"}
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                id="operating_region"
                                options={dropDownList.region_list || []}
                                getOptionLabel={option => option.name}
                                value={data?.new_tec_spec?.operating_region}
                                onChange={(e, value) => onFieldChange('operating_region', value, 'new_tec_spec')}
                                renderInput={params => <TextField  {...params} label="Operating Region" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                id="operating_environment"
                                options={['Neutral', 'Middle East/North Africa', 'India', 'China']}
                                getOptionLabel={option => option}
                                value={data?.new_tec_spec?.operating_environment}
                                disabled
                                // onChange={(e, value) => onFieldChange('operating_environment', value,'new_tec_spec')}
                                renderInput={params => <TextField  {...params} label="Operating Enviroment " margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Date of installation"
                                    inputVariant='outlined'
                                    error={false}
                                    helperText={''}
                                    fullWidth
                                    disableFuture
                                    format={fieldDateFormat}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(basicDetailData.date_of_manufacture)}
                                    value={data?.date_of_installation ? data?.date_of_installation : null}
                                    onChange={(data, value) => onFieldChange('date_of_installation', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Date of Removal"
                                    inputVariant='outlined'
                                    fullWidth
                                    disableFuture
                                    error={false}
                                    helperText={''}
                                    format={fieldDateFormat}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(basicDetailData.date_of_manufacture)}
                                    value={data.new_tec_spec?.date_of_removal || null}
                                    onChange={(data, value) => onFieldChange('date_of_removal', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Reporting Month"
                                    inputVariant='outlined'
                                    fullWidth
                                    error={false}
                                    helperText={''}
                                    disableFuture
                                    format={fieldDateFormat}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(basicDetailData.date_of_manufacture)}
                                    value={data?.last_utilization_updated ? data?.last_utilization_updated : null}
                                    onChange={(data, value) => onFieldChange('last_utilization_updated', moment(data).isValid() ? moment(data).format(backendDateFormat) : null)}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        {/* <Grid item md={3}>
                            <TextField
                                label="Avg. Flight Ratio"
                                margin="normal"
                                variant='outlined'
                                fullWidth
                                disabled
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{ shrink: true }}
                                value={data?.avg_flight_ratio ? (data?.avg_flight_ratio).toFixed(2) : ""}
                            />
                        </Grid> */}
                        <Grid item md={3}>
                            <TextField
                                label="Avg. Derate %"
                                margin="normal"
                                variant='outlined'
                                fullWidth
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{ shrink: true }}
                                value={data.new_tec_spec?.avg_derate || ""}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^\d.]/g, '');
                                    const dotCount = (numericValue.match(/\./g) || []).length;
                                    const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 3 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 2 && regexConstants.numberWithDot.test(numericValue);
                                    if (isValid || value === "") { onFieldChange("avg_derate", numericValue, "new_tec_spec"); }
                                }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                variant='outlined'
                                margin="normal"
                                label="TSLV"
                                fullWidth
                                disabled
                                value={data?.tslsv || ""}
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                variant='outlined'
                                margin="normal"
                                label="CSLV"
                                fullWidth
                                disabled
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                                value={data?.cslsv || ""}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="LLP Limiter(s)"
                                margin="normal"
                                variant='outlined'
                                disabled
                                fullWidth
                                value={data?.llp_limiter || ""}
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Soft-time Limiter(s)"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                disabled
                                value={data?.Soft_time_limiter}
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                id="owner"
                                options={dropDownList.owner_list || []}
                                getOptionLabel={option => option.name}
                                value={data?.owner}
                                onChange={(e, value) => onFieldChange('owner', value)}
                                renderInput={params => <TextField {...params} label="Asset Owner" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                label="Engine Stand Prime P/N"
                                margin="normal"
                                variant='outlined'
                                fullWidth
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{ shrink: true }}
                                value={data?.new_tec_spec?.engine_stand_part_number || ""}
                                onChange={(e, value) => onFieldChange('engine_stand_part_number', e.target.value, "new_tec_spec")}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Engine Stand Prime S/N"
                                margin="normal"
                                variant='outlined'
                                fullWidth
                                value={data.new_tec_spec?.engine_stand_serial_number || ""}
                                onChange={(e, value) => onFieldChange('engine_stand_serial_number', e.target.value, "new_tec_spec")}
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Engine Stand Base P/N"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.new_tec_spec?.engine_stand_base_pn || ""}
                                onChange={(e, value) => onFieldChange('engine_stand_base_pn', e.target.value, "new_tec_spec")}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Engine Stand Base S/N "
                                margin="normal"
                                variant='outlined'
                                fullWidth
                                value={data.new_tec_spec?.engine_stand_base_sn || ""}
                                onChange={(e, value) => onFieldChange('engine_stand_base_sn', e.target.value, "new_tec_spec")}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                label="Engine Stand Cradle P/N"
                                margin="normal"
                                variant='outlined'
                                fullWidth
                                value={data.new_tec_spec?.engine_stand_cradle_pn || ""}
                                onChange={(e, value) => onFieldChange('engine_stand_cradle_pn', e.target.value, "new_tec_spec")}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Engine Stand Cradle S/N"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={data.new_tec_spec?.engine_stand_cradle_sn || ""}
                                onChange={(e, value) => onFieldChange('engine_stand_cradle_sn', e.target.value, "new_tec_spec")}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Engine Stand Location"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{ shrink: true }}
                                value={data.new_tec_spec?.engine_stand_location || ""}
                                onChange={(e, value) => onFieldChange('engine_stand_location', e.target.value, "new_tec_spec")}
                            />
                        </Grid>

                        <Grid item md={3}>
                            <TextField
                                label="QEC Config."
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                                value={data.new_tec_spec?.qec_config || ""}
                                onChange={(e, value) => onFieldChange('qec_config', e.target.value, "new_tec_spec")}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                label="QEC P/N."
                                margin="normal"
                                variant='outlined'
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                                value={data.new_tec_spec?.qec_part_number || ""}
                                onChange={(e, value) => onFieldChange('qec_part_number', e.target.value, "new_tec_spec")}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Min LLP Prior to Term. Date"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                                value={data.new_tec_spec?.min_llp_prior_to_term_date || ""}
                                onChange={(e, value) => onFieldChange('min_llp_prior_to_term_date', e.target.value, "new_tec_spec")}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                id="sub_status"
                                options={dropDownList.asset_sub_status_list || []}
                                getOptionLabel={option => option.label}
                                value={data?.sub_status}
                                onChange={(e, value) => onFieldChange('sub_status', value)}
                                renderInput={params => <TextField {...params} label="Sub Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Lease Start Date"
                                    inputVariant='outlined'
                                    fullWidth
                                    disableFuture
                                    error={false}
                                    helperText={''}
                                    format={fieldDateFormat}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(basicDetailData.date_of_manufacture)}
                                    value={data.new_tec_spec?.lease_start_date || null}
                                    onChange={(data, value) => onFieldChange('lease_start_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Lease Term Date"
                                    inputVariant='outlined'
                                    fullWidth
                                    error={false}
                                    helperText={''}
                                    format={fieldDateFormat}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, 'years')}
                                    value={data.new_tec_spec?.lease_term_date || null}
                                    onChange={(data, value) => onFieldChange('lease_term_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Redelivery Date"
                                    inputVariant='outlined'
                                    fullWidth
                                    error={false}
                                    helperText={''}
                                    format={fieldDateFormat}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment().subtract(25, 'years')}
                                    value={data.new_tec_spec?.redelivery_date || null}
                                    onChange={(data, value) => onFieldChange('redelivery_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, "new_tec_spec")}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Month Remaining"
                                margin="normal"
                                variant='outlined'
                                disabled
                                fullWidth
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{ shrink: true }}
                                value={monthRemaining >= 0 ? monthRemaining : 0}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                id="status"
                                options={dropDownList.asset_status_list || []}
                                getOptionLabel={option => option.label}
                                value={data?.status}
                                onChange={(e, value) => onFieldChange('status', value)}
                                renderInput={params => <TextField  {...params} label="On/Off Lease Status" margin="normal" fullWidth InputLabelProps={{ shrink: true }} variant='outlined' />}
                            />
                        </Grid>

                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                label="EEC Software P/N"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                                value={data.new_tec_spec?.eec_software_part_number || ""}
                                onChange={(e, value) => onFieldChange('eec_software_part_number', e.target.value, "new_tec_spec")}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="EEC Software Version"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                                value={data.new_tec_spec?.eec_software_version || ""}
                                onChange={(e, value) => onFieldChange('eec_software_version', e.target.value, "new_tec_spec")}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Monthly Use Fee"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                disabled
                                value={data.monthly_use_fee || ""}
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Monthly LLP Fee"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                disabled
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                                value={data.monthly_llp_fee || ""}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <TextField
                                label="Accumulated MR"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                disabled
                                value={data.accumulated_mr || ""}
                                inputProps={{ maxLength: 25 }}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                options={['YES', 'NO', 'N/A']}
                                getOptionLabel={(option) => option}
                                value={data?.new_tec_spec?.stand || null}
                                onChange={(e, value) => onFieldChange('stand', value, "new_tec_spec")}
                                InputLabelProps={{ shrink: true }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Stand"
                                        margin="normal"
                                        placeholder="Select Stand"
                                        fullWidth
                                        variant="outlined"

                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <Autocomplete
                                options={['YES', 'NO', 'N/A']}
                                getOptionLabel={(option) => option}
                                value={data?.new_tec_spec?.bag || null}
                                onChange={(e, value) => onFieldChange('bag', value, "new_tec_spec")}
                                InputLabelProps={{ shrink: true }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Bag"
                                        margin="normal"
                                        fullWidth
                                        placeholder="Select Bag"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Preservation Date"
                                    inputVariant='outlined'
                                    fullWidth
                                    error={false}
                                    helperText={''}
                                    disableFuture
                                    format={fieldDateFormat}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(basicDetailData.date_of_manufacture)}
                                    value={data?.new_tec_spec?.preservation_date || null}
                                    onChange={(data, value) => onFieldChange('preservation_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, 'new_tec_spec')}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item md={3}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    label="Preservation Expiry Date"
                                    inputVariant='outlined'
                                    fullWidth
                                    error={false}
                                    helperText={''}
                                    disableFuture
                                    format={fieldDateFormat}
                                    InputLabelProps={{ shrink: true }}
                                    minDate={moment(data?.new_tec_spec?.preservation_date ? data?.new_tec_spec?.preservation_date : basicDetailData.date_of_manufacture)}
                                    value={data?.new_tec_spec?.preservation_expiry_date || null}
                                    onChange={(data, value) => onFieldChange('preservation_expiry_date', moment(data).isValid() ? moment(data).format(backendDateFormat) : null, 'new_tec_spec')}

                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Target On-Wing Hours Sum"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                value={data?.new_tec_spec?.target_on_wing_hours || ""}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    const numericValue = value.replace(/[^\d.]/g, '');
                                    const dotCount = (numericValue.match(/\./g) || []).length;
                                    const isValid = dotCount ? (numericValue.replace(".", "").length - dotCount <= 12 && regexConstants.numberWithDot.test(numericValue)) : value.length <= 10 && regexConstants.numberWithDot.test(numericValue);
                                    if (isValid || value === "") {
                                        onFieldChange("target_on_wing_hours", numericValue, 'new_tec_spec');
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Target On-Wing Cycle Sum"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                value={data?.new_tec_spec?.target_on_wing_cyle ? data?.new_tec_spec?.target_on_wing_cyle : ""}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => { (regexConstants.onlyNumeric.test(e.target.value) || e.target.value === "") && onFieldChange('target_on_wing_cyle', e.target.value, "new_tec_spec") }}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Min. Build Standard"
                                variant='outlined'
                                margin="normal"
                                fullWidth
                                value={data?.new_tec_spec?.min_build_standard || ""}
                                inputProps={{ maxLength: 50 }}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e, value) => onFieldChange('min_build_standard', e.target.value, "new_tec_spec")}

                            />
                        </Grid>
                        <Grid item md={3}>
                            <TextField
                                label="Avg. Flight Ratio"
                                margin="normal"
                                variant='outlined'
                                fullWidth
                                disabled
                                inputProps={{ maxLength: 20 }}
                                InputLabelProps={{ shrink: true }}
                                value={data?.avg_flight_ratio ? (data?.avg_flight_ratio).toFixed(2) : ""}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button color="primary" variant="outlined" onClick={toggleModalFn}>  CANCEL</Button>
                    <Button color="primary" variant="contained" onClick={onEditTechSpec} > SAVE </Button>
                </DialogActions>
            </Dialog>
            {pageLoader ? <PageLoader /> : null}
        </Fragment>


    )
}

export default EditTechSpec
