import AddEditSr from "./AddEditSr";
import SrListing from "./SrListing";
import SrHeader from "./SrHeader";
export {
    AddEditSr,
    SrListing,
    SrHeader
}

export const srObj={
    asset_id: null,
    asset_type_id: null,
    ata_chapter: null,
    repair_ref_number: null,
    repair_type: "DENT",
    repair_description: "",
    repair_inspection: null,
    repair_location: null,
    repair_detail: null,
    repair_compliance_tsn: null,
    repair_compliance_csn: null,
    repair_compliance_date: null,
    repair_compliance_ref: null,
    repair_status: "OPEN",
    repair_DUE: "",
    last_done_date: null,
    last_done_fh: null,
    last_done_fc: null,
    dnb_compliance_dfp: "",
    release_certificate: "",
    certificate_of_conformity: "",
    srm_ref_document: "",
    oem_repair_scheme: "",
    faa_equivalent: "",
    remark: null,
    attachments: [],
    checklist:[],
    repair_interval_type: "D",
    repair_interval: null,
    folder_uuid:'',
    linkFile:''
}