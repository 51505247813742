import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
const OpHistoryHeader = ({ sortInfo, createSortHandler }) => {
	const tableSorting = (key, name) => {
		return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
	}
	return (
		<TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
			<TableRow>
				<TableCell colSpan={1} align="center"></TableCell>
				<TableCell colSpan={10} align="center">Fitment</TableCell>
				<TableCell colSpan={11} align="center">Removal</TableCell>
				<TableCell colSpan={2} align="center">Maintenance</TableCell>
				<TableCell colSpan={1} align="center"></TableCell>
			</TableRow>
			<TableRow>
				<TableCell>Action </TableCell>
				<TableCell>{tableSorting('fitment_date', 'Date')}</TableCell>
				<TableCell>{tableSorting('fitment_operator', 'Operator')} </TableCell>
				<TableCell>{tableSorting('fitment_aircraft', 'A/C')}</TableCell>
				<TableCell>{tableSorting('fitment_tail', 'Tail')} </TableCell>
				<TableCell>{tableSorting('fitment_psn', 'PSN')}</TableCell>
				<TableCell>Thrust</TableCell>
				<TableCell>{tableSorting('fitment_aircraft_flying_hours', 'A/F Hours')}</TableCell>
				<TableCell>{tableSorting('fitment_aircraft_flying_cycle', 'A/F Cycle ')}</TableCell>
				<TableCell>{tableSorting('fitment_engine_tsn', 'Engine TSN')}</TableCell>
				<TableCell>{tableSorting('fitment_engine_csn', 'Engine CSN')}</TableCell>
				<TableCell>{tableSorting('removal_date', 'Date')}</TableCell>
				<TableCell>{tableSorting('removal_aircraft_flying_hours', 'A/F Hours')}</TableCell>
				<TableCell>{tableSorting('removal_aircraft_flying_cycle', 'A/F Cycle')}</TableCell>
				<TableCell>{tableSorting('removal_engine_tsn', 'Engine TSN')}</TableCell>
				<TableCell>{tableSorting('removal_engine_csn', 'Engine CSN')}</TableCell>
				<TableCell>{tableSorting('removal_tslsv', 'TSLV')}</TableCell>
				<TableCell>{tableSorting('removal_cslsv', 'CSLV')}</TableCell>
				<TableCell>{tableSorting('removal_hours_used', 'Hours Used')}</TableCell>
				<TableCell>{tableSorting('removal_cycle_used', 'Cylces Used')}</TableCell>
				<TableCell>{tableSorting('removal_egtm', 'EGTMD')}</TableCell>
				<TableCell>{tableSorting('removal_event', 'Event')}</TableCell>
				<TableCell style={{minWidth:"210px"}}>{tableSorting('maint_effective_work_carried_out', 'Effective Work Carried Out')}</TableCell>
				<TableCell>{tableSorting('maint_shop', 'Shop')}</TableCell>
				<TableCell>Attachments </TableCell>
			</TableRow>
		</TableHead>
	)
}
export default OpHistoryHeader