import React, { useState } from "react";
import { TableCell, TableRow, Tooltip, IconButton} from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { withRouter,Link } from "react-router-dom";
import moment from 'moment';
import { smbcInstance } from "../..";
import { fieldDateFormatShort } from "../../../../constants";
import RevisionHistory from '../../../../shared_elements/components/RevisionHistory';
import AttachFileIcon from "@material-ui/icons/AttachFile";

const AdListing = ({ match, item, toggleModalFn, toggleDelteModalFn, handleOpenAttachments }) => {
   
    const [lessMore, setLessMore] = useState({});

    const toggleLessMore = (id) => {
        setLessMore({
            ...lessMore,
            [id]: !lessMore[id],
        });
    };

console.log("item",item)
    const lessMoreFn = (name) => {
        return <>
            {name && name.length > 22 ? (
                <>
                    {lessMore[name] ?
                        (<> {name} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..less </span> </>)
                        :
                        (<>{name.substring(0, 22)} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..more </span> </>)
                    }
                </>
            ) : name || '--'
            }
        </>
    }
    function getAttachmentCount(item) {
        const { attachments, checklist } = item;
        const { files, folders } = checklist || {};

        const attachmentCount = attachments?.length || 0;
        const fileCount = files?.length || 0;
        const folderCount = folders?.length || 0;

        if (attachmentCount && fileCount && folderCount) {
            return attachmentCount + fileCount + folderCount;
        } else if (fileCount && folderCount) {
            return fileCount + folderCount;
        } else if (attachmentCount && folderCount) {
            return attachmentCount + folderCount;
        } else if (attachmentCount && fileCount) {
            return attachmentCount + fileCount;
        } else {
            return folderCount || fileCount || attachmentCount || '--';
        }
    }
    const attachmentCount = getAttachmentCount(item);
    let adUrl = `audit/camo/adstatus/?object_id=${item.id}&action=0,1&timezone=${moment().format()}&timestamp=${Math.floor(Date.now() / 1000)}`
   
    const nextDueFormat = (item) => {
        if (item.ad_compliance_status === "NOT APPLICABLE") {
            return ("N/A")
        } else if (item.remaining.due_at && item.remaining.due_at_type === 'd') {
            return (moment(item.remaining.due_at).isValid() ? moment(item.remaining.due_at).format(fieldDateFormatShort) : '--')
        } else if (item.remaining.due_at && item.remaining.due_at_type === 's') {
            return item.remaining.due_at || '--'
        } else {
            return moment(item.due_date).isValid() ? moment(item.due_date).format(fieldDateFormatShort) : '--'
        }
    }
    return (
        <TableRow>
            <TableCell>
                <Tooltip title="Edit" placement='top'>
                    <IconButton style={{ padding: 'unset' }} color='primary' onClick={toggleModalFn} > <CreateOutlinedIcon /> </IconButton>
                </Tooltip>
                <Tooltip title="Delete" placement='top' >
                    <IconButton style={{ padding: 'unset' }} color='secondary' onClick={toggleDelteModalFn}> <DeleteOutlinedIcon /> </IconButton>
                </Tooltip>
                <RevisionHistory buttonType={true} url={adUrl} />
            </TableCell>
            <TableCell>{smbcInstance ? item.faa_ad : item?.ata || "--"}</TableCell>
            <TableCell>{smbcInstance ? item.faa_superseded : item.ad_no || "--"} </TableCell>
            {smbcInstance ? <TableCell>{item.easa_ad}</TableCell> : null}
            <TableCell>{smbcInstance ? item.easa_superseded : item.issuing_authority || "--"} </TableCell>
            <TableCell>{item?.sb_number?.length > 0 && item?.sb_number?.map(items=>(<Link to={`/${match.params.asset}/${match.params.assetType}/sb/list?query=${items?.name}`}  target="_self">{items?.name + ","}</Link>)) || '--'}</TableCell>
            <TableCell>{lessMoreFn(item.description)} </TableCell>
            {smbcInstance ? <TableCell>{item?.related_document || "--"}</TableCell> : null}
            <TableCell>{item?.applicability || '--'}</TableCell>
            <TableCell>{item.effectivity ? item.effectivity : "--"}</TableCell>
            {smbcInstance ? <TableCell>{item?.faa_effective_date || "--"}</TableCell> : null}
            {smbcInstance ? <TableCell>{item?.easa_effective_date || "--"}</TableCell> : null}
            {smbcInstance ? null : <TableCell>{item.ad_type ? item.ad_type : "--"}</TableCell>}
            {smbcInstance ? null : <TableCell>{item.effective_date ? moment(item.effective_date).format(fieldDateFormatShort) : "--"}  </TableCell>}
            {smbcInstance ? null : <TableCell>{item.dimension_type ? item.dimension_type : "--"}  </TableCell>}
            <TableCell>{item.ad_compliance_status ? item.ad_compliance_status : "--"} </TableCell>
            {smbcInstance ? null : <TableCell>{item.ad_compliance_tsn ? item.ad_compliance_tsn : "--"} </TableCell>}
            {smbcInstance ? null : <TableCell>{item.ad_compliance_csn ? item.ad_compliance_csn : "--"} </TableCell>}
            {smbcInstance ? null : <TableCell>{item.ad_compliance_date ? moment(item.ad_compliance_date).format(fieldDateFormatShort) : "--"}</TableCell>}
            <TableCell>{nextDueFormat(item)} </TableCell>
            {smbcInstance ? <TableCell>{item?.reference || "--"}</TableCell> : null}
            <TableCell >{lessMoreFn(item.remark)} </TableCell>
            <TableCell>
                {attachmentCount && (
                    <Tooltip title="View Attachments">
                        <span className="center">
                            <AttachFileIcon className="attach-icon" onClick={handleOpenAttachments} />
                            <a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
                        </span>
                    </Tooltip>
                )}
            </TableCell>
        </TableRow>
    )
}

export default withRouter(AdListing)