import { React, useState } from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import { fieldDateFormat } from "../../../../constants";
import RevisionHistory from '../../../../shared_elements/components/RevisionHistory';
import AttachFileIcon from "@material-ui/icons/AttachFile";
const AmpListing = ({ item, toggleModalFn, toggleDelteModalFn, handleOpenAttachments }) => {

	const [lessMore, setLessMore] = useState({});

	const toggleLessMore = (id) => {
		setLessMore({
			...lessMore,
			[id]: !lessMore[id],
		});
	};
	const lessMoreFn = (name) => {
		return <>
			{name && name.length > 22 ? (
				<>
					{lessMore[name] ?
						(<> {name} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..less </span> </>)
						:
						(<>{name.substring(0, 22)} <span style={{ color: "#0e7fe1" }} onClick={(e) => { e.preventDefault(); toggleLessMore(name); }}  > ..more </span> </>)
					}
				</>
			) : name || '--'
			}
		</>
	}
	function getAttachmentCount(item) {
        const { attachments, checklist } = item;
        const { files, folders } = checklist || {};
      
        const attachmentCount = attachments?.length || 0;
        const fileCount = files?.length || 0;
        const folderCount = folders?.length || 0;
      
        if (attachmentCount && fileCount && folderCount) {
          return attachmentCount + fileCount + folderCount;
        } else if (fileCount && folderCount) {
          return fileCount + folderCount;
        } else if (attachmentCount && folderCount) {
          return attachmentCount + folderCount;
        } else if (attachmentCount && fileCount) {
          return attachmentCount + fileCount;
        } else {
          return folderCount || fileCount || attachmentCount || '--';
        }
      }
    const attachmentCount = getAttachmentCount(item);
	let ampUrl = `audit/camo/ampcompliance/?object_id=${item.id}&action=0,1&timezone=${moment().format()}&timestamp=${Math.floor(Date.now() / 1000)}`

	return (
		<TableRow>
			<TableCell>
				<Tooltip title="Edit" placement='top'>
					<IconButton style={{ padding: 'unset' }} color='primary' onClick={toggleModalFn} > <CreateOutlinedIcon /> </IconButton>
				</Tooltip>
				<Tooltip title="Delete" placement='top' >
					<IconButton style={{ padding: 'unset' }} color='secondary' onClick={toggleDelteModalFn}> <DeleteOutlinedIcon /> </IconButton>
				</Tooltip>
				<RevisionHistory buttonType={true} url={ampUrl} />
			</TableCell>
			<TableCell align='center'>{item?.MPD_item_no || "--"}</TableCell>
			<TableCell >{item?.task_card_no || "--"}</TableCell>
			<TableCell>{item?.previous_LDND_Taskcard || "--"} </TableCell>
			<TableCell>{lessMoreFn(item.Task_Description)} </TableCell>
			<TableCell>{item?.threshold_D || "--"}</TableCell>
			<TableCell>{item?.threshold_FH || "--"}</TableCell>
			<TableCell>{item?.threshold_FC || "--"}</TableCell>
			<TableCell>{item?.interval_D || "--"}</TableCell>
			<TableCell>{item?.interval_FH || "--"}</TableCell>
			<TableCell>{item?.interval_FC || "--"}</TableCell>
			<TableCell>{item?.lastDone_D ? moment(item?.lastDone_D).format(fieldDateFormat) : "--"} </TableCell>
			<TableCell>{item?.lastDone_FH || "--"}</TableCell>
			<TableCell>{item?.lastDone_FC || "--"}</TableCell>
			<TableCell>{item?.nextDue_D ? moment(item?.nextDue_D).format(fieldDateFormat) : "--"} </TableCell>
			<TableCell>{item?.nextDue_FH || "--"}</TableCell>
			<TableCell>{item?.nextDue_FC || "--"}</TableCell>
			<TableCell>{item?.remainingValues_D || "--"} </TableCell>
			<TableCell>{item?.remainingValues_FH || "--"} </TableCell>
			<TableCell>{item?.remainingValues_FC || "--"} </TableCell>
			<TableCell style={{ width: "200px", textAlign: "left" }}> {item?.remarks || "--"} </TableCell>
			<TableCell>
                {attachmentCount &&(
                    <Tooltip title="View Attachments">
                        <span className="center">
                            <AttachFileIcon className="attach-icon" onClick={handleOpenAttachments} />
                            <a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
                        </span>
                    </Tooltip>
                )}
            </TableCell>
		</TableRow>
	)
}

export default withRouter(AmpListing)