import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
const InventoryHeader = ({ sortInfo, createSortHandler }) => {
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }
    return (
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
                <TableCell>Action </TableCell>
                <TableCell style={{ minWidth: "250px" }}>Section</TableCell>
                <TableCell style={{ minWidth: "250px" }} >{tableSorting('description', 'Description')} </TableCell>
                <TableCell>{tableSorting('iin', 'IIN')} </TableCell>
                <TableCell>{tableSorting('spec', 'SPEC')}  </TableCell>
                <TableCell style={{ minWidth: "220px" }}>{tableSorting('ipc_ppbu_fig_ref', 'IPC & PPBU FIG. / REF ')}  </TableCell>
                <TableCell>{tableSorting('quantity', 'Quantity')} </TableCell>
                <TableCell style={{ minWidth: "120px" }}>{tableSorting('part_number', 'Part Number')} </TableCell>
                <TableCell style={{ minWidth: "120px" }}>{tableSorting('serial_number', 'Serial Number')}  </TableCell>
                <TableCell style={{ minWidth: "120px" }}>{tableSorting('installed', 'Installed')} </TableCell>
                <TableCell>{tableSorting('tsn', 'TSN')} </TableCell>
                <TableCell>{tableSorting('csn', 'CSN')} </TableCell>
                <TableCell>{tableSorting('tso', 'TSO')} </TableCell>
                <TableCell>{tableSorting('cso', 'CSO')} </TableCell>
                <TableCell>{tableSorting('condition', 'Condition')} </TableCell>
                <TableCell>Attachments </TableCell>
            </TableRow>
        </TableHead>
    )
}

export default InventoryHeader