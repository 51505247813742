import AddEditSb from "./AddEditSb";
import SbListing from "./SbListing";
import SbHeader from "./SbHeader";
export {
    AddEditSb,
    SbListing,
    SbHeader
}

export const sbobjData={
	asset_id: null,
	asset_type_id: null,
	ata_chapter: '',
	sb_ref_number: '',
	sb_issued_by: null,
	sb_applicability: null,
	description: "",
	type: "ALERT",
	sb_category:null,
	effectivity: null,
	effectivity_date: null,
	sb_interval_type: "D",
	sb_interval: null,
	sb_compliance_status: "OPEN",
	sb_compliance_tsn: null,
	sb_compliance_csn: null,
	sb_compliance_date: null,
	service_bulletin: null,
	revision: null,
	revision_date: null,
	ad_no: null,
	subject: null,
	workorder: null,
	remark: "",
	last_done_date: null,
	last_done_fh: null,
	last_done_fc: null,
	sb_compliance_dfp: null,
	release_certificate: null,
	certificate_of_conformity: null,
	ad_due: null,
	remaining_val: null,
	attachments: [],
	dimension_interval_days: null,
	dimension_interval_fc: null,
	checklist: [],
	folder_uuid: '',
	linkFile:''
}