import React from 'react'
import { TableCell, TableRow, Tooltip, IconButton } from "@material-ui/core"
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { withRouter } from "react-router-dom";
import moment from 'moment';
import AttachFileIcon from "@material-ui/icons/AttachFile";
import RevisionHistory from '../../../../shared_elements/components/RevisionHistory';
const OpHistoryListing = ({ item, toggleModalFn, toggleDelteModalFn, handleOpenAttachments }) => {
	function getAttachmentCount(item) {
        const { attachments, checklist } = item;
        const { files, folders } = checklist || {};
      
        const attachmentCount = attachments?.length || 0;
        const fileCount = files?.length || 0;
        const folderCount = folders?.length || 0;
      
        if (attachmentCount && fileCount && folderCount) {
          return attachmentCount + fileCount + folderCount;
        } else if (fileCount && folderCount) {
          return fileCount + folderCount;
        } else if (attachmentCount && folderCount) {
          return attachmentCount + folderCount;
        } else if (attachmentCount && fileCount) {
          return attachmentCount + fileCount;
        } else {
          return folderCount || fileCount || attachmentCount || '--';
        }
      }
    const attachmentCount = getAttachmentCount(item);
	let opHistoryUrl = `audit/camo/operationhistory/?object_id=${item.id}&action=0,1&timezone=${moment().format()}&timestamp=${Math.floor(Date.now() / 1000)}`
	return (
		<TableRow>
			<TableCell>
				<Tooltip title="Edit" placement='top'>
					<IconButton style={{ padding: 'unset' }} color='primary' onClick={toggleModalFn} > <CreateOutlinedIcon /> </IconButton>
				</Tooltip>
				<Tooltip title="Delete" placement='top' >
					<IconButton style={{ padding: 'unset' }} color='secondary' onClick={toggleDelteModalFn}> <DeleteOutlinedIcon /> </IconButton>
				</Tooltip>
				<RevisionHistory buttonType={true} url={opHistoryUrl} />
			</TableCell>
			<TableCell>{item.fitment_date || "--"}</TableCell>
			<TableCell>{item.fitment_operator ? item.fitment_operator?.name : "--"}</TableCell>
			<TableCell>{item.fitment_aircraft ? item.fitment_aircraft : "--"} </TableCell>
			<TableCell>{item.fitment_tail ? item.fitment_tail : "--"}</TableCell>
			<TableCell>{item.fitment_psn ? item.fitment_psn?.label : "--"}</TableCell>
			<TableCell>{item?.fitment_thrust?.name || "--"}</TableCell>
			<TableCell>{item.fitment_aircraft_flying_hours || '--'}</TableCell>
			<TableCell>{item.fitment_aircraft_flying_cycle}</TableCell>
			<TableCell>{typeof item.fitment_engine_tsn === 'string' && item.fitment_engine_tsn.length > 0 ? parseFloat(item.fitment_engine_tsn).toFixed(2) : '--'}</TableCell>
			<TableCell>{item.fitment_engine_csn}</TableCell>
			<TableCell>{item.removal_date ? item.removal_date : "--"}</TableCell>
			<TableCell>{item.removal_aircraft_flying_hours || '--'}</TableCell>
			<TableCell>{item.removal_aircraft_flying_cycle}</TableCell>
			<TableCell>{typeof item.removal_engine_tsn === 'string' && item.removal_engine_tsn.length > 0 ? parseFloat(item.removal_engine_tsn).toFixed(2) : '--'}</TableCell>
			<TableCell>{item.removal_engine_csn}</TableCell>
			<TableCell>{item.removal_tslsv || '--'}</TableCell>
			<TableCell>{item.removal_cslsv}</TableCell>
			<TableCell>{item.removal_hours_used || '--'}</TableCell>
			<TableCell>{item.removal_cycle_used}</TableCell>
			<TableCell>{item.removal_egtm ? item.removal_egtm : "--"}</TableCell>
			<TableCell>{item.removal_event ? item.removal_event : "--"}</TableCell>
			<TableCell>{item.maint_effective_work_carried_out ? item.maint_effective_work_carried_out : "--"}</TableCell>
			<TableCell>{item.maint_shop ? item.maint_shop : "--"}</TableCell>
			<TableCell>
                {attachmentCount &&(
                    <Tooltip title="View Attachments">
                        <span className="center">
                            <AttachFileIcon className="attach-icon" onClick={handleOpenAttachments} />
                            <a href="#" style={{ color: "#0e7fe1" }}>{attachmentCount}</a>
                        </span>
                    </Tooltip>
                )}
            </TableCell>
		</TableRow>
	)
}

export default withRouter(OpHistoryListing)