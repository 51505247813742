import AddEditLLP from "./AddEditLLP";
import LLPListing from "./LLPListing";
import LLPHeader from "./LLPHeader";
export {
    AddEditLLP,
    LLPListing,
    LLPHeader
}

export const llpObj = {
    asset_id: '',
    asset_type_id: '',
    module_type: '',
    part_number: '',
    serial_number: '',
    description: '',
    tsn: '',
    csn: '',
    remarks: '',
    attachments: [],
    checklist: [],
    folder_uuid: '',
    linkFile: '',
    COOTR_thrust_a: '',
    COOTR_thrust_b: '',
    COOTR_thrust_c: '',
    COOTR_thrust_d: '',
    COOTR_thrust_e: '',
    life_limit_thrust_a: '',
    life_limit_thrust_b: '',
    life_limit_thrust_c: '',
    life_limit_thrust_d: '',
    life_limit_thrust_e: '',
}