import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
import React, { Fragment } from "react"
const SoftTimeHeader = ({ sortInfo, createSortHandler }) => {
	const tableSorting = (key, name) => {
		return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
	}
	return (
		<TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
			<TableRow>
				<TableCell>Action </TableCell>
				<TableCell style={{ minWidth: "150px" }}>{tableSorting('part_number', 'Part Number')} </TableCell>
				<TableCell style={{ minWidth: "150px" }}>{tableSorting('serial_number', 'Serial Number')}</TableCell>
				<TableCell style={{ minWidth: "280px" }}>{tableSorting('description', 'Description')}</TableCell>
				<TableCell>{tableSorting('life_limit', 'Life Limit')}</TableCell>
				<TableCell>{tableSorting('tsn', 'TSN')} </TableCell>
				<TableCell>{tableSorting('csn', 'CSN')}</TableCell>
				<TableCell style={{ minWidth: "150px" }}>Life Remaining</TableCell>
				<TableCell style={{ minWidth: "280px" }}>{tableSorting('remarks', 'Remarks')} </TableCell>
				<TableCell>Attachments </TableCell>
			</TableRow>
		</TableHead>
	)
}

export default SoftTimeHeader