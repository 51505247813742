import React, { Fragment } from "react"
import { withRouter } from 'react-router-dom/cjs/react-router-dom';
import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core"
const HtHeader = ({ match, createSortHandler, sortInfo }) => {
    const tableSorting = (key, name) => {
        return <TableSortLabel active={sortInfo.sort === key ? true : false} direction={sortInfo.sort === key ? sortInfo.sort_by : 'asc'} onClick={() => createSortHandler(key)} > {name}</TableSortLabel>
    }
    return (
        <TableHead style={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
                <TableCell colSpan={11} align="center"></TableCell>
                <TableCell colSpan={3} align="center">Dimension</TableCell>
                <TableCell colSpan={3} align="center">Next Due</TableCell>
                <TableCell colSpan={3} align="center">Life Remaining</TableCell>
                <TableCell colSpan={2} align="center"></TableCell>
            </TableRow>
            <TableRow>
                <TableCell>Action </TableCell>
                <TableCell style={{ minWidth: "150px" }}> {tableSorting('ata_chapter', 'ATA Chapter')} </TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('part_number', 'Part Number')}  </TableCell>
                <TableCell style={{ minWidth: "150px" }}> {tableSorting('part_serial_number', 'Part Serial Number')} </TableCell>
                <TableCell style={{ minWidth: "250px" }}> {tableSorting('part_description', 'Part Description')} </TableCell>
                <TableCell style={{ minWidth: "200px" }}> {tableSorting('part_installation_date', 'Part Installation Date')} </TableCell>
                <TableCell style={{ minWidth: "200px" }}>{tableSorting('aircraft_tsn_installation', match?.params?.assetType == 2  ?  'Engine TSN at Installation'  : 'Aircraft TSN at Installation')} </TableCell>
                <TableCell style={{ minWidth: "200px" }}>{tableSorting('aircraft_csn_installation', match?.params?.assetType == 2  ?   'Engine CSN at Installation' : 'Aircraft CSN at Installation')} </TableCell>
                <TableCell style={{ minWidth: "200px" }}> {tableSorting('part_tsn_installation', 'Part TSN at Installation')} </TableCell>
                <TableCell style={{ minWidth: "200px" }}> {tableSorting('part_csn_installation', 'Part CSN at Installation')} </TableCell>
                <TableCell style={{ minWidth: "150px" }}>{tableSorting('ht_requirement', 'HT Requirement')} </TableCell>
                <TableCell>Days </TableCell>
                <TableCell>FH </TableCell>
                <TableCell>FC </TableCell>
                <TableCell>Date</TableCell>
                <TableCell>FH</TableCell>
                <TableCell>FC </TableCell>
                <TableCell> Days</TableCell>
                <TableCell>FH </TableCell>
                <TableCell>FC </TableCell>
                <TableCell style={{ minWidth: "250px" }}> {tableSorting('remarks', 'Remarks')}  </TableCell>
                <TableCell>Attachments </TableCell>
            </TableRow>
        </TableHead>
    )
}
export default withRouter(HtHeader)